import React, { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import "../../assets/css/login.css"
import authLayout from "../../hoc/authLayout";
import { postApi } from '../../services/apiCommonService'
import { create_password_api_url } from '../../services/endPointsConstant'
import { isEmpty } from '../../Utils/utils'

const VerifyYourAccount = () => {
  const { role } = window.sessionStorage.getItem('singup') ? JSON.parse(window.sessionStorage.getItem('singup')) : {}
  const [formData, setFormData] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confShowPassword, setConfShowPassword] = useState(false);
  let navigate = useNavigate();
  const params = useParams();

  const {
    register,
    formState: { errors, isDirty, isSubmitting, touched },
    handleSubmit,
    getValues
  } = useForm({
    mode: "onBlur",
    shouldFocusError: false
  });


  const errorHandler = () => {
    if (!isEmpty(errors)) {
      toast.error("Fill all manditatory fields")
      return
    }
  }

  const onSubmit = async (data) => {
    setFormData(data);

    const apiReqBody = {
      userid: params?.id,
      password: data?.password,
      otp: data?.securitycode
    }

    const apiResponse = await postApi(create_password_api_url, apiReqBody)
    if (apiResponse?.data) {
      toast.success("Password updated successfully")
      if (apiResponse?.data?.userData?.role === 'ADMIN') {
        navigate('/admin-login/665e00c8ae3f8bee10e638df');
      } else if (apiResponse?.data?.userData?.role === 'ACTMGR') {
        navigate('/act-manager-login');
      } else if (apiResponse?.data?.userData?.role === 'EMPLOYEE') {
        navigate('/employee-login')
      } else {
        navigate('/login');
      }
    } else {
      toast.error(apiResponse?.response?.data.message)
    }

  };

  return (
    <div className="form-holder login-form vh-100 rounded-0">
      <div className="d-flex justify-content-center align-items-center my-4 mt-5">
        <img src={require('./../../assets/images/login_page_logo.svg').default} alt="Alt content" />
      </div>
      {!isMobile ?
        <div className="justify-content-center align-items-center my-4 mt-5">
          <h1 className="text-center fw-normal mb-1 mt-3 pt-3 pb-0">Please verify your</h1>
          <h1 className="text-center fw-normal mb-5 mt-0 me-3">email address & set password</h1>
        </div> :
        <div className="justify-content-center align-items-center my-4 mt-4 mb-5">
          <div className="col-md-5">
            <h1 className="text-center fw-normal mb-0.5 mt-4 pt-3 pb-0">Please verify your</h1>
          </div>
          <div className="col-md-5">
            <h1 className="text-center fw-normal mb-5 mt-0">email address & set password</h1>
          </div>
        </div>
      }

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`form-outline did-floating-label-content ${errors.securitycode && 'did-error-input'}`}>
          <input
            type="text"
            name="securitycode"
            placeholder=""
            {...register("securitycode", {
              required: true,
              minLength: {
                value: 2,
                message: "Min length is 2"
              },
              pattern: {
                value: /^[a-zA-Z 0-9]+$/,
                message: "Allow only alphabets"
              }
            })}
            className="did-floating-input"
          />
          <label className="did-floating-label requiredTrue">{'Enter 4 digit code'}</label>
          {errors.securitycode && <p className="error">{errors.securitycode.message}</p>}
        </div>

        <div className={`form-outline did-floating-label-content ${errors.password && 'did-error-input'}`}>
          <input
            type={showPassword ? 'text' : "password"}
            name="password"
            className="did-floating-input"
            placeholder=""
            {...register("password", {
              required: true,
              minLength: {
                value: 6,
                message: "Min length is 6"
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
              },
            })}
          />
          {showPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye" onClick={() => { setShowPassword(!showPassword) }}></span>}
          {!showPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye" onClick={() => { setShowPassword(!showPassword) }}></span>}
          <label className="did-floating-label requiredTrue">{'Enter the password'}</label>
          {errors.password && <p className="error">{errors.password.message}</p>}
        </div>

        <div className={`form-outline did-floating-label-content ${errors.confirmPassword && 'did-error-input'}`}>
          <input
            type={confShowPassword ? 'text' : "password"}
            name="confirmPassword"
            className="did-floating-input"
            placeholder=""

            {...register("confirmPassword", {
              validate: (value) => {
                const { password } = getValues();
                return password === value || "Passwords should match!";
              },
              required: true,
            })}
          />
          {confShowPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye" onClick={() => { setConfShowPassword(!confShowPassword) }}></span>}
          {!confShowPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye" onClick={() => { setConfShowPassword(!confShowPassword) }}></span>}
          <label className="did-floating-label requiredTrue">{'Enter the confirmPassword'}</label>
          {errors.confirmPassword && <p className="error">{errors.confirmPassword.message}</p>}
        </div>

        <button type="submit" disabled={isSubmitting} className="w-100 rounded-2" onClick={errorHandler}>
          {isSubmitting && <span>Submitting</span>}
          {!isSubmitting && <span>Reset password & Login</span>}
        </button>

        <div className="d-flex justify-content-center align-items-center mb-5">
          <span className="d-flex text-center text-lg-start justify-content-center align-items-center">
            {!isMobile ?
              <p className="small fw-bold">
                <Link to="/help-us" >Trouble in Login ?</Link>
                <strong className="fontColor"><Link to="/help-us" > Contact Support.</Link> </strong>
                <div className="vr me-2 ms-2 mt-0 fontColor" style={{ height: 16, width: 4 }}></div>
                <Link to="/login" className="fontColor me-2"><strong> Back to Login</strong></Link>
              </p> :
              <>
                <p className="small fw-bold">
                  <Link to="/help-us" >Trouble in Login ?</Link>
                  <strong className="fontColor"><Link to="/help-us" > Contact Support.</Link> </strong>
                  <div className="vr me-2 ms-2 mt-0 fontColor" style={{ height: 16, width: 4 }}></div>
                  <Link to="/login" className="fontColor me-2"><strong> Back to Login</strong></Link>
                </p>
              </>
            }
          </span>
        </div>
      </form>
    </div>
  );
}

export default authLayout(VerifyYourAccount);