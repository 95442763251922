import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../../hoc/adminLayout";
import Pagination from '../../../common/pagination/Pagination';
import { returnArryOfObjects } from '../../../Utils/utils'
import { emptyJobList, PageSize } from '../../../Utils/masterData/staticContent'
import { getAllApi, deleteApiWithBody, updateWithOutParamsApi, postApi } from '../../../services/apiCommonService'
import { get_all_companies_list_api_url, delete_offer_api_url, get_acct_manager_id } from '../../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import CustomSingleSelect from '../../../components/CustomSelectComponent';

const AssignedCompaniesList = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { selectedManagerDetails, isViewAssignCompanyList, assignedCmpyListStateValue, viewSelectedAccountManagerDetails, isCheckboxOff, AddSelectedAccountManagerDetails, viewSendSelectedAccountManagerDetails, isSelectedNew } = props
    const [currentPage, setCurrentPage] = useState(1);
    const [allCompaniesList, setAllCompaniesList] = useState([]);
    const [allCompaniesOldList, setAllCompaniesOldList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedCompanyObjects, setSelectedCompanyObjects] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [viewSelectedCompanyDetails, setViewSelectedCompanyDetails] = useState('')
    const [pageSize, setPageSize] = useState(PageSize);

    useEffect(() => {
        if (selectedManagerDetails?.totalCompanyAssigned?.length > 0) {
            dispatch(addLoader());
            setAllCompaniesList(selectedManagerDetails?.totalCompanyAssigned)
            setAllCompaniesOldList(selectedManagerDetails?.totalCompanyAssigned)
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        }
        if (selectedManagerDetails?.totalCompanyAssigned?.length === 0) {
            dispatch(addLoader());
            setAllCompaniesList(selectedManagerDetails?.totalCompanyAssigned)
            setAllCompaniesOldList(selectedManagerDetails?.totalCompanyAssigned)
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        }

        if (assignedCmpyListStateValue && assignedCmpyListStateValue?.length > 0) {
            dispatch(addLoader());
            setAllCompaniesList(
                window.sessionStorage.getItem('selectedCompanyListObjects')
                    ?
                    JSON.parse(window.sessionStorage.getItem('selectedCompanyListObjects'))
                    :
                    assignedCmpyListStateValue
            )
            setAllCompaniesOldList(
                window.sessionStorage.getItem('selectedCompanyListObjects')
                    ?
                    JSON.parse(window.sessionStorage.getItem('selectedCompanyListObjects'))
                    :
                    assignedCmpyListStateValue
            )
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        }

        if (assignedCmpyListStateValue && assignedCmpyListStateValue?.length === 0) {
            dispatch(addLoader());
            setAllCompaniesList(assignedCmpyListStateValue)
            setAllCompaniesOldList(assignedCmpyListStateValue)
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        }
    }, [selectedManagerDetails, assignedCmpyListStateValue]);

    useEffect(() => {
        if (viewSelectedAccountManagerDetails?.length > 0) {
            setSelectedCompany(viewSelectedAccountManagerDetails.map(({ _id }) => _id));
            callCompaniesData(viewSelectedAccountManagerDetails.map(({ _id }) => _id))
        }
    }, [viewSelectedAccountManagerDetails])

    useEffect(() => {
        if (AddSelectedAccountManagerDetails?.length > 0) {
            setSelectedCompany(AddSelectedAccountManagerDetails.map(({ _id }) => _id));
            // callCompaniesData(AddSelectedAccountManagerDetails.map(({ _id }) => _id))
        }
    }, [AddSelectedAccountManagerDetails])

    useEffect(() => {
        // console.log("=======hju===",
        //     // window.localStorage.getItem('selectedCompanyListObjects'),
        //     window.localStorage.getItem('selectedCompanyListValues'),
        //     selectedCompany
        // )
        let extractList = window.sessionStorage.getItem('selectedCompanyListValues')
            ?
            JSON.parse(window.sessionStorage.getItem('selectedCompanyListValues'))
            :
            []
        if (extractList?.length > 0) {
            setSelectedCompany(extractList);
        } else if (viewSendSelectedAccountManagerDetails?.length > 0) {
            setSelectedCompany(viewSendSelectedAccountManagerDetails?.map(({ _id }) => _id));
            // callCompaniesData(AddSelectedAccountManagerDetails.map(({ _id }) => _id))
        }
    }, [viewSendSelectedAccountManagerDetails])

    useEffect(() => {
        void getAllCompaniesListApi()
    }, [])

    const getAllCompaniesListApi = async () => {
        if (!isViewAssignCompanyList) {
            dispatch(addLoader());
            const apiResponse = await getAllApi(get_all_companies_list_api_url)
            if (apiResponse?.data?.Data) {
                const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
                setAllCompaniesList(apiResponseData)
                setAllCompaniesOldList(apiResponseData)
                // toast.success("Retrived all Companies List Successfully !!")
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 2500);
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        }
    }

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.label;
        const lastPageIndex = firstPageIndex + pageSize?.label;
        const finalResult = allCompaniesList?.toSorted((a, b) => {
            return selectedCompany?.indexOf(b?._id) - selectedCompany?.indexOf(a?._id)
        });
        return finalResult.slice(firstPageIndex, lastPageIndex);
    };

    const callCompaniesData = (ids) => {
        // props.returnAssignedCmpyList(ids)
        const finalFilteredList = returnArryOfObjects(allCompaniesList, ids, '_id')
        setSelectedCompanyObjects(finalFilteredList)
        props?.returnAssignedCmpyList(ids, finalFilteredList)
    }

    const selectAll = () => {
        setSelectedCompany([]);
        if (!isAllSelected) {
            allCompaniesList.forEach(jobData => {
                setSelectedCompany(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            });
            callCompaniesData(allCompaniesList.map((item) => item._id))
        }
        if (isAllSelected) {
            callCompaniesData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const companySelectHandler = (jobData) => {
        if (selectedCompany.includes(jobData._id)) {
            setSelectedCompany(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData._id));
            callCompaniesData(selectedCompany.filter(_id => _id !== jobData._id))
            const finalFilteredList = returnArryOfObjects(allCompaniesList, selectedCompany.filter(_id => _id !== jobData._id), '_id')
            window.sessionStorage.setItem('selectedCompanyListObjects', JSON.stringify(finalFilteredList))
            window.sessionStorage.setItem('selectedCompanyListValues', JSON.stringify(selectedCompany.filter(_id => _id !== jobData._id)))
        } else {
            setSelectedCompany(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);//...prevSelectedUsers,
            callCompaniesData([...selectedCompany, jobData._id])
            const finalFilteredList = returnArryOfObjects(allCompaniesList, [...selectedCompany, jobData._id], '_id')
            window.sessionStorage.setItem('selectedCompanyListObjects', JSON.stringify(finalFilteredList))
            window.sessionStorage.setItem('selectedCompanyListValues', JSON.stringify([...selectedCompany, jobData._id]))
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedCompany.includes(jobData._id);
        return isUserSelectedVal
    };

    const isAllUsersSelected = () => {
        return selectedCompany.length === allCompaniesList.length;
    };

    const filterTextSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = allCompaniesOldList.filter((jobData) => {
                return jobData?.companyName?.toLowerCase().startsWith(keyword.toLowerCase())
                // jobData?.contactName?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                // jobData?.skill?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                // jobData?.positionDate?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                // jobData?.office_type?.toLowerCase().startsWith(keyword.toLowerCase())
            });
            setAllCompaniesList(results);
        } else {
            setAllCompaniesList(allCompaniesOldList);
        }
        setKeywordName(keyword);
    };

    const onChangeHandler = (event) => {
        setPageSize(event)
    }

    const onClickAssignCmpyHandler = () => {
        props.onClickAssignCmpyHandlerFunProp()
    }

    const onClickUnAssignCmpyHandler = () => {
        // selectedCompany selectedCompanyObjects
        props.onClickUnAssignCmpyHandlerFunProp(selectedCompany, selectedCompanyObjects, allCompaniesList, selectedManagerDetails)
    }

    return <>
        <div>
            {allCompaniesOldList.length > 0 &&
                <>
                    <div className="mt-0 mb-0 pe-2">
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <div className="col-md-4 d-flex justify-content-start align-items-center gap-2 ps-2">
                                {props.isAssign &&
                                    <button
                                        type="button"
                                        className="btn btn-primary btn_background_color pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                        onClick={onClickAssignCmpyHandler}
                                    >
                                        Assign Companies
                                    </button>
                                }
                                {props.isUnAssign !== false &&
                                    <button
                                        type="button"
                                        className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                        onClick={() => { onClickUnAssignCmpyHandler() }}
                                    >
                                        Unassign
                                    </button>
                                }
                                <span>Selected Companies list : (<span className='orange_color'>{selectedCompany?.length || selectedManagerDetails?.totalCompanyAssigned?.length}</span>)</span>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="search"
                                    value={keywordName}
                                    onChange={filterTextSearch}
                                    className="searchInputCls pb-1"
                                    placeholder="Search..."
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row bd-example">
                            <div className="table-container pt-0 mt-1">
                                <div className="d-flex text-muted height_popup_cmp_list_table">
                                    <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-0">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    {!isCheckboxOff && <th>
                                                        {/* <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} /> */}
                                                    </th>
                                                    }
                                                    {['Company', 'Company type', 'Total Resource', 'Location', 'Company Email', 'Registered Date'].map((item, idx) =>
                                                        <th key={idx} className={`text-white z_index`}>
                                                            {item}
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData().map((item, idx) => {
                                                    return (
                                                        <tr key={item._id}>
                                                            {!isCheckboxOff && <td>
                                                                <input
                                                                    className="width_12"
                                                                    type="checkbox"
                                                                    checked={isUserSelected(item)}
                                                                    onChange={() => companySelectHandler(item)}
                                                                />
                                                            </td>
                                                            }
                                                            {/* <td>
                                                                <img src={item?.logo} alt="logo" className='img_w_h_23' />
                                                            </td> */}
                                                            <td className='fw-bolder'>{item?.companyName}</td>
                                                            <td>{item?.companyType}</td>
                                                            <td>{item?.totalAssociatesCount || 0}</td>
                                                            <td>{item?.location}</td>
                                                            <td>{item?.companyEmail}</td>
                                                            <td>
                                                                {new Date(item.createdAt)?.toLocaleString() || '--'}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </PerfectScrollbar>
                                </div>
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <div className="d-flex justify-content-between align-items-baseline gap-2">
                                        <div>Items per page</div>
                                        <CustomSingleSelect
                                            options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                                            selectedPage={pageSize}
                                            onChangeHandler={onChangeHandler}
                                            disabled={allCompaniesList?.length <= 10}
                                        />
                                        <div>{currentPage}-{pageSize?.label} of {allCompaniesList.length} items</div>
                                    </div>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={allCompaniesList.length}
                                        pageSize={pageSize?.label}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {allCompaniesOldList.length === 0 &&
                <>
                    <div className="row bd-example">
                        <div className="table-container">
                            <div className="text_v_h_center vh-30 font_size_24">
                                No Data Found
                            </div>
                        </div>
                    </div>
                </>
            }
        </div >
        <button className="d-none mt-0 mb-0 pb-0" id="ViewAssignedCompanyDetailsIds" data-bs-dismiss="modal"></button>
    </>
}

export default AssignedCompaniesList;