import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../../hoc/adminLayout";
import Pagination from '../../../common/pagination/Pagination';
import { returnArryOfObjects } from '../../../Utils/utils'
import { emptyJobList, PageSize } from '../../../Utils/masterData/staticContent'
import ModalComponent from "../../../components/ModalComponent";
import AddAccountManager from "../../adminComponents/accountManagers/AddAccountManager";
import EditAccountManager from '../../adminComponents/accountManagers/EditAccountManager'
import CustomSingleSelect from '../../../components/CustomSelectComponent';
import ViewAccountManagerDetails from "./ViewAccountManagerDetails";
import { getAllApi, postApi, deleteApiWithBody, updateWithOutParamsApi } from '../../../services/apiCommonService'
import { get_all_account_managers, delete_manager_list_api_url, get_CandidateOf_ActMgr, assign_acct_mgr_api_url, edit_company_details } from '../../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import AssignedCompaniesList from './AssignCompaniesList'
import AssignedCandidatesListComponent from './AssignedCandidatesList'

const AccountManager = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [accountManagerList, setAccountManagerList] = useState([]);
    const [oldAccountManagerList, setOldAccountManagerList] = useState([]);
    const [selectedAccountManager, setSelectedAccountManager] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedAccountManagerObject, setSelectedAccountManagerObject] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [viewSelectedAccountManagerDetails, setViewSelectedAccountManagerDetails] = useState('')
    const [viewSelectedCandidatesDetails, setViewSelectedCandidatesDetails] = useState('')
    const [modalPopUpCancel, setModalPopUpCancel] = useState(false);
    const [oldCandidatesOfActMgrList, setOldCandidatesOfActMgrList] = useState([]);
    const [candidatesOfActMgrList, setCandidatesOfActMgrList] = useState([]);
    const [pageSize, setPageSize] = useState(PageSize);

    useEffect(() => {
        void getAccountManagerListApi()
    }, []);

    const getAccountManagerListApi = async () => {
        dispatch(addLoader());
        const apiReqBody = {}
        const apiResponse = await postApi(get_all_account_managers, apiReqBody)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            setAccountManagerList(apiResponseData)
            setOldAccountManagerList(apiResponseData)
            // toast.success("Retrived all Manager List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.value;
        const lastPageIndex = firstPageIndex + pageSize?.value;
        return accountManagerList.slice(firstPageIndex, lastPageIndex);
    };

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(accountManagerList, ids, 'actManagerId')
        setSelectedAccountManagerObject(finalUsersData)
    }

    const selectAll = () => {
        setSelectedAccountManager([]);
        if (!isAllSelected) {
            accountManagerList.forEach(jobData => {
                setSelectedAccountManager(prevSelectedUsers => [...prevSelectedUsers, jobData.actManagerId]);
            });
            callUsersData(accountManagerList.map((item) => item.actManagerId))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const handleUserSelect = (jobData) => {
        if (selectedAccountManager.includes(jobData.actManagerId)) {
            setSelectedAccountManager(prevSelectedUsers => prevSelectedUsers.filter(actManagerId => actManagerId !== jobData.actManagerId));
            callUsersData(selectedAccountManager.filter(actManagerId => actManagerId !== jobData.actManagerId))
        } else {
            setSelectedAccountManager(prevSelectedUsers => [...prevSelectedUsers, jobData.actManagerId]);
            callUsersData([...selectedAccountManager, jobData.actManagerId])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedAccountManager.includes(jobData.actManagerId);
        return isUserSelectedVal
    };

    const isAllUsersSelected = () => {
        return selectedAccountManager.length === accountManagerList.length;
    };

    const filterTextSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = oldAccountManagerList.filter((jobData) => {
                return jobData?.name?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.phoneNumber?.toString()?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.location?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.totalEmployeeAssigned?.toString()?.startsWith(keyword) ||
                    jobData?.emailId?.toLowerCase().startsWith(keyword.toLowerCase())
            });
            setAccountManagerList(results);
        } else {
            setAccountManagerList(oldAccountManagerList);
        }
        setKeywordName(keyword);
    };

    const viewSelectedAccountManagerDetailsFunForCandidates = (item, idx) => {
        selectedRecordDataFun(item)
        setViewSelectedCandidatesDetails({ pageData: item, idxValue: idx })
    }

    const viewSelectedAccountManagerDetailsFun = (item, idx) => {
        selectedRecordDataFun(item)
        setViewSelectedAccountManagerDetails({ pageData: item, idxValue: idx })
    }

    const returnAddNewRequirementFormDetailsFun = (formDetails) => {
        void getAccountManagerListApi()
        setAccountManagerList([...accountManagerList, formDetails])
    }

    const returnEditJobRequirementFormDetailsFun = (formDetails) => {
        void getAccountManagerListApi()
        setAccountManagerList([...accountManagerList, formDetails])
    }

    const returnAssignedCmpyListFun = (assignedCmpyList, object) => {

    }

    const onClickUnAssignCmpyHandlerFunProp = async (selectedCompanyData, selectedCompanyObjectsData, allCompaniesListData, selectedManagerDetailsData) => {
        dispatch(addLoader());
        let allCompaniesIds = allCompaniesListData.map(({ _id }) => _id)
        allCompaniesIds = allCompaniesIds.filter((id) => selectedCompanyData.includes(id))
        const assignedCmpyListStateValue = allCompaniesIds
        const apiReqBody = {
            companyId: assignedCmpyListStateValue[0],
            actManagerId: null,//selectedManagerDetailsData.actManagerId,
            userid: selectedManagerDetailsData?.userid
        }
        const apiResponse = await updateWithOutParamsApi(edit_company_details, apiReqBody)
        if (apiResponse?.data?.message) {
            toast.success("Successfully unAssigned!!")
            void getAccountManagerListApi()
            document.getElementById("ViewAssignedCompanyDetailsIds").click();
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.data.message || "Api Error Response !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        }
    }

    const addAccountManagerModalContent = (props) => {
        return <>
            <AddAccountManager
                modalPopUpCancel={modalPopUpCancel}
                returnAddAccountManagerFormDetails={returnAddNewRequirementFormDetailsFun}
            />
        </>;
    }

    const editAccountManagerModalContent = () => {
        return <>
            <EditAccountManager
                modalPopUpCancel={modalPopUpCancel}
                selectedAccountManagerObject={selectedAccountManagerObject}
                returnEditJobRequirementFormDetails={returnEditJobRequirementFormDetailsFun}
                returnAddAccountManagerFormDetails={returnAddNewRequirementFormDetailsFun}
            />
        </>;
    }

    const ViewAccountManagerDetailsModalBodyFun = (props) => {
        return <>
            <ViewAccountManagerDetails
                viewSelectedAccountManagerDetails={viewSelectedAccountManagerDetails || undefined}
                accountManagerList={accountManagerList}
                selectedRecordDataFun={selectedRecordDataFun}
            />
        </>;
    }

    const editAccountManagerModal = () => {
        // toast.info("Edit job Modal Popup is Opened !!")
    }

    const cancelPopUpFun = (vale) => {
        setModalPopUpCancel(vale)
        document.location.reload();
    }

    const selectedRecordDataFun = (selected_job_data) => {
        if (selected_job_data) {
            setSelectedAccountManager([selected_job_data?.actManagerId]);
            setSelectedAccountManagerObject([selected_job_data])
        }
    }

    const cancelModalFun = () => {
        document.location.reload();
    }

    const onSubmit = async () => {
        const apiReqBody = {
            'acctManagerId': selectedAccountManager.length > 0 && selectedAccountManager
        }
        const apiResponse = await deleteApiWithBody(delete_manager_list_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success(apiResponse?.data?.message + ' !!')
            void getAccountManagerListApi()
            document.location.reload();
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const deleteModalContent = () => {
        return <>
            <ul>
                {selectedAccountManager.length > 0 && selectedAccountManagerObject?.map((item, idx) =>
                    <li key={idx}>
                        {item?.name}
                    </li>
                )}
            </ul>
        </>;
    }

    const deleteModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={onSubmit}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={cancelModalFun}
            >
                Cancel
            </button>
        </>;
    }

    const deleteFun = () => {
        // toast.info("Delete Confirmation Modal Popup is Opened !!")
    }

    const onChangeHandler = (event) => {
        setPageSize(event)
    }

    const ViewAssignedCompanyDetailsModalBodyFun = () => {
        return (
            <div className="form-holder pt-4 ps-8 pe-8 pb-0 main_bg rounded-0">
                <AssignedCompaniesList
                    selectedManagerDetails={viewSelectedAccountManagerDetails?.pageData}
                    isViewAssignCompanyList={true}
                    onClickUnAssignCmpyHandlerFunProp={onClickUnAssignCmpyHandlerFunProp}
                    returnAssignedCmpyList={returnAssignedCmpyListFun}
                    isUnAssign={false}
                    isCheckboxOff={true}
                />
            </div>
        )
    }

    const ViewAssignedCandidatesDetailsModalBodyFun = () => {
        return (
            <div className="form-holder pt-4 ps-8 pe-8 pb-0 main_bg rounded-0">
                <AssignedCandidatesListComponent
                    selectedManagerDetails={viewSelectedCandidatesDetails?.pageData}
                />
            </div>
        )
    }

    return <>
        <div className="my-1 p-3 bg-body rounded shadow-sm rounded_20">
            <div className="d-flex justify-content-between align-items-center border-bottom pt-2">
                <div className="col-xl-4 col-sm-6 ps-3">
                    <h6 className="font_color_imp font_size_16 fw-bolded">List of AccountManagers</h6>
                </div>
                <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                    <>
                        <button
                            type="button"
                            className="btn btn-primary btn_background_color pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                            data-bs-toggle="modal"
                            data-bs-target="#addAccountManager"
                        >
                            Add Manager
                        </button>
                        {oldAccountManagerList.length > 0 &&
                            <>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                                    disabled={selectedAccountManager.length > 0 && selectedAccountManager.length < 2 ? false : true}
                                    data-bs-target="#EditAccountManagerDetailsId"
                                    data-bs-toggle="modal"
                                    onClick={editAccountManagerModal}
                                >
                                    Edit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                    disabled={selectedAccountManager.length > 0 ? false : true}
                                    data-bs-target="#deleteManagerDetailsModalID"
                                    data-bs-toggle="modal"
                                    onClick={deleteFun}
                                >
                                    Delete
                                </button>
                            </>
                        }
                    </>
                </div>
            </div>
            {oldAccountManagerList.length > 0 &&
                <>
                    <div className="mt-3 mb-0 pe-2">
                        <div className="col-md-12 d-flex justify-content-between align-items-center gap-4">
                            <div className="col-md-4 d-flex justify-content-start align-items-baseline gap-1 ms-2 margin_bottom_20">
                                <span><b>Total Account Manges list : </b>(<span className='orange_color'><b>{oldAccountManagerList?.length}</b></span>)</span>
                                {/* <button className="border-0 mt-0 mb-0 pb-0 bg-white">
                                    <img src={require('../../../assets/images/vector_filter.svg').default} alt="Alt content" />
                                </button> */}
                                {/* <div className="col-md-8"> */}
                                {/* <RangeDatePicker /> */}
                                {/* </div> */}
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="search"
                                    value={keywordName}
                                    onChange={filterTextSearch}
                                    className="searchInputCls"
                                    placeholder="Search company & account M..."
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row bd-example">
                            <div className="table-container">
                                <div className="d-flex text-muted height_job_post_table">
                                    <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                    </th>
                                                    {['Company Name', 'Name', 'Total Resource', 'Companies Assigned', 'Phone Number', 'Contact email', 'Location', 'View Details'].
                                                        map((item, idx) =>
                                                            <th key={idx} className={`text-white`}>
                                                                {item}
                                                            </th>
                                                        )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData().map((item, idx) => {
                                                    return (
                                                        <tr key={item.actManagerId}>
                                                            <td>
                                                                <input
                                                                    className="width_12"
                                                                    type="checkbox"
                                                                    checked={isUserSelected(item)}
                                                                    onChange={() => handleUserSelect(item)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className='text_ellipse_100'>
                                                                    {item?.totalCompanyAssigned.length > 0 && item?.totalCompanyAssigned?.map((newitem) => <div className={`fw-bolder ${item?.totalCompanyAssigned?.length > 1 ? 'border-bottom pt-1 pb-1' : ''}`}>{newitem?.companyName}</div>)}
                                                                    {item?.totalCompanyAssigned.length === 0 && <div>No Company Assigned</div>}
                                                                </div>
                                                            </td>
                                                            <td className='fw-bolder'><div className='text_ellipse_100'>{item?.name}</div></td>
                                                            <td className='w-10'>
                                                                <span className='orange_color hover_underline'
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#ViewAssignedCandidatesDetailsId"
                                                                    onClick={() => { viewSelectedAccountManagerDetailsFunForCandidates(item, idx) }}
                                                                >
                                                                    {item?.totalEmployeeAssigned}
                                                                </span>
                                                            </td>
                                                            <td className='w-10'>
                                                                <span className='orange_color hover_underline'
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#ViewAssignedCompanyDetailsId"
                                                                    onClick={() => { viewSelectedAccountManagerDetailsFun(item, idx) }}
                                                                >
                                                                    {item?.totalCompanyAssigned?.length}
                                                                </span>
                                                            </td>
                                                            <td>{item?.phoneNumber}</td>
                                                            <td><div className='text_ellipse_100'>{item?.emailId}</div></td>
                                                            <td>{item?.location}</td>
                                                            <td>
                                                                <div className="dropdown table-action-dropdown text-center">
                                                                    <div
                                                                        className="dropdown-toggle mt-0 mb-0 p-0"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#ViewAccountManagerDetailsId"
                                                                        onClick={() => { viewSelectedAccountManagerDetailsFun(item, idx) }}
                                                                    >
                                                                        <img
                                                                            src={require('../../../assets/images/view_account_details_nm.svg').default}
                                                                            alt="Alt content"
                                                                            style={{ height: 12, padding: '0px 6px 0px 2px' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </PerfectScrollbar>
                                </div>
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <div className="d-flex justify-content-between align-items-baseline gap-2">
                                        <div>Items per page</div>
                                        <CustomSingleSelect
                                            options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                                            selectedPage={pageSize}
                                            onChangeHandler={onChangeHandler}
                                            disabled={accountManagerList?.length <= 10}
                                        />
                                        <div>{currentPage}-{pageSize?.label} of {accountManagerList.length} items</div>
                                    </div>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={accountManagerList.length}
                                        pageSize={pageSize?.label}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {oldAccountManagerList.length === 0 &&
                <>
                    <div className="row bd-example">
                        <div className="table-container">
                            <div className="text_v_h_center vh-80 font_size_24">
                                No Data Found
                            </div>
                        </div>
                    </div>
                </>
            }
        </div >
        {/* Add Account Manager Details modals */}
        <ModalComponent
            title="Create Account Manager"
            content={addAccountManagerModalContent()}
            dataBsBackdrop="static"
            id="addAccountManager"
            // modalDialogClass="add_requirement_modal_w"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}
        />
        {/* Edit Account Manager Details modals */}
        <ModalComponent
            title="Edit Account Manager Details"
            content={editAccountManagerModalContent()}
            dataBsBackdrop="static"
            id="EditAccountManagerDetailsId"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}

        />
        {/*Assigned Company Details modals */}
        <ModalComponent
            title={viewSelectedAccountManagerDetails?.pageData?.name}
            content={ViewAssignedCompanyDetailsModalBodyFun()}
            dataBsBackdrop="static"
            id="ViewAssignedCompanyDetailsId"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}
        />
        {/*ViewAssignedCandidates Details modals */}
        <ModalComponent
            title={viewSelectedCandidatesDetails?.pageData?.name}
            content={ViewAssignedCandidatesDetailsModalBodyFun()}
            dataBsBackdrop="static"
            id="ViewAssignedCandidatesDetailsId"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}
        />
        {/* View Account Manager Details modals */}
        <ModalComponent
            title={'Account Manager Summary' || viewSelectedAccountManagerDetails?.pageData?.name}
            content={ViewAccountManagerDetailsModalBodyFun()}
            dataBsBackdrop="static"
            id="ViewAccountManagerDetailsId"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}
        />
        {/* delete Account Manager Details modal */}
        <ModalComponent
            title="Do you want delete selected Account Manager list ?"
            footerContent={deleteModalFooterContent()}
            content={deleteModalContent()}
            dataBsBackdrop="static"
            id="deleteManagerDetailsModalID"
            cancelPopUpFun={cancelPopUpFun}
            w_max_content={true}
        />
    </>
}

export default adminLayout(AccountManager);