import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../../hoc/adminLayout";
import Pagination from '../../../common/pagination/Pagination';
import RangeDatePicker from '../../../components/RangeDatePicker';
import data from '../../../common/pagination/examples/data/mock-data.json';
import { returnArryOfObjects, formatDate, randStr, getHashOfString, generateHSL, HSLtoString, generateColorHsl, getInitials, getRange } from '../../../Utils/utils'
import { typesOfEmployeeContractList } from '../../../Utils/masterData/dropDownJson'
import { emptyJobList, PageSize } from '../../../Utils/masterData/staticContent'
import ModalComponent from "../../../components/ModalComponent";
import AddNewRequirement from "../../jobCRUDComponent/AddNewRequirement";
import EditJobRequirement from "../../jobCRUDComponent/EditJobRequirement";
import ViewJobDetails from "./CompanyViewJobDetails";
import { getAllApi, deleteApiWithBody, postApi } from '../../../services/apiCommonService'
import { get_job_list_api_url, get_jobs_by_company_id, delete_job_list_api_url, get_all_candidates, get_company_id, candidates_rejected_report_url, offer_getShortlisted } from '../../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import { shortListedCandidatesList, rejectedListCandidatesList } from "../../../redux/actions/rejectAndShortListed";
import { addSelectedJobDetails, deleteSelectedJobDetails } from "../../../redux/actions/jobDetails";
import AddCandidate from "../../jobCRUDComponent/AddCandidate";
import CustomSingleSelect from '../../../components/CustomSelectComponent';
import storePersist from '../../../Utils/storePersist';
import UploadResumeJobDetails from './UploadResumeJobDetails'
import ProvidedResumesList from './ProvidedResumesList'
import RejectedResumesList from './RejectedResumesList';
import ShortlistedCVResumesList from './ShortlistedCVResumesList';

const PostJobsPage = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [jobList, setJobList] = useState([]);
  const [oldJobList, setOldJobList] = useState([]);
  const [selectedJob, setSelectedJob] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedJobsObjects, setSelectedJobsObjects] = useState([]);
  const [keywordName, setKeywordName] = useState('');
  const [viewSelectedJobDetails, setViewSelectedJobDetails] = useState('')
  const [modalPopUpCancel, setModalPpopUpCancel] = useState(false);
  const [candidateModalPopUpCancel, setCandidateModalPopUpCancel] = useState(false);
  const [pageSize, setPageSize] = useState(PageSize);
  const [isOpenViewResume, setIsOpenViewResume] = useState(false)
  const [companyDetails, setCompanyDetails] = useState();
  const [IsShortListView, setIsShortListView] = useState(false)
  const [IsRejectView, setIsRejectView] = useState(false)
  const [IsProvidedView, setIsProvidedView] = useState(false)
  const [allCandidatesList, setAllCandidatesList] = useState([])

  const { role } = storePersist.get('userData')

  const [theme, setTheme] = React.useState('Light');
  const [range, setRange] = React.useState(10);
  const [saturation, setSaturation] = React.useState(50);
  const [lightness, setLightness] = React.useState(50);
  const saturationRange = getRange(saturation, range);
  const lightnessRange = getRange(lightness, range);

  useEffect(() => {
    void getJobListApi()
    void getCompanyApiCallFun()
  }, []);

  const getJobListApi = async () => {
    dispatch(addLoader());
    // const apiResponse = await getAllApi(get_job_list_api_url)
    // const companyDetailsStorage = storePersist.get('companyDetails')
    const apiReqBody = { companyId: params?.id }
    const apiResponse = await postApi(get_jobs_by_company_id, apiReqBody)
    if (apiResponse?.data?.Data) {
      const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
      setJobList(apiResponseData)
      setOldJobList(apiResponseData)
      // setSelectedJobsObjects(apiResponseData?.length > 0 && apiResponseData)
      setViewSelectedJobDetails({ pageData: apiResponseData?.length > 0 && apiResponseData[0], idxValue: 0 })
      // setSelectedJob([apiResponseData?.length > 0 && apiResponseData[0]?._id]);
      // viewSelectedJobDetailsFun(apiResponseData?.length > 0 && apiResponseData[0])
      // callUsersData([apiResponseData?.length > 0 && apiResponseData[0]._id])
      // toast.success("Retrived all Job List Successfully !!")
      setTimeout(() => {
        dispatch(deleteLoader());
      }, 2500);
    } else {
      toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
      dispatch(deleteLoader());
    }
  }

  const getCompanyApiCallFun = async () => {
    dispatch(addLoader());
    let apiReqBody = {
      companyId: params?.id
    }
    const apiResponse = await postApi(get_company_id, apiReqBody)
    if (apiResponse?.data?.Data) {
      const finalRes = apiResponse?.data?.Data[0]
      setCompanyDetails(finalRes)
      // toast.success(apiResponse?.response?.data.message || "Successfully retrived details of documents !!")
      dispatch(deleteLoader());
    } else {
      toast.error(apiResponse?.response?.data.message)
      dispatch(deleteLoader());
    }
  };

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * pageSize?.value;
    const lastPageIndex = firstPageIndex + pageSize?.value;
    return jobList.slice(firstPageIndex, lastPageIndex);
  };

  const callUsersData = (ids) => {
    const finalUsersData = returnArryOfObjects(jobList, ids, '_id')
    setSelectedJobsObjects(finalUsersData)
    setViewSelectedJobDetails({ pageData: finalUsersData[0], idxValue: 0 })
  }

  const returnCallUsersData = (ids) => {
    const finalUsersData = returnArryOfObjects(jobList, ids, '_id')
    return finalUsersData
  }

  const selectAll = () => {
    setSelectedJob([]);
    if (!isAllSelected) {
      jobList.forEach(jobData => {
        setSelectedJob(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
      });
      callUsersData(jobList.map((item) => item._id))
    }
    if (isAllSelected) {
      callUsersData([])
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleUserSelect = (jobData) => {
    if (selectedJob.includes(jobData._id)) {
      setSelectedJob(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData._id));
      callUsersData(selectedJob.filter(_id => _id !== jobData._id))
    } else {
      setSelectedJob(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
      callUsersData([...selectedJob, jobData._id])
    }
  };

  const isUserSelected = (jobData) => {
    const isUserSelectedVal = selectedJob.includes(jobData._id);
    return isUserSelectedVal
  };

  const isAllUsersSelected = () => {
    return selectedJob.length === jobList.length;
  };

  const filterTextSearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = oldJobList.filter((jobData) => {
        return jobData?.job_code?.toLowerCase().startsWith(keyword.toLowerCase()) ||
          jobData?.job_type?.toLowerCase().startsWith(keyword.toLowerCase())
        // jobData?.skill?.toLowerCase().startsWith(keyword.toLowerCase()) ||
        // jobData?.positionDate?.toLowerCase().startsWith(keyword.toLowerCase()) 
        // jobData?.office_type?.toLowerCase().startsWith(keyword.toLowerCase())
      });
      setJobList(results);
    } else {
      setJobList(oldJobList);
    }
    setKeywordName(keyword);
  };

  const viewSelectedJobDetailsFun = (item, idx) => {
    selectedRecordDataFun(item)
    setViewSelectedJobDetails({ pageData: item, idxValue: idx })
  }

  const returnAddNewRequirementFormDetailsFun = (formDetails) => {
    void getJobListApi()
    setJobList([...jobList, formDetails])
  }

  const returnEditJobRequirementFormDetailsFun = (formDetails) => {
    void getJobListApi()
    setJobList([...jobList, formDetails])
  }

  const modalContent = (props) => {
    return <>
      <AddNewRequirement
        modalPopUpCancel={modalPopUpCancel}
        returnAddNewRequirementFormDetails={returnAddNewRequirementFormDetailsFun}
        job_code_custom={randStr(8)}
      />
    </>;
  }

  const editModalContent = () => {
    let tempSelectedJobsObjects = selectedJobsObjects || []
    if (selectedJobsObjects?.length > 0) {
      tempSelectedJobsObjects = {
        ...selectedJobsObjects[0],
        office_type: returnArryOfObjects(typesOfEmployeeContractList, (selectedJobsObjects[0]?.office_type || []), 'label')
      }
    }
    return <>
      <EditJobRequirement
        returnEditJobRequirementFormDetails={returnEditJobRequirementFormDetailsFun}
        modalPopUpCancel={modalPopUpCancel}
        selectedJobsObjects={[tempSelectedJobsObjects]}
      />
    </>;
  }

  const viewJobDetailsModalContent = (props) => {
    return <>
      <ViewJobDetails
        viewSelectedJobDetails={viewSelectedJobDetails}
        jobList={jobList}
        selectedRecordDataFun={selectedRecordDataFun}
      />
    </>;
  }

  const editJob = () => {
    // toast.info("Edit job Modal Popup is Opened !!")
  }

  const cancelPopUpFun = (vale) => {
    setModalPpopUpCancel(vale)
    document.location.reload();
    void getJobListApi()
  }

  const selectedRecordDataFun = (selected_job_data) => {
    if (selected_job_data) {
      setSelectedJob([selected_job_data?._id]);
      setSelectedJobsObjects([selected_job_data])
    }
  }

  const onSubmit = async () => {
    const apiReqBody = {
      'jobId': selectedJob.length > 0 && selectedJob
    }
    const apiResponse = await deleteApiWithBody(delete_job_list_api_url, apiReqBody)
    if (apiResponse?.data) {
      toast.success('Job deleted successfully !!')
      document.location.reload();
      void getJobListApi()
    } else {
      toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
    }
  };

  const deleteModalContent = () => {
    return <>
      <div className='pb-1'><strong>Do you want delete selected jobs ?</strong></div>
      <ul>
        {selectedJobsObjects?.length > 0 && selectedJobsObjects?.map((item, idx) =>
          <li key={idx}>
            {item?.job_code}
          </li>
        )}
      </ul>
    </>;
  }

  const deleteModalFooterContent = (props) => {
    return <>
      <button
        className="btn btn-primary btn_background_color mt-0 mb-0"
        data-bs-dismiss="modal"
        onClick={onSubmit}
      >
        Yes
      </button>
      <button
        className="btn btn-primary btn_background_color mt-0 mb-0"
        data-bs-dismiss="modal"
        onClick={() => {
          document.location.reload();
        }}
      >
        Cancel
      </button>
    </>;
  }

  const deleteFun = () => {
    // toast.info("Delete Confirmation Modal Popup is Opened !!")
  }

  const onClickPageRedirectionProvoded = async (item) => {
    dispatch(addLoader());
    const apiReqBody = { jobId: item?._id }
    const apiResponse = await postApi(get_all_candidates, apiReqBody)
    if (apiResponse?.data?.Data) {
      let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
      // apiResponseData = apiResponseData.filter((itemR) => itemR.jobId === item?._id)
      setTimeout(() => {
        if (apiResponseData) {
          dispatch(shortListedCandidatesList(apiResponseData))
          setAllCandidatesList(apiResponseData)
          // toast.success("Retrived all Candidates List Successfully !!")
          setIsProvidedView(true)
        }
        setTimeout(() => {
          dispatch(deleteLoader());
        }, 3000)
      }, 3000)
    } else {
      toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
      dispatch(deleteLoader());
    }
  };

  const onClickPageRedirection = async (item) => {
    // navigate('/short-list-resumes');
    dispatch(addLoader());
    const apiReqBody = { companyId: item?.companyId }
    const apiResponse = await postApi(candidates_rejected_report_url, apiReqBody)
    if (apiResponse?.data?.Data) {
      let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
      apiResponseData = apiResponseData.filter((itemR) => itemR.jobId === item?._id)
      setTimeout(() => {
        if (apiResponseData) {
          dispatch(rejectedListCandidatesList(apiResponseData))
          setAllCandidatesList(apiResponseData)
          // toast.success("Retrived all Candidates List Successfully !!")
          setIsRejectView(true)
        }
        setTimeout(() => {
          dispatch(deleteLoader());
        }, 3000)
      }, 3000)
    } else {
      toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
      dispatch(deleteLoader());
    }
  }

  const onClickPageRedirectionShort = async (item) => {
    if (item?.resumeShortlistedCount === 0) {
      navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 0, jobDetails: item } });
    } else if (item?.resumeShortlistedCount > 0) {
      navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 1, jobDetails: item } });
    }
    // dispatch(addLoader());
    // const apiReqBody = { companyId: item?.companyId }
    // const apiResponse = await postApi(offer_getShortlisted, apiReqBody)
    // if (apiResponse?.data?.Data) {
    //   let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
    //   apiResponseData = apiResponseData.filter((itemS) => itemS.jobId === item?._id)
    //   setTimeout(() => {
    //     if (apiResponseData) {
    //       dispatch(shortListedCandidatesList(apiResponseData))
    //       setAllCandidatesList(apiResponseData)
    //       toast.success("Retrived all Candidates List Successfully !!")
    //       setIsShortListView(true)
    //     }
    //     dispatch(deleteLoader());
    //   }, 3000)
    // } else {
    //   toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
    //   dispatch(deleteLoader());
    // }
  }

  const onClickPageRedirectionInterviewShort = (item) => {
    if (item?.resumeShortlistedCount > 0) {
      navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 1, jobDetails: item } });
    }
    // else if (item?.resumeShortlistedCount === 0 && item?.newResumeCount === 0) {
    //   navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 0 } });
    // } else if (item?.jobOfferedCount > 0) {
    //   navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 2 } });
    // } else {
    //   navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 0 } });
    // }
  }

  const onClickPageRedirectionInterviewScreen = (item) => {
    // if ((item?.resumeShortlistedCount > 0 || item?.resumeShortlistedCount === 0) && item?.newResumeCount > 0) {
    navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 0, jobDetails: item } });
    // } else if (item?.resumeShortlistedCount > 0 && item?.newResumeCount === 0) {
    //   navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 1 } });
    // } else if (item?.resumeShortlistedCount === 0 && item?.newResumeCount === 0) {
    //   navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 0 } });
    // } else if (item?.jobOfferedCount > 0) {
    //   navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 2 } });
    // } else {
    //   navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 0 } });
    // }
  }

  const onClickPageRedirectionInterviewOffer = (item) => {
    if (item?.jobOfferedCount > 0) {
      if (role === 'COMPANY') {
        navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 2, jobDetails: item, readOnly: true } });
      } else {
        navigate(`/short-list-resumes`, { state: { jobId: item?._id, companyId: item?.companyId, stage: 2, jobDetails: item } });
      }
    }
  }

  const selctedFilterOption = () => {
    dispatch(addLoader());
    setTimeout(() => {
      dispatch(deleteLoader());
    }, 3000);
  }

  // --- candidate ----
  const returnAddCandidateFormDetailsFun = (formDetails) => {

  }

  const cancelCandidatePopUpFun = (vale) => {
    setCandidateModalPopUpCancel(vale)
  }

  const addCandidateModalContent = (props) => {
    return <>
      <AddCandidate
        modalPopUpCancel={candidateModalPopUpCancel}
        returnAddCandidateFormDetails={returnAddCandidateFormDetailsFun}
      />
    </>;
  }

  const onChangeHandler = (event) => {
    setPageSize(event)
  }

  const onClickAddCandidateFun = (candidateDetail) => {
    dispatch(addSelectedJobDetails(candidateDetail));
  }

  const cancelModalFun = () => {
    setIsRejectView(false)
    setIsShortListView(false)
    setIsProvidedView(false)
    cancelPopUpFun()
  }

  const viewJobDetailsModalContentJob = (props) => {
    return <>
      <UploadResumeJobDetails
        viewSelectedJobDetails={viewSelectedJobDetails?.pageData || selectedJobsObjects?.length > 0 && selectedJobsObjects[0] || undefined}
        jobList={allCandidatesList}
        selectedRecordDataFun={selectedRecordDataFun}
        isOpenViewResume={isOpenViewResume}
        cancelModalFun={cancelModalFun}
        modalPopUpCancel={modalPopUpCancel}
      // selectedJobId={selectedJob}
      />
    </>;
  }

  const viewRejectedResumesList = (props) => {
    return <>
      <RejectedResumesList
        viewSelectedJobDetails={viewSelectedJobDetails?.pageData || selectedJobsObjects?.length > 0 && selectedJobsObjects[0] || undefined}
        jobList={allCandidatesList}
        selectedRecordDataFun={selectedRecordDataFun}
        isOpenViewResume={IsRejectView}
        cancelModalFun={cancelModalFun}
        modalPopUpCancel={modalPopUpCancel}
        selectedJobId={selectedJob}
      />
    </>;
  }

  const viewShortListedResumesList = (props) => {
    return <>
      <ShortlistedCVResumesList
        viewSelectedJobDetails={viewSelectedJobDetails?.pageData || selectedJobsObjects?.length > 0 && selectedJobsObjects[0] || undefined}
        jobList={allCandidatesList}
        selectedRecordDataFun={selectedRecordDataFun}
        isOpenViewResume={IsShortListView}
        cancelModalFun={cancelModalFun}
        modalPopUpCancel={modalPopUpCancel}
      />
    </>;
  }

  const viewProvidedResumesList = (props) => {
    return <>
      <ProvidedResumesList
        viewSelectedJobDetails={viewSelectedJobDetails?.pageData || selectedJobsObjects?.length > 0 && selectedJobsObjects[0] || undefined}
        jobList={allCandidatesList}
        selectedRecordDataFun={selectedRecordDataFun}
        isOpenViewResume={IsProvidedView}
        cancelModalFun={cancelModalFun}
        modalPopUpCancel={modalPopUpCancel}
      />
    </>;
  }

  const uploadResumeJobDetailsFun = () => {
    if (viewSelectedJobDetails?.pageData || selectedJobsObjects?.length > 0 && selectedJobsObjects[0]) {
      setIsOpenViewResume(true)
    } else {
      // toast.error("Please select the job !!")
    }
  }
  //const [range, setRange] = React.useState(10);
  //const [saturation, setSaturation] = React.useState(50);
  //const [lightness, setLightness] = React.useState(50);
  // const [theme, setTheme] = React.useState('Light');
  //const saturationRange = getRange(saturation, range);
  //const lightnessRange = getRange(lightness, range);
  // getHashOfString, generateHSL,generateHSL,HSLtoString,generateColorHsl,getInitials,getRange
  const userName = `${companyDetails?.company_name || companyDetails?.companyName} ${companyDetails?.company_name || companyDetails?.companyName}`;
  const color = generateColorHsl(userName, saturationRange, lightnessRange);
  const initials = getInitials(companyDetails?.company_name || companyDetails?.companyName);
  // <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>

  return <>
    <div className="my-1 pt-2 p-3 bg-body rounded shadow-sm rounded_20">
      <div className="d-flex justify-content-between align-items-center border-bottom ">
        <div className="col-xl-3 col-sm-6 ps-3 d-flex align-items-center">
          <div>
            <h6 className="font_color_imp font_size_16 fw-bolded">Company:</h6>
          </div>
          <div className={`${companyDetails?.logo ? 'ps-2' : 'p-4'}`}>
            {companyDetails?.logo
              ?
              <img src={companyDetails?.logo} alt="logo" className='img_w_h_50' />
              :
              <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
            }
          </div>
        </div>
        <div className="col-md-2 text-center">
          <label className='font_color_ornage fw-bolder'>Company Name</label>
          <div className=''>{companyDetails?.company_name || companyDetails?.companyName}</div>
        </div>
        <div className="col-xl-7 col-sm-7 ps-3 d-flex align-items-center justify-content-end">
          {oldJobList.length > 0 &&
            <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
              <button
                type="button"
                className="btn btn-primary btn_background_color pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                data-bs-toggle="modal"
                data-bs-target="#postJobsList"
              >
                Add Jobs Listing
              </button>

              <button
                type="button"
                className="btn btn-primary btn_background_color pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                data-bs-toggle="modal"
                data-bs-target="#UploadResumeJobDetailsModalIds"
                onClick={() => { uploadResumeJobDetailsFun() }}
                disabled={selectedJob.length > 0 && selectedJob.length < 2 ? false : true}
              >
                Upload Resume
              </button>

              <button
                type="button"
                className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                disabled={selectedJob.length > 0 && selectedJob.length < 2 ? false : true}
                data-bs-target="#editJobsDetails"
                data-bs-toggle="modal"
                onClick={editJob}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                disabled={selectedJob.length > 0 ? false : true}
                data-bs-target="#deleteJobListModal"
                data-bs-toggle="modal"
                onClick={deleteFun}
              >
                Delete
              </button>
            </div>
          }
        </div>
      </div>
      {oldJobList.length > 0 &&
        <>
          <div className="mt-3 mb-0 pe-2">
            <div className="col-md-12 d-flex justify-content-between align-items-center gap-4">
              <div className="col-md-4 d-flex justify-content-start align-items-center gap-1 ms-2 margin_bottom_20">
                <span><b>Total Jobs list : </b>(<span className='orange_color'><b>{oldJobList?.length}</b></span>)</span>
              </div>
              {/* <div className="col-md-4 d-flex justify-content-end align-items-center gap-1">
                <div className="dropdown table-action-dropdown">
                  <button
                    className="border-0 mt-0 mb-0 pb-0 bg-white dropdown-toggle"
                    id="dropdownMenuButtonSM"
                    data-bs-toggle="dropdown"
                  >
                    <img src={require('../../../assets/images/vector_filter.svg').default} alt="Alt content" />
                  </button>
                  <ul className="dropdown-menu shadow-sm-dd font_size_14" aria-labelledby="dropdownMenuButtonSM">
                    {typesOfEmployeeContractList.map((item, idx) =>
                      <li className={`pt-2 pb-2 ps-4 pe-2 d-flex align-items-center pointer_event opacity_dull ${typesOfEmployeeContractList?.length !== idx + 1 && 'border-bottom'}`}
                        onClick={() => { selctedFilterOption(item) }}
                      >
                        {item.value}
                      </li>
                    )}
                  </ul>
                </div>
                <RangeDatePicker />
              </div> */}
              <div className="col-md-3">
                <input
                  type="search"
                  value={keywordName}
                  onChange={filterTextSearch}
                  className="searchInputCls"
                  placeholder="Search Job type, skills etc..."
                />
              </div>
            </div>
          </div>
          <div>
            <div className="row bd-example">
              <div className="table-container">
                <div className="d-flex text-muted height_job_post_table">
                  <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                          </th>
                          {['Job Type', 'Job Code', 'Experience', 'Posted Date', 'Crossed 24 Hours', 'Provided Resume', 'Short listed', 'ResumeStatus', 'Rejected', 'View Details'].
                            map((item, idx) =>
                              <th key={idx} className={`text-white`}>
                                {item}
                                {/* ${['Job Type', 'Job Code', 'Skills'].includes(item) &&  */}
                              </th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {currentTableData().map((item, idx) => {
                          return (
                            <tr key={item._id}>
                              <td>
                                <input
                                  className="width_12"
                                  type="checkbox"
                                  checked={isUserSelected(item)}
                                  onChange={() => handleUserSelect(item)}
                                />
                              </td>
                              <td>{item.job_type}</td>
                              <td className='fw-bolder'>{item.job_code}</td>
                              <td>{item.year_of_exp + ' Years'}</td>
                              {/* <td>{item.skill}</td> */}
                              <td className='widthTd'>{formatDate(item.createdAt) || '--'}</td>
                              <td className='widthTd'>{item.crossed72Hours === 'Yes (Urgent)' ? <span className='badge rounded-pill bg-secondary hover_underline'>{item.crossed72Hours}</span> : item.crossed72Hours}</td>
                              {item.resume_total_count > 0 ?
                                <td>
                                  <span className='orange_color hover_underline'
                                    data-bs-toggle="modal"
                                    data-bs-target="#showProvidedResumesModalIds"
                                    onClick={() => { onClickPageRedirectionProvoded(item) }}
                                  >
                                    {item.resume_total_count || 0}
                                  </span>
                                </td>
                                :
                                <td>
                                  <span>
                                    {item.resume_total_count || 0}
                                  </span>
                                </td>
                              }
                              <td>
                                <span className={`${item?.resumeShortlistedCount > 0 && 'orange_color hover_underline'}`}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#showShortlistedResumesModalIds"
                                  onClick={() => { item.resumeShortlistedCount > 0 && onClickPageRedirectionShort(item) }}
                                >
                                  {item.resumeShortlistedCount || 0}
                                </span>
                              </td>
                              <td>
                                {(item?.resumeShortlistedCount > 0)
                                  && //bg_color_user_details_selected_item
                                  <div className='hover_underline badge rounded-pill bg-success' onClick={() => { onClickPageRedirectionInterviewShort(item) }}>{`Shortlisted (${item.resumeShortlistedCount})`}</div>
                                }
                                {item?.newResumeCount > 0 &&
                                  <div className='hover_underline badge rounded-pill bg-primary' onClick={() => { onClickPageRedirectionInterviewScreen(item) }}>{`Screening (${item.newResumeCount})`}</div>
                                }
                                {item?.newResumeCount === 0 && item?.jobOfferedCount === 0 && item?.resumeShortlistedCount === 0 &&
                                  <div className='badge rounded-pill bg-warning text-dark'>No Resumes</div>
                                }

                                {item?.jobOfferedCount > 0
                                  &&
                                  <div className=''>
                                    <span className='hover_underline badge rounded-pill bg-info text-dark' onClick={() => { onClickPageRedirectionInterviewOffer(item) }}>Job Offered</span>
                                  </div>
                                }
                              </td>
                              <td>
                                <span className={`${item.resumeRejectedCount > 0 && 'orange_color hover_underline'}`}
                                  data-bs-toggle={item.resumeRejectedCount > 0 && "modal"}
                                  data-bs-target={item.resumeRejectedCount > 0 && "#showRejectedResumesModalIds"}
                                  onClick={() => { item.resumeRejectedCount > 0 && onClickPageRedirection(item) }}
                                >
                                  {item.resumeRejectedCount || 0}
                                </span>
                              </td>
                              <td>
                                <li className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                  data-bs-toggle="modal"
                                  data-bs-target="#viewJobResumeDetailsModalID"
                                  onClick={() => { viewSelectedJobDetailsFun(item, idx) }}
                                >
                                  <img
                                    src={require('../../../assets/images/view_account_details_nm.svg').default}
                                    alt="Alt content"
                                    style={{ height: 12, padding: '0px 6px 0px 2px' }}
                                  />
                                </li>
                                {/* <div className="dropdown table-action-dropdown">
                                  <button
                                    className="btn btn-secondary btn-sm dropdown-toggle mt-0 mb-0 p-0"
                                    type="button"
                                    id="dropdownMenuButtonSM"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                  </button>
                                  <ul className="dropdown-menu shadow-sm-dd font_size_14 min_w" aria-labelledby="dropdownMenuButtonSM">
                                    <li className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                      data-bs-toggle="modal"
                                      data-bs-target="#viewJobResumeDetailsModalID"
                                      onClick={() => { viewSelectedJobDetailsFun(item, idx) }}
                                    >
                                      <img
                                        src={require('../../../assets/images/view_account_details_nm.svg').default}
                                        alt="Alt content"
                                        style={{ height: 12, padding: '0px 6px 0px 2px' }}
                                      />
                                      View Full Details
                                    </li>
                                    <div className="dropdown-divider"></div>
                                    <li
                                      className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                      onClick={() => { onClickPageRedirection() }}
                                    >
                                      <img
                                        src={require('../../../assets/images/scheduled_resume.svg').default}
                                        alt="Alt content"
                                        style={{ padding: '0px 6px 0px 2px' }}
                                      />
                                      Shortlisted Resume
                                    </li>
                                    {role === 'ACTMGR' &&
                                      <>
                                        <div className="dropdown-divider"></div>
                                        <li
                                          className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                          data-bs-toggle="modal"
                                          data-bs-target="#addCandidateDetailsId"
                                          onClick={() => { onClickAddCandidateFun(item) }}
                                        >
                                          <img
                                            src={require('../../../assets/images/type_of_contract.svg').default}
                                            alt="Alt content"
                                            style={{ padding: '0px 6px 0px 2px' }}
                                          />
                                          Add Candidate
                                        </li>
                                      </>
                                    }
                                  </ul>
                                </div> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </PerfectScrollbar>
                </div>
                <div className="d-flex justify-content-between align-items-baseline">
                  <div className="d-flex justify-content-between align-items-baseline gap-2">
                    <div>Items per page</div>
                    <CustomSingleSelect
                      options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                      selectedPage={pageSize}
                      onChangeHandler={onChangeHandler}
                      disabled={jobList?.length <= 10}
                    />
                    <div>{currentPage}-{pageSize?.label} of {jobList.length} items</div>
                  </div>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={jobList.length}
                    pageSize={pageSize?.label}
                    onPageChange={page => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {oldJobList.length === 0 &&
        <div className="row text-center padding_73">
          <div className="col-md-12 text-center">
            <img
              src={require('../../../assets/images/empty_job_list.svg').default}
              alt="Alt content"
            />
            <div className='col-md-12 d-flex pt-5 align-item-center justify-content-center'>
              <span className='font_size_19 w-50 fw-medium font_dark_color'>{emptyJobList}</span>
            </div>
          </div>
          <div className="col-md-12 pt-4 d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn btn-primary btn_background_color pt-1 pb-2 font_size_14 fw-medium btn_height_36 p-3"
              data-bs-toggle="modal"
              data-bs-target="#postJobsList"
            >
              Add Jobs Listing
            </button>
          </div>
        </div>
      }
    </div >
    {/* Add Post Job Details modals */}
    <ModalComponent
      title="Add New Requirement"
      content={modalContent()}
      dataBsBackdrop="static"
      id="postJobsList"
      // modalDialogClass="add_requirement_modal_w"
      modalBodyPaddingBottomCls="p-0"
      cancelPopUpFun={cancelPopUpFun}
      fullScreen={true}
    />
    {/* Edit Post Job Details modals */}
    <ModalComponent
      title="Edit Job Details"
      content={editModalContent()}
      dataBsBackdrop="static"
      id="editJobsDetails"
      // modalDialogClass="add_requirement_modal_w"
      modalBodyPaddingBottomCls="p-0"
      cancelPopUpFun={cancelPopUpFun}
      fullScreen={true}
    />
    {/* View Job Details modals */}
    <ModalComponent
      title={viewSelectedJobDetails?.pageData?.job_code}
      content={viewJobDetailsModalContent()}
      dataBsBackdrop="static"
      id="viewJobResumeDetailsModalID"
      modalDialogClass="view_job_details_modal_w"
      modalBodyPaddingBottomCls="p-0 overflow_custom"
      isHeaderHide={true}
      cancelPopUpFun={cancelPopUpFun}
    />
    {/* delete Job list modal */}
    <ModalComponent
      title="Confirmation !!"
      footerContent={deleteModalFooterContent()}
      content={deleteModalContent()}
      dataBsBackdrop="static"
      id="deleteJobListModal"
      w_max_content={true}
      cancelPopUpFun={cancelPopUpFun}
    />
    {/* Add candidate modals */}
    {/* <ModalComponent
      title="Add Candidate Details"
      content={addCandidateModalContent()}
      dataBsBackdrop="static"
      id="addCandidateDetailsId"
      // modalDialogClass="add_requirement_modal_w"
      modalBodyPaddingBottomCls="p-0"
      cancelPopUpFun={cancelCandidatePopUpFun}
      fullScreen={true}
    /> */}
    {/* Upload Candidate Details */}
    <ModalComponent
      title={`${viewSelectedJobDetails?.pageData?.job_code} | ${viewSelectedJobDetails?.pageData?.job_status}`}
      content={viewJobDetailsModalContentJob()}
      // dataBsBackdrop="static"
      // id="UploadResumeJobDetailsModalIds"
      // modalBodyPaddingBottomCls="p-0"
      // cancelPopUpFun={cancelPopUpFun}
      // fullScreen={true}
      cancelPopUpFun={cancelPopUpFun}
      dataBsBackdrop="static"
      id="UploadResumeJobDetailsModalIds"
      // modalDialogClass="view_job_details_modal_w"
      // modalBodyPaddingBottomCls="p-0 overflow_custom"
      modalBodyPaddingBottomCls="p-0"
      fullScreen={true}
    // isHeaderHide={true}
    />
    <ModalComponent
      title={`All Candidates | Edit | Delete`}
      content={viewProvidedResumesList()}
      cancelPopUpFun={cancelPopUpFun}
      dataBsBackdrop="static"
      id="showProvidedResumesModalIds"
      modalBodyPaddingBottomCls="p-0"
      fullScreen={true}
    />
    <ModalComponent
      title={`ShortListed Candidates`}
      content={viewShortListedResumesList()}
      cancelPopUpFun={cancelPopUpFun}
      dataBsBackdrop="static"
      id="showShortlistedResumesModalIds"
      modalBodyPaddingBottomCls="p-0"
      fullScreen={true}
    />
    <ModalComponent
      title={`Rejected Candidates`}
      content={viewRejectedResumesList()}
      cancelPopUpFun={cancelPopUpFun}
      dataBsBackdrop="static"
      id="showRejectedResumesModalIds"
      modalBodyPaddingBottomCls="p-0"
      fullScreen={true}
    />
  </>
}

export default adminLayout(PostJobsPage);