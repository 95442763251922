import React, { useState, useLayoutEffect, useEffect } from "react";
import { toast } from 'react-toastify';
import swal from "sweetalert";
// import 'bootstrap/dist/css/bootstrap.css';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlayLoader from 'react-loading-overlay';
import { useForm, Controller } from "react-hook-form";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import { downloadPDF } from '../../../services/axios-common'
import storePersist from '../../../Utils/storePersist'
import { isEmpty } from '../../../Utils/utils'
import { emptyJobList, PageSize } from '../../../Utils/masterData/staticContent'
import { postApi, deleteApiWithBody, getAllApi } from '../../../services/apiCommonService'
import { approved_by_admin_url, rejected_by_admin_url, delete_user_url, get_all_account_managers, getAllRejectedUsers_url, upload_company_files, upload_candidate_file, create_candidate_details, get_all_candidates } from '../../../services/endPointsConstant'
import { returnArryOfObjects } from '../../../Utils/utils'
import CustomSingleSelect from '../../../components/CustomSelectComponent';
import Pagination from '../../../common/pagination/Pagination';
import adminLayout from "../../../hoc/adminLayout";

const masterData = [
    { label: 'Job Type', response: 'job_type', value: 'Remote', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', value: '1234', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', value: '5-10 years', img: 'Y_O_Exp.svg' },
]

const RejectedRegCmpList = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const d = new Date()
    const newDate = d.toISOString().substring(0, 10);
    const { loading } = useSelector((state) => state.loaderReducer);
    const { viewSelectedJobDetails, jobList, selectedRecordDataFun, isOpenViewResume, modalPopUpCancel } = props
    const [cmpyDocumentValue, setCmpyDocumentValue] = useState(null);
    const [current, setCurrent] = useState(viewSelectedJobDetails?.idxValue);
    const [formDataDoc, setFormDataDoc] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [allCandidatesList, setAllCandidatesList] = useState([])
    const [allCandidatesOldList, setAllCandidatesOldList] = useState([])
    const [selectedCompanyDetailsObject, setSelectedCompanyDetailsObject] = useState([]);
    const [selectedCompanyDetailsRowList, setSelectedCompanyDetailsRowList] = useState([]);
    const [selectedJobsObjects, setSelectedJobsObjects] = useState([]);
    const [newEditPoup, setNewEditPoup] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(PageSize);
    const [keywordName, setKeywordName] = useState('');
    const [ascOrder, setAscOrder] = useState(true);
    const [keywordFilter, setKeywordNameFilter] = useState('');
    const [oldAccountManagerList, setOldAccountManagerList] = useState([]);

    const {
        register: registerDoc,
        formState: {
            errors: errorsDoc,
            isSubmitting: isSubmittingDoc
        },
        handleSubmit: handleSubmitDoc,
        control: controlDoc,
        reset: resetDoc,
        getValues: getValuesDoc
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        void getAccountManagerListApi()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (keywordFilter !== '') {
                const results = allCandidatesList.filter((jobData) => {
                    return jobData?.role?.toLowerCase().startsWith(keywordFilter.toLowerCase()) ||
                        jobData?.company_name?.toLowerCase().startsWith(keywordFilter.toLowerCase()) ||
                        jobData?.buisness_email_id?.toLowerCase().startsWith(keywordFilter.toLowerCase()) ||
                        jobData?.contact_person_name?.toLowerCase().startsWith(keywordFilter.toLowerCase()) ||
                        jobData?.phone_number?.startsWith(keywordFilter.toLowerCase())
                });
                setAllCandidatesList(results);
            }
            dispatch(deleteLoader());
        }, 3000)
    }, [keywordFilter])

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.value;
        const lastPageIndex = firstPageIndex + pageSize?.value;
        return allCandidatesList.slice(firstPageIndex, lastPageIndex);
    };
    const stopLoader = () => {
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(allCandidatesList, ids, '_id')
        setSelectedCompanyDetailsObject(finalUsersData)
    }

    const onChangeHandler = (event) => {
        setPageSize(event)
    }

    const handleUserSelect = (jobData) => {
        if (selectedCompanyDetailsRowList.includes(jobData._id)) {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData._id));
            callUsersData(selectedCompanyDetailsRowList.filter(_id => _id !== jobData._id))
        } else {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            callUsersData([...selectedCompanyDetailsRowList, jobData._id])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedCompanyDetailsRowList.includes(jobData._id);
        return isUserSelectedVal
    };

    const selectAll = () => {
        setSelectedCompanyDetailsRowList([]);
        if (!isAllSelected) {
            allCandidatesList.forEach(jobData => {
                setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            });
            callUsersData(allCandidatesList.map((item) => item._id))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const getAccountManagerListApi = async () => {
        dispatch(addLoader());
        const apiReqBody = {}
        const apiResponse = await postApi(get_all_account_managers, apiReqBody)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            setOldAccountManagerList(apiResponseData)
            void getCandidatesApiCallFun(apiResponseData)
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const getCandidatesApiCallFun = async (apiResponseDataOldManger) => {
        dispatch(addLoader());
        const apiReqBody = { jobId: viewSelectedJobDetails?._id }
        const apiResponse = await getAllApi(getAllRejectedUsers_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            const mainList = []
            apiResponseData?.map(async (item) => {
                const finalList = apiResponseDataOldManger?.filter(({ actManagerId }) => item?.CompanyDetails[0]?.actManagerId?.includes(actManagerId))
                item.totalMangers = finalList?.map(({ emailId }) => emailId)
                mainList.push(item)
            })
            setAllCandidatesList(mainList)
            setAllCandidatesOldList(mainList)
            // toast.success("Retrived all Candidates List Successfully !!")
            dispatch(deleteLoader());
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    };

    const downloadPdf = (downloadPath, name) => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(downloadPath, name)
    }

    const deleteFun = () => {
        swal({
            title: "Delete !!",
            text: "Are you sure you want to Delete Candidate ?",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Candidate deleted successfully !',
                    icon: 'success'
                }).then(async function () {
                    dispatch(addLoader());
                    const apiReqBody = {
                        ids: selectedCompanyDetailsObject && selectedCompanyDetailsObject.map((item) => item._id)
                    }
                    const apiResponse = await postApi(delete_user_url, apiReqBody)
                    if (apiResponse?.data) {
                        toast.success('user deleted successfully!!')
                        // document.getElementById("ProvidedcandidatesListTableModalIdDismiss").click();
                        setSelectedCompanyDetailsRowList([]);
                        void getCandidatesApiCallFun()
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                        dispatch(deleteLoader());
                    }
                });
            } else {
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const filterTextSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = allCandidatesList.filter((jobData) => {
                return jobData?.role?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.company_name?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.buisness_email_id?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.contact_person_name?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.phone_number?.startsWith(keyword.toLowerCase())
            });
            setAllCandidatesList(results);
        } else {
            setAllCandidatesList(allCandidatesOldList);
        }
        setKeywordName(keyword);
    };

    const filterTextSearchTemp = (e) => {
        dispatch(addLoader());
        const keyword = e.target.value;
        if (e?.target?.value === keywordFilter) {
            setTimeout(() => {
                const results = allCandidatesOldList.filter((jobData) => {
                    return jobData?.role?.toLowerCase().startsWith(keyword.toLowerCase())
                });
                setAllCandidatesList(results);
                dispatch(deleteLoader());
            }, 2000)
        } else {
            if (keyword !== '') {
                const results = allCandidatesOldList.filter((jobData) => {
                    return jobData?.role?.toLowerCase().startsWith(keyword.toLowerCase())
                });
                setAllCandidatesList(results);
            } else {
                setAllCandidatesList(allCandidatesOldList);
            }
        }
        setKeywordNameFilter(keyword);
    };

    const sortFun = (key) => {
        dispatch(addLoader());
        key = key === 'Role' ? 'role' : key
        const final = allCandidatesList?.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            if (ascOrder) {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            } else {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
        setAllCandidatesList(final);
        setAscOrder(!ascOrder)
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 2000)
    }

    const approvedAccountFun = (item) => {
        swal({
            title: "Approved !!",
            text: "Are you sure you want to approve this company ?",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Approved!',
                    text: 'Approved company request successfully !',
                    icon: 'success'
                }).then(async function () {
                    dispatch(addLoader());
                    const apiReqBody = {
                        _id: item._id
                    }
                    const apiResponse = await postApi(approved_by_admin_url, apiReqBody)
                    if (apiResponse?.data) {
                        toast.success('Company request verified and approved successfully!!')
                        // document.getElementById("ProvidedcandidatesListTableModalIdDismiss").click();
                        setSelectedCompanyDetailsRowList([]);
                        void getCandidatesApiCallFun()
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                        dispatch(deleteLoader());
                    }
                });
            } else {
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const rejectedAccountFun = (item) => {
        swal({
            title: "Rejected !!",
            text: "Are you sure you want to reject this company ?",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Rejected!',
                    text: 'Rejected company request successfully !',
                    icon: 'success'
                }).then(async function () {
                    dispatch(addLoader());
                    const apiReqBody = {
                        _id: item._id
                    }
                    const apiResponse = await postApi(rejected_by_admin_url, apiReqBody)
                    if (apiResponse?.data) {
                        toast.success('Company request verified and rejected successfully!!')
                        // document.getElementById("ProvidedcandidatesListTableModalIdDismiss").click();
                        setSelectedCompanyDetailsRowList([]);
                        void getCandidatesApiCallFun()
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                        dispatch(deleteLoader());
                    }
                });
            } else {
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    return (
        <>
            <LoadingOverlayLoader
                active={loading}
                spinner={true}
                text="Loading your content..."
                styles={{
                    overlay: (base) => ({
                        ...base,
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        zIndex: 9999
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: '70px',
                        '& svg circle': {
                            stroke: '#FE5244',//rgba(255, 0, 0, 0.5)
                        }
                    }),
                    content: (base) => ({
                        ...base,
                        top: "40%",
                        left: '45%',
                        color: '#FE5244',
                    })
                }}
            >
                <div className=" pt-0 pb-5 ps-3 pe-3">
                    {/* overflow_y_on modal-dialog-scrollable */}
                    <h5 className="font_color_white_c tex text-center p-2 bg_main_dark_color">Rejected registered companies (<span className="fw-bolder font_color_ornage">{allCandidatesList.length}</span>)</h5>
                    <div className="pt-2 font_size_14">
                        <div>
                            <div className="row bd-example">
                                <div className="d-flex justify-content-between align-items-center border-bottom pt-2">
                                    <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                                        <div className="font_size_16 fw-bold text_btn_color pt-1">
                                            Filters :
                                        </div>
                                        <div
                                            className={`${keywordFilter === 'ACTMGR' ? 'btn bg_color_ornage font_color_ornage_border font_color_white_c' : 'btn border_btn_color'} pb-2 font_size_14 fw-medium btn_height_36 padding_top_6`}
                                            onClick={() => { filterTextSearchTemp({ target: { value: 'ACTMGR' } }) }}
                                        >
                                            Account Manger
                                        </div>
                                        <div
                                            className={`${keywordFilter === 'COMPANY' ? 'btn bg_color_ornage font_color_ornage_border font_color_white_c' : 'btn border_btn_color'} pb-2 font_size_14 fw-medium btn_height_36 padding_top_6`}
                                            onClick={() => { filterTextSearchTemp({ target: { value: 'COMPANY' } }) }}
                                        >
                                            Company
                                        </div>
                                        <div
                                            className={`${keywordFilter === 'ADMIN' ? 'btn bg_color_ornage font_color_ornage_border font_color_white_c' : 'btn border_btn_color'} pb-2 font_size_14 fw-medium btn_height_36 padding_top_6`}
                                            onClick={() => { filterTextSearchTemp({ target: { value: 'ADMIN' } }) }}
                                        >
                                            Admin
                                        </div>
                                    </div>
                                    <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                                        <input
                                            type="search"
                                            value={keywordName}
                                            onChange={filterTextSearch}
                                            className="searchInputCls pb-1"
                                            placeholder="Search..."
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                            disabled={selectedCompanyDetailsRowList.length > 0 ? false : true}
                                            onClick={deleteFun}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <div className="table-container">
                                    <div className="d-flex text-muted">
                                        <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                        </th>
                                                        {['Company Name', 'Business email', 'Status', 'Phone', 'Admin Verify', 'contact person name', 'OTP', 'Role', 'Account Manager', 'Action'].
                                                            map((item, idx) =>
                                                                <th key={idx} onClick={() => { sortFun(item) }} className={`${item === 'Role' ? 'font_color_ornage_imp hover_orange hover_underline' : 'text-white'} `}>
                                                                    {item}
                                                                </th>
                                                            )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentTableData()?.map((item, idx) => {
                                                        return (
                                                            <tr key={item._id}>
                                                                <td>
                                                                    <input
                                                                        className="width_12"
                                                                        type="checkbox"
                                                                        checked={isUserSelected(item)}
                                                                        onChange={() => handleUserSelect(item)}
                                                                    />
                                                                </td>
                                                                <td><span className="fw-bolder">{item?.company_name}</span></td>
                                                                <td><div className="text_ellipse_150">{item?.buisness_email_id}</div></td>
                                                                <td>
                                                                    {item?.isRejected
                                                                        ?
                                                                        <span class="badge rounded-pill bg-danger">Rejected</span>
                                                                        :
                                                                        <span class="badge rounded-pill bg-warning text-dark">Pending</span>
                                                                    }
                                                                </td>
                                                                {/* <td>{item?.passwordString}</td> */}
                                                                <td>{item?.phone_number || '--'}</td>
                                                                <td>
                                                                    {item?.isAdminVerify
                                                                        ?
                                                                        <span class="badge rounded-pill bg-success">Verified</span>
                                                                        :
                                                                        <span class="badge rounded-pill bg-warning text-dark">Pending</span>
                                                                    }
                                                                </td>
                                                                <td><div className="text_ellipse_100">{item?.contact_person_name || '--'}</div></td>
                                                                <td><span className="font_color_ornage">{item?.otp?.length > 0 && item?.otp[item?.otp?.length - 1]?.otp}</span></td>
                                                                <td>
                                                                    <div className={`fw-bolder text-center 
                                                                    ${item?.role === 'COMPANY'
                                                                            ? 'font_color_ornage' :
                                                                            item?.role === 'ADMIN' ? 'font_dark_green_color' : 'font_dark_red_color'}`
                                                                    }
                                                                    >
                                                                        {item?.role}
                                                                    </div>
                                                                </td>
                                                                {/* <td><div className="text_ellipse_100">{item?.no_of_employee}</div></td> */}
                                                                <td>
                                                                    {item?.totalMangers?.length > 0
                                                                        ?
                                                                        item?.totalMangers?.map((itemVal) => <span className="font_color_ornage fw-bolder">{itemVal}</span>)
                                                                        :
                                                                        <div className="fw-bolder text-center">{'---'}</div>
                                                                    }
                                                                </td>
                                                                {/* <td>{new Date(item?.createdAt).toLocaleString() || '--'}</td> */}
                                                                <td>
                                                                    <div className="d-flex justify-content-between align-items-end">
                                                                        <i
                                                                            className="fa fa-check-circle-o pointer_event" aria-hidden="true" style={{ fontSize: '20px', color: 'green' }}
                                                                            onClick={() => { approvedAccountFun(item) }}>
                                                                        </i>
                                                                        {/* <span className="vr me-3 ms-3 mt-2" style={{ height: 0, width: 3 }}></span>
                                                                        <i class="fa fa-times-circle-o pointer_event" aria-hidden="true" style={{ fontSize: '20px', color: 'red' }}
                                                                            onClick={() => { rejectedAccountFun(item) }}>
                                                                        </i> */}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </PerfectScrollbar>
                                    </div>
                                    {allCandidatesList.length > 0 ?
                                        <div className="d-flex justify-content-between align-items-baseline">
                                            <div className="d-flex justify-content-between align-items-baseline gap-2">
                                                <div>Items per page</div>
                                                <CustomSingleSelect
                                                    options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                                                    selectedPage={pageSize}
                                                    onChangeHandler={onChangeHandler}
                                                />
                                                <div>{currentPage}-{pageSize?.label} of {allCandidatesList.length} items</div>
                                            </div>
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={allCandidatesList.length}
                                                pageSize={pageSize?.label}
                                                onPageChange={page => setCurrentPage(page)}
                                            />
                                        </div>
                                        :
                                        <div className="row bd-example">
                                            <div className="table-container">
                                                <div className="text_v_h_center vh-40 font_size_24">
                                                    No Data Found
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlayLoader >
        </>
    );
}

export default adminLayout(RejectedRegCmpList);
