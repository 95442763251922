import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import { addShortlistedResume } from "../../redux/actions/shortlistedResumes";
import adminLayout from "../../hoc/adminLayout";
import { downloadPDF } from '../../services/axios-common'
import { postApi, getAllApi, deleteApiWithBody, updateWithOutParamsApi } from '../../services/apiCommonService'
import { get_all_candidates, delete_candidates_details_api_url, get_Company_ActMgr_url, get_jobs_by_company_id, existed_job_data_url, delete_offer_api_url, request_for_resumes, edit_Offer_api_url, post_comment_api_url, exclude_all_candidates } from '../../services/endPointsConstant'
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Pagination from '../../common/pagination/Pagination';
import RangeDatePicker from '../../components/RangeDatePicker';
import { returnArryOfObjects } from '../../Utils/utils'
import { emptyJobList, PageSize } from '../../Utils/masterData/staticContent';
import CustomSingleSelect from '../../components/CustomSelectComponent';
import ModalComponent from "../../components/ModalComponent";
import storePersist from '../../Utils/storePersist'

const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
};

const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
    return `${user[0]?.toUpperCase()}${user[1]?.toUpperCase()}`
}

const setValue = (functionFor) => {
    return (e) => {
        const value = parseInt(e.target.value);
        functionFor(value);
    }
};

const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
}

const ShortListResumesComponent = (props) => {
    const location = useLocation()
    const { role } = storePersist.get('userData')
    const { stageUpdateProps } = props
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { defaultCompanyDetails } = useSelector((state) => state.defaultCompanyDetailsReducer);
    const [selectedUserDetails, setSelectedUserDetails] = useState();
    const [shortListedProfiles, setShortListedProfiles] = useState([]);
    const [allCandidatesList, setAllCandidatesList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [candidatesList, setCandidatesList] = useState([]);
    const [oldCandidatesList, setOldCandidatesList] = useState([]);
    const [selectedCandidatesStateList, setSelectedCandidatesStateList] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedJobOfferObjects, setSelectedJobOfferObjects] = useState([]);
    const [rejectedJobOfferObjects, setRejectedJobOfferObjects] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [isshowDesignView, setIsshowDesignView] = useState(true);
    const [pageSize, setPageSize] = useState(PageSize);
    const [selectedProfilesStateVal, setSelectedProfiles] = useState([])
    const [rejectSelectedJobOfferData, setRejectSelectedJobOfferData] = useState();
    const [rejectSelectedJobOfferDataCommentText, setRejectSelectedJobOfferDataCommentText] = useState();
    const [modalPopUpCancel, setModalPpopUpCancel] = useState(false);
    const [moreResumesDataCommentText, setMoreResumesDataCommentText] = useState();
    const [accountManagerListOfCmp, setAccountManagerListOfCmp] = useState([])
    const [currentJobData, setCurrentJobData] = useState([])

    const [range, setRange] = React.useState(10);
    const [saturation, setSaturation] = React.useState(50);
    const [lightness, setLightness] = React.useState(50);
    const [theme, setTheme] = React.useState('Light');

    const saturationRange = getRange(saturation, range);
    const lightnessRange = getRange(lightness, range);

    useEffect(() => {
        async function fetchData() {
            await getAllCandidatesList()
            await accountMangerDetailsFunApiCall()
            await getJobListApi()
        }
        fetchData()
    }, [])

    const accountMangerDetailsFunApiCall = async () => {
        const companyDetailsStorage = storePersist.get('companyDetails')
        const apiReqBody = { companyId: companyDetailsStorage && companyDetailsStorage?._id }
        const apiResponse = await postApi(get_Company_ActMgr_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            setAccountManagerListOfCmp(apiResponse?.data?.Data)
            return apiResponse?.data?.Data
        } else {
            toast.error(apiResponse?.response?.data?.message + '!!')
        }
    }

    const getJobListApi = async () => {
        dispatch(addLoader());
        if (location?.state && location?.state?.jobId && location?.state?.companyId) {
            const apiReqBody = { companyId: location?.state?.companyId, jobId: location?.state?.jobId }
            const apiResponse = await postApi(existed_job_data_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                const apiResponseData = [...apiResponse?.data?.Data]?.reverse()?.filter(({ _id }) => _id === location?.state?.jobId)
                setCurrentJobData(apiResponseData && apiResponseData[0] || {})
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 2500);
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        }
    }

    const getAllCandidatesList = async () => {
        dispatch(addLoader());
        // const companyDetailsStorage = storePersist.get('companyDetails')
        // companyId: companyDetailsStorage && companyDetailsStorage?._id
        if (location?.state && location?.state?.jobId && location?.state?.companyId) {
            const apiReqBody = { jobId: location?.state?.jobId }
            const apiResponse = await postApi(get_all_candidates, apiReqBody)
            if (apiResponse?.data?.Data) {
                let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
                apiResponseData = apiResponseData?.filter(({ resume_status }) => ["interested", null, 'null', 'notInterested']?.includes(resume_status))
                setCandidatesList(apiResponseData)
                setOldCandidatesList(apiResponseData)
                setAllCandidatesList(apiResponseData)
                const shortListedList = apiResponseData?.filter(({ resume_status }) => resume_status === "interested")
                setShortListedProfiles(shortListedList.map(({ offer_id }) => offer_id))
                // toast.success("Retrived all Candidates List Successfully !!")
                setSelectedUserDetails(apiResponseData[0])
                setSelectedProfiles([apiResponseData[0]?.offer_id])
                dispatch(deleteLoader());
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        } else {
            // setCandidatesList([])
            // setOldCandidatesList([])
            // setAllCandidatesList([])
            // setShortListedProfiles([])
            // setSelectedUserDetails({})
            // setSelectedProfiles([])
        }
    }

    const userDetailsOnClikHandler = (userData) => {
        dispatch(addLoader());
        setSelectedUserDetails(userData)
        setSelectedProfiles([userData.offer_id])
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 2000);
    }

    const excludeAllCandidates = async (ids) => {
        const apiReqBody = {
            offerId: ids,
            resume_status: 'rejected'
        }
        const apiResponse = await updateWithOutParamsApi(exclude_all_candidates, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Profiles Updated Successfully !!')
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const excludeAllCandidatesShort = async (ids) => {
        const apiReqBody = {
            offerId: ids,
            resume_status: 'shortlisted'
        }
        const apiResponse = await updateWithOutParamsApi(exclude_all_candidates, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Profiles scheduled for interview!!')
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const onClickPageRedirection = () => {
        let allFilteredList = allCandidatesList?.map(({ offer_id }) => offer_id)

        let allFilteredListNull = allCandidatesList?.filter(({ resume_status }) => resume_status === 'notInterested')
        allFilteredListNull = allFilteredListNull?.map(({ offer_id }) => offer_id)

        // const allFilteredListTemp = allFilteredListNull?.filter((id) => !shortListedProfiles.includes(id))
        void excludeAllCandidates(allFilteredListNull)

        const allFilteredListShort = allFilteredList?.filter((id) => shortListedProfiles.includes(id))
        void excludeAllCandidatesShort(allFilteredListShort)

        const result = allCandidatesList?.filter(({ offer_id }) => shortListedProfiles.includes(offer_id));
        if (result?.length > 0) {
            dispatch(addShortlistedResume(result))
            stageUpdateProps(1)
            navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 1 } });
        } else {
            if (isshowDesignView && oldCandidatesList.length === 0) {
                stageUpdateProps(1)
                navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 1 } });
            } else {
                toast.error("Please select & shortlist the profiles to procced to next")
            }
        }
    }

    const downloadPdf = () => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(selectedUserDetails?.file_location, selectedUserDetails?.candidateFirstName) //'https://arxiv.org/pdf/quant-ph/0410100.pdf'
    }

    const downloadPdfTable = (item) => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(item?.file_location, item?.candidateFirstName) //'https://arxiv.org/pdf/quant-ph/0410100.pdf'
    }

    const shortListApiCall = async (id) => {
        const apiReqBody = {
            offerId: id,
            resume_status: "interested"
        }
        const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success("You've shortlisted the profile successfully.")
            await getAllCandidatesList()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const unShortListApiCall = async (id) => {
        const apiReqBody = {
            offerId: id,
            resume_status: null
        }
        const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.info("Removed from shortlisted List")
            await getAllCandidatesList()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const onClickShortListProfile = async () => {
        // if (shortListedProfiles?.length > 0) {
        //     setShortListedProfiles([...shortListedProfiles, selectedUserDetails?.offer_id])
        // } else {
        const alreadyShortListedList = candidatesList?.filter(({ resume_status, offer_id }) => resume_status === "interested" && offer_id === selectedUserDetails?.offer_id)
        if (alreadyShortListedList.length > 0) {
            toast.error("Already Candidate is Shortlisted !!")
        } else {
            setShortListedProfiles([selectedUserDetails?.offer_id])
            await shortListApiCall(selectedUserDetails?.offer_id)
        }
        // }
    }

    const designViewToggleButton = () => {
        setIsshowDesignView(!isshowDesignView)
    }

    //---table--
    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.value;
        const lastPageIndex = firstPageIndex + pageSize?.value;
        return candidatesList.slice(firstPageIndex, lastPageIndex);
    };

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(candidatesList, ids, 'offer_id')
        setSelectedJobOfferObjects(finalUsersData)
        let allFilteredList = allCandidatesList?.map(({ offer_id }) => offer_id)
        allFilteredList = allFilteredList?.filter((id) => !shortListedProfiles.includes(id))
        const finalUsersDataRejected = returnArryOfObjects(candidatesList, allFilteredList, 'offer_id')
        setRejectedJobOfferObjects(finalUsersDataRejected)

    }

    const selectAll = () => {
        setSelectedCandidatesStateList([]);
        if (!isAllSelected) {
            candidatesList.forEach(jobData => {
                setSelectedCandidatesStateList(prevSelectedUsers => [...prevSelectedUsers, jobData.offer_id]);
            });
            callUsersData(candidatesList.map((item) => item.offer_id))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const handleUserSelect = (jobData) => {
        if (selectedCandidatesStateList.includes(jobData.offer_id)) {
            setSelectedCandidatesStateList(prevSelectedUsers => prevSelectedUsers.filter(offer_id => offer_id !== jobData.offer_id));
            callUsersData(selectedCandidatesStateList.filter(offer_id => offer_id !== jobData.offer_id))
        } else {
            setSelectedCandidatesStateList(prevSelectedUsers => [...prevSelectedUsers, jobData.offer_id]);
            callUsersData([...selectedCandidatesStateList, jobData.offer_id])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedCandidatesStateList.includes(jobData.offer_id);
        return isUserSelectedVal
    };

    const filterTextSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = oldCandidatesList.filter((jobData) => {
                return jobData?.candidateFirstName?.toLowerCase().includes(keyword.toLowerCase()) ||
                    jobData?.candidateLastName?.toLowerCase().includes(keyword.toLowerCase()) ||
                    jobData?.profileTitle?.toLowerCase().includes(keyword.toLowerCase()) ||
                    jobData?.candidateExperience?.toLowerCase().includes(keyword.toLowerCase()) ||
                    jobData?.skillset?.join()?.toLowerCase().includes(keyword.toLowerCase()) ||
                    jobData?.candidate_status?.toLowerCase().includes(keyword.toLowerCase()) ||
                    jobData?.fileName?.toLowerCase().includes(keyword.toLowerCase())
            });
            setCandidatesList(results);
        } else {
            setCandidatesList(oldCandidatesList);
        }
        setKeywordName(keyword);
    };

    const selctedFilterOption = () => {
        dispatch(addLoader());
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }

    const onSubmit = async () => {
        const arrList = selectedCandidatesStateList.length > 0 ? selectedCandidatesStateList : selectedProfilesStateVal
        const apiReqBody = {
            offerId: arrList
        }
        const apiResponse = await deleteApiWithBody(delete_offer_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Profile Updated Successfully !!')
            void getAllCandidatesList()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const deleteModalContent = () => {
        const arrList = selectedCandidatesStateList.length > 0 ? selectedCandidatesStateList : selectedProfilesStateVal
        const finalUsersDataunShortlist = returnArryOfObjects(candidatesList, arrList, 'offer_id')

        return <>
            <ul>
                {finalUsersDataunShortlist?.length > 0 && finalUsersDataunShortlist?.map((item, idx) =>
                    <li key={idx}>
                        {item?.candidateFirstName}
                    </li>
                )}
            </ul>
        </>;
    }

    const deleteModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={onSubmit}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
            >
                Cancel
            </button>
        </>;
    }

    //--- table end ---

    const onChangeHandler = (event) => {
        setPageSize(event)
    }

    const rejectJobOfferFun = (item) => {
        setRejectSelectedJobOfferData(item)
    }

    const rejectModalContent = () => {
        return <>
            <textarea
                value={rejectSelectedJobOfferDataCommentText}
                onChange={(e) => { setRejectSelectedJobOfferDataCommentText(e.target.value) }}
                name="Comment"
                placeholder='Enter comment here'
                rows={4}
                cols={33}
            />
        </>
    }

    const cancelFun = () => {
        setRejectSelectedJobOfferDataCommentText('')
    }

    const rejectModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={rejectProfileApiCallHandler}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={cancelFun}
            >
                Cancel
            </button>
        </>;
    }

    const moreResumesModalContent = () => {
        return <>
            <textarea
                value={rejectSelectedJobOfferDataCommentText}
                onChange={(e) => { setMoreResumesDataCommentText(e.target.value) }}
                name="Comment"
                placeholder='Mention your requirement'
                rows={4}
                cols={39}
            />
        </>
    }

    const cancelMoreResumesFun = () => {
        setMoreResumesDataCommentText('')
    }

    const moreResumesModalFooterContent = (props) => {
        return <div className='d-flex align-item-center justify-content-end gap-2'>
            <button
                className="btn btn-secondary active mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={cancelMoreResumesFun}
            >
                Cancel
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={requestForResumesApiCall}
            >
                Send request
            </button>
        </div>;
    }

    const moreProfileApiCallHandler = async () => {
        const { id } = storePersist.get('userData')
        const apiReqBody = {
            // offerId: selectedUserDetails?.offer_id,
            rejectComments: moreResumesDataCommentText
        }
        requestForResumesApiCall()
        // const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
        // if (apiResponse?.data) {
        // toast.success('request sent to account manager !!')
        //     await getAllCandidatesList()
        // } else {
        //     toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        // }
    }

    const rejectProfileApiCallHandler = async () => {
        const { id } = storePersist.get('userData')
        const apiRejectReqBody = {
            offerId: selectedUserDetails?.offer_id,
            userid: id,
            candidate_id: selectedUserDetails?.candidate_id,
            comment: rejectSelectedJobOfferDataCommentText
        }
        const apiReqBody = {
            offerId: selectedUserDetails?.offer_id,
            resume_status: "notInterested",
            rejectComments: rejectSelectedJobOfferDataCommentText
        }
        const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Profile status updated successfully !!')
            await getAllCandidatesList()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }

        const apiResponseComments = await postApi(post_comment_api_url, apiRejectReqBody)
        if (apiResponseComments?.data) {
            toast.success('Comment updated successfully !!')
            await getAllCandidatesList()
            cancelFun()
        } else {
            toast.error(apiResponseComments?.response?.data.message || "Api Error Response !!")
        }

    }

    const groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );

    const cancelPopUpFun = (vale) => {
        setModalPpopUpCancel(vale)
    }

    const requestForResumesApiCall = async () => {
        dispatch(addLoader())
        const apiReqBody = {
            jobId: location?.state?.jobId,
        }
        const apiResponse = await postApi(request_for_resumes, apiReqBody)
        if (apiResponse?.data?.message) {
            toast.success("Request sent successfully !!")
            cancelFun()
            dispatch(deleteLoader())
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const showRejectedCandidateListInModal = () => {
        let allFilteredList = allCandidatesList?.map(({ offer_id }) => offer_id)

        let allFilteredListNull = allCandidatesList?.filter(({ resume_status }) => resume_status === 'notInterested')
        allFilteredListNull = allFilteredListNull?.map(({ offer_id }) => offer_id)

        // const allFilteredListUnShort = allFilteredListNull?.filter((id) => !shortListedProfiles.includes(id))
        const finalUsersDataunShortlist = returnArryOfObjects(candidatesList, allFilteredListNull, 'offer_id')

        const resultIdsShortlist = allFilteredList?.filter((id) => shortListedProfiles.includes(id));
        const finalUsersDataShortList = returnArryOfObjects(candidatesList, resultIdsShortlist, 'offer_id')

        return (<>
            <div className="overflow_y_on modal-dialog-scrollable pt-0 pb-5 ps-3 pe-3">
                <div className="col-md-12 d-flex align-content-center flex-wrap pb-2">
                    <h6>List of resumes rejected/shortlisted from the list</h6>
                    {finalUsersDataunShortlist?.length > 0 &&
                        <div className='col-md-12 pt-3'>
                            <h6 className='orange_color'>Removing Resumes :- </h6>
                            <div className='ps-5'>
                                {finalUsersDataunShortlist?.map((item) => <li className='font_color_imp'> {item?.candidateFirstName}</li>)}
                            </div>
                        </div>
                    }
                    {finalUsersDataShortList?.length > 0 &&
                        <div className='col-md-12 pt-3'>
                            <h6 className='orange_color'>Shortlisted Resumes :- </h6>
                            <div className='ps-5'>
                                {finalUsersDataShortList?.map((item) => <li className='font_dark_green_color'> {item?.candidateFirstName}</li>)}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="col-md-12 border-top position-sticky bottom-0 footer mt-auto py-0 px-3 d-flex align-item-center justify-content-center">
                <div className='col-md-6'>
                    <button
                        type="close"
                        className="mt-1 mb-1 fw-medium_imp btn btn-outline-primary bg-white btn_height_36 padding_top_6 pb-2 pe-4 ps-4 font_size_14 orange_color_border orange_color"
                        data-bs-dismiss="modal"
                        onClick={() => { cancelPopUpFun() }}
                    >
                        Cancel
                    </button>
                </div>
                <div className='col-md-6 d-flex align-item-center justify-content-end'>
                    <button
                        type="close"
                        className="mt-1 mb-1 fw-medium_imp btn btn-outline-primary bg-white btn_height_36 padding_top_6 pb-2 pe-4 ps-4 font_size_14 orange_color_border orange_color"
                        data-bs-dismiss="modal"
                        onClick={() => { onClickPageRedirection() }}
                    >
                        Ok
                    </button>
                </div>
            </div>
            <button className="d-none" id="showRejectedCandidateListInModalIdDismiss" data-bs-dismiss="modal"></button>
        </>)
    }

    const unShortListFun = () => {
        if (selectedUserDetails && selectedUserDetails?.resume_status === "interested") {
            unShortListApiCall(selectedUserDetails?.offer_id)
        }
    }

    const requestMoreResumesModal = () => {

    }

    const onClickPageRedirectionInterviewScreen = () => {
        stageUpdateProps(0)
        // navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 0 } });
    }
    const onClickPageRedirectionInterviewShort = () => {
        stageUpdateProps(1)
        // navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 1 } });
    }
    const onClickPageRedirectionInterviewOffer = () => {
        stageUpdateProps(2)
        // navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 2 } });
    }

    return (
        <>
            <div className={`my-1 bg-body rounded shadow-sm rounded_20 ${isshowDesignView ? 'height_870 py-3' : 'p-3'}`}>
                <div className="d-flex justify-content-between align-items-center border-bottom pt-2 pb-2">
                    <div className="col-xl-4 col-sm-6 ps-3">
                        <h6 className="font_color_imp font_size_16 fw-bolded">Screen Resumes & candidates</h6>
                    </div>
                    <div className={`d-flex gap-3 margin_top_neg_32 ${isshowDesignView && 'me-3'} pt-4 pb-2`}>
                        <div className='d-flex justify-content-between align-items-center p-1 px-3 gap-2 border_btn_color rounded pointer_event text_btn_color btn_bg_color' onClick={() => designViewToggleButton()}>
                            <span className='font_size_14 btn_bg_color fw-semibold'>{isshowDesignView ? 'Design View' : 'Table view'}</span>
                            <span>
                                {isshowDesignView ? <i class="fa fa-th" aria-hidden="true"></i> : <i className="fa fa-table" aria-hidden="true"></i>}
                            </span>
                        </div>
                        {oldCandidatesList.length > 0 && (selectedCandidatesStateList.length > 0 || selectedProfilesStateVal.length > 0) && (role === 'ACTMGR' || role === 'ADMIN') &&
                            <button
                                type="button"
                                className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                disabled={oldCandidatesList.length > 0 ? false : true}
                                data-bs-target="#deleteCandidateDetailsModalID"
                                data-bs-toggle="modal"
                            >
                                Delete
                            </button>
                        }
                    </div>
                </div>

                {oldCandidatesList?.length === 0 &&
                    <div className="d-flex align-content-center ps-2 pt-1 pb-3 margin_left_right_15 main_bg_interview_sheduled_card">
                        {masterData.map((item, idx) => {
                            return (<div className={`col-md-3 d-flex justify-content-center align-content-center`} key={idx}>
                                <div className="col-xs-12 align-middle">
                                    <div className="col-xs-6 me-3">
                                        <img
                                            src={require(`../../assets/images/${item.img}`)}
                                            alt="jobType"
                                            className="img_w_h_23"
                                        />
                                    </div>
                                    <div className="col-xs-6">
                                        <div className="fw-700 font_size_14 mb-1">{item.label}</div>
                                        <div className="fw-medium font_size_14">{(location?.state?.jobDetails && location?.state?.jobDetails[item.response] || currentJobData && currentJobData[item.response])}</div>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                }

                {isshowDesignView && oldCandidatesList.length > 0 &&
                    <>
                        <div className="left-sidebar ps-2 pt-1">
                            <div>
                                {isshowDesignView && oldCandidatesList.length > 0 &&
                                    <div className="d-flex align-content-center pb-3 vw-59">
                                        {masterData.map((item, idx) => {
                                            return (<div className={`col-md-3 d-flex justify-content-start align-content-center`} key={idx}>
                                                <div className="col-xs-12 align-middle">
                                                    <div className="col-xs-6 me-3">
                                                        <img
                                                            src={require(`../../assets/images/${item.img}`)}
                                                            alt="jobType"
                                                            className="img_w_h_23"
                                                        />
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <div className="fw-700 font_size_14 mb-1">{item.label}</div>
                                                        <div className="fw-medium font_size_14">{selectedUserDetails && selectedUserDetails[item.response]}</div>
                                                    </div>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                }
                                <div className='border_cls vh-90 vw-57'>
                                    {isshowDesignView && oldCandidatesList.length > 0 &&
                                        <>
                                            {/* <iframe
                                                style={{ width: '100%', height: '100%', border: 'none' }}
                                                src={`https://docs.google.com/viewer?url=${selectedUserDetails?.file_location}&embedded=true`}
                                                // src={selectedUserDetails?.file_location + "#frameborder=0"}//{'https://arxiv.org/pdf/quant-ph/0410100.pdf'}
                                                title="candidate resume"
                                                frameborder="0"
                                                toolbar="0"
                                                allowfullscreen
                                            // type="application/pdf"
                                            // width="100%"
                                            // height="100%"
                                            // webkitallowfullscreen
                                            // mozallowfullscreen
                                            // content-disposition= {'attachment'}
                                            >
                                            </iframe> */}
                                            <iframe
                                                style={{ width: '100%', height: '100%', border: 'none' }}
                                                // src={`https://docs.google.com/viewer?url=${selectedUserDetails?.file_location}&embedded=true`}
                                                // src={selectedUserDetails?.file_location + "#frameborder=0"}//{'https://arxiv.org/pdf/quant-ph/0410100.pdf'}
                                                src={selectedUserDetails?.file_location}
                                                title="candidate resume"
                                                frameborder="0"
                                                toolbar="0"
                                                allowfullscreen
                                            // type="application/pdf"
                                            // width="100%"
                                            // height="100%"
                                            // webkitallowfullscreen
                                            // mozallowfullscreen
                                            // content-disposition= {'attachment'}
                                            >
                                            </iframe>
                                        </>
                                    }
                                    <div className="col-md-12 border_dark footer mt-auto py-2 px-3 bg-white">
                                        <div className="d-flex align-item-center justify-content-end">
                                            {isshowDesignView && oldCandidatesList.length > 0 &&
                                                <>
                                                    <button
                                                        type="close" // btn btn-primary bg_color_ornage btn btn-outline-primary bg-white orange_color white_font_color
                                                        className="mt-1 mb-1 fw-medium_imp btn btn-primary bg_color_ornage white_font_color btn_height_36 padding_top_6 pb-2 pe-2 ps-2 font_size_14 orange_color_border me-3"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#isOpenShortListRejectModalPopUpId"
                                                        onClick={() => { rejectJobOfferFun(selectedUserDetails) }}
                                                        disabled={selectedUserDetails?.resume_status === 'nonInterested'}
                                                    >
                                                        <span>Not Interested</span>
                                                        <img
                                                            src={require(`../../assets/images/cancel_reject_icon.svg`).default}
                                                            alt="jobType"
                                                            className="img_w_h_23 ms-1"
                                                        />
                                                    </button>
                                                    {selectedUserDetails?.resume_status === 'interested' ?
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn_border pb-2 pe-2 ps-2 font_size_14 fw-medium btn_height_36 mt-1 me-3 padding_top_6"
                                                            onClick={() => { unShortListFun() }}
                                                        // disabled={selectedUserDetails?.resume_status === 'rejected'}
                                                        >
                                                            un-Shortlist
                                                            <img
                                                                src={require(`../../assets/images/short_list_icon.svg`).default}
                                                                alt="jobType"
                                                                className="img_w_h_15 ms-2"
                                                            />
                                                        </button>
                                                        :
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn_border pb-2 pe-2 ps-2 font_size_14 fw-medium btn_height_36 mt-1 me-3 padding_top_6"
                                                            onClick={() => { onClickShortListProfile() }}
                                                        // disabled={selectedUserDetails?.resume_status === 'rejected'}
                                                        >
                                                            Shortlist Resume
                                                            <img
                                                                src={require(`../../assets/images/short_list_icon.svg`).default}
                                                                alt="jobType"
                                                                className="img_w_h_15 ms-2"
                                                            />
                                                        </button>
                                                    }
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-primary btn_border pb-2 pe-2 ps-2 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6 me-3"
                                                        onClick={() => { downloadPdf() }}
                                                    >
                                                        {/* <a href={selectedUserDetails?.file_location} download={selectedUserDetails?.fileName} target="_blank" rel="noreferrer" type="application/octet-stream"> */}
                                                        Download Resume
                                                        <img
                                                            src={require(`../../assets/images/download_icon.svg`).default}
                                                            alt="jobType"
                                                            className="img_w_h_23 ms-1"
                                                        />
                                                        {/* </a> */}
                                                    </button>
                                                </>
                                            }
                                            <button
                                                type="button"
                                                className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                                                // onClick={() => { onClickPageRedirection() }}
                                                data-bs-toggle="modal"
                                                data-bs-target="#showRejectedCandidateListInModalId"
                                                disabled={shortListedProfiles?.length === 0}
                                            >
                                                Next
                                                <i className="fa fa-angle-right ps-2"></i>
                                            </button>
                                        </div>
                                        <div className='mt-4'>
                                            {(role === 'COMPANY' || role === 'USER') &&
                                                <div className='font_dark_color fw-bold font_dark_color_underline hover_underline hover_orange'
                                                    // onClick={reSendInvite}
                                                    data-bs-target="#requestMoreResumesModalId"
                                                    data-bs-toggle="modal"
                                                >
                                                    Send Request if you did not short any resume
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isshowDesignView && oldCandidatesList.length > 0 &&
                                <div className='pt-1_8 vw-22'>
                                    <span><strong>Profiles Updated : </strong> </span>
                                    {new Date().toLocaleDateString('en-GB', {
                                        day: 'numeric', month: 'short', year: 'numeric'
                                    }).replace(/ /g, '-')}
                                    {/* overflow-auto-y */}
                                    <PerfectScrollbar
                                        className="vh-90 mt-3offer/getShortlisted"
                                        options={{ suppressScrollX: true }}
                                    >
                                        {Object.keys(groupBy(allCandidatesList, 'resume_status'))?.map((key, id) =>
                                            <>
                                                <div className={`font_size_12 fw-bold font_color_ornage ${id === 0 ? 'mt-4' : 'mt-2'}`}>{['rejected', 'notInterested'].includes(key) ? 'Not Interested' : ['null', null].includes(key) ? 'New Resumes' : key} :- </div>
                                                {groupBy(allCandidatesList, 'resume_status')[key]?.map((item, idx) => {
                                                    const userName = `${item?.name} ${item?.candidateFirstName}`;
                                                    const color = generateColorHsl(userName, saturationRange, lightnessRange);
                                                    const initials = getInitials(item?.candidateFirstName);
                                                    return (
                                                        <div
                                                            className={`mt-2 border_cls pb-1 pt-3 main_bg_interview_sheduled_card pointer_event ${selectedUserDetails?.offer_id === item?.offer_id && 'selected_card_border'} ${item?.resume_status === 'interested' ? 'bg_color_user_details_selected_item' : ['rejected', 'notInterested'].includes(item?.resume_status) ? 'bg_color_shortlisted_resume_list_cls' : ''}`}
                                                            key={idx}
                                                            onClick={() => { userDetailsOnClikHandler(item); }}
                                                            title={item?.rejectComments}
                                                        >
                                                            <div className="d-flex justify-content-center align-items-center border-bottom_2">
                                                                <div className="col-md-2 ps-4_5 mb-2">
                                                                    <div className="row1" key={idx}>
                                                                        <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
                                                                    </div>
                                                                    {/* <div className="position-relative rounded-circle overflow-hidden mx-auto custom-circle-image border blackcircle">
                                                                    <img className="w-100 h-100 whitecircle rounded-circle" alt="Alt content" src={require('../../assets/images/user-profile-icon.svg').default} />
                                                                </div> */}
                                                                </div>
                                                                <div className="col-md-10 ps-5">
                                                                    <div>
                                                                        <h6 className={`pb-1 mb-0 font_color_imp fw-medium font_size_14 ${selectedUserDetails?.offer_id === item?.offer_id && 'red_color'}`}>{item.candidateFirstName}</h6>
                                                                        <span className={`font_color_imp fw-medium font_size_14 ${selectedUserDetails?.offer_id === item?.offer_id && 'red_color'}`}>{item.profileTitle}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 d-flex justify-content-start align-items-center margin_top_neg_60">
                                                                    {shortListedProfiles?.includes(item?.offer_id) &&
                                                                        <img
                                                                            src={require(`../../assets/images/scheduledInterviewRibbon.svg`).default}
                                                                            alt="jobType"
                                                                            className="img_w_h_18"
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='p-2'>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className='fw-medium font_size_14'>Years of experience</div>
                                                                    <div>{item.candidateExperience}</div>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className='fw-medium font_size_14'>Profile match your requirement</div>
                                                                    <div>{item.profileMatch || 'Yes'}</div>
                                                                </div>
                                                            </div>
                                                            {/* <span class="tooltiptext">{item?.rejectComments}</span> */}
                                                        </div>
                                                    )
                                                }
                                                )}
                                            </>
                                        )}
                                    </PerfectScrollbar>
                                    <div className='mt-3 d-flex align-items-center'>
                                        <img
                                            src={require(`../../assets/images/scheduledInterviewRibbon.svg`).default}
                                            alt="shortListedResumes"
                                            className="img_w_h_18"
                                        />
                                        <span className='ps-1'>{`${shortListedProfiles?.length} of ${allCandidatesList?.length} profiles Shortlisted`}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }
                {oldCandidatesList.length === 0 &&
                    <>
                        <div className="disply_block_t_center">
                            <div className={`vh-60 font_size_24 disply_block_t_center ${Object.keys(currentJobData)?.length > 0 ? 'p-4' : 'padding_15_p'}`}>
                                {Object.keys(currentJobData)?.length > 0 &&
                                    <div className='p-3'>
                                        <img
                                            src={require(`../../assets/images/no_cv_img.svg`).default}
                                            alt="Sow"
                                            className="w-20"
                                        />
                                        {(currentJobData?.newResumeCount > 0 || currentJobData?.jobOfferedCount > 0 || currentJobData?.resumeShortlistedCount > 0) &&
                                            <>
                                                <div className='fw_bold font_dark_color p-2 pb-1 font_size_16'>Candidate resumes is on below state please click on it</div>
                                                <div className='fw_bold font_dark_color p-2 pb-1 font_size_16'> to go to current stage of candidate</div>
                                            </>
                                        }
                                    </div>
                                }

                                {Object.keys(currentJobData)?.length > 0 &&
                                    <div className=''>
                                        {(currentJobData?.resumeShortlistedCount > 0)
                                            &&
                                            <div className='font_dark_green_color hover_underline pb-1 font_size_18' onClick={() => { onClickPageRedirectionInterviewShort(currentJobData) }}>{`Shortlisted (${currentJobData.resumeShortlistedCount})`}</div>
                                        }
                                        {currentJobData?.newResumeCount > 0
                                            &&
                                            <div className='orange_color hover_underline pb-1 font_size_18' onClick={() => { onClickPageRedirectionInterviewScreen(currentJobData) }}>{`Screening (${currentJobData.newResumeCount})`}</div>
                                        }
                                        {/* currentJobData?.job_status === "open" && */}
                                        {currentJobData?.newResumeCount === 0 && currentJobData?.jobOfferedCount === 0 && currentJobData?.resumeShortlistedCount === 0 &&
                                            <div>
                                                {role === 'ACTMGR'
                                                    ?
                                                    <>
                                                        {/* <label className='fw_bold font_color_ornage'>No data found / No resumes found for Screening</label> */}
                                                        <div className='font_size_17 text-center line_height_23'>
                                                            Please wait for few hours, Your assigned account manager will upload
                                                            <br />
                                                            suitable resumes of candidates as per your requirement. You will be notified through email.
                                                        </div>
                                                        <div className='font_dark_color pt-4'>
                                                            <label>{'please conatct below admin for more info.'}</label>
                                                            <div className="my-3 p-3 mt-4 bg-body rounded shadow-sm rounded_20 font_size_18 w-50 margin_left_25">
                                                                <div className="gap-1">
                                                                    <label>Name:</label>
                                                                    <span className="ms-1">Sayed</span>
                                                                </div>
                                                                <div className="gap-1">
                                                                    <label>Email ID:</label>
                                                                    <span className="ms-1">sayed@remote72.com</span>
                                                                </div>
                                                                <div className="gap-1">
                                                                    <label>cell Number:</label>
                                                                    <span className="ms-1">741914007</span>
                                                                </div>
                                                                <div className="gap-1">
                                                                    <label>Location:</label>
                                                                    <span className="ms-1">Kolkata</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div>
                                                        <div className='font_size_17 text-center line_height_23'>
                                                            Please wait for few hours, account manager will upload
                                                            <br />
                                                            suitable resumes of candidates as per your requirement. You will be notified through email.
                                                        </div>
                                                        <button
                                                            // className='font_dark_color fw-bold font_dark_color_underline hover_underline hover_orange'
                                                            // onClick={reSendInvite}
                                                            data-bs-target="#requestMoreResumesModalId"
                                                            data-bs-toggle="modal"
                                                            type="button"
                                                            className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-3 me-3 padding_top_6"
                                                        >
                                                            Send request if your wait time exceeds 24 hours
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {currentJobData?.jobOfferedCount > 0
                                            &&
                                            <>
                                                <div className='orange_color hover_underline pb-2' onClick={() => { onClickPageRedirectionInterviewOffer(currentJobData) }}>{`Job offered (${currentJobData.jobOfferedCount})`}</div>
                                            </>
                                        }
                                    </div>
                                }
                                <div className='mt-5'>
                                    {((role === 'COMPANY' || role === 'USER') && accountManagerListOfCmp?.length > 0 &&
                                        currentJobData?.newResumeCount > 0 && currentJobData?.jobOfferedCount > 0 && currentJobData?.resumeShortlistedCount > 0) ?
                                        <button
                                            // className='font_dark_color fw-bold font_dark_color_underline hover_underline hover_orange'
                                            // onClick={reSendInvite}
                                            data-bs-target="#requestMoreResumesModalId"
                                            data-bs-toggle="modal"
                                            type="button"
                                            className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 me-3 padding_top_6"
                                        >
                                            Send request if your wait time exceeds 24 hours
                                        </button>
                                        :
                                        <>
                                            {accountManagerListOfCmp?.length === 0 && accountManagerListOfCmp?.map((item) =>
                                                <>
                                                    <label>{'Account Manager is not assigned, please conatct below admin'}</label>
                                                    <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
                                                        <div className="gap-1">
                                                            <label>Name:</label>
                                                            <span className="ms-1">Sayed</span>
                                                        </div>
                                                        <div className="gap-1">
                                                            <label>Email ID:</label>
                                                            <span className="ms-1">sayed@remote72.com</span>
                                                        </div>
                                                        <div className="gap-1">
                                                            <label>cell Number:</label>
                                                            <span className="ms-1">741914007</span>
                                                        </div>
                                                        <div className="gap-1">
                                                            <label>Location:</label>
                                                            <span className="ms-1">Kolkata</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 footer mt-auto py-2 px-3 bg-white  d-flex align-item-center justify-content-end">
                            {/* <button
                                type="button"
                                className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                                onClick={() => { onClickPageRedirection() }}
                            >
                                Next
                                <i className="fa fa-angle-right ps-2"></i>
                            </button> */}
                        </div>
                    </>
                }

                {/* Table start */}
                {!isshowDesignView && oldCandidatesList.length > 0 &&
                    <>
                        <div className="mt-3 mb-0 pe-2">
                            <div className="col-md-12 d-flex justify-content-end align-items-center gap-4">
                                <div className="col-md-4 d-flex justify-content-end align-items-center gap-1">
                                    {/* <div className="dropdown table-action-dropdown">
                                        <button
                                            className="border-0 mt-0 mb-0 pb-0 bg-white dropdown-toggle"
                                            id="dropdownMenuButtonSM"
                                            data-bs-toggle="dropdown"
                                        >
                                            <img src={require('../../assets/images/vector_filter.svg').default} alt="Alt content" />
                                        </button>
                                        <ul className="dropdown-menu shadow-sm-dd font_size_14" aria-labelledby="dropdownMenuButtonSM">
                                            {[{ value: 'Accepted' }, { value: 'Rejected' }].map((item, idx) =>
                                                <li className={`pt-2 pb-2 ps-4 pe-2 d-flex align-items-center pointer_event opacity_dull ${2 !== idx + 1 && 'border-bottom'}`}
                                                    onClick={() => { selctedFilterOption(item) }}
                                                >
                                                    {item.value}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <RangeDatePicker /> */}
                                </div>
                                <div className="col-md-3">
                                    <input
                                        type="search"
                                        value={keywordName}
                                        onChange={filterTextSearch}
                                        className="searchInputCls"
                                        placeholder="Search Job type, skills etc..."
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row bd-example">
                                <div className="table-container">
                                    <div className="d-flex text-muted height_job_post_table">
                                        <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                        </th>
                                                        {['First Name', 'Mail ID', 'Profile Title', 'Experience', 'Skillset', 'Onboard status', 'Resume']
                                                            .map((item, idx) =>
                                                                <th key={idx} className={`text-white`}>
                                                                    {item}
                                                                </th>
                                                            )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentTableData().map((item, idx) => {
                                                        return (
                                                            <tr key={item.offer_id}>
                                                                <td>
                                                                    <input
                                                                        className="width_12"
                                                                        type="checkbox"
                                                                        checked={isUserSelected(item)}
                                                                        onChange={() => handleUserSelect(item)}
                                                                    />
                                                                </td>
                                                                <td>{item.candidateFirstName}</td>
                                                                <td>{item.candidateEmailId}</td>
                                                                <td>{item.profileTitle}</td>
                                                                <td>{(item.candidateExperience || 0) + ' Years'}</td>
                                                                <td>{item?.candidateSkillset?.join()}</td>
                                                                <td><b>{item.onboarding_status || 'Screening'}</b></td>
                                                                <td>
                                                                    <span
                                                                        className="d-flex justify-content-between align-items-center gap-2 border_color py-1 px-2"
                                                                        onClick={() => {
                                                                            downloadPdfTable(item)
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={require(`../../assets/images/pdf-file-icon.svg`).default}
                                                                            alt="Sow"
                                                                            className="img_w_h_18"
                                                                        />
                                                                        <div
                                                                            className="fw-medium font_size_12 border-1"
                                                                        >
                                                                            {item?.candidateCvfileName}
                                                                        </div>
                                                                        <img
                                                                            src={require(`../../assets/images/download_gray_edge.svg`).default}
                                                                            alt="Sow"
                                                                            className="img_w_h_15"
                                                                        />
                                                                    </span>

                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </PerfectScrollbar>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <CustomSingleSelect
                                            options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                                            selectedPage={pageSize}
                                            onChangeHandler={onChangeHandler}
                                        />
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={candidatesList.length}
                                            pageSize={pageSize?.label}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {!isshowDesignView && oldCandidatesList.length === 0 &&
                    <>
                        {/* <div className="row bd-example">
                            <div className="table-container">
                                <div className="text_v_h_center vh-80 font_size_24">
                                    No Data Found
                                </div>
                            </div>
                        </div> */}
                        <div className="disply_block_t_center">
                            <div className="vh-60 font_size_13 disply_block_t_center padding_15_p">
                                <div className='font_size_17 text-center line_height_23'>
                                    Please wait for few hours, Your assigned account manager will upload
                                    <br />
                                    suitable resumes of candidates as per your requirement. You will be notified through email.
                                </div>
                                <div className='mt-5'>
                                    {(role === 'COMPANY' || role === 'USER') && accountManagerListOfCmp?.length > 0 ?
                                        <div
                                            // className='font_dark_color fw-bold font_dark_color_underline hover_underline hover_orange'
                                            // onClick={reSendInvite}
                                            data-bs-target="#requestMoreResumesModalId"
                                            data-bs-toggle="modal"
                                            type="button"
                                            className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 me-3 padding_top_6"
                                        >
                                            Send request if your wait time exceeds 24 hours
                                        </div>
                                        :
                                        <>
                                            {accountManagerListOfCmp?.length === 0 && accountManagerListOfCmp?.map((item) =>
                                                <>
                                                    <label>{'Account Manager is not assigned, please conatct below admin'}</label>
                                                    <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
                                                        <div className="gap-1">
                                                            <label>Name:</label>
                                                            <span className="ms-1">Sayed</span>
                                                        </div>
                                                        <div className="gap-1">
                                                            <label>Email ID:</label>
                                                            <span className="ms-1">sayed@remote72.com</span>
                                                        </div>
                                                        <div className="gap-1">
                                                            <label>cell Number:</label>
                                                            <span className="ms-1">741914007</span>
                                                        </div>
                                                        <div className="gap-1">
                                                            <label>Location:</label>
                                                            <span className="ms-1">Kolkata</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div >
            {/* delete Company Details modal */}
            < ModalComponent
                title="Do you want delete selected Profiles ?"
                footerContent={deleteModalFooterContent()}
                content={deleteModalContent()}
                dataBsBackdrop="static"
                id="deleteCandidateDetailsModalID"
                w_max_content={true}
            />
            {/* Reject Offer Modal */}
            < ModalComponent
                title="Confirmation !!"
                footerContent={rejectModalFooterContent()}
                content={rejectModalContent()}
                dataBsBackdrop="static"
                id="isOpenShortListRejectModalPopUpId"
                w_max_content={true}
            />
            <ModalComponent
                title={`Confimation !!`}
                content={showRejectedCandidateListInModal()}
                // dataBsBackdrop="static"
                // id="UploadResumeJobDetailsModalIds"
                // modalBodyPaddingBottomCls="p-0"
                // cancelPopUpFun={cancelPopUpFun}
                // fullScreen={true}
                cancelPopUpFun={cancelPopUpFun}
                dataBsBackdrop="static"
                id="showRejectedCandidateListInModalId"
            // modalDialogClass="view_job_details_modal_w"
            // modalBodyPaddingBottomCls="p-0 overflow_custom"
            // modalBodyPaddingBottomCls="p-0"
            //   fullScreen={true}
            // isHeaderHide={true}
            />
            <ModalComponent
                title="Request More Resumes !!"
                footerContent={moreResumesModalFooterContent()}
                content={moreResumesModalContent()}
                dataBsBackdrop="static"
                id="requestMoreResumesModalId"
                w_max_content={true}
                footerRight={true}
            />

        </>
    )
}

export default ShortListResumesComponent

// export default adminLayout(ShortListResumesComponent)

const masterData = [
    { label: 'Company Name', response: 'companyName', img: 'skills_needed.svg' },
    { label: 'Job Type', response: 'job_type', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', img: 'Y_O_Exp.svg' },
]