import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { persistor, store } from "../redux/store";
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "./RadialSeparators";
import { addTour, deleteTour } from "../redux/actions/tour";
import storePersist from '../Utils/storePersist'
import ModalComponent from "../components/ModalComponent";
import { addLoader, deleteLoader } from "../redux/actions/loader";
import { logOutAllStores } from '../redux/actions/logOut';
import { getAllApi, postApi } from '../services/apiCommonService';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import {
    get_all_companies_list_api_url, get_job_list_api_url, usergetAllQueryTickets_url, get_verify_all_user_url,
    post_acct_manager_companies, get_jobs_by_company_id, get_Company_ActMgr_url
} from '../services/endPointsConstant'
import { addDefaultCompanyDetails, deleteDefaultCompanyDetails } from "../redux/actions/defaultCompanyDetails";
import EditCompanyDetails from "../pages/adminComponents/companies/EditCompanyDetails";
import ViewCompanyDetails from "../pages/adminComponents/companies/ViewCompanyDetails";

const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
};

const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
    return `${user[0]?.toUpperCase()}${user[1]?.toUpperCase()}`
}

const setValue = (functionFor) => {
    return (e) => {
        const value = parseInt(e.target.value);
        functionFor(value);
    }
};

const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
}

function Example(props) {
    return (
        <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'baseline', gap: 6, paddingTop: 4 }}>
            <div style={{ width: "10%" }}>{props.children}</div>
            <div style={{ width: "" }}>
                <h3 className="h6">{props.label}</h3>
                <p>{props.description}</p>
            </div>
        </div>
    );
}

const Header = (props) => {
    let navigate = useNavigate();
    const { user, email, role, id } = storePersist.get('userData')
    const dispatch = useDispatch();
    const [allCompaniesList, setAllCompaniesList] = useState([]);
    const [allCompaniesOldList, setAllCompaniesOldList] = useState([]);
    const [viewSelectedCompanyDetails, setViewSelectedCompanyDetails] = useState('')
    const [modalPopUpCancel, setModalPopUpCancel] = useState(false);
    const [accountManagerListOfCmp, setAccountManagerListOfCmp] = useState([])

    const { total_completed } = useSelector((state) => state.employeeStepsBarReducer)
    const [range, setRange] = React.useState(10);
    const [saturation, setSaturation] = React.useState(50);
    const [lightness, setLightness] = React.useState(50);
    const [theme, setTheme] = React.useState('Light');

    const [isCheck1, setCheckBox1] = useState(true)
    const [isCheck2, setCheckBox2] = useState(true)
    const [isCheck3, setCheckBox3] = useState(true)
    const [isCheck4, setCheckBox4] = useState(true)
    const [activateUsers, setActivateUsers] = useState([])
    const [ticketsList, setTicketsList] = useState([])

    const saturationRange = getRange(saturation, range);
    const lightnessRange = getRange(lightness, range);

    useEffect(() => {
        dispatch(addTour({ steps: customSteps }))
        void getAllCompaniesListApi()
        void accountMangerDetailsFunApiCall()
        void getCandidatesApiCallFun()
        void getTicketsApiCallFun()
    }, []);

    const getCandidatesApiCallFun = async () => {
        const apiReqBody = {}
        const apiResponse = await getAllApi(get_verify_all_user_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            setActivateUsers(apiResponseData)
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const getTicketsApiCallFun = async () => {
        const apiReqBody = {}
        const apiResponse = await getAllApi(usergetAllQueryTickets_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData?.filter((item) => item.isTicketResolved === false)
            setTicketsList(apiResponseData)
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const accountManagerCompanyObject = async () => {
        const apiReqBody = {
            actMgrId: id
        }
        const apiResponse = await postApi(post_acct_manager_companies, apiReqBody)
        if (apiResponse?.data) {
            // let { user } = storePersist.get('userData')
            // const orgData = storePersist.get('userData')
            // const newUser = { ...user, companyId: apiResponse?.data?.Data[0]._id }
            // storePersist.set('userData', { ...orgData, user: newUser })
            return apiResponse?.data
        } else {
            toast.error(apiResponse?.response?.data?.message + '!!')
        }
    }

    const accountMangerDetailsFunApiCall = async () => {
        const companyDetailsStorage = storePersist.get('companyDetails')
        const apiReqBody = { companyId: companyDetailsStorage && companyDetailsStorage?._id }
        if (companyDetailsStorage && companyDetailsStorage?._id) {
            const apiResponse = await postApi(get_Company_ActMgr_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                setAccountManagerListOfCmp(apiResponse?.data?.Data)
                return apiResponse?.data?.Data
            } else {
                toast.error(apiResponse?.response?.data?.message + '!!')
            }
        }
    }

    const getAllCompaniesListApi = async () => {
        dispatch(addLoader());
        const apiResponse = await getAllApi(get_all_companies_list_api_url)
        if (apiResponse?.data?.Data) {
            let actMgrCompanies = []
            if (role === 'ACTMGR') {
                actMgrCompanies = await accountManagerCompanyObject()
            }
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()?.filter((item) => item._id === user?.companyId)//item.companyEmail === email
            setAllCompaniesList(apiResponseData)
            setAllCompaniesOldList(apiResponseData)
            dispatch(addDefaultCompanyDetails(apiResponseData));
            // storePersist.set('companyDetails', apiResponseData ? apiResponseData : '')
            getJobListApi()
            // toast.success("Retrived all Companies List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const getJobListApi = async () => {
        dispatch(addLoader());
        // const apiResponse = await getAllApi(get_job_list_api_url)
        const companyDetailsStorage = storePersist.get('companyDetails')
        const apiReqBody = { companyId: companyDetailsStorage && companyDetailsStorage?._id }
        const apiResponse = await postApi(get_jobs_by_company_id, apiReqBody)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            // storePersist.set('jobDetails', apiResponseData ? apiResponseData : '')
            // toast.success("Retrived all Job List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const onSubmit = () => {
        storePersist.clear();
        // persistor.pause();
        // persistor.flush().then(() => {
        //   return persistor.purge();
        // });
        store.dispatch({
            type: 'RESET'
        })

        // dispatch(logOutAllStores())
        if (role === 'ACTMGR') {
            navigate('/act-manager-login');
            document.location.reload()
        } else if (role === 'COMPANY') {
            navigate('/login');
            document.location.reload()
        } else if (role === 'ADMIN') {
            navigate('/admin-login/665e00c8ae3f8bee10e638df');
            document.location.reload()
        } else if (role === 'EMPLOYEE') {
            navigate('/employee-login');
            document.location.reload()
        }
    };

    const navigateTickets = () => {
        navigate('/resolved-tickets-list')
    }

    const navigatePendingRequest = () => {
        navigate('/activate-users-list')
    }

    const navigateToStepsbar = async () => {
        dispatch(addLoader());
        navigate('/Employee-steps-bar')
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 2500);
    }

    const modalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={onSubmit}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
            >
                Cancel
            </button>
        </>;
    }

    const modalContentAdminActMangerSettings = () => {
        return <>
            <b className="font_color_ornage">Notification Settings</b>
            <div>
                <div className="my-3 p-3 mt-0">
                    <div className="gap-1">
                        <div>
                            <b>On-Boarded:</b>
                        </div>
                        <div className="ms-3 pt-2 pb-1">
                            <div className="d-flex justify-content-start align-items-center">
                                <span><input className="form-check-input form-check-padding me-2" type="checkbox" name="" checked={isCheck1} onChange={() => { setCheckBox1(!isCheck1) }} /></span>
                                <span className="font_size_14"> Candidate On Boarded ? </span>
                            </div>
                        </div>
                    </div>
                    <div className="gap-1">
                        <div>
                            <b>Account Manager:</b>
                        </div>
                        <div className="ms-3 pt-2 pb-1">
                            <div className="d-flex justify-content-start align-items-center">
                                <span><input className="form-check-input form-check-padding me-2" type="checkbox" name="" checked={isCheck2} onChange={() => { setCheckBox1(!isCheck2) }} /></span>
                                <span className="font_size_14"> AccountManager On Boarded ? </span>
                            </div>
                        </div>
                    </div>
                    <div className="gap-1">
                        <div>
                            <b>Comapany:</b>
                        </div>
                        <div className="ms-3 pt-2 pb-1">
                            <div className="d-flex justify-content-start align-items-center">
                                <span><input className="form-check-input form-check-padding me-2" type="checkbox" name="" checked={isCheck3} onChange={() => { setCheckBox1(!isCheck3) }} /></span>
                                <span className="font_size_14"> Comapany On Boarded ? </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b className="font_color_ornage">EMail Notification Settings</b>

        </>
    }

    const modalContentSettings = () => {
        return <>
            <b>{accountManagerListOfCmp?.length > 1 ? 'Account Managers Details' : accountManagerListOfCmp?.length > 0 ? 'Account Manager Detail' : 'Account Manager is not assigned, please conatct below admin'}</b>
            <div>
                {accountManagerListOfCmp.length > 0 && accountManagerListOfCmp?.map((item) =>
                    <div>
                        {item?.actMgr?.map((item, idx) =>
                            <div className="my-3 p-3 mt-2">
                                <label>{idx === 0 ? 'Primary Contact:' : idx === 1 ? 'Secondary Contact' : 'Last Contact'}</label>
                                <div className="p-3 bg-body rounded shadow-sm rounded_20">
                                    <div className="gap-1">
                                        <label>Name:</label>
                                        <span className="ms-1">{item?.name}</span>
                                    </div>
                                    <div className="gap-1">
                                        <label>Email ID:</label>
                                        <span className="ms-1">{item?.emailId}</span>
                                    </div>
                                    <div className="gap-1">
                                        <label>cell Number:</label>
                                        <span className="ms-1">{item?.phoneNumber}</span>
                                    </div>
                                    <div className="gap-1">
                                        <label>Location:</label>
                                        <span className="ms-1">{item?.location}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                )}
                {accountManagerListOfCmp?.length === 0 &&
                    <>
                        <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20 font_size_12">
                            <span className="font_size_12">
                                Please wait we will assign a account manager soon, later you request for the profiles or account manager will upload the resumets
                            </span>
                        </div>
                        <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
                            <div className="gap-1">
                                <label>Name:</label>
                                <span className="ms-1">Sayed</span>
                            </div>
                            <div className="gap-1">
                                <label>Email ID:</label>
                                <span className="ms-1">sayed@remote72.com</span>
                            </div>
                            <div className="gap-1">
                                <label>cell Number:</label>
                                <span className="ms-1">741914007</span>
                            </div>
                            <div className="gap-1">
                                <label>Location:</label>
                                <span className="ms-1">Kolkata</span>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    }

    const modalContentHelp = () => {
        return <>
            <b>Please conatct below admin for more details</b>
            <div>
                <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
                    <div className="gap-1">
                        <label>Name:</label>
                        <span className="ms-1">Sayed</span>
                    </div>
                    <div className="gap-1">
                        <label>Email ID:</label>
                        <span className="ms-1">sayed@remote72.com</span>
                    </div>
                    <div className="gap-1">
                        <label>cell Number:</label>
                        <span className="ms-1">741914007</span>
                    </div>
                    <div className="gap-1">
                        <label>Location:</label>
                        <span className="ms-1">Kolkata</span>
                    </div>
                </div>

            </div>
        </>
    }

    const modalFooterContentRightSideBar = (props) => {
        return <>
            <div></div>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
            >
                Cancel
            </button>
        </>;
    }

    const modalContent = (props) => {
        return <>Do you want to log out? </>;
    }

    const cancelPopUpFun = (vale) => {
        setModalPopUpCancel(vale)
    }

    const returnedEditCompanyFormDetails = (formDetails) => {
        void getAllCompaniesListApi()
        // setAllCompaniesList([...allCompaniesList, formDetails])
    }

    const editModalContent = () => {
        return <>
            <EditCompanyDetails
                returnedEditCompanyFormDetails={returnedEditCompanyFormDetails}
                modalPopUpCancel={modalPopUpCancel}
                selectedCompanyDetailsObject={allCompaniesOldList[0] || {}}
            />
        </>;
    }

    const viewModalContent = (props) => {
        return <>
            <ViewCompanyDetails
                viewSelectedCompanyDetails={viewSelectedCompanyDetails || undefined}
                allCompaniesOldList={allCompaniesOldList}
                selectedRecordDataFun={selectedRecordDataFun}
                isDeleteHide={true}
            />
        </>;
    }

    const viewSelectedCompanyDetailsFun = (item, idx) => {
        // selectedRecordDataFun(item)
        item = { ...item, emailId: item?.actMgr?.length > 0 && item?.actMgr[0]?.emailId }
        setViewSelectedCompanyDetails({ pageData: item, idxValue: idx })
    }

    const selectedRecordDataFun = (selected_job_data) => {
    }

    const userName = `${user} ${user}`;
    const color = generateColorHsl(userName, saturationRange, lightnessRange);
    const initials = getInitials(user?.company_name);

    return <>
        <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top border-bottom ">
            <div className="container-fluid">
                <button
                    className="navbar-toggler m-0 pt-1 pb-0 bg-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mt-0 mt-lg-0 my-3-step">
                        {role === 'EMPLOYEE' && total_completed !== 100 && <li className="nav-item dropdown mt-0 my-4-step">
                            <button type="button" className="m-0 pt-0 pb-0 bg-white focus_onhover fw-bold" onClick={() => { navigateToStepsbar() }}>
                                <span class="flashe">
                                    <Example label="Update profile">
                                        {/* https://codesandbox.io/p/sandbox/vymm4oln6y?file=%2Findex.js%3A33%2C1-34%2C1 */}
                                        <CircularProgressbar
                                            value={total_completed}
                                            text={`${total_completed}%`}
                                            styles={buildStyles({
                                                textColor: "green",
                                                pathColor: "green",
                                                //   trailColor: "gold"
                                            })} />
                                    </Example>
                                </span>
                            </button>
                        </li>
                        }
                        <li className="nav-item dropdown my-4-step margin_right_10">
                            <button type="button" className="m-0 pt-2 pb-0 bg-white focus_onhover" data-bs-toggle="modal" data-bs-target="#helprightModalDefault">
                                <div> <i className="fa fa-question-circle mb-0 pb-1 d-block" aria-hidden="true" style={{ fontSize: 27 }}></i> </div>
                                <span className="header_icons_cls">Help</span>
                            </button>
                        </li>
                        {role === 'ADMIN' &&
                            <li className="nav-item dropdown mt-3 my-4-step margin_right_12">
                                <button type="button" className="m-0 pt-1 pb-0 bg-white focus_onhover button_badge_css">
                                    <i className="fa fa-globe" aria-hidden="true" style={{ fontSize: 27 }} onClick={() => { navigatePendingRequest() }}></i>
                                    {activateUsers?.length > 0 && <span class="button__badge">{activateUsers?.length}</span>}
                                </button>
                            </li>
                        }

                        {role === 'ADMIN' &&
                            <li className="nav-item dropdown mt-3 me-0 my-4-step">
                                <button type="button" className="m-0 pt-1 pb-0 bg-white focus_onhover button_badge_css">
                                    <i className="fa fa-ticket" aria-hidden="true" style={{ fontSize: 27 }} onClick={() => { navigateTickets() }}></i>
                                    {ticketsList?.length > 0 && <span class="button__badge">{ticketsList?.length}</span>}
                                </button>
                            </li>
                        }
                        {role === 'ADMIN' &&
                            <li className="nav-item dropdown mt-3 me-4 font_dark_color_underline font_dark_color my-4-step">
                                <a href="https://remote72.co/active-users-list" target="_blank">Active users</a>
                            </li>
                        }
                        {/* <li className="nav-item dropdown my-5-step">
                            <div className="pt-1 me-0 bg-white font_color_ornage"> */}
                        {/* <img
                                    alt="Alt content"
                                    className="focus_on_hover_img"
                                    src={require('./../assets/images/icon_profile circle.svg').default}
                                />
                                <br /> */}
                        {/* <span className="font_size_16 fw-bold">
                                    {
                                        role === 'ACTMGR' ? 'Account Manger' :
                                            role === 'ADMIN' ? 'Admin' :
                                                role === 'USER' ? 'User' :
                                                    role === 'EMPLOYEE' ? 'EMPLOYEE' : 'Company'
                                    }
                                </span> */}
                        {/* <div className="font_size_12 fw-medium font_dark_color">{user?.company_name}</div> */}
                        {/* <div className="font_size_14 fw-medium font_dark_color padding_Top_2">{user?.buisness_email_id}</div>
                            </div>
                        </li> */}
                        {/* {allCompaniesList && allCompaniesList?.length > 0 && role !== 'ACTMGR' && 
                        <li className="nav-item dropdown">
                            <div className="m-0 pt-1 pb-0 bg-white focus_onhover d-flex justify-content-end"
                                data-bs-toggle="modal"
                                data-bs-target="#viewCompaniesDetailsModalIDHeader"
                                onClick={() => { viewSelectedCompanyDetailsFun(allCompaniesList[0], 0) }}
                            > */}
                        {/* <div className="d-block">
                                    <img
                                        alt="Alt content"
                                        className="company_header_profile" //focus_on_hover_img
                                        src={allCompaniesList[0]?.logo}
                                    />
                                </div>
                                <br /> */}
                        {/* <span className="header_icons_cls">{'Profile'}</span> */}
                        {/* <span className="header_icons_cls">{user?.company_name}</span> */}
                        {/* </div>
                        </li>} */}
                        {/* <li className="nav-item dropdown">
                            <button type="button" className="m-0 pt-1 pb-0 bg-white focus_onhover">
                                <img
                                    alt="Alt content"
                                    className="focus_on_hover_img"
                                    src={require('./../assets/images/icon_profile circle.svg').default}
                                />
                                <br />
                                <span className="header_icons_cls">User Name</span>
                            </button>
                        </li> */}
                        {/* dropdown-toggle" id="navbarDropdown" data-bs-toggle="dropdown"
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="#!">Action</a>
                        </div> */}
                        <li className="nav-item dropdown my-6-step">
                            <button type="button" className="m-0 pt-1 pb-0 bg-white focus_onhover" data-bs-toggle="modal" data-bs-target="#rightModalDefault">
                                <img alt="Alt content" className="focus_on_hover_img" src={require('./../assets/images/icon_setting.svg').default} /><br />
                                {/* <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div> */}
                                <span className="header_icons_cls">Settings</span>
                            </button>
                        </li>
                        <li className="nav-item dropdown my-other-step my-7-step">
                            <div class="dropdown">
                                {/* btn btn-secondary dropdown-toggle */}
                                {/* <button class="" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"> */}
                                <a className="nav-link dropdown-toggle header_profile_icon" id="dropdownMenuButton2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {/* <img
                                        alt="Alt content"
                                        className="company_header_profile" //focus_on_hover_img
                                        src={allCompaniesList[0]?.logo}
                                    /> */}
                                    {allCompaniesList && allCompaniesList?.length > 0 && role === 'COMPANY' ?
                                        <>
                                            {allCompaniesList[0]?.logo ? <picture>
                                                <img
                                                    alt="Alt content"
                                                    className="company_header_profile" //focus_on_hover_img
                                                    src={allCompaniesList[0]?.logo}
                                                />
                                            </picture>
                                                :
                                                <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
                                            }
                                        </>
                                        :
                                        <>
                                            {/* <picture> */}
                                            <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
                                            {/* <img
                                                    alt="Alt content"
                                                    className="company_header_profile" //focus_on_hover_img
                                                    src={require('../assets/images/user-profile-icon.svg')?.default}
                                                /> */}
                                            {/* </picture> */}
                                        </>
                                    }
                                </a>
                                {/* dropdown-menu-dark  */}
                                <ul class="dropdown-menu dropdown-menu-dark drop_down_profile dropdown-menu-lg-end pointer_event" aria-labelledby="dropdownMenuButton2">
                                    <div class="custom_arrow_login_dd"></div>
                                    {allCompaniesList && allCompaniesList?.length > 0 && role === 'COMPANY' &&
                                        <li className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#viewCompaniesDetailsModalIDHeader"
                                            onClick={() => { viewSelectedCompanyDetailsFun(allCompaniesList[0], 0) }}
                                        >
                                            Edit/Detail Profile
                                        </li>
                                    }
                                    {/* <li className="dropdown-item">Update Logo</li> */}
                                    {/* {allCompaniesList && allCompaniesList?.length > 0 && role === 'COMPANY' &&
                                        <li><hr class="dropdown-divider" /></li>
                                    } */}
                                    <li className="dropdown-item">
                                        <div className="pt-1 me-0">
                                            <span className="font_size_16 fw-bold">
                                                {
                                                    role === 'ACTMGR' ? 'Account Manger' :
                                                        role === 'ADMIN' ? 'Admin' :
                                                            role === 'USER' ? 'User' :
                                                                role === 'EMPLOYEE' ? '' : 'Company'
                                                }
                                            </span>
                                            <div className="font_size_14 fw-medium padding_Top_2">{user?.buisness_email_id}</div>
                                        </div>
                                    </li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li class="dropdown-item"
                                        data-bs-toggle="modal"
                                        data-bs-target="#logOutModal">
                                        Logout
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Dropdown
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">
                                                <button
                                                    type="button"
                                                    className="m-0 pt-1 pb-0 bg-white focus_onhover"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#logOutModal"
                                                >
                                                    <img
                                                        alt="Alt content"
                                                        className="focus_on_hover_img"
                                                        src={require('./../assets/images/icon_logout.svg').default}
                                                    />
                                                    <br />
                                                    <span className="header_icons_cls">Logout</span>
                                                </button>
                                            </a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div> */}

                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        {/* modals */}
        <ModalComponent
            title="Confirmation !!"
            footerContent={modalFooterContent()}
            content={modalContent()}
            dataBsBackdrop="static"
            id="logOutModal"
            w_max_content={true}
        />
        {/* Edit Company Details modals */}
        <ModalComponent
            title="Edit company Details"
            content={editModalContent()}
            dataBsBackdrop="static"
            id="editCompanyDetailsModalId"
            // modalDialogClass="add_requirement_modal_w"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}
        />
        {/* View Company Details modals */}
        <ModalComponent
            title={viewSelectedCompanyDetails?.pageData?.companyName}
            content={viewModalContent()}
            dataBsBackdrop="static"
            id="viewCompaniesDetailsModalIDHeader"
            modalDialogClass="view_job_details_modal_w"
            modalBodyPaddingBottomCls="p-0 overflow_custom"
            isHeaderHide={true}
            cancelPopUpFun={cancelPopUpFun}
        />
        <ModalComponent
            title="Settings"
            content={role === 'COMPANY' ? modalContentSettings() : modalContentAdminActMangerSettings()}
            footerContent={modalFooterContentRightSideBar()}
            className="right"
            dataBsBackdrop="static"
            id="rightModalDefault"
        />
        <ModalComponent
            title="Help"
            content={modalContentHelp()}
            footerContent={modalFooterContentRightSideBar()}
            className="right"
            dataBsBackdrop="static"
            id="helprightModalDefault"
        />
    </>
}

export default Header;

const customSteps = [
    {
        selector: '.my-3-step',
        content: 'This another awesome menu feature!',
    },
    {
        selector: '.my-4-step',
        content: 'This another awesome help feature!',
    },
    {
        selector: '.my-5-step',
        content: 'This another awesome detail feature!',
    },
    {
        selector: '.my-6-step',
        content: 'This another awesome settings feature!',
    },
    {
        selector: '.my-7-step',
        content: 'This another awesome logout feature!',
    },
]
