import React, { useState, useEffect } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ClientCaptcha from "react-client-captcha";
import "../../assets/css/login.css"
import storePersist from '../../Utils/storePersist'
import authLayout from "../../hoc/authLayout";
import { postApi } from '../../services/apiCommonService'
import { addTour, deleteTour } from "../../redux/actions/tour";
import { sign_in_api_url, post_acct_manager_companies } from '../../services/endPointsConstant'
import { isEmpty } from '../../Utils/utils'
const mainRoles = ['ADMIN']
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const LoginPage = () => {
    const [formData, setFormData] = useState(false);
    const [captchaCode, setCaptcha] = useState();
    const [showPassword, setShowPassword] = useState(false);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        register,
        formState: { errors, isDirty, isSubmitting, touched },
        handleSubmit
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        dispatch(addTour({ steps: customSteps }))
    }, [])

    const errorHandler = () => {
        if (errors.email) {
            toast.error("Check businessEmailId address, It should be example@google.com")
        } else if (errors.password) {
            toast.error("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.")
        } else if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const onSubmit = async (data) => {
        setFormData(data);
        const apiReqBody = {
            "buisness_email_id": data?.email,
            "password": data?.password,
        }
        const apiResponse = await postApi(sign_in_api_url, apiReqBody)
        if (apiResponse?.response?.data?.message === 'User Not found.') {
            toast.error(apiResponse?.response?.data?.message + 'please signup!!')
        } else if (apiResponse?.response?.data?.message === 'Please wait your account is under verification!') {
            toast.error('Please wait your account is under verification!')
        } else if (apiResponse?.data) {
            if (apiResponse?.data?.role === 'ACTMGR') {
                toast.success("Log in Successfull")
                storePersist.set('userData', apiResponse?.data)
                storePersist.set('actMgrData', apiResponse.data?.actMgrData ? apiResponse?.data?.actMgrData : {})
                storePersist.set('companyDetails', apiResponse.data?.companyData ? apiResponse?.data?.companyData : {})
                navigate(mainRoles?.includes(apiResponse?.data?.role) ? '/admin-dashboard' : '/dashboard');
            } else {
                toast.error('Only AccountManager is allowed to login with this link !!')
            }
        } else {
            toast.error(apiResponse?.response?.data?.message + '!!')
        }
    };

    return (
        <div className="form-holder login-form vh-100 rounded-0">
            <div className="d-flex justify-content-center align-items-center my-4 mt-5">
                <img src={require('./../../assets/images/login_page_logo.svg').default} alt="Alt content" />
            </div>
            {!isMobile ?
                <div className="d-flex justify-content-center align-items-center my-4">
                    <h1 className="text-center fw-normal mb-4 mt-3 me-3 my-1-step">Account Manager login!</h1>
                </div> :
                <div className="justify-content-center align-items-center my-4 mt-5 mb-5">
                    <div className="col-md-5">
                        <h1 className="text-center fw-normal mb-1 mt-3">Good Morning !</h1>
                    </div>
                    <div className="col-md-5">
                        <h1 className="text-center fw-normal mb-4 mt-1">Please login here.</h1>
                    </div>
                </div>
            }
            {/* {formData && (
                <article>
                    <h2>{formData.firstName}, thank you for your registration</h2>
                </article>
            )}

            {!formData && ( */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-outline did-floating-label-content ${errors.email && 'did-error-input'}`}>
                    <input
                        type="email"
                        name="email"
                        className="did-floating-input"
                        placeholder=""
                        {...register("email", {
                            required: "Field is required",
                            minLength: {
                                value: 2,
                                message: "Min length is 2"
                            },
                            pattern: {
                                value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                // /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Invalid email address, It should be example@google.com"
                            }
                        })}
                    />
                    <label className="did-floating-label requiredTrue">{'Enter username'}</label>
                    {/* {errors.email && <p className="error">{errors.email.message}</p>} */}
                </div>

                <div className={`form-outline did-floating-label-content ${errors.password && 'did-error-input'}`}>
                    <input
                        type={showPassword ? 'text' : "password"}
                        name="password"
                        className="did-floating-input"
                        placeholder=""
                        {...register("password", {
                            required: "Field is required",
                            minLength: {
                                value: 6,
                                message: "Min length is 6"
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
                            },
                        })}
                    />
                    {showPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye" onClick={() => { setShowPassword(!showPassword) }}></span>}
                    {!showPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye" onClick={() => { setShowPassword(!showPassword) }}></span>}
                    <label className="did-floating-label requiredTrue">{'Enter the password'}</label>
                    {/* {errors.password && <p className="error">{errors.password.message}</p>} */}
                </div>

                <div className="input-row captcha-holder d-flex align-items-center">
                    <div className="col-md-7 d-flex align-items-center">
                        <div className="col-md-4 d-flex align-items-center">
                            <ClientCaptcha
                                captchaCode={setCaptcha}
                                charsCount={4}
                                // width={isMobile ? 100 : 140}
                                height={50}
                                fontSize={22}
                                backgroundColor="#FFFFFF"
                                fontColor="green"
                                refreshButton={true}
                                captchaClassName={'reCaptcha'}
                            />
                            <span className={`color_main_1 font_size_18 ${isMobile ? 'ms-3' : 'ms-1'}`}>{!isMobile ? "Refresh" : ''} </span>
                        </div>
                        <div className="col-md-2">
                            <input
                                type="hidden"
                                value={captchaCode}
                                {...register("captchaCode")}
                            />
                        </div>
                    </div>
                    <div className={`col-md-5 did-floating-label-content mb-0 ${errors.captcha && errors.captcha.type && 'did-error-input'}`}>
                        <input
                            type="text"
                            name="captcha"
                            placeholder=""
                            className="did-floating-input"
                            {...register("captcha", {
                                required: "Field is required",
                                validate: {
                                    valid: (value) => value === captchaCode
                                }
                            })}
                        />
                        <label className="did-floating-label requiredTrue">{'Enter Captcha'}</label>
                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <div className="col-md-8"></div>
                    <div className="col-md-4 margintop">
                        {/* {errors.captcha && (
                            <p className="error">{errors.captcha.message}</p>
                        )} */}
                        {/* {errors.captcha && errors.captcha.type === "valid" && (
                            <p className="error">Code is incorrect!</p>
                        )} */}
                    </div>
                </div>

                {/* {isSubmitting && (
                    <p className="loading">Please wait, submit in progress</p>
                )} */}

                <button type="submit" disabled={isSubmitting} className="w-100 rounded-2 my-first-step my-2-step" onClick={errorHandler}>
                    {isSubmitting && <span>Submitting</span>}
                    {!isSubmitting && <span>Login</span>}
                </button>
                {/* <Link to="/" type="submit" disabled={isSubmitting} className="w-100">
                        {isSubmitting && <span>Submitting</span>}
                        {!isSubmitting && <span>Login</span>}
                    </Link> */}

                <div className="d-flex justify-content-center align-items-center mb-5">
                    <span className="d-flex text-center text-lg-start justify-content-center align-items-center">
                        <Link to="/reset-password" className="fontColor font_size_16 fw-700 margin_top_n_7"><strong>Forgot password?</strong></Link>
                        {/* <div className="vr me-3 ms-3 mt-2" style={{ height: 25, width: 3 }}></div>
                        {!isMobile ?
                            <p className="small fw-bold pt-2">Do not have an account ?
                                <Link to="/signup" className="fontColor me-2 font_size_15 fw-700"><strong>  Sign up</strong></Link>
                            </p> :
                            <>
                                <p className="small fw-bold pt-2">Do not have an account ?</p>
                                <Link to="/signup" className="fontColor me-2 font_size_15 fw-700"><strong>Sign up</strong></Link>
                            </>
                        } */}
                    </span>
                </div>

            </form>
            {/* )} */}
        </div>
    );
}

export default authLayout(LoginPage);

const customSteps = [
    {
        selector: '.my-1-step',
        content: 'Login form for user!  ',
    },
    {
        selector: '.my-2-step',
        content: 'button click to login!  ',
    }
]