import React, { useState, useEffect } from "react";
import InputIcon from "react-multi-date-picker/components/input_icon"
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import adminLayout from "../../hoc/adminLayout";
import { downloadPDF } from '../../services/axios-common'
import ModalComponent from "../../components/ModalComponent";
import storePersist from '../../Utils/storePersist'
import { isEmpty, formatBytes } from '../../Utils/utils'
import { updateWithOutParamsApi, postApi, getAllApi } from '../../services/apiCommonService'
import { edit_Offer_api_url, get_Offer_by_job_api_url, edit_offfer_details, create_offer_details, upload_offer_sow_file, get_job_list_api_url, post_comment_api_url, get_jobs_by_company_id, existed_job_data_url } from '../../services/endPointsConstant';
import DatePicker from "react-multi-date-picker";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
};

const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
    return `${user[0]?.toUpperCase()}${user[1]?.toUpperCase()}`
}

const setValue = (functionFor) => {
    return (e) => {
        const value = parseInt(e.target.value);
        functionFor(value);
    }
};

const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
}

const JobOfferedComponent = (props) => {
    const location = useLocation()
    const { stageUpdateProps } = props
    const { role } = storePersist.get('userData')
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [jobList, setJobList] = useState([])
    const { shortlistedResumesList } = useSelector((state) => state.shortlistedResumesReducer);
    const [jobListSelectedValue, setJobListSelectedValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const [selectedUserDetails, setSelectedUserDetails] = useState();
    // const [offeredCandidateDetails, setOfferedCandidateDetails] = useState(shortlistedResumesList?.length > 0 ? [shortlistedResumesList[0]] : [])
    const [offeredCandidateDetails, setOfferedCandidateDetails] = useState([])
    const [rejectSelectedJobOfferData, setRejectSelectedJobOfferData] = useState();
    const [rejectSelectedJobOfferDataCommentText, setRejectSelectedJobOfferDataCommentText] = useState();
    const [formData, setFormData] = useState(false);
    const isAuthenticate = storePersist.get('userData')
    const [currentJobData, setCurrentJobData] = useState([])
    const [isSOWUploaded, setIsSOWUploaded] = useState(false)


    const [range, setRange] = React.useState(10);
    const [saturation, setSaturation] = React.useState(50);
    const [lightness, setLightness] = React.useState(50);
    const [theme, setTheme] = React.useState('Light');

    const saturationRange = getRange(saturation, range);
    const lightnessRange = getRange(lightness, range);

    const {
        register,
        formState: { errors, isDirty, isSubmitting, touched },
        handleSubmit,
        control,
        reset
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        if (role === 'COMPANY') {
            window.addEventListener('popstate', (e) => {
                window.history.go(1);
            });
        }
        async function fetchData() {
            await getJobsList()
            await getAllCandidatesList()
            await getJobListApi()
        }
        fetchData()
    }, [])

    useEffect(() => {
        setIsSOWUploaded(window.sessionStorage.getItem('isSOWUploaded') || false)
    }, [location])

    const getJobListApi = async () => {
        dispatch(addLoader());
        if (location?.state && location?.state?.jobId && location?.state?.companyId) {
            const apiReqBody = { companyId: location?.state?.companyId, jobId: location?.state?.jobId }
            const apiResponse = await postApi(existed_job_data_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                const apiResponseData = [...apiResponse?.data?.Data]?.reverse()?.filter(({ _id }) => _id === location?.state?.jobId)
                setCurrentJobData(apiResponseData && apiResponseData[0] || {})
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 2500);
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        }
    }

    const getAllCandidatesList = async () => {
        dispatch(addLoader());
        // const jobDetailsStorage = storePersist.get('jobDetails')
        // const apiReqBody = { jobId: jobDetailsStorage && jobDetailsStorage[0]?._id }
        if (location?.state && location?.state?.jobId && location?.state?.companyId) {
            const apiReqBody = { jobId: location?.state?.jobId }
            const apiResponse = await postApi(get_Offer_by_job_api_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
                if (apiResponseData?.length > 0) {
                    if (apiResponse?.data?.Data[0]?.file_Name_sow && apiResponse?.data?.Data[0]?.file_location_sow) {
                        setOfferedCandidateDetails([...apiResponseData,
                        {
                            ...apiResponse?.data?.Data[0], yearsOfExperience: 'Yes', profileMatch: 'Yes/No',
                            file_location: apiResponse?.data?.Data[0]?.file_location_sow,
                            fileName: apiResponse?.data?.Data[0]?.file_Name_sow,
                            candidateFirstName: 'Contact Details',
                            profileTitle: 'PO/SOW'
                        }])
                    } else {
                        setOfferedCandidateDetails(apiResponseData)
                    }
                    setSelectedUserDetails(apiResponseData[0])
                    reset({ jobId: location?.state?.jobId })

                } else if (location?.state?.resultObject) {

                    const offer_id = location?.state?.resultObject?._id
                    const file_location_sow_path = location?.state?.resultObject?.file_location
                    const file_Name_sow_custom = location?.state?.resultObject?.sowFileName

                    const savedData = [{ offer_id: offer_id, ...location?.state?.resultObject, ...location?.state?.resultObject?.candidateDetails[0], ...location?.state?.resultObject?.jobDetails[0], file_location_sow: file_location_sow_path, file_Name_sow: file_Name_sow_custom }]
                    if (savedData[0]?.file_Name_sow && savedData[0]?.file_location_sow) {
                        setOfferedCandidateDetails([...savedData,
                        {
                            ...savedData[0], yearsOfExperience: 'Yes', profileMatch: 'Yes/No',
                            file_location: savedData?.file_location_sow,
                            fileName: savedData?.file_Name_sow,
                            candidateFirstName: 'Contact Details',
                            profileTitle: 'PO/SOW'
                        }])
                    } else {
                        setOfferedCandidateDetails([{ offer_id: offer_id, ...location?.state?.resultObject, ...location?.state?.resultObject?.candidateDetails[0], ...location?.state?.resultObject?.jobDetails[0], file_location_sow: file_location_sow_path, file_Name_sow: file_Name_sow_custom }])
                    }
                    setSelectedUserDetails({ offer_id: offer_id, ...location?.state?.resultObject, ...location?.state?.resultObject?.candidateDetails[0], ...location?.state?.resultObject?.jobDetails[0], file_location_sow: file_location_sow_path, file_Name_sow: file_Name_sow_custom })
                    reset({ jobId: location?.state?.resultObject?.jobId })
                }
                // toast.success("Retrived all Candidates List Successfully !!")
                dispatch(deleteLoader());
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        }
    }

    // useEffect(() => {
    //     setSelectedUserDetails(offeredCandidateDetails[0])
    // }, [offeredCandidateDetails])

    const userDetailsOnClikHandler = (offeredCandidateDetails) => {
        dispatch(addLoader());
        setSelectedUserDetails(offeredCandidateDetails)
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 2000);
    }

    const errorHandler = () => {
        if (errors.joiningDate) {
            toast.error("enter Joining Date")
        } else if (errors.purchaseOrderName) {
            toast.error("enter Purchase order name")
        } else if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const getJobsList = async () => {
        // const apiResponse = await getAllApi(get_job_list_api_url)
        const companyDetailsStorage = storePersist.get('companyDetails')
        const apiReqBody = { companyId: companyDetailsStorage && companyDetailsStorage?._id }
        const apiResponse = await postApi(get_jobs_by_company_id, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData.map(({ _id, job_type }) => { return { value: _id, label: job_type } })
            setJobList(apiResponseData)
            // toast.success("Retrived all Job List Successfully !!")
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const powOrSowUpload = async (data, offerDetails) => {
        const baseUrl = 'https://free.remote72.in'
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("offerId", offerDetails?._id)
        try {
            const apiResponse = await fetch(`${baseUrl}/${upload_offer_sow_file}`, {
                method: "POST",
                body: formData,
            }).then((res) => res.json());
            if (apiResponse?.message) {
                if (apiResponse?.data) {
                    toast.success('Profile Updated Successfully !!')
                    // document.location.reload()
                } else {
                    toast.success(apiResponse?.response?.data.message || "Uploaded file successfully !!")
                    await getAllCandidatesList()
                }
            } else {
                toast.error(apiResponse?.response?.data?.message + '!!')
            }
        } catch (err) {
            if (err === 'TypeError: Failed to fetch') {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            } else {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            }
        }
    }

    const onSubmit = async (data) => {
        setFormData(data);
        const { id } = storePersist.get('userData')
        const apiReqBody = {
            offerId: selectedUserDetails?.offer_id,
            userid: id,
            joining_date: data.date,
            sow_po_status: "onboarded",
            // interview_status: "completed",
            // offer_status: "yes",
            interview_date: new Date()?.format?.("YYYY/MM/DD"),
            // onboarding_status: 'onboarded'
        }
        let file_size = data.file[0]?.size;
        let file_name = data.file[0]?.name;
        let file_type = data.file[0]?.type;
        if (formatBytes(file_size) < 500) {
            const apiResponse = await updateWithOutParamsApi(edit_offfer_details, apiReqBody)
            if (apiResponse?.data) {
                powOrSowUpload(data, { _id: selectedUserDetails?.offer_id })
                toast.success("Successfully release offer and uploaded sow/pow details !!")
                document.getElementById("buttonDismiss").click();
                setIsSOWUploaded(true)
                await getAllCandidatesList()
                await getJobListApi()
                window.sessionStorage.setItem('isSOWUploaded', true)
                // navigate(`/onboarded-candidate/${selectedUserDetails.candidate_id}`); //apiResponse?.data?.Data?.candidate_id 
                // navigate(`/onboarded-candidate/${selectedUserDetails?.candidate_id}`, { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 3 } });
                // stageUpdateProps(3)
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            }
        } else {
            toast.error('Failed to upload file, file size is excceded more than 500KB')
        }
    };

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['salary', 'office_type', 'currencyValue'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    const rejectProfile = async (event) => {
        dispatch(addLoader());
        const apiReqBody = {
            offerId: selectedUserDetails?.offer_id,
            resume_status: "rejected",
            interview_status: null
            // resume_status: 'shortlisted'
        }
        const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Profile Updated Successfully !!')
            await getAllCandidatesList()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }


    const rejectJobOfferFun = (item) => {
        setRejectSelectedJobOfferData(item)
    }

    const rejectModalContent = () => {
        return <>
            <textarea
                value={rejectSelectedJobOfferDataCommentText}
                onChange={(e) => { setRejectSelectedJobOfferDataCommentText(e.target.value) }}
                name="Comment"
                placeholder='Enter comment here'
                rows={4}
                cols={33}
            />
        </>
    }

    const cancelFun = () => {
        setRejectSelectedJobOfferDataCommentText('')
    }

    const rejectModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={rejectProfileApiCallHandler}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={cancelFun}
            >
                Cancel
            </button>
        </>;
    }

    const rejectProfileApiCallHandler = async () => {
        const { id } = storePersist.get('userData')
        const apiRejectReqBody = {
            offerId: selectedUserDetails?.offer_id,
            userid: id,
            candidate_id: selectedUserDetails?.candidate_id,
            comment: rejectSelectedJobOfferDataCommentText
        }
        const apiReqBody = {
            offerId: selectedUserDetails?.offer_id,
            resume_status: "rejected",
            interview_status: null,
            rejectComments: rejectSelectedJobOfferDataCommentText
        }
        const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Profile Rejected Successfully !!')
            document.location.reload()
            await getAllCandidatesList()
            await getJobListApi()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }

        const apiResponseComments = await postApi(post_comment_api_url, apiRejectReqBody)
        if (apiResponseComments?.data) {
            // toast.success('Profile Updated Successfully !!')
            await getAllCandidatesList()
        } else {
            toast.error(apiResponseComments?.response?.data.message || "Api Error Response !!")
        }

    }

    const releaseOfPurchaseOrderBodyContent = () => {
        return <>
            <div className="form-holder pb-0 main_bg rounded-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`form-outline marginbottom_int did-floating-label-content ${errors.date && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            name="date"
                            type="date"
                            placeholder=""
                            {...register("date", {
                                required: "Field is required",

                            })}
                        />
                        <label className="did-floating-label">{'Joining date'}</label>
                    </div>
                    {/* <Controller
                        control={control}
                        name="date"
                        rules={{ required: "Field is required" }} //optional
                        render={({
                            field: { onChange, name, value },
                            fieldState: { invalid, isDirty }, //optional
                            formState: { errors }, //optional, but necessary if you want to show an error message
                        }) => (
                            <>
                                <DatePicker
                                    render={<InputIcon />}
                                    value={value || ""}
                                    containerClassName={errors && errors.date ? "custom-input-error" : "custom-container"}
                                    onChange={(date) => {
                                        onChange(date?.isValid ? date : "");
                                    }}
                                    format={"YYYY/MM/DD"}
                                />
                            </>
                        )}
                    /> */}

                    {/* <div className="form-outline marginbottom_int did-floating-label-content">
                        <Controller
                            name="jobId"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={jobList}
                                    value={jobList.find((c) => c.value === value)}
                                    onChange={(val) => onChange(val.value)}
                                    defaultValue={jobList.find((c) => c.value === jobListSelectedValue)}
                                    isSearchable={true}
                                    placeholder={'select related job'}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'jobId': true }) }}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.jobId && 'jobId'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            marginTop: 25,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['jobId'] ? '1px solid #cccccc' : ''
                                        })
                                    }}
                                />
                            )}
                            rules={{ required: "Field is required" }}
                        />
                    </div> */}
                    <div className={`form-outline did-floating-label-content ${errors.job_code && 'did-error-input'} mt-4`}>
                        <input
                            className="did-floating-input" //mb-4 font_size_14
                            type="text"
                            name="job_code"
                            placeholder=""
                            value={selectedUserDetails?.job_code}
                            {...register("job_code", {
                                // required: "Field is required",
                                // minLength: {
                                //     value: 2,
                                //     message: "Min length is 2"
                                // }
                            })}
                            disabled={true}
                            readOnly={true}
                        />
                        <label className="did-floating-label">{'Job Code'}</label>
                    </div>
                    <div className={`form-outline did-floating-label-content ${errors.file && 'did-error-input'} mt-4`}>
                        <input
                            className="did-floating-input" //mb-4 font_size_14
                            type="file"
                            name="file"
                            accept=".pdf"
                            placeholder=""
                            {...register("file", {
                                required: "File is required",
                                validate: (value) => {
                                    const acceptedFormats = ['pdf'];
                                    const fileExtension = value[0]?.name.split('.').pop().toLowerCase();
                                    if (!acceptedFormats.includes(fileExtension)) {
                                        return 'Invalid file format. Only PDF files are allowed.';
                                    }
                                    return true;
                                }
                            })}
                            style={{ paddingTop: '7px' }}
                        />
                        <label className="did-floating-label">{'SOW'}</label>
                    </div>
                    <div className="border-top position-sticky bottom-0 footer mt-auto py-3 pb-3 pt-2 main_bg text-center d-flex justify-content-between">
                        <button
                            type="submit"
                            className="btn btn-primary btn_background_color pt-1 pb-1 mt-0 mb-0 btn_height_36 fw-medium_imp"
                            onClick={errorHandler}
                        >
                            {isSubmitting && <span>Submitting</span>}
                            {!isSubmitting && <span>Update</span>}
                        </button>
                        <button
                            className="btn btn-primary btn_background_color pt-1 pb-1 mt-0 mb-0 btn_height_36 fw-medium_imp"
                            data-bs-dismiss="modal"
                            onClick={() => { doItLaterBtnFun() }}
                        >
                            Do it later
                        </button>
                    </div>
                </form>
                <button className="d-none" id="buttonDismiss" data-bs-dismiss="modal"></button>
                {/* )} */}
            </div>
        </>;
    }

    const releaseOfPurchaseOrderModalFooterContent = () => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={() => { }}
            >
                Update
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0 pb-2"
                data-bs-dismiss="modal"
            >
                Do it later
            </button>
        </>;
    }

    const onClickRedirectToScheduledForInterviewPage = async () => {
        const apiReqBody = {
            offerId: selectedUserDetails?.offer_id,
            offer_status: "yes",
            interview_date: new Date()?.format?.("YYYY/MM/DD"),
            interview_status: "completed",
            onboarding_status: 'onboarded'
        }
        const apiResponse = await updateWithOutParamsApi(edit_offfer_details, apiReqBody)
        if (apiResponse?.data) {
            toast.success("Congratulations! Candidate on-boarded successfully")
            window.sessionStorage.removeItem('isSOWUploaded')
            navigate(`/onboarded-candidate/${selectedUserDetails?.candidate_id}`, { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 3, readOnly: true } });
            // navigate(`/onboarded-candidate/${selectedUserDetails?.candidate_id}`, { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 3 } });
            stageUpdateProps(3)
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }

    }

    const doItLaterBtnFun = async () => {
        const apiReqBody = {
            offerId: selectedUserDetails?.offer_id,
            offer_status: "yes",
            interview_date: new Date()?.format?.("YYYY/MM/DD"),
            // interview_status: "completed",
            // onboarding_status: 'onboarded',
            sow_po_status: null,
        }
        const apiResponse = await updateWithOutParamsApi(edit_offfer_details, apiReqBody)
        if (apiResponse?.data) {
            // toast.success("congratulation successfully on-boarded !!")
            // navigate(`/onboarded-candidate/${selectedUserDetails?.candidate_id}`, { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 3 } });
            // navigate(`/onboarded-candidate/${selectedUserDetails?.candidate_id}`, { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 3 } });
            // stageUpdateProps(3)
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }

    }

    const onClickRedirectToOnBoardPagenext = () => {
        // navigate('/onboarded-candidate');
        stageUpdateProps(3)
    }

    const downloadPdf = () => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(selectedUserDetails?.file_location, selectedUserDetails?.candidateFirstName || selectedUserDetails?.firstName)
    }

    const onClickPageRedirectionInterviewScreen = () => {
        stageUpdateProps(0)
    }
    const onClickPageRedirectionInterviewShort = () => {
        stageUpdateProps(1)
    }
    const onClickPageRedirectionInterviewOffer = () => {
        stageUpdateProps(2)
    }

    return (
        <div className="my-1 py-3 bg-body rounded shadow-sm rounded_20 height_870">
            {/* <div className="d-flex justify-content-between align-items-center border-bottom pt-2"> */}
            <div className="col-xl-12 col-sm-12 ps-3 pb-2 border-bottom">
                <h6 className="font_color_imp font_size_16 fw-bolded"> {'Job Offered'} </h6>
            </div>
            {/* </div> */}

            {offeredCandidateDetails?.length === 0 &&
                <div className="d-flex align-content-center ps-2 pt-1 pb-3 margin_left_right_15 main_bg_interview_sheduled_card">
                    {masterData.map((item, idx) => {
                        return (<div className={`col-md-3 d-flex justify-content-center align-content-center`} key={idx}>
                            <div className="col-xs-12 align-middle">
                                <div className="col-xs-6 me-3">
                                    <img
                                        src={require(`../../assets/images/${item.img}`)}
                                        alt="jobType"
                                        className="img_w_h_23"
                                    />
                                </div>
                                <div className="col-xs-6">
                                    <div className="fw-700 font_size_14 mb-1">{item.label}</div>
                                    <div className="fw-medium font_size_14">{(location?.state?.jobDetails && location?.state?.jobDetails[item.response] || currentJobData && currentJobData[item.response])}</div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            }

            {offeredCandidateDetails?.length > 0 &&
                <>
                    <div className="d-flex align-content-center justify-content-start p-2 bg_header_shortlist_resume ps-4 m-2 ms-4 me-2_0 mt-3">
                        {masterData.map((item, idx) => {
                            return (<div className="col-md-3 d-flex justify-content-start align-content-center" key={idx}>
                                <div className="col-xs-12 align-middle">
                                    <div className="col-xs-6 me-3">
                                        <img
                                            src={require(`../../assets/images/${item.img}`)}
                                            alt="jobType"
                                            className="img_w_h_23"
                                        />
                                    </div>
                                    <div className="col-xs-6">
                                        <div className="fw-700 font_size_14 mb-1">{item.label}</div>
                                        <div className="fw-medium font_size_14">{selectedUserDetails && selectedUserDetails[item.response]}</div>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                    <div className="m-4 mt-3 me-2_0 border_cls">
                        <div className="border_cls d-flex align-content-center p-2 ps-3 d-flex align-item-center justify-content-between">
                            {/* <div className='d-flex align-item-center justify-content-between'> */}
                            <div className='pt-2'>
                                <span className="font_color_ornage">
                                    Congratulation! You have selected candidate for this Position.
                                </span>
                            </div>
                            <div className='d-flex align-item-center justify-content-end'>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                                    onClick={() => { downloadPdf() }}
                                >
                                    Download Resume
                                    <img
                                        src={require(`../../assets/images/download_icon.svg`).default}
                                        alt="jobType"
                                        className="img_w_h_23 ms-1"
                                    />
                                </button>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="left-sidebar_new mt-0">
                            {(selectedUserDetails?.file_Name_sow && selectedUserDetails?.file_location_sow && selectedUserDetails?.profileTitle === 'PO/SOW') ?
                                <div className='pt-1 d-flex align-items-center justify-content-center flex-column position-absolute'>
                                    <div className='border_cls vh-90 vw-57'>
                                        <iframe
                                            // style={{ width: '100%', height: '100%' }}
                                            // src={selectedUserDetails?.file_location || selectedUserDetails?.file_location_sow}//{'https://arxiv.org/pdf/quant-ph/0410100.pdf'} offerDetails?.length > 0 ? offerDetails[0].file_location :
                                            title="candidate resume"
                                            style={{ width: '100%', height: '100%', border: 'none' }}
                                            // src={`https://docs.google.com/viewer?url=${selectedUserDetails?.file_location || selectedUserDetails?.file_location_sow}&embedded=true`}
                                            src={selectedUserDetails?.file_location}
                                            frameborder="0"
                                            toolbar="0"
                                            allowfullscreen
                                            type="application/pdf"
                                        >
                                        </iframe>
                                    </div>
                                </div>
                                :
                                <div className='pt-1 d-flex align-items-center justify-content-center flex-column position-absolute'>
                                    {/*vw-57 {["Full Name", "Profile Summary", "Work Experience", "Roles and Responsibilities", "Skills", "Technologies", "Education"].map((itemKey, idx) => */}
                                    <div className="pb-2">
                                        <img
                                            src={require(`../../assets/images/congratulation_jpeg.jpeg`)}
                                            alt="jobType"
                                            className="ms-1"
                                        />
                                        {/* <div className='font_size_14'>{'Rahul Dev'}</div> */}
                                        {/* <div className='pt-2 font_size_16 fw-bolded'>You cleared all the rounds of interview !!!</div> */}
                                    </div>
                                    <div className="pb-2">
                                        <div className='pt-2 font_size_16 fw-bolded text-center'>
                                            You have sent your job offer
                                            <br />
                                            You have found the right candidate
                                        </div>
                                    </div>
                                    <div className="pb-2 textAlignCenter">
                                        {(!selectedUserDetails?.file_location_sow && !selectedUserDetails?.file_Name_sow)
                                            ?
                                            <>
                                                <div className='pt-2 font_size_16 fw-bolded font_color_ornage'>{'Upload PO/SOW forms and continue'}</div>
                                            </>
                                            :
                                            <>
                                                <div className='pt-2 font_size_16 fw-bolded font_dark_green_color'>{'Successfully Uploaded PO/SOW Form'}</div>
                                                <div className='pt-2 font_size_16 fw-bolded font_color_ornage'>{'All the Processors are completed, click next button and continue'}</div>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            <div className='vw-22 col-md-4'>
                                <PerfectScrollbar
                                    className={`${(selectedUserDetails?.file_location_sow !== null && selectedUserDetails?.file_Name_sow !== null && selectedUserDetails?.profileTitle === 'PO/SOW') ? 'vh-90' : 'vh-60'} mt-3`}
                                    options={{ suppressScrollX: true }}
                                >
                                    {offeredCandidateDetails.map((item, idx) => {
                                        const userName = `${item?.name} ${item?.candidateFirstName}`;
                                        const color = generateColorHsl(userName, saturationRange, lightnessRange);
                                        const initials = getInitials(item?.candidateFirstName);
                                        return (
                                            <div
                                                className={`border_cls ${idx === 0 ? 'mt-0' : 'mt-2'} pb-1 pt-3 main_bg_interview_sheduled_card pointer_event ${selectedUserDetails?.yearsOfExperience === item?.yearsOfExperience && 'selected_card_border'} ${item?.resume_status === 'shortlisted' ? 'bg_color_user_details_selected_item' : 'bg_color_shortlisted_resume_list_cls'}}`}
                                                key={idx}
                                                onClick={() => { userDetailsOnClikHandler(item) }}
                                            >
                                                <div className="d-flex justify-content-center align-items-center border-bottom_2">
                                                    {item.profileMatch !== 'Yes/No' &&
                                                        <div className="col-md-2 ps-4_5 mb-2">
                                                            <div className="row1" key={idx}>
                                                                <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
                                                            </div>
                                                            {/* <div className="position-relative rounded-circle overflow-hidden mx-auto custom-circle-image border blackcircle">
                                                            <img className="w-100 h-100 whitecircle rounded-circle" alt="Alt content" src={require('../../assets/images/user-profile-icon.svg').default} />
                                                        </div> */}
                                                        </div>
                                                    }
                                                    <div className={`${item.profileMatch === 'Yes/No' ? 'col-md-12' : 'col-md-10'} ps-5`}>
                                                        <div>
                                                            <h6 className={`pb-1 mb-0 font_color_imp fw-medium font_size_14 ${selectedUserDetails?.offer_id === item?.offer_id && 'red_color'}`}>{item?.candidateFirstName || item?.firstName}</h6>
                                                            <span className={`font_color_imp fw-medium font_size_14 ${selectedUserDetails?.offer_id === item?.offer_id && 'red_color'}`}>{item?.profileTitle}</span>
                                                        </div>
                                                    </div>
                                                    <div className={`col-md-2 d-flex justify-content-start align-items-center margin_top_neg_60 ${item.profileMatch === 'Yes/No' ? 'pt-4' : 'pt-3'}`}>
                                                        <img
                                                            src={require(`../../assets/images/scheduledInterviewRibbon.svg`).default}
                                                            alt="jobType"
                                                            className="img_w_h_18"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='p-2'>
                                                    {/* <div className="d-flex justify-content-between align-items-center">
                                                    <div className='fw-medium font_size_14'>Years of experience</div>
                                                    <div>{item?.candidateExperience || item?.experience}</div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className='fw-medium font_size_14'>Profile match your requirement</div>
                                                    <div>{item?.profileMatch || 'Yes'}</div>
                                                </div> */}
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className='fw-medium font_size_13'>{item.yearsOfExperience === 'Yes' ? 'SOW Updated' : 'Years of experience'}</div>
                                                        <div className="font_size_13">{item?.yearsOfExperience || item?.candidateExperience || item?.year_of_exp}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className='fw-medium font_size_13'>{item.profileMatch === 'Yes/No' ? 'Offer given' : 'Profile match your requirement'}</div>
                                                        <div className="font_size_13">{item.profileMatch || 'Yes'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </PerfectScrollbar>
                            </div>
                        </div>
                        <div className="col-md-12 border_cls footer mt-auto py-2 px-3 bg-white  d-flex align-item-center justify-content-between">
                            {/* <div className='d-flex align-item-center justify-content-between'> */}
                            <div className='d-flex align-item-center justify-content-center'>
                                <span className='pt-2 orange_color font_size_12 fw-bolded'>
                                    Note: If the candidate is not joining due to some reason, you can reject the candidate by selecting reject
                                </span>
                            </div>
                            <div className='d-flex align-item-center justify-content-end'>
                                <button
                                    type="button"
                                    className="btn btn-primary bg_color_ornage pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6 me-3"
                                    onClick={() => { rejectJobOfferFun(selectedUserDetails) }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#isOpenJobOfferRejectModalPopUpId"
                                >
                                    Reject
                                </button>
                                {(selectedUserDetails?.file_location_sow !== null && selectedUserDetails?.file_Name_sow !== null) ?
                                    <button
                                        type="button"
                                        className="btn btn-primary btn_border pb-2 pe-2 ps-3 font_size_14 fw-medium btn_height_36 mt-1 me-3 padding_top_6"
                                        // onClick={() => { onClickPageRedirection() }}
                                        data-bs-target="#releaseOfPurchaseOrderModal"
                                        data-bs-toggle="modal"
                                    >
                                        Modify Release PO/SOW
                                        <i className="fa fa-angle-right ps-2"></i>
                                    </button>
                                    :
                                    <button
                                        type="button"
                                        className="btn btn-primary btn_border pb-2 pe-2 ps-3 font_size_14 fw-medium btn_height_36 mt-1 me-3 padding_top_6"
                                        // onClick={() => { onClickPageRedirection() }}
                                        data-bs-target="#releaseOfPurchaseOrderModal"
                                        data-bs-toggle="modal"
                                    >
                                        Upload Release PO/SOW
                                        <i className="fa fa-angle-right ps-2"></i>
                                    </button>
                                }
                                <button
                                    type="button"
                                    className="btn btn-primary btn_border pb-2 pe-2 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                                    onClick={() => { onClickRedirectToScheduledForInterviewPage() }}
                                >
                                    Next
                                    <i className="fa fa-angle-right ps-2"></i>
                                </button>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </>
            }
            {offeredCandidateDetails && offeredCandidateDetails.length === 0 &&
                <>
                    {/* <div className="row bd-example">
                        <div className="table-container"> */}
                    <div className="disply_block_t_center">
                        <div className={`vh-60 font_size_24 disply_block_t_center ${Object.keys(currentJobData)?.length > 0 ? 'p-5' : 'padding_15_p'}`}>
                            {Object.keys(currentJobData)?.length > 0 &&
                                <div className='p-3'>
                                    <img
                                        src={require(`../../assets/images/no_cv_img.svg`).default}
                                        alt="Sow"
                                        className="w-20"
                                    />
                                    <div className='fw_bold font_dark_color p-2 pb-1 font_size_16'>Candidate resumes is on below state please click on it</div>
                                    <div className='fw_bold font_dark_color p-2 pb-1 font_size_16'> to go to current stage of candidate</div>
                                </div>
                            }
                            {Object.keys(currentJobData)?.length > 0 &&
                                <div className='font_color_ornage hover_underline'>
                                    {(currentJobData?.resumeShortlistedCount > 0)
                                        &&
                                        <div className='font_dark_green_color hover_underline pb-1 font_size_18' onClick={() => { onClickPageRedirectionInterviewShort(currentJobData) }}>{`Shortlisted (${currentJobData.resumeShortlistedCount})`}</div>
                                    }
                                    {currentJobData?.newResumeCount > 0
                                        &&
                                        <div className='orange_color hover_underline pb-1 font_size_18' onClick={() => { onClickPageRedirectionInterviewScreen(currentJobData) }}>{`Screening (${currentJobData.newResumeCount})`}</div>
                                    }
                                    {currentJobData?.newResumeCount === 0 && currentJobData?.jobOfferedCount === 0 && currentJobData?.resumeShortlistedCount === 0 &&
                                        <div className="font_orange_color_underline pb-1 font_size_18" onClick={() => { onClickPageRedirectionInterviewScreen(currentJobData) }}>No resumes available</div>
                                    }
                                    {currentJobData?.jobOfferedCount > 0
                                        &&
                                        <>
                                            <div className='orange_color hover_underline pb-1 font_size_18' onClick={() => { onClickPageRedirectionInterviewOffer(currentJobData) }}>Job Offered</div>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {/* <div className="col-md-12 footer mt-auto py-2 px-3 bg-white  d-flex align-item-center justify-content-end">
                        <button
                            type="button"
                            className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                            onClick={() => { onClickRedirectToOnBoardPagenext() }}
                        >
                            Next
                            <i className="fa fa-angle-right ps-2"></i>
                        </button>
                    </div> */}
                </>
            }
            <ModalComponent
                title="Release of Purchase order !!"
                // footerContent={releaseOfPurchaseOrderModalFooterContent()}
                content={releaseOfPurchaseOrderBodyContent()}
                dataBsBackdrop="static"
                id="releaseOfPurchaseOrderModal"
                modalDialogClass="add_requirement_modal_w"
                // w_max_content={true}
                modalBodyPaddingBottomCls="p-0"
            // fullScreen={true}
            />
            {/* Reject Offer Modal */}
            <ModalComponent
                title="Confirmation !!"
                footerContent={rejectModalFooterContent()}
                content={rejectModalContent()}
                dataBsBackdrop="static"
                id="isOpenJobOfferRejectModalPopUpId"
                w_max_content={true}
            />
        </div>
    )
}

// export default adminLayout(JobOfferedComponent)
export default JobOfferedComponent

const masterData = [
    { label: 'Company Name', response: 'companyName', img: 'skills_needed.svg' },
    { label: 'Job Type', response: 'job_type', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', img: 'Y_O_Exp.svg' },
]