import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { isMobile } from 'react-device-detect';
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import PhoneInput from 'react-phone-input-2';
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import Multiselect from "multiselect-react-dropdown";
import "../../assets/css/login.css"
import authLayout from "../../hoc/authLayout";
import { addTour, deleteTour } from "../../redux/actions/tour";
import { typesOfEmployeeContractList, typeOfCompanyList, numberOfEmployeesList } from '../../Utils/masterData/dropDownJson'
import { postApi } from '../../services/apiCommonService'
import { sign_up_api_url, post_company_logo_api_url } from '../../services/endPointsConstant'
import { isEmpty } from '../../Utils/utils'
import { useDispatch } from "react-redux";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};
const SignUp = () => {
    const [formData, setFormData] = useState(false);
    const [typeOfCompanyValue, setTypeOfCompanyValue] = useState(null);
    const [numberOfEmployeesValue, setNumberOfEmployeesValue] = useState(null);
    const [typesOfEmployeeContractValue, setTypesOfEmployeeContractValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const [phoneValue, setPhoneValue] = useState('');
    const [countrySelectedValue, setCountrySelectedValue] = useState('IN');
    const [countryOptions, setCountryOptions] = useState(countryList().getData())
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        setValue,
        reset,
        control,
        getValues
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        dispatch(addTour({ steps: customSteps }))
        reset({ country: 'IN' })
    }, [])

    const errorHandler = () => {
        if (errors.email) {
            toast.error("Check businessEmailId address, It should be example@google.com")
        } else if (errors.password) {
            toast.error("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.")
        } else if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    useEffect(() => {
        if (countrySelectedValue) {
            setPhoneValue('')
        } else {
            setPhoneValue('')
        }
    }, [countrySelectedValue])

    const logoUpload = async (data, companyDetails) => {
        const baseUrl = 'https://free.remote72.in'
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("companyId", companyDetails?.companyId)
        const apiResponse = await fetch(`${baseUrl}/${post_company_logo_api_url}`, {
            method: "POST",
            body: formData,
        }).then((res) => res.json());
        if (apiResponse?.message) {
            if (apiResponse?.data) {
                toast.success(apiResponse?.message)
            } else {
                toast.success(apiResponse?.response?.data.message || "Image uploaded successfully !!")
            }
        } else {
            toast.error(apiResponse?.response?.data?.message + '!!')
        }
    }

    const onSubmit = async (data) => {
        setFormData(data);
        const apiReqBody = {
            company_name: data?.companyName,
            contact_person_name: data?.firstName + ' ' + data?.lastName,
            buisness_email_id: data?.businessEmailId,
            phone_number: data?.phoneNumber,
            company_type: data?.typeOfCompany,
            no_of_employee: data?.numberOfEmployees,
            country: data?.country,
            logo: data?.file,
            location: data?.location,
            contractType: data?.contractType?.map(({ name }) => name)
        }
        const apiResponse = await postApi(sign_up_api_url, apiReqBody)
        if (apiResponse?.data?.message === 'phone number already exist') {
            toast.error(apiResponse?.data?.message)
        } else {
            if (apiResponse?.data?.username) {
                window.sessionStorage.setItem('singup', JSON.stringify(apiResponse?.data?.username))
                if (data.file?.length > 0) {
                    logoUpload(data, apiResponse?.data?.username)
                }
                toast.success("Successfully singedup, Please Activate your account !!")
                // toast.success(`OTP : ${apiResponse?.data?.otp}`)
                navigate(`/admin-verification-pending-page`)
                // navigate(`/verify-your-account/${apiResponse?.data?.username?._id}`);
                // navigate('/login');
            } else {
                toast.error(apiResponse?.response?.data.message)
            }
        }
    };

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['country', 'phoneNumber', 'typeOfCompany', 'numberOfEmployees', 'contractType'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    const validate = (value) => {
        const { file } = getValues()
        if (!file?.length === 0) return true;
        if (file?.length > 0) {
            const acceptedFormats = ['jpg', 'jpeg', 'png'];
            const fileExtension = value[0]?.name?.split('.')?.pop()?.toLowerCase();
            if (!acceptedFormats.includes(fileExtension)) {
                return 'Invalid file format. Only jpg, jpeg, png files are allowed.';
            }
            return true;
        }
    };

    $('#file-upload').change(function () {
        var i = $(this).prev('label').clone();
        var file = $('#file-upload')[0]?.files[0]?.name;
        $(this)?.prev('label')?.text(file);
    });

    return (
        <>
            <div className="form-holder login-form vh-100 rounded-0 overflow-auto pb-0 pt-0">
                <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
                    <h1 className="text-center fw-normal mb-0 mt-0 me-3 my-1-step">Sign up for</h1>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`form-outline did-floating-label-content ${errors.companyName && 'did-error-input'}`}>
                        <input
                            type="text"
                            name="companyName"
                            placeholder=""
                            {...register("companyName", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9._-\s]+$/,
                                    message: "Special characters are not allowed"
                                }
                            })}
                            className="did-floating-input"
                        />
                        <label className="did-floating-label requiredTrue">{'Company Name'}</label>
                        {/* {errors.companyName && <p className="error">{errors.companyName.message}</p>} */}
                    </div>

                    <div className="form-outline row gx-3">
                        <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.firstName && 'did-error-input'}`}>
                            <input
                                type="text"
                                name="firstName"
                                placeholder=""
                                {...register("firstName", {
                                    required: "Field is required",
                                    minLength: {
                                        value: 2,
                                        message: "Min length is 2"
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-\s]+$/,
                                        message: "Special characters are not allowed"
                                    }
                                })}
                                className="did-floating-input"
                            />
                            <label className="did-floating-label ms-2 requiredTrue">{'Firstname'}</label>
                            {/* {errors.firstName && <p className="error">{errors.firstName.message}</p>} */}
                        </div>
                        <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.lastName && 'did-error-input'}`}>
                            <input
                                type="text"
                                name="lastName"
                                placeholder=""
                                {...register("lastName", {
                                    required: false,
                                    // minLength: {
                                    //     value: 2,
                                    //     message: "Min length is 2"
                                    // },
                                    // pattern: {
                                    //     value: /^[a-zA-Z0-9._-\s]+$/,
                                    //     message: "Special characters are not allowed"
                                    // }
                                })}
                                className="did-floating-input"
                            />
                            <label className="did-floating-label ms-2">{'Lastname'}</label>
                            {/* {errors.lastName && <p className="error">{errors.lastName.message}</p>} */}
                        </div>
                    </div>
                    <div className={`form-outline did-floating-label-content ${errors.businessEmailId && 'did-error-input'}`}>
                        <input
                            type="email"
                            name="businessEmailId"
                            placeholder=""
                            {...register("businessEmailId", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                                pattern: {
                                    value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                    message: "Invalid businessEmailId address, It should be example@google.com"
                                }
                            })}
                            className="did-floating-input"
                        />
                        <label className="did-floating-label requiredTrue">{'Business Email ID'}</label>
                        {/* {errors.businessEmailId && <p className="error">{errors.businessEmailId.message}</p>} */}
                    </div>

                    <div className="form-outline row gx-3">
                        <div className={`col-md-6 marginbottom_int did-floating-label-content'}`}>
                            {/* <div className="form-outline row did-floating-label-content"> */}
                            {/* <div className={`marginbottom_int did-floating-label-content'}`}> */}
                            <Controller
                                name="country"
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div
                                        // className="countryFlag"
                                        style={{ display: "flex", alignItems: "center", width: "100%" }}
                                    >
                                        <ReactCountryFlag
                                            countryCode={
                                                countrySelectedValue ? countrySelectedValue : ""
                                            }
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            title={countrySelectedValue ? countrySelectedValue : ""}
                                            className={`countryFlag ${errors.country && 'countryFlag_error'}`}
                                        // style={{ border: '1px solid red' }}
                                        />
                                        <div
                                            style={{ color: "black", width: "100%" }}
                                        >
                                            <Select
                                                isSearchable={true}
                                                options={countryOptions}
                                                value={countryOptions.find((c) => c.value === value)}
                                                // onChange={this.changeHandler}
                                                // value={typeOfCompanyList.find((c) => c.value === value)}
                                                onChange={(val) => { onChange(val?.value); setCountrySelectedValue(val?.value) }}
                                                defaultValue={countryOptions.find((c) => c.value === countrySelectedValue)}
                                                placeholder=""
                                                className="basic-single my-react-select"
                                                // classNamePrefix="select"
                                                components={{
                                                    ValueContainer: CustomValueContainer
                                                }}
                                                onMenuClose={() => { value && setIsSelected({ ...isSelected, 'country': true }) }}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        borderRadius: '2px',
                                                        ...errorBorderForSelectBox(errors.country && 'country'),
                                                        minHeight: !countrySelectedValue ? '40px' : '38px'
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                        marginTop: 2,
                                                    }),
                                                    valueContainer: (provided, state) => ({
                                                        ...provided,
                                                        overflow: "visible"
                                                    }),
                                                    placeholder: (provided, state) => ({
                                                        ...provided,
                                                        position: "absolute",
                                                        top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                        background: "white",
                                                        transition: "top 0.1s, font-size 0.1s",
                                                        fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                        fontWeight: 100,
                                                        padding: '0.3px 8.5px',
                                                        borderRadius: '8px',
                                                        color: 'rgba(6, 44, 80, 0.7);',
                                                        border: isSelected['country'] ? '1px solid #cccccc' : ''
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        fontSize: 14,
                                                        color: 'rgba(32, 53, 98, 1)',
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                rules={{ required: "Field is required" }}
                            />
                            {/* {errors.country && <p className="error">{errors.country.message}</p>} */}
                            {/* </div> */}
                        </div>
                        <div className="col-md-6 marginbottom_int did-floating-label-content">
                            {/* <div className="form-outline row did-floating-label-content"> */}
                            {/* <div className="marginbottom_int did-floating-label-content"> */}
                            <Controller
                                render={(props) => (
                                    <PhoneInput
                                        placeholder="Phone number"
                                        country={countrySelectedValue?.toLowerCase()}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        onChange={(e) => {
                                            props.field.onChange(e); setPhoneValue(e)
                                        }}
                                        value={props.field.value}
                                        inputClass={errors.phoneNumber ? 'error_telphone' : ''}
                                        containerClass={errors.phoneNumber ? 'error_telphone_container' : ''}
                                        countryCodeEditable={false}
                                    />
                                )}
                                defaultValue=""
                                name="phoneNumber"
                                control={control}
                                rules={{ required: "Field is required" }}
                            />
                            {/* {errors.phoneNumber && <p className="error">{errors.phoneNumber.message}</p>} */}
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="form-outline mt-2">
                        <Controller
                            name="typeOfCompany"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={typeOfCompanyList}
                                    value={typeOfCompanyList.find((c) => c.value === value)}
                                    onChange={(val) => onChange(val?.value)}
                                    defaultValue={typeOfCompanyList.find((c) => c.value === typeOfCompanyValue)}
                                    isSearchable={true}
                                    placeholder="Type Of Company"
                                    className="basic-single my-react-select"
                                    classNamePrefix="select"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'typeOfCompany': true }) }}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.typeOfCompany && 'typeOfCompany'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            marginTop: 5,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['typeOfCompany'] ? '1px solid #cccccc' : ''
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                            ...styles,
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            //   cursor: isDisabled ? 'not-allowed' : 'default',
                                        })
                                    }}
                                />
                            )}
                            rules={{ required: "Field is required" }}
                        />
                        {/* <label className="did-floating-label">Type of company</label> */}
                        {/* {errors.typeOfCompany && <p className="error">{errors.typeOfCompany.message}</p>} */}
                    </div>

                    <div className="form-outline mt-4">
                        <Controller
                            name="numberOfEmployees"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={numberOfEmployeesList}
                                    value={numberOfEmployeesList.find((c) => c.value === value)}
                                    onChange={(val) => onChange(val.value)}
                                    defaultValue={numberOfEmployeesList.find((c) => c.value === numberOfEmployeesValue)}
                                    isSearchable={true}
                                    placeholder="Number Of Employees"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'numberOfEmployees': true }) }}
                                    styles={{
                                        // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.numberOfEmployees && 'numberOfEmployees'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            marginTop: 22,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['numberOfEmployees'] ? '1px solid #cccccc' : ''
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                            ...styles,
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            //   cursor: isDisabled ? 'not-allowed' : 'default',
                                        })
                                    }} />
                            )}
                            rules={{ required: "Field is required" }}
                        />
                        {/* <label className="did-floating-label">Number of employees</label> */}
                        {/* {errors.numberOfEmployees && <p className="error">{errors.numberOfEmployees.message}</p>} */}
                    </div>

                    <div className="form-outline mt-4">
                        <Controller
                            name="contractType"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { ref, ...field } }) => {
                                return (<>
                                    <span className={`requiredTrue ${(field?.value?.length > 0) ? 'singupselectedValuesMultiple' : 'signupnonSelectedValuesMultiple'}`}></span>
                                    <Multiselect
                                        {...field}
                                        inputRef={ref}
                                        displayValue="name"
                                        onSelect={(selected, item) => {
                                            setValue("contractType", selected);
                                        }}
                                        onRemove={(selected, item) => {
                                            setValue("contractType", selected);
                                        }}
                                        options={typesOfEmployeeContractList}
                                        avoidHighlightFirstOption
                                        placeholder="Contract type"
                                        isSearchable={false}
                                        showArrow
                                        style={{
                                            multiselectContainer: {
                                                minHeight: 30,
                                                background: 'white',
                                            },
                                            searchBox: { // To change search box element look
                                                display: 'flex',
                                                fontSize: 14,
                                                minHeight: 0,
                                                padding: field?.value?.length > 0 ? '6px 6px 1px 6px' : '8px 8px 6px 16px',
                                                border: errors?.office_type ? '1px solid #bf1650' : ''
                                                // margin: '6px 6px 1px 6px',
                                            },
                                            inputField: { // To change input field position or margin
                                                margin: 0,
                                                position: field?.value?.length > 0 ? "absolute" : 'relative',
                                                top: field?.value?.length > 0 ? -18 : "10%",
                                                background: "white",
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                fontWeight: 100,
                                                padding: field?.value?.length > 0 ? '0.3px 8.5px' : 0,
                                                borderRadius: '5px',
                                                color: 'rgba(6, 44, 80, 0.7);',
                                                // border: isSelected['office_type'] ? '1px solid #cccccc' : '',
                                                width: field?.value?.length > 0 && 152,
                                                border: field?.value?.length > 0 && '1px solid #cccccc'
                                            },
                                            chips: { // To change css chips(Selected options)
                                                background: 'rgba(6, 44, 80, 1)',
                                                color: 'white'
                                            },
                                        }}
                                    />
                                </>
                                );
                            }}
                        />
                        {/* <Controller
                            name="contractType"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                                console.log("=====value======", value)
                                return (
                                    <Select
                                        isClearable
                                        isMulti
                                        options={typesOfEmployeeContractList}
                                        value={typesOfEmployeeContractList.find((c) => c.value === value)}
                                        onChange={(val) => onChange(val.value)}
                                        defaultValue={typesOfEmployeeContractList.find((c) => c.value === typesOfEmployeeContractValue)}
                                        isSearchable={true}
                                        placeholder="What kind of contract are you looking for ?"
                                        className="basic-single"
                                        classNamePrefix="select"
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        onMenuClose={() => { value && setIsSelected({ ...isSelected, 'contractType': true }) }}
                                        styles={{
                                            // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                            control: (styles) => ({
                                                ...styles,
                                                ...errorBorderForSelectBox(errors.contractType && 'contractType'),
                                            }),
                                            container: (provided, state) => ({
                                                ...provided,
                                                marginTop: 22,
                                            }),
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                overflow: "visible"
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                background: "white",
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                fontWeight: 100,
                                                padding: '0.3px 8.5px',
                                                borderRadius: '8px',
                                                color: 'rgba(6, 44, 80, 0.7);',
                                                border: isSelected['contractType'] ? '1px solid #cccccc' : ''
                                            }),
                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                ...styles,
                                                color: 'rgba(6, 44, 80, 0.7);',
                                                //   cursor: isDisabled ? 'not-allowed' : 'default',
                                            })
                                        }}
                                    />
                                )
                            }
                            }

                            rules={{ required: "Field is required" }}
                        /> */}
                        {/* <label className="did-floating-label">What kind of contract are you looking for ?</label> */}
                        {/* {errors.contractType && <p className="error">{errors.contractType.message}</p>} */}
                    </div>

                    <div className="form-outline row gx-3 mt-4">
                        <div className={`col-md-6 marginbottom_int did-floating-label-content`}>
                            {/* ${errors.file && 'did-error-input'} */}
                            <form className={`d-flex border_c justify-content-between align-items-center bg-white rounded`}>
                                {/* ${errors?.file && 'error_border'} */}
                                <div>
                                    <label for="file-upload" class="custom-file-upload color_dull_1 font_size_12 ms-2 position-absolute border-top-0 border-start-0 border-bottom-0">
                                        {/* <i class="fa fa-cloud-upload"></i>  */}
                                        Upload business logo
                                    </label>
                                    {/* <input id="file-upload" name='upload_cont_img' type="file" style="display:none;"> */}
                                    <input
                                        className="did-floating-input width_170 border-bottom-0 input_file_border" //mb-4 font_size_14
                                        type="file"
                                        name="file"
                                        // accept="image/png, image/jpeg" 
                                        accept=".jpg, .jpeg, .png"
                                        placeholder=""
                                        {...register("file", {
                                            required: false,
                                            validate
                                            // validate: (value) => {
                                            //     const acceptedFormats = ['jpg', 'jpeg', 'png'];
                                            //     const fileExtension = value[0]?.name.split('.').pop().toLowerCase();
                                            //     if (!acceptedFormats.includes(fileExtension)) {
                                            //         return 'Invalid file format. Only jpg, jpeg, png files are allowed.';
                                            //     }
                                            //     return true;
                                            // }
                                        })}
                                        style={{ paddingTop: '7px' }}
                                        id="file-upload"
                                    />
                                </div>
                                <i class="fa fa-cloud-upload font_size_21 me-0_5"></i>
                                {/* <label className="did-floating-label">{'Logo'}</label> */}
                            </form>
                        </div>

                        <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.location && 'did-error-input'}`}>
                            <input
                                className="did-floating-input"
                                type="text"
                                name="location"
                                placeholder=''
                                rows="5" cols="33"
                                {...register("location", {
                                    required: "Field is required",
                                    minLength: {
                                        value: 2,
                                        message: "Min length is 2"
                                    },
                                })}
                            />
                            <label className="did-floating-label requiredTrue">{'Location'}</label>
                        </div>
                    </div>

                    <button type="submit" disabled={isSubmitting} className="w-100 rounded-2 mt-1 mb-1_5 my-2-step" onClick={errorHandler}>
                        {isSubmitting && <span>Submitting</span>}
                        {!isSubmitting && <span>Sign up now</span>}
                    </button>

                    <div className="d-flex justify-content-center align-items-center">
                        <span className="d-flex text-center text-lg-start justify-content-center align-items-center">
                            {!isMobile ?
                                <p className="small fw-bold">Already have an account?
                                    <Link to="/login" className="fontColor me-2 font_size_16 fw-700"><strong>  Login Here</strong></Link>
                                </p> :
                                <>
                                    <p className="small fw-bold">Already have an account?</p>
                                    <Link to="/login" className="fontColor me-2 font_size_16 fw-700"><strong>Login Here</strong></Link>
                                </>
                            }
                        </span>
                    </div>

                </form>
            </div>
        </>
    );
}

export default authLayout(SignUp);

const customSteps = [
    {
        selector: '.my-1-step',
        content: 'singup form for user!  ',
    },
    {
        selector: '.my-2-step',
        content: 'button click to signup!  ',
    }
]