import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../../hoc/adminLayout";
import Pagination from '../../../common/pagination/Pagination';
import RangeDatePicker from '../../../components/RangeDatePicker';
import data from '../../../common/pagination/examples/data/mock-data.json';
import { returnArryOfObjects } from '../../../Utils/utils'
import { emptyJobList, PageSize } from '../../../Utils/masterData/staticContent'
// import ModalComponent from "../../../components/ModalComponent";
// import AddNewRequirement from "../jobCRUDComponent/AddNewRequirement";
// import EditJobOffer from "../jobOffer/EditJobOffer";
import { postApi, deleteApiWithBody, updateWithOutParamsApi } from '../../../services/apiCommonService'
import { candidates_resigned_report_url, delete_job_list_api_url, edit_Offer_api_url, get_CandidateOf_ActMgr } from '../../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import CustomSingleSelect from '../../../components/CustomSelectComponent';
import storePersist from '../../../Utils/storePersist'

const AssignedCandidatesListComponent = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [assignCandidatesList, setAssignCandidatesList] = useState([]);
    const [oldAssignCandidatesList, setOldAssignCandidatesList] = useState([]);
    const [selectedJobOffer, setSelectedJobOffer] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedJobOfferObjects, setSelectedJobOfferObjects] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [viewSelectedJobOfferDetails, setViewSelectedJobDetails] = useState('')
    const [modalPopUpCancel, setModalPopUpCancel] = useState(false);
    const [rejectSelectedJobOfferData, setRejectSelectedJobOfferData] = useState({});
    const [pageSize, setPageSize] = useState(PageSize);

    useEffect(() => {
        void getCandidatesOfActMgrListApi()
    }, [props?.selectedManagerDetails]);

    const getCandidatesOfActMgrListApi = async () => {
        dispatch(addLoader());
        const apiReqBody = { actMgrId: props?.selectedManagerDetails?.actManagerId }
        const apiResponse = await postApi(get_CandidateOf_ActMgr, apiReqBody)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.filter((item) => item?.onboarding_status === "onboarded")?.reverse()
            setAssignCandidatesList(apiResponseData)
            setOldAssignCandidatesList(apiResponseData)
            // toast.success("Retrived all Candidates List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.value;
        const lastPageIndex = firstPageIndex + pageSize?.value;
        return assignCandidatesList.slice(firstPageIndex, lastPageIndex);
    };

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(assignCandidatesList, ids, '_id')
        setSelectedJobOfferObjects(finalUsersData)
    }

    const selectAll = () => {
        setSelectedJobOffer([]);
        if (!isAllSelected) {
            assignCandidatesList.forEach(jobData => {
                setSelectedJobOffer(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            });
            callUsersData(assignCandidatesList.map((item) => item._id))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const handleUserSelect = (jobData) => {
        if (selectedJobOffer.includes(jobData._id)) {
            setSelectedJobOffer(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData._id));
            callUsersData(selectedJobOffer.filter(_id => _id !== jobData._id))
        } else {
            setSelectedJobOffer(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            callUsersData([...selectedJobOffer, jobData._id])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedJobOffer.includes(jobData._id);
        return isUserSelectedVal
    };

    const isAllUsersSelected = () => {
        return selectedJobOffer.length === assignCandidatesList.length;
    };

    const filterTextSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = oldAssignCandidatesList.filter((jobData) => {
                return jobData?.job_type?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.job_code?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    // jobData?.skill?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.position_status?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    // jobData?.office_type?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.candidateFirstName?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.candidateLastName?.toLowerCase().startsWith(keyword.toLowerCase())
            });
            setAssignCandidatesList(results);
        } else {
            setAssignCandidatesList(oldAssignCandidatesList);
        }
        setKeywordName(keyword);
    };

    const viewSelectedJobOfferDetailsFun = (item, idx) => {
        selectedRecordDataFun(item)
        setViewSelectedJobDetails({ pageData: item, idxValue: idx })
    }

    // const returnAddNewRequirementFormDetailsFun = (formDetails) => {
    //     void resignedCandidatesListApiCall()
    //     setAssignCandidatesList([...assignCandidatesList, formDetails])
    // }

    // const returnEditJobRequirementFormDetailsFun = (formDetails) => {
    //     void resignedCandidatesListApiCall()
    //     setAssignCandidatesList([...assignCandidatesList, formDetails])
    // }

    // const modalContent = (props) => {
    //     return <>
    //         <AddNewRequirement
    //             modalPopUpCancel={modalPopUpCancel}
    //             returnAddNewRequirementFormDetails={returnAddNewRequirementFormDetailsFun}
    //         />
    //     </>;
    // }

    const editModalContent = () => {
        return <>
            {/* <EditJobOffer
                // returnEditJobRequirementFormDetails={returnEditJobRequirementFormDetailsFun}
                modalPopUpCancel={modalPopUpCancel}
                selectedJobOfferObjects={selectedJobOfferObjects}
            /> */}
        </>;
    }

    const viewJobDetailsModalContent = (props) => {
        return <>

        </>;
    }

    const editJob = () => {
        toast.info("Edit job Modal Popup is Opened !!")
    }

    const cancelPopUpFun = (vale) => {
        setModalPopUpCancel(vale)
    }

    const selectedRecordDataFun = (selected_job_data) => {
        if (selected_job_data) {
            setSelectedJobOffer([selected_job_data?._id]);
            setSelectedJobOfferObjects([selected_job_data])
        }
    }

    const onSubmit = async () => {
        const apiReqBody = {
            'jobId': selectedJobOffer.length > 0 && selectedJobOffer
        }
        const apiResponse = await deleteApiWithBody(delete_job_list_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Job deleted successfully !!')
            // void resignedCandidatesListApiCall()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const deleteModalContent = () => {
        return <>
            <ul>
                {selectedJobOffer.map((item, idx) =>
                    <li key={idx}>
                        {item}
                    </li>
                )}
            </ul>
        </>;
    }

    const deleteModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={onSubmit}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
            >
                Cancel
            </button>
        </>;
    }

    const deleteFun = () => {
        toast.info("Delete Confirmation Modal Popup is Opened !!")
    }

    const rejectJobOfferFun = (item, idx) => {
        setRejectSelectedJobOfferData({ pageData: item, idxValue: idx })
    }

    const rejectModalContent = () => {
        return <>Do you want to Reject the Job Offer ?</>
    }

    const rejectModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
            // onClick={rejectJobApiCallHandler}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
            >
                Cancel
            </button>
        </>;
    }

    // const rejectJobApiCallHandler = async () => {
    //     const apiReqBody = {
    //         'offerId': rejectSelectedJobOfferData?.pageData?._id,
    //         "sow_po_status": "Rejected"
    //     }
    //     const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
    //     if (apiResponse?.data) {
    //         toast.success(apiResponse?.data.message + ' !!')
    //         void resignedCandidatesListApiCall()
    //     } else {
    //         toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
    //     }
    // }

    const onChangeHandler = (event) => {
        setPageSize(event)
    }

    return <>
        {/* <div className="my-1 p-3 bg-body rounded shadow-sm rounded_20">
            <div className="d-flex justify-content-between align-items-center border-bottom pt-2"> */}
        {/* <div className="col-xl-8 col-sm-6 ps-3 d-flex gap-2 align-items-baseline m_14_neg">
                    <Link className='link_cls' to="/dashboard">Back to dashboard</Link>
                    <b>{'>'}</b>
                    <h6 className="font_color_imp font_size_16 fw-bolded">Assigned Candidates List</h6>
                </div> */}
        {/* {oldAssignCandidatesList.length > 0 &&
                    <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                        <button
                            type="button"
                            className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                            disabled={selectedJobOffer.length > 0 && selectedJobOffer.length < 2 ? false : true}
                            data-bs-target="#EditJobOfferDetails"
                            data-bs-toggle="modal"
                            onClick={editJob}
                        >
                            Edit
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                            disabled={selectedJobOffer.length > 0 ? false : true}
                            data-bs-target="#deleteJobOfferDetailsModal"
                            data-bs-toggle="modal"
                            onClick={deleteFun}
                        >
                            Delete
                        </button>
                    </div>
                } */}
        {/* </div> */}
        {oldAssignCandidatesList.length > 0 &&
            <>
                <div className="mt-3 mb-0 pe-2">
                    <div className="col-md-12 d-flex justify-content-end align-items-center gap-4">
                        <div className="col-md-4 d-flex justify-content-end align-items-center gap-1">
                            {/* <button className="border-0 mt-0 mb-0 pb-0 bg-white">
                                <img src={require('../../../assets/images/vector_filter.svg').default} alt="Alt content" />
                            </button> */}
                            {/* <div className="col-md-8"> */}
                            {/* <RangeDatePicker /> */}
                            {/* </div> */}
                        </div>
                        <div className="col-md-3">
                            <input
                                type="search"
                                value={keywordName}
                                onChange={filterTextSearch}
                                className="searchInputCls"
                                placeholder="Search Job type, skills etc..."
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row bd-example">
                        <div className="table-container">
                            <div className="d-flex text-muted height_job_post_table">
                                <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                </th>
                                                {['Company Name', 'Candidate Name', 'Job Code', 'Job Type', 'Office Type', 'Onboarding Status', 'On-boarded Date'].
                                                    map((item, idx) =>
                                                        <th key={idx} className={`text-white`}>
                                                            {item}
                                                        </th>
                                                    )}
                                                {/* <th></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentTableData().map((item, idx) => {
                                                return (
                                                    <tr key={item._id}>
                                                        <td>
                                                            <input
                                                                className="width_12"
                                                                type="checkbox"
                                                                checked={isUserSelected(item)}
                                                                onChange={() => handleUserSelect(item)}
                                                            />
                                                        </td>
                                                        <td className='fw-bolder'>{item?.companyName}</td>
                                                        <td>{item.candidateFirstName}</td>
                                                        <td>{item.job_code}</td>
                                                        <td>{item.job_type}</td>
                                                        <td>{item.office_type}</td>
                                                        <td>{item.onboarding_status}</td>
                                                        <td>
                                                            {new Date(item.joining_date)?.toLocaleString() || '--'}
                                                        </td>
                                                        {/* <td>
                                                            <div className="dropdown table-action-dropdown">
                                                                <button
                                                                    className="btn btn-secondary btn-sm dropdown-toggle mt-0 mb-0 p-0"
                                                                    type="button"
                                                                    id="dropdownMenuButtonSM"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                </button>
                                                                <ul className="dropdown-menu shadow-sm-dd font_size_14 min_w" aria-labelledby="dropdownMenuButtonSM">
                                                                    <li className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#viewJobOfferDetails"
                                                                        onClick={() => { viewSelectedJobOfferDetailsFun(item, idx) }}
                                                                    >
                                                                        <img
                                                                            src={require('../../../assets/images/view_account_details_nm.svg').default}
                                                                            alt="Alt content"
                                                                            style={{ height: 12, padding: '0px 6px 0px 2px' }}
                                                                        />
                                                                        View Full Details
                                                                    </li>
                                                                    <div className="dropdown-divider"></div>
                                                                    <li
                                                                        className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#isOpenRejectModalPopUpId"
                                                                        onClick={() => { rejectJobOfferFun(item, idx) }}
                                                                    >
                                                                        <img
                                                                            src={require('../../../assets/images/scheduled_resume.svg').default}
                                                                            alt="Alt content"
                                                                            style={{ padding: '0px 6px 0px 2px' }}
                                                                        />
                                                                        Reject
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </PerfectScrollbar>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                                <CustomSingleSelect
                                    options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                                    selectedPage={pageSize}
                                    onChangeHandler={onChangeHandler}
                                />
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={assignCandidatesList.length}
                                    pageSize={pageSize?.label}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        {oldAssignCandidatesList.length === 0 &&
            <>
                <div className="row bd-example">
                    <div className="table-container">
                        <div className="text_v_h_center vh-80 font_size_24">
                            No Data Found
                        </div>
                    </div>
                </div>
            </>
        }
        {/* </div > */}
        {/* Edit Post Job Details modals */}
        {/* <ModalComponent
            title="Edit Job Details"
            content={editModalContent()}
            dataBsBackdrop="static"
            id="EditJobOfferDetails"
            modalDialogClass="add_requirement_modal_w"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
        /> */}
        {/* View Job Offer Details modals */}
        {/* <ModalComponent
            title={viewSelectedJobOfferDetails?.job_code}
            content={viewJobDetailsModalContent()}
            dataBsBackdrop="static"
            id="viewJobOfferDetails"
            modalDialogClass="view_job_details_modal_w"
            modalBodyPaddingBottomCls="p-0 overflow_custom"
            isHeaderHide={true}
            cancelPopUpFun={cancelPopUpFun}
        /> */}
        {/* delete Job Offer Details modal */}
        {/* <ModalComponent
            title="Do you want delete selected job offer details ?"
            footerContent={deleteModalFooterContent()}
            content={deleteModalContent()}
            dataBsBackdrop="static"
            id="deleteJobOfferDetailsModal"
            w_max_content={true}
        /> */}
        {/* Reject Offer Modal */}
        {/* <ModalComponent
            title="Confirmation !!"
            footerContent={rejectModalFooterContent()}
            content={rejectModalContent()}
            dataBsBackdrop="static"
            id="isOpenRejectModalPopUpId"
            w_max_content={true}
        /> */}
    </>
}

export default AssignedCandidatesListComponent;