import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../hoc/adminLayout";
import Pagination from '../../common/pagination/Pagination';
import RangeDatePicker from '../../components/RangeDatePicker';
import data from '../../common/pagination/examples/data/mock-data.json';
import { returnArryOfObjects, randStr } from '../../Utils/utils'
import { typesOfEmployeeContractList } from '../../Utils/masterData/dropDownJson'
import { emptyJobList, PageSize } from '../../Utils/masterData/staticContent'
import { getAllApi, deleteApiWithBody, postApi } from '../../services/apiCommonService'
import { get_job_list_api_url, get_jobs_by_company_id, delete_job_list_api_url, request_for_resumes } from '../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import { addSelectedJobDetails, deleteSelectedJobDetails } from "../../redux/actions/jobDetails";
import CustomSingleSelect from '../../components/CustomSelectComponent';
import storePersist from '../../Utils/storePersist';
import Chipcomponent from '../../components/ChipComponent';

const EmployeeDashboardPage = (props) => {
    const location = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [jobList, setJobList] = useState([]);
    const [oldJobList, setOldJobList] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedJobsObjects, setSelectedJobsObjects] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [viewSelectedJobDetails, setViewSelectedJobDetails] = useState('')
    const [modalPopUpCancel, setModalPpopUpCancel] = useState(false);
    const [candidateModalPopUpCancel, setCandidateModalPopUpCancel] = useState(false);
    const [pageSize, setPageSize] = useState(PageSize);
    const { role } = storePersist.get('userData')
    const { total_completed } = useSelector((state) => state.employeeStepsBarReducer)

    useEffect(() => {
        void getJobListApi()
    }, []);

    const getJobListApi = async () => {
        dispatch(addLoader());
        // const apiResponse = await getAllApi(get_job_list_api_url)
        const companyDetailsStorage = storePersist.get('companyDetails')
        const apiReqBody = { companyId: companyDetailsStorage && companyDetailsStorage?._id }
        const apiResponse = await postApi(get_jobs_by_company_id, apiReqBody)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            setJobList(apiResponseData)
            setOldJobList(apiResponseData)
            // toast.success("Retrived all Job List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    return <>
        <div className="rounded rounded_20">
            <div className="pt-2">
                <div className="col-xl-4 col-sm-6 ps-1">
                    <h6 className="font_color_imp font_size_16 fw-bolded">Good Morning ! Welcome Back, Rahul Dev</h6>
                </div>
            </div>
        </div>

        <div className="row d-flex justify-content-center align-items-start gx-3">
            {/* Left side card */}
            <div className="col-md-8 width_col_69">
                {/* Card-0 */}
                <div className="my-3 p-3 pb-3 mt-2 bg-body shadow-sm rounded_20">
                    {/* d-flex justify-content-between align-items-center  */}
                    <div className="">
                        <div className="d-flex justify-content-between align-items-center border-bottom ">
                            <h6 className="pb-2 mb-2 font_color_imp fw-semibold">Job Lists / apply for the Job</h6>
                        </div>
                        <div className="pb-2 mb-2 pt-1 font_color_imp font_size_13 ms-3">
                            click to apply for the relevant jobs
                        </div>
                        <div className='col-md-12 d-flex justify-content-evenly align-items-center'>
                            <div>
                                <img className="w-90 h-100" alt="Alt content" src={require('../../assets/images/communication_test_svg.svg').default} />
                                <div className='text-center my-1 me-3 pt-2'>
                                    <button
                                        type="button"
                                        className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                        onClick={() => {
                                            navigate('/employee-jobs-list');
                                        }}
                                    >
                                        {'Job list / apply for the job'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* card-1 */}
                <div className="my-3 p-3 pb-3 mt-2 bg-body shadow-sm rounded_20">
                    {/* d-flex justify-content-between align-items-center  */}
                    <div className="">
                        <div className="pb-1 mb-1 font_color_imp font_size_14 fw-bold">Take a coding test for analysis of your  coding skills and Communication skills for
                            better opportunities.
                        </div>
                        <div className="pb-2 mb-2 font_color_imp font_size_13">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </div>
                        <div className='col-md-12 d-flex justify-content-evenly align-items-center'>
                            <div>
                                {/* whitecircle rounded-circle */}
                                <img className="w-90 h-100" alt="Alt content" src={require('../../assets/images/coding_test_svg.svg').default} />
                                <div className='text-center my-1 me-4 pt-2'>
                                    <button
                                        type="button"
                                        className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                        onClick={() => { }}
                                    >
                                        {'Coding Test'}
                                    </button>
                                </div>
                            </div>
                            <div>
                                <img className="w-90 h-100" alt="Alt content" src={require('../../assets/images/communication_test_svg.svg').default} />
                                <div className='text-center my-1 me-3 pt-2'>
                                    <button
                                        type="button"
                                        className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                        onClick={() => { }}
                                    >
                                        {'Communication Test'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* card-2 */}
                {/* <div className="my-3 p-3 mt-2 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom ">
                        <h6 className="pb-2 mb-2 font_color_imp fw-semibold">Today’s Scheduled Interview</h6>
                    </div>
                    <div>
                        <div className="row bd-example p-2 font_size_14">
                            <div className="d-flex justify-content-start align-items-center pb-2 ms-5">
                                <img className="w-5 whitecircle rounded-circle" alt="Alt content" src={require('../../assets/images/user-profile-icon.svg').default} />
                                <h6 className="pt-2 ps-3 font_color_imp fw-semibold">TCS</h6>
                            </div>
                            <div className='border-bottom me-5 ms-5 w-90'></div>
                            <div className='pt-3 ms-5'>
                                <div>Select the dates for your availability for 1st Round of Interview, Confirm any one date!   </div>
                                <div className="d-flex justify-content-start align-items-center">
                                    <span><input type='radio' value={''} onChange={() => { }} /></span>
                                    <span className='ps-1 font_size_13'>04-Aug-2023, Wednesday 10.00 AM  to 11.00 AM </span>
                                </div>
                                <div className="d-flex justify-content-start align-items-center">
                                    <span> <input type='radio' value={''} onChange={() => { }} /></span>
                                    <span className='ps-1 font_size_13'>06-Aug-2023, Friday 10.00 AM to 11.00 AM</span>
                                </div>
                            </div>
                            <div className='me-5 ms-5 my-3'>
                                <div className='border-bottom w-90'></div>
                                <div className='my-2'>
                                    panel
                                </div>
                                <div className='border-bottom w-90'></div>
                            </div>
                            <div className='me-5 ms-5 my-1'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <button
                                        type="button"
                                        className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                        onClick={() => { }}
                                    >
                                        {'Confirm Interview'}
                                    </button>
                                    <span className='ps-2 font_color_ornage'>Note: Date cannot be changed once Confimed </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

            {/* right side cards */}
            <div className="col-md-4 width_col_30">
                {/* card-1 */}
                <div className="my-3 p-3 mt-2 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom">
                        <h6 className="pb-2 mb-0 font_color_imp fw-semibold">Your Profile Status</h6>
                    </div>
                    <div>
                        {/* height_scheduled_int_table */}
                        <div className="row bd-example height_scheduled_int_table_y_p_s">
                            <div className="">
                                <progress max="100" value={total_completed}></progress>
                                <div className='ps-2 font_color_ornage text-center'>{`${total_completed} completed`} </div>
                            </div>
                            <div className='text-center my-1 me-1 p-2'>
                                <button
                                    type="button"
                                    className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                    onClick={() => {
                                        navigate('/Employee-steps-bar');
                                    }}
                                >
                                    {'Update Profile'}
                                </button>
                            </div>
                            <div className='border-bottom mb-1'></div>
                        </div>
                    </div>
                    <div className='font_size_14 text-center'>{'Completing profile will make your chances better to get more interviews'}</div>
                </div>

                <div className="my-3 p-3 mt-3 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom ">
                        <h6 className="pb-2 mb-0 font_color_imp fw-semibold">Resources</h6>
                    </div>
                    <div>
                        <div className="row bd-example height_scheduled_int_table_resource font_size_14">
                            <div className='text-center my-1 me-1 p-2 mx-3'>
                                <div className="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img className="" alt="Alt content" src={require('../../assets/images/take_test.svg').default} />
                                    </span>
                                    <span className='mx-2'>Take a screening test</span>
                                </div>
                                <div className="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img className="" alt="Alt content" src={require('../../assets/images/question-answer-line-1 1.svg').default} />
                                    </span>
                                    <span className='mx-2'>Interview Q&As.</span>
                                </div>
                                <div className="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img className="" alt="Alt content" src={require('../../assets/images/lasttest.svg').default} />
                                    </span>
                                    <span className='mx-2'>Resume Templated & Tips</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}


export default adminLayout(EmployeeDashboardPage);