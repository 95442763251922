import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { Radio, RadioGroup } from 'react-radio-group'
import CheckboxGroup from 'react-checkbox-group'
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useNavigate, useLocation } from 'react-router-dom';
import {
    useForm, Controller, useController,
} from "react-hook-form";
import Select, { components } from "react-select";
import { convertToRaw, EditorState } from "draft-js";
import { downloadPDF, downloadPDFNew } from '../../services/axios-common'
import { languageArr } from './softWareLanguages';
import { unversities_colleges } from './ListOfCollegesAndUniversites';
import { highestDegreeArrList } from './highestDegree';
import { masterListFieldOfStudies } from './fieldOfStudiesList'
import { isEmpty } from '../../Utils/utils'
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../hoc/adminLayout";
import Pagination from '../../common/pagination/Pagination';
import RangeDatePicker from '../../components/RangeDatePicker';

import DatePicker, { DateObject } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";

import data from '../../common/pagination/examples/data/mock-data.json';
import { returnArryOfObjects, randStr } from '../../Utils/utils'
import { typesOfEmployeeContractList } from '../../Utils/masterData/dropDownJson'
import { emptyJobList, PageSize } from '../../Utils/masterData/staticContent'
import { getAllApi, deleteApiWithBody, postApi } from '../../services/apiCommonService'
import { getAllEmployeesList_api_url, employee_update_profile_api_url, employee_download_resume_api_url, createEmployeeProfile_api_url, uploadEmployeeResume_api_url } from '../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import { addUpdateEmployeeStages } from "../../redux/actions/employeeStepsBar";
import { addSelectedJobDetails, deleteSelectedJobDetails } from "../../redux/actions/jobDetails";
import CustomSingleSelect from '../../components/CustomSelectComponent';
import storePersist from '../../Utils/storePersist';
import Chipcomponent from '../../components/ChipComponent';

const { ValueContainer, Placeholder } = components;

const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
};

const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
    if (user) {
        return `${user[0]?.toUpperCase()}${user[user?.length - 1]?.toUpperCase()}`
    }
}

// const setValue = (functionFor) => {
//     return (e) => {
//         const value = parseInt(e.target.value);
//         functionFor(value);
//     }
// };

const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
}

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const VerticalStepBar = (props) => {
    const location = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [jobList, setJobList] = useState([]);
    const [oldJobList, setOldJobList] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedJobsObjects, setSelectedJobsObjects] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [viewSelectedJobDetails, setViewSelectedJobDetails] = useState('')
    const [modalPopUpCancel, setModalPpopUpCancel] = useState(false);
    const [candidateModalPopUpCancel, setCandidateModalPopUpCancel] = useState(false);

    const [typeOfLanguageValue, settypeOfLanguageValue] = useState(null);
    const [typeOfschool, settypeOfschool] = useState(null);
    const [highestDegreeValue, setHighestDegreeValue] = useState(null);
    const [fieldOfStudyArrState, setFieldOfStudyArrState] = useState([])
    const [fieldOfstudyValue, setfieldOfstudyValue] = useState(null);

    const [isSelected, setIsSelected] = useState({});

    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    //employeeStepsBarReducer ADD_UPDATE_EMPLOYEE
    const { step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed, resumeFileName, resume_file_location, remotefile_location, remotefileName } = useSelector((state) => state.employeeStepsBarReducer)
    const [stepsCompleted, setStepsCompleted] = useState({ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed });

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);

    const [projectDesEditorSatte, setProjectDesEditorSatte] = useState(EditorState.createEmpty());
    const [convertedContentProjDes, setConvertedContentProjDes] = useState(null);

    const [pageSize, setPageSize] = useState(PageSize);
    const [languageArrState, setlanguageArrState] = useState([])
    const [collegesArrState, setCollegesArrState] = useState([])
    const [highestEduArrState, setHighestEduArrState] = useState([])

    const { role, user, employeeDataAll } = storePersist.get('userData')
    const { _id } = employeeDataAll && employeeDataAll || {}

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [dateRangeProject, setDateRangeProject] = useState([null, null]);
    const [startDateProject, endDateProject] = dateRangeProject;

    const [fileUploadedLength, setFileUploadedLength] = useState(true)
    const [selectedCompanyDetailsObject, setSelectedCompanyDetailsObject] = useState([]);
    const [selectedCompanyDetailsRowList, setSelectedCompanyDetailsRowList] = useState([]);
    const [uploadedResumes, setUploadedResumes] = useState([]);
    const [technicalExpertizeSkills, setTechnicalExpertizeSkills] = useState([]);
    const [professionalExperience, setProfessionalExperience] = useState([]);
    const [certificationsList, setCertificationsList] = useState([]);
    const [projectExpList, setprojectExpList] = useState([]);
    const [educationQualificationList, setEducationQualificationList] = useState([]);

    const [formData, setFormData] = useState(false);

    const [range, setRange] = React.useState(10);
    const [saturation, setSaturation] = React.useState(50);
    const [lightness, setLightness] = React.useState(50);
    const [theme, setTheme] = React.useState('Light');
    const [isEdit, setIsEdit] = useState(false);
    const [currentOldStep, setCurrentOldStep] = useState(1);

    const saturationRange = getRange(saturation, range);
    const lightnessRange = getRange(lightness, range);

    const userName = `${'Pandu'} ${'Pandu'}`;
    const color = generateColorHsl(userName, saturationRange, lightnessRange);
    const initials = getInitials('Pandu');

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        resetField,
        setValue,
        reset,
        control,
        getValues
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false,
        defaultValues: {
            jobType: []
        }
    });

    useEffect(() => {
        const arr = []
        languageArr?.map((item) => {
            arr.push({ label: item, value: item })
        })
        setlanguageArrState(arr)

        const college_arr = []
        unversities_colleges?.map((item) => {
            college_arr.push({ label: item, value: item })
        })
        setCollegesArrState(college_arr)

        const highestDegreeArrListArr = []
        highestDegreeArrList?.map((item) => {
            highestDegreeArrListArr.push({ label: item, value: item })
        })
        setHighestEduArrState(highestDegreeArrListArr)

        const masterListFieldOfStudiesArr = []
        masterListFieldOfStudies?.map((item) => {
            masterListFieldOfStudiesArr.push({ label: item, value: item })
        })
        setFieldOfStudyArrState(masterListFieldOfStudiesArr)

        void getEmployeeDetailsAndCv()
    }, []);

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    const getEmployeeDetailsAndCv = async () => {
        dispatch(addLoader());
        // const apiResponse = await getAllApi(get_job_list_api_url)
        const apiReqBody = { employeeId: _id }
        const apiResponse = await postApi(getAllEmployeesList_api_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            const apiResponseData = apiResponse?.data?.Data[apiResponse?.data?.Data?.length - 1] || {}
            reset({ ...apiResponseData })
            setTechnicalExpertizeSkills(apiResponseData?.technicalExpertizeSkillsList || [])
            setProfessionalExperience(apiResponseData?.professionalExperienceList || [])
            setCertificationsList(apiResponseData?.certificationsList || [])
            setprojectExpList(apiResponseData?.projectExpList || [])
            setEducationQualificationList(apiResponseData?.educationQualificationList || [])
            setConvertedContent(apiResponseData?.profileSummary)
            if (apiResponse?.data?.Data?.length > 0) {
                nextStep({
                    step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'is-done', step_8: 'current', total_completed: total_completed >= 100 ? total_completed : 100,
                    resumeFileName: apiResponseData?.resumeFileName, resume_file_location: apiResponseData?.resume_file_location,
                    remotefile_location: apiResponseData?.remotefile_location, remotefileName: apiResponseData?.remotefileName
                })
                setCurrentOldStep(8)
                setIsEdit(true)
            } else {
                nextStep({ step_1: 'current', step_2: '', step_3: '', step_4: '', step_5: '', step_6: '', step_7: '', step_8: '', total_completed: 0, resumeFileName: '', resume_file_location: '' })
                setCurrentOldStep(1)
            }
            setJobList(apiResponseData)
            setOldJobList(apiResponseData)
            // toast.success("Retrived all Job List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    useEffect(() => {
        if (dateRange) {
            setValue('dateRange', new Date(startDate * 1000) + ' ' + new Date(endDate * 1000))
        }
    }, [dateRange])

    useEffect(() => {
        setUploadedResumes(getValues('file')?.length > 0 ? getValues('file')[0]?.name : '')
    }, [getValues()])

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['languageType', 'fieldOfstudy', 'highestDegree', 'school'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    useEffect(() => {
        if (step_1 === 'current') {
            setSubTitle('Sharing your full resume or online profile will help our Talent partners match you with jobs more quickly')
            setTitle('Summary')
        } else if (step_2 === 'current') {
            setSubTitle('Adding 3+  technologies you have  better chances to find better matches')
            setTitle('Technical Expertise & Skills')
        } else if (step_3 === 'current') {
            setSubTitle('Help us to understand the companies you worked for ..')
            setTitle('Professional Experience')
        } else if (step_4 === 'current') {
            setSubTitle('Increase your chances of hiring')
            setTitle('Certification')
        } else if (step_5 === 'current') {
            setSubTitle('Increase your chances of hiring')
            setTitle('Project Experience')
        } else if (step_6 === 'current') {
            setSubTitle('Add your education details')
            setTitle('Education Qualifications Upload resume')
        } else if (step_7 === 'current') {
            setSubTitle('Sharing your full resume or online profile will help our Talent partners match you with jobs more quickly')
            setTitle('Upload resume')
        } else if (step_8 === 'current') {
            setSubTitle('Sharing your full resume or online profile will help our Talent partners match you with jobs more quickly ')
            setTitle('Profile Summary')
        }
    }, [step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, stepsCompleted])

    const nextStep = (obj, stepVal) => {
        dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
        setStepsCompleted({ ...stepsCompleted, ...obj })
        // setCurrentOldStep(stepVal)
    }

    const validate = (value) => {
        const { file } = getValues()
        if (!file?.length === 0) return true;
        if (file?.length > 0) {
            const acceptedFormats = ['pdf'];
            const fileExtension = value[0]?.name.split('.').pop().toLowerCase();
            if (!acceptedFormats.includes(fileExtension)) {
                return 'Invalid file format. Only PDF files are allowed.';
            }
            return true;
        }
    };

    const fileUploadedOnDown = (e) => {
        if (getValues('file')?.length > 0) {
            setUploadedResumes(getValues('file')?.length > 0 ? getValues('file')[0]?.name : '')
            setFileUploadedLength(false)
        } else {
            setFileUploadedLength(true)
        }
    }

    const downloadPdf = (downloadPath, name) => {
        console.log("=====downloadPath, name=====", downloadPath, name)
        toast.success("Successfully downloaded profile !!")
        downloadPDF(downloadPath, name)
    }

    const downloadPDFNewCall = (downloadPath, name) => {
        console.log("=====downloadPath, name=====", downloadPath, name)
        toast.success("Successfully downloaded profile !!")
        downloadPDFNew(downloadPath, name)
    }

    const errorHandlerDoc = () => {
        if (errors.documentName) {
            toast.error("document name can not be emepty")
        } else if (errors.file) {
            toast.error("please select file")
        } else if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const resumeUpload = async (data, candidateDetails) => {
        const baseUrl = 'https://free.remote72.in'
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("employeeId", _id)
        try {
            const apiResponse = await fetch(`${baseUrl}/${uploadEmployeeResume_api_url}`, {
                method: "POST",
                body: formData,
            }).then((res) => res.json());
            if (apiResponse?.message) {
                if (apiResponse?.Data) {
                    toast.success(apiResponse?.message)
                    nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'is-done', step_8: 'current', total_completed: total_completed >= 100 ? total_completed : 100, ...apiResponse?.Data?.$set })
                    setCurrentOldStep(8)
                    dispatch(deleteLoader());
                } else {
                    toast.success(apiResponse?.response?.data.message || "resume uploaded successfully !!")
                }
            } else {
                toast.error(apiResponse?.response?.data?.message + '!!')
                dispatch(deleteLoader());
            }
        } catch (err) {
            if (err === 'TypeError: Failed to fetch') {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            } else {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            }
            dispatch(deleteLoader());
        }
    }

    const onUpdate = async (data) => {
        const {
            jobType,
            jobSearchStatus,
            technicalExpertizeSkillsList,
            certificationsList,
            projectExpList,
            professionalExperienceList,
            educationQualificationList
        } = getValues()

        setFormData(getValues());
        const apiReqBody = {
            employeeId: _id,
            profileSummary: convertedContent,
            jobType: jobType,
            jobSearchStatus: jobSearchStatus,
            technicalExpertizeSkillsList: technicalExpertizeSkillsList,
            certificationsList: certificationsList,
            projectExpList: projectExpList,
            professionalExperienceList: professionalExperienceList,
            educationQualificationList: educationQualificationList,
            convertedContent: convertedContent,
        }
        const apiResponse = await postApi(employee_update_profile_api_url, apiReqBody)
        if (apiResponse?.data?.message === 'phone number already exist') {
            toast.error(apiResponse?.data?.message)
        } else {
            if (apiResponse?.data?.message === "Employee details has added successfully") {
                if (getValues('file').length > 0) {
                    resumeUpload(getValues(), _id)
                }
                toast.success("Successfully updated your profile data !!")
                nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'is-done', step_8: 'current', total_completed: total_completed >= 100 ? total_completed : 100 })
                setCurrentOldStep(8)
            } else {
                toast.error(apiResponse?.response?.data.message)
            }
        }
    };

    const onSubmit = async (data) => {
        const {
            jobType,
            jobSearchStatus,
            technicalExpertizeSkillsList,
            certificationsList,
            projectExpList,
            professionalExperienceList,
            educationQualificationList
        } = getValues()

        setFormData(getValues());
        const apiReqBody = {
            employeeId: _id,
            jobType: jobType,
            jobSearchStatus: jobSearchStatus,
            technicalExpertizeSkillsList: technicalExpertizeSkillsList,
            certificationsList: certificationsList,
            projectExpList: projectExpList,
            professionalExperienceList: professionalExperienceList,
            educationQualificationList: educationQualificationList,
            convertedContent: convertedContent,
            profileSummary: convertedContent,
        }
        const apiResponse = await postApi(createEmployeeProfile_api_url, apiReqBody)
        if (apiResponse?.data?.message === 'phone number already exist') {
            toast.error(apiResponse?.data?.message)
        } else {
            if (apiResponse?.data?.message === 'Employee details already exist') {
                toast.error(apiResponse?.data?.message)
                return
            }
            if (apiResponse?.data?.Data) {
                if (getValues('file').length > 0) {
                    resumeUpload(getValues(), _id)
                }
                toast.success("Successfully updated your profile !!")
                nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'is-done', step_8: 'current', total_completed: total_completed >= 100 ? total_completed : 100 })
                setCurrentOldStep(8)
            } else {
                toast.error(apiResponse?.response?.data.message)
            }
        }
    };

    const downLoadresume = async () => {
        const apiReqBody = { employeeId: _id }
        const apiResponse = await postApi(employee_download_resume_api_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            if (getValues('file').length > 0) {
                resumeUpload(getValues(), _id)
            }
            toast.success("Successfully downloaded resume !!")
        } else {
            toast.error(apiResponse?.response?.data.message)
        }
    }

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    useEffect(() => {
        let html = convertToHTML(projectDesEditorSatte.getCurrentContent());
        setConvertedContentProjDes(html);
    }, [projectDesEditorSatte]);

    const step_2_fun = () => {
        const { languageType, competency, numberOfYears } = getValues()
        if (languageType && competency && numberOfYears) {
            const ID = technicalExpertizeSkills?.length + 1
            setTechnicalExpertizeSkills([...technicalExpertizeSkills, { languageType, competency, numberOfYears, ID }])
            dispatch(addLoader())
            setTimeout(() => {
                setValue("languageType", '')
                setValue("competency", '')
                setValue("numberOfYears", '')
                resetField("languageType")
                settypeOfLanguageValue(null)
                setIsSelected({ ...isSelected, 'languageType': false })
                dispatch(deleteLoader())
            }, 300);
        } else {
            toast.error("Please fill all the manditory fields")
        }
    }

    const step_3_fun = () => {
        const { company, designation, duration } = getValues()
        if (company && designation && duration) {
            const ID = professionalExperience?.length + 1
            setProfessionalExperience([...professionalExperience, { company, designation, startDate, endDate, ID }])
            dispatch(addLoader())
            setTimeout(() => {
                setValue("company", '')
                setValue("designation", '')
                setValue("duration", '')
                resetField("duration")
                setDateRange([null, null])
                dispatch(deleteLoader())
            }, 300);
        } else {
            toast.error("Please fill all the manditory fields")
        }
    }

    const step_4_fun = () => {
        const { certificateName, completedDate, Platform, urlForCertificationon } = getValues()
        if (certificateName && completedDate && Platform) {
            const ID = certificationsList?.length + 1
            setCertificationsList([...certificationsList, { certificateName, completedDate, Platform, urlForCertificationon, ID }])
            dispatch(addLoader())
            setTimeout(() => {
                setValue("certificateName", '')
                setValue("completedDate", '')
                setValue("Platform", '')
                setValue("urlForCertificationon", '')
                resetField("completedDate")
                dispatch(deleteLoader())
            }, 300);
        } else {
            toast.error("Please fill all the manditory fields")
        }
    }

    const step_5_fun = () => {
        const { projectName, experienceDuration, toolsTechnology } = getValues()
        if (projectName && experienceDuration && toolsTechnology) {
            const ID = projectExpList?.length + 1
            setprojectExpList([...projectExpList, { projectName, startDateProject, endDateProject, toolsTechnology, convertedContentProjDes, ID }])
            dispatch(addLoader())
            setTimeout(() => {
                setValue("projectName", '')
                setValue("experienceDuration", '')
                setValue("toolsTechnology", '')
                resetField("experienceDuration")
                dispatch(deleteLoader())
                setProjectDesEditorSatte(EditorState.createEmpty())
                setConvertedContentProjDes(null)
            }, 300);
        } else {
            toast.error("Please fill all the manditory fields")
        }
    }

    const step_6_fun = () => {
        const { school, fieldOfstudy, highestDegree, otheerHighestDegree, fromDateCollege, toDateCollege } = getValues()
        if (school && fieldOfstudy && highestDegree && fromDateCollege && toDateCollege) {
            const ID = educationQualificationList?.length + 1
            setEducationQualificationList([...educationQualificationList, { school, fieldOfstudy, highestDegree, otheerHighestDegree, fromDateCollege, toDateCollege, ID }])
            dispatch(addLoader())
            setTimeout(() => {
                setValue("fieldOfstudy", '')
                setValue("highestDegree", '')
                setValue("school", '')
                setValue("otheerHighestDegree", '')
                setValue("fromDateCollege", '')
                setValue("toDateCollege", '')

                resetField("fieldOfstudy")
                resetField("highestDegree")
                resetField("school")

                settypeOfschool(null)
                setfieldOfstudyValue(null)
                setHighestDegreeValue(null)

                setIsSelected({ ...isSelected, 'typeOfschool': false, 'fieldOfstudyValue': false, 'highestDegreeValue': false })
                dispatch(deleteLoader())
            }, 300);
        } else {
            toast.error("Please fill all the manditory fields")
        }
    }

    const deleteSkillsFun = async (event) => {
        dispatch(addLoader());
        swal({
            title: "Delete !!",
            text: "Are you sure you want to delete?",
            // icon: "info",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Skill deleted successfully!',
                    // icon: 'success'
                }).then(async function () {
                    const filteredData = technicalExpertizeSkills?.filter(({ ID }) => ID !== event?.ID)
                    setTechnicalExpertizeSkills(filteredData)
                    setTimeout(() => {
                        dispatch(deleteLoader());
                    }, 500);
                    // 
                    // const apiReqBody = {
                    //     offerId: selectedUserDetails?.offer_id,
                    //     interview_status: "accepted",
                    //     onboarding_status: 'inprogress'
                    // }
                    // const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
                    // if (apiResponse?.data) {
                    //     toast.success('Accepted interview !!')
                    //     // await getAllCandidatesList()
                    //     await onClickRedirectToJobOfferedPage()
                    // } else {
                    //     toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                    //     dispatch(deleteLoader());
                    // }
                });
            } else {
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 500);
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const deleteWrkExperienceFun = async (event) => {
        dispatch(addLoader());
        swal({
            title: "Delete !!",
            text: "Are you sure you want to delete?",
            // icon: "info",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Experience deleted successfully!',
                    // icon: 'success'
                }).then(async function () {
                    const filteredData = professionalExperience?.filter(({ ID }) => ID !== event?.ID)
                    setProfessionalExperience(filteredData)
                    setTimeout(() => {
                        dispatch(deleteLoader());
                    }, 500);
                });
            } else {
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 500);
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const deleteCertificationsFun = async (event) => {
        dispatch(addLoader());
        swal({
            title: "Delete !!",
            text: "Are you sure you want to delete?",
            // icon: "info",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Certification deleted successfully!',
                    // icon: 'success'
                }).then(async function () {
                    const filteredData = certificationsList?.filter(({ ID }) => ID !== event?.ID)
                    setCertificationsList(filteredData)
                    setTimeout(() => {
                        dispatch(deleteLoader());
                    }, 500);
                });
            } else {
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 500);
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const deleteProjectExpFun = async (event) => {
        dispatch(addLoader());
        swal({
            title: "Delete !!",
            text: "Are you sure you want to delete?",
            // icon: "info",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Project experience deleted successfully!',
                    // icon: 'success'
                }).then(async function () {
                    const filteredData = projectExpList?.filter(({ ID }) => ID !== event?.ID)
                    setprojectExpList(filteredData)
                    setTimeout(() => {
                        dispatch(deleteLoader());
                    }, 500);
                });
            } else {
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 500);
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const deleteEducationsFun = (event) => {
        dispatch(addLoader());
        swal({
            title: "Delete !!",
            text: "Are you sure you want to delete?",
            // icon: "info",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Project experience deleted successfully!',
                    // icon: 'success'
                }).then(async function () {
                    const filteredData = educationQualificationList?.filter(({ ID }) => ID !== event?.ID)
                    setEducationQualificationList(filteredData)
                    setTimeout(() => {
                        dispatch(deleteLoader());
                    }, 500);
                });
            } else {
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 500);
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const certificationSuccessNext = () => {
        if (certificationsList?.length > 0) {
            nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'current', total_completed: total_completed >= 40 ? total_completed : 40 })
            setCurrentOldStep(5)
            setValue("certificationsList", certificationsList)
            toast.success('Successfully completed certification step')
        } else {
            toast.error('Please add atleast one certification details')
        }
    }

    const workExperienceSuccessNext = () => {
        if (professionalExperience?.length > 0) {
            nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'current', total_completed: total_completed >= 30 ? total_completed : 30 })
            setCurrentOldStep(4)
            setValue("professionalExperienceList", professionalExperience)
            toast.success('Successfully completed Work Experience step')
        } else {
            toast.error('Please add atleast one work experience details')
        }
    }

    const technicalExpertizeSuccessNext = () => {
        if (technicalExpertizeSkills?.length > 0) {
            nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'current', total_completed: total_completed >= 20 ? total_completed : 20 })
            setCurrentOldStep(3)
            setValue("technicalExpertizeSkillsList", technicalExpertizeSkills)
            toast.success('Successfully completed Technical expertize step')
        } else {
            toast.error('Please add atleast one skill')
        }
    }

    const projectExpSuccessNext = () => {
        if (projectExpList?.length > 0) {
            nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'current', total_completed: total_completed >= 50 ? total_completed : 50 })
            setCurrentOldStep(6)
            setValue("projectExpList", projectExpList)
            toast.success('Successfully completed project experience step')
        } else {
            toast.error('Please add atleast one project experience')
        }
    }

    const educationStepSuccessNext = () => {
        if (educationQualificationList?.length > 0) {
            nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'current', total_completed: total_completed >= 60 ? total_completed : 60 })
            setCurrentOldStep(7)
            setValue("educationQualificationList", educationQualificationList)
            toast.success('Successfully completed education details step')
        } else {
            toast.error('Please add atleast one education qualification')
        }
    }

    const previousStepsUpdate = (prevStep) => {
        if (currentOldStep === 1) {
            const obj = { step_1: 'current', step_2: '', step_3: '', step_4: '', step_5: '', step_6: '', step_7: '', step_8: '', total_completed: total_completed }
            dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
            setStepsCompleted({ ...stepsCompleted, ...obj })
            return
        } else if (currentOldStep > 1 && prevStep === 'step_1') {
            const obj = { step_1: 'current', step_2: 'is-done', step_3: step_3, step_4: step_4, step_5: step_5, step_6: step_6, step_7: step_7, step_8: step_8, total_completed: total_completed }
            dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
            setStepsCompleted({ ...stepsCompleted, ...obj })
            return
        } else if (currentOldStep > 2 && prevStep === 'step_2') {
            const obj = { step_1: 'is-done', step_2: 'current', step_3: 'is-done', step_4: step_4, step_5: step_5, step_6: step_6, step_7: step_7, step_8: step_8, total_completed: total_completed }
            dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
            setStepsCompleted({ ...stepsCompleted, ...obj })
            return
        } else if (currentOldStep > 3 && prevStep === 'step_3') {
            const obj = { step_1: 'is-done', step_2: 'is-done', step_3: 'current', step_4: 'is-done', step_5: step_5, step_6: step_6, step_7: step_7, step_8: step_8, total_completed: total_completed }
            dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
            setStepsCompleted({ ...stepsCompleted, ...obj })
            return
        } else if (currentOldStep > 4 && prevStep === 'step_4') {
            const obj = { step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'current', step_5: 'is-done', step_6: step_6, step_7: step_7, step_8: step_8, total_completed: total_completed }
            dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
            setStepsCompleted({ ...stepsCompleted, ...obj })
            return
        } else if (currentOldStep > 5 && prevStep === 'step_5') {
            const obj = { step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'current', step_6: 'is-done', step_7: step_7, step_8: step_8, total_completed: total_completed }
            dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
            setStepsCompleted({ ...stepsCompleted, ...obj })
            return
        } else if (currentOldStep > 6 && prevStep === 'step_6') {
            const obj = { step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'current', step_7: 'is-done', step_8: 'is-done', total_completed: total_completed }
            dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
            setStepsCompleted({ ...stepsCompleted, ...obj })
            return
        } else if (currentOldStep > 7 && prevStep === 'step_7') {
            const obj = { step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'current', step_8: 'is-done', total_completed: total_completed }
            dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
            setStepsCompleted({ ...stepsCompleted, ...obj })
            return
        } else if (total_completed === 100 && prevStep === 'step_7') {
            const obj = { step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'current', step_8: 'is-done', total_completed: total_completed }
            dispatch(addUpdateEmployeeStages({ ...{ step_1, step_2, step_3, step_4, step_5, step_6, step_7, step_8, total_completed }, ...obj }));
            setStepsCompleted({ ...stepsCompleted, ...obj })
            return
        }
    }

    const Checkboxes = ({ options, control, name }) => {
        const { field } = useController({
            control,
            name
        });
        //const [value, setValue] = React.useState(field.value || []);
        console.log(options);
        return (
            <>
                {options.map((option, index) => (
                    <div className='col-md-2 d-flex justify-content-start' >
                        <input
                            style={{
                                margin: '0px 9px 0px 0px',
                                width: 18
                            }}
                            onChange={(e) => {
                                const valueCopy = [...field.value];
                                valueCopy[index] = e.target.checked ? e.target.value : null;
                                field.onChange(valueCopy?.filter(n => n));
                            }}
                            key={option}
                            type="checkbox"
                            checked={field?.value?.includes(option)}
                            value={option}
                        />
                        <span className='fw-medium'>{option}</span>
                    </div>
                ))}
            </>
        );
    };
    console.log("=====getValues('file')===", getValues(), editorState, convertedContent, convertedContentProjDes)
    return <>
        <div className="row d-flex justify-content-center align-items-start gx-3 margintop">
            {/* Left side card */}
            <div className="col-md-8 width_col_69">
                {/* card-1 */}
                <div className="my-3 mb-1 p-3 pt-0 ps-1 mt-1 pb-0 rounded rounded_20">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="pb-2 mb-0 font_color_imp fw-semibold">{title}</h6>
                    </div>
                    <div>
                        <div className="row bd-example">
                            <div className="col-xl-12 col-sm-6 ps-3">
                                <span className="subtitle">{subTitle}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* card-2 */}
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}

                <div className={`my-1 ${stepsCompleted?.step_8 !== 'current' && 'p-1'} mt-1 ms-2 me-2 rounded_20 bg-body shadow-sm 
                    ${stepsCompleted?.step_5 === 'current' ? 'height_employee_step_1_page_c' : 'height_employee_step_1_page'}`}
                >
                    {stepsCompleted?.step_1 === 'current' && <div className='my-3 mx-4 summaryDescription'>
                        <div>
                            <h6 className="pb-2 mb-0 font_color_imp fw-semibold requiredTrue">{'Job types'}</h6>
                            <div className="row bd-example font_size_14 p-3">
                                <Checkboxes
                                    options={["Remote", "Hybrid"]}
                                    control={control}
                                    name="jobType"
                                />
                                {/* <Controller
                                    name="jobType"
                                    defaultValue={getValues('jobType')}
                                    control={control}
                                    render={({ field }) => (
                                        <CheckboxGroup
                                            {...field}
                                            row
                                            onChange={(event, value) => field.onChange(value)}
                                            value={field.value}
                                            selectedValue={field.value}
                                            className="d-flex justify-content-start align-items-center gap-5"
                                        >
                                            <span className="radio-button-background d-flex gap-1">
                                                <input type="checkbox" value="Remote" className="radio-button" />c
                                            </span>
                                            <span className="radio-button-background d-flex gap-1">
                                                <input type="checkbox" value="Hybrid" className="radio-button" />Hybrid
                                            </span>
                                            <span className="radio-button-background d-flex gap-1">
                                                <input type="checkbox" value="Shorttime" className="radio-button" />Shorttime
                                            </span>
                                            <span className="radio-button-background d-flex gap-2">
                                                <input type="checkbox" value="Fulltime" className="radio-button" />Fulltime
                                            </span>
                                        </CheckboxGroup>
                                    )}
                                /> */}
                            </div>
                        </div>
                        <div>
                            <h6 className="pb-2 pt-2 mb-0 font_color_imp fw-semibold requiredTrue">{'Job Search Status'}</h6>
                            <div className="row bd-example font_size_14 p-3">
                                <Controller
                                    name="jobSearchStatus"
                                    defaultValue={getValues('jobSearchStatus')}
                                    control={control}
                                    render={({ field }) => (<>
                                        <RadioGroup
                                            {...field}
                                            row
                                            onChange={(event, value) => field.onChange(value)}
                                            value={field.value}
                                            selectedValue={field.value}
                                            className="d-flex justify-content-start align-items-center gap-10"
                                        >
                                            <span className="radio-button-background d-flex gap-2 font_size_16">
                                                <Radio value="Activelylooking" className="radio-button font_color_imp  jobSearch " />ActivelyApplying
                                            </span>
                                            <span className="radio-button-background d-flex gap-2 pe-8 font_size_16" >
                                                <Radio value="Casuallylooking" className="radio-button font_color_imp  jobSearch " />CasuallyLooking
                                            </span>
                                        </RadioGroup>
                                        <div className='col-md-10 d-flex justify-content-between align-items-center gap-2' style={{ fontFamily: 'open-sans-regular', marginLeft: 20 }}>
                                            <div className="d-flex gap-2 font_size_14 ">Looking actively and ready for interview</div>
                                            <div className="d-flex gap-2 pe-7 font_size_14">Open for new opportunies</div>
                                        </div>
                                    </>
                                    )}
                                />
                            </div>
                        </div>
                        <div >
                            <Editor
                                defaultEditorState={convertedContent}
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class min_h_150"
                                toolbarClassName="toolbar-class"
                            />
                        </div>
                        <div className='text-start ps-4 ms-2 pb-2 mt-2 d-flex justify-content-end align-items-center gap-2'>
                            <button
                                type="button"
                                className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                onClick={() => {
                                    nextStep({ step_1: 'is-done', step_2: 'current', total_completed: total_completed >= 10 ? total_completed : 10 })
                                    setCurrentOldStep(2)
                                    toast.info("You skipped summary step!")
                                }}
                            >
                                {'Skip this step'}
                            </button>
                            <button
                                type="button"
                                className={`btn btn-primary font_size_12 pt-1 pb-1 fw-medium`}
                                onClick={() => {
                                    const { jobType, jobSearchStatus } = getValues()
                                    if (jobType && jobSearchStatus) {
                                        nextStep({ step_1: 'is-done', step_2: 'current', total_completed: total_completed >= 10 ? total_completed : 10 })
                                        setCurrentOldStep(2)
                                        toast.success("Completed summary step!")
                                    } else {
                                        toast.error("Please fill all mandatory!")
                                    }
                                }}
                            >
                                {'Next'}
                            </button>
                        </div>
                    </div>
                    }
                    {stepsCompleted?.step_2 === 'current' &&
                        <div className='my-3 mx-4 techHeader pt-0'>
                            <div className='techsubHead'>
                                <h6 className="pb-2 mb-0 font_color_imp fw-semibold page2Header">{'Technologies you  are expert on ...Add  below'}</h6>
                                <div className="row bd-example font_size_14 p-3 border-bottom">
                                    <div className='row text-start pb-2 mt-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-md-4'>
                                            <label className="requiredTrue mb-3">{'Technologies'}</label>
                                            <div className="form-outline">
                                                <Controller
                                                    name="languageType"
                                                    control={control}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            // isClearable
                                                            options={languageArrState}
                                                            value={getValues('languageType') ? languageArrState?.find((c) => c.value === value) : null}
                                                            onChange={(val) => onChange(val?.value)}
                                                            defaultValue={getValues('languageType') ? languageArrState?.find((c) => c.value === typeOfLanguageValue) : null}
                                                            isSearchable={true}
                                                            placeholder="Add Technologies"
                                                            className="basic-single my-react-select"
                                                            classNamePrefix="select"
                                                            components={{
                                                                ValueContainer: CustomValueContainer
                                                            }}
                                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'languageType': true }) }}
                                                            styles={{
                                                                control: (styles) => ({
                                                                    ...styles,
                                                                    ...errorBorderForSelectBox(errors.languageType && 'languageType'),
                                                                }),
                                                                container: (provided, state) => ({
                                                                    ...provided,
                                                                    marginTop: 5,
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    overflow: "visible"
                                                                }),
                                                                placeholder: (provided, state) => ({
                                                                    ...provided,
                                                                    position: "absolute",
                                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                                    background: "white",
                                                                    transition: "top 0.1s, font-size 0.1s",
                                                                    fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                                    fontWeight: 100,
                                                                    padding: '0.3px 8.5px',
                                                                    borderRadius: '8px',
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    border: isSelected['languageType'] ? '1px solid #cccccc' : ''
                                                                }),
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                                    ...styles,
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    //   cursor: isDisabled ? 'not-allowed' : 'default',
                                                                })
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "Field is required" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="techExpert mb-3">{'Competency'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.competency && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="competency"
                                                    placeholder=""
                                                    {...register("competency", {
                                                        required: "Field is required",
                                                        minLength: {
                                                            value: 2,
                                                            message: "Min length is 2"
                                                        },
                                                    })}
                                                    className="did-floating-input exp"
                                                />
                                                <label className="did-floating-label expinner">{'Add tools'}</label>
                                                {/* {errors.firstName && <p className="error">{errors.firstName.message}</p>} */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <label className="techExpert mb-3">{'Experience'}</label>
                                            <div className={`marginbottom_int did-floating-label-content mb-0 ${errors.numberOfYears && 'did-error-input'}`}>
                                                <input
                                                    type="number"
                                                    name="numberOfYears"
                                                    placeholder=""
                                                    {...register("numberOfYears", {
                                                        required: false,
                                                        // minLength: {
                                                        //     value: 2,
                                                        //     message: "Min length is 2"
                                                        // },
                                                        // pattern: {
                                                        //     value: /^[a-zA-Z0-9._-\s]+$/,
                                                        //     message: "Special characters are not allowed"
                                                        // }
                                                    })}
                                                    className="did-floating-input exp"
                                                />
                                                <label className="did-floating-label expinner1">{'Number Of Years'}</label>
                                                {/* {errors.lastName && <p className="error">{errors.lastName.message}</p>} */}
                                            </div>
                                        </div>
                                        <div className='col-md-1'>
                                            <label className="mb-3">{''}</label>
                                            <div className={`did-floating-label-content mt-3 mb-0 border p-2 text-center pointer_event hover_orange_bg`} onClick={() => { step_2_fun() }}>
                                                <i class="fa fa-plus" style={{ fontSize: "14px" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex text-muted height_step_2_employees_v_bar">
                                        <PerfectScrollbar className="sidebar-items w-100 pt-2 pb-3">
                                            {technicalExpertizeSkills?.map((item) =>
                                                <div className='border row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                    <div className='col-md-5 border-end pt-1 pb-1'>
                                                        {item?.languageType}
                                                    </div>
                                                    <div className='col-md-4 border-end pt-1 pb-1'>
                                                        {item?.competency}
                                                    </div>
                                                    <div className='col-md-2 border-end pt-1 pb-1'>
                                                        {item?.numberOfYears} Years
                                                    </div>
                                                    <div className='col-md-1 text-center pt-1 pb-1'>
                                                        <i className='fa fa-trash pointer_event' style={{ color: 'red' }} onClick={() => { deleteSkillsFun(item) }}></i>
                                                    </div>
                                                </div>)}
                                            {technicalExpertizeSkills?.length === 0 &&
                                                <>
                                                    <div className='border row text-start d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                        <div className='col-md-12 pt-1 pb-1'>
                                                            No Data Found !!
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>

                            <div className='text-start ms-2 pb-2 mt-3 d-flex justify-content-between align-items-center gap-2'>
                                <button
                                    type="button"
                                    className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                    style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                    onClick={() => {
                                        previousStepsUpdate('step_1')
                                        // nextStep({ step_1: 'current', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'is-done', step_8: 'is-done' })
                                    }}
                                >
                                    {'Previous'}
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-primary font_size_12 pt-1 pb-1 fw-medium`}
                                    onClick={() => { technicalExpertizeSuccessNext() }}
                                >
                                    {'Next'}
                                </button>
                            </div>
                        </div>
                    }
                    {stepsCompleted?.step_3 === 'current' &&
                        <div className='my-3 mx-4'>
                            <div>
                                <div className="row bd-example font_size_14 p-3 pt-1 border-bottom">
                                    <div className='row text-start pb-2 mt-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-md-3'>
                                            <label className="mb-3">{'Total Experience'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.experience && 'did-error-input'}`}>
                                                <input
                                                    type="number"
                                                    name="experience"
                                                    placeholder=""
                                                    {...register("experience", {
                                                        required: "Field is required",
                                                        minLength: {
                                                            value: 2,
                                                            message: "Min length is 2"
                                                        },
                                                    })}
                                                    className="did-floating-input textBorder"
                                                />
                                                <label className="did-floating-label ms-2 ">{'Total Experience'}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row text-start pb-2 mt-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-md-3'>
                                            <label className="mb-3">{'Company'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.company && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="Total Experience"
                                                    placeholder=""
                                                    {...register("company", {
                                                        required: "Field is required",
                                                        minLength: {
                                                            value: 2,
                                                            message: "Min length is 2"
                                                        },
                                                    })}
                                                    className="did-floating-input textBorder"
                                                />
                                                <label className="did-floating-label ms-2 ">{'Company'}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="mb-3">{'Designation/Role'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.designation && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="designation"
                                                    placeholder=""
                                                    {...register("designation", {
                                                        required: "Field is required",
                                                        minLength: {
                                                            value: 2,
                                                            message: "Min length is 2"
                                                        },
                                                    })}
                                                    className="did-floating-input textBorder"
                                                />
                                                <label className="did-floating-label ms-2">{'Designation/Role'}</label>
                                                {/* {errors.firstName && <p className="error">{errors.firstName.message}</p>} */}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label className="mb-3">{'Duration (from – to)'}</label>

                                            <Controller
                                                name="duration"
                                                control={control}
                                                render={({
                                                    field: { onChange, name, value },
                                                }) => (
                                                    // render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <DatePicker
                                                        // value={value || ""}
                                                        // onChange={(date) => {
                                                        //     onChange(date);
                                                        // }}
                                                        range
                                                        numberOfMonths={2}
                                                        plugins={[
                                                            // <Footer position="bottom" />
                                                        ]}
                                                        placeholder='Select Date'
                                                        selectsRange={true}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(e) => {
                                                            setDateRange(e);
                                                            onChange(e);
                                                        }}
                                                        isClearable={true}
                                                        className="form-control"
                                                    />
                                                )}
                                            />

                                        </div>
                                        <div className='col-md-1'>
                                            <label className="mb-3">{''}</label>
                                            <div className={`did-floating-label-content mt-3 mb-0 border p-2 text-center pointer_event hover_orange_bg`} onClick={() => { step_3_fun() }}>
                                                <i class="fa fa-plus" style={{ fontSize: "14px" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex text-muted height_step_2_employees_v_bar">
                                        <PerfectScrollbar className="sidebar-items w-100 pt-2 pb-3">
                                            {professionalExperience?.map((item) =>
                                                <div className='border row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                    <div className='col-md-3 border-end pe-2 pr-2 pt-1 pb-1 ps-1'>
                                                        {item?.company}
                                                    </div>
                                                    <div className='col-md-4 border-end pt-1 pb-1'>
                                                        {item?.designation}
                                                    </div>
                                                    <div className='col-md-4 border-end pt-1 pb-1'>
                                                        {new Date(item?.startDate)?.toLocaleDateString() + ' To ' + new Date(item?.endDate)?.toLocaleDateString()}
                                                    </div>
                                                    <div className='col-md-1 text-center pt-1 pb-1'>
                                                        <i className='fa fa-trash' style={{ color: 'red' }} onClick={() => { deleteWrkExperienceFun(item) }}></i>
                                                    </div>
                                                </div>)}
                                            {professionalExperience?.length === 0 &&
                                                <>
                                                    <div className='border row text-center d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                        <div className='col-md-12 pt-1 pb-1'>
                                                            No Data Found !!
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>

                            <div className='text-start ms-2 pb-2 mt-1 d-flex justify-content-between align-items-center gap-2'>
                                <button
                                    type="button"
                                    className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                    style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                    onClick={() => {
                                        previousStepsUpdate('step_2')
                                        //nextStep({ step_1: 'is-done', step_2: 'current', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'is-done', step_8: 'is-done' })
                                    }}
                                >
                                    {'Previous'}
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-primary font_size_12 pt-1 pb-1 fw-medium`}
                                    onClick={() => { workExperienceSuccessNext() }}
                                >
                                    {'Next'}
                                </button>
                            </div>
                        </div>
                    }
                    {stepsCompleted?.step_4 === 'current' &&
                        <div className='my-3 mx-4'>
                            <div>
                                <div className="row bd-example font_size_14 p-3 border-bottom">
                                    <div className='row text-start pb-2 mt-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-md-5'>
                                            <label className="requiredTrue mb-3">{'Certification'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.certificateName && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="certificateName"
                                                    placeholder=""
                                                    {...register("certificateName", {
                                                        //   required: "Field is required",
                                                        minLength: {
                                                            value: 2,
                                                            message: "Min length is 2"
                                                        },
                                                    })}
                                                    className="did-floating-input"
                                                />
                                                <label className="did-floating-label ms-2">{'Name of certificate'}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <label className="requiredTrue mb-3">{'Completed Date'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.completedDate && 'did-error-input'}`}>
                                                {/* <input
                                                    type="text"
                                                    name="completedDate"
                                                    placeholder=""
                                                    {...register("completedDate", {
                                                        required: "Field is required",
                                                        minLength: {
                                                            value: 2,
                                                            message: "Min length is 2"
                                                        },
                                                    })}
                                                    className="did-floating-input"
                                                /> */}
                                                <input
                                                    className="did-floating-input"
                                                    name="completedDate"
                                                    type="date"
                                                    placeholder=""
                                                    {...register("completedDate", {
                                                        required: "Field is required",

                                                    })}
                                                />
                                                <label className="did-floating-label ms-2">{'Completed Date'}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-1'>
                                            <label className="mb-3">{''}</label>
                                            <div className={`did-floating-label-content mt-3 mb-0 p-2 text-center`}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row text-start pb-2 mt-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-md-5'>
                                            <label className="requiredTrue mb-3">{'Platform'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.Platform && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="Platform"
                                                    placeholder=""
                                                    {...register("Platform", {
                                                        required: "Field is required",
                                                        minLength: {
                                                            value: 2,
                                                            message: "Min length is 2"
                                                        },
                                                    })}
                                                    className="did-floating-input"
                                                />
                                                <label className="did-floating-label ms-2">{'Platform'}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <label className="mb-3">{'URL For certification'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.urlForCertificationon && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="urlForCertificationon"
                                                    placeholder=""
                                                    {...register("urlForCertificationon", {
                                                        required: false,
                                                        // minLength: {
                                                        //     value: 2,
                                                        //     message: "Min length is 2"
                                                        // },
                                                    })}
                                                    className="did-floating-input"
                                                />
                                                <label className="did-floating-label ms-2">{'URL For certification'}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-1'>
                                            <label className="mb-3">{''}</label>
                                            <div className={`did-floating-label-content mt-3 mb-0 border p-2 text-center pointer_event hover_orange_bg`} onClick={() => { step_4_fun() }}>
                                                <i class="fa fa-plus" style={{ fontSize: "14px" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex text-muted height_step_2_employees_v_bar_cer">
                                        <PerfectScrollbar className="sidebar-items w-100 pt-2 pb-3">
                                            {certificationsList?.map((item) =>
                                                <div className='border row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                    <div className='col-md-3 border-end pt-1 pb-1'>
                                                        {item?.certificateName}
                                                    </div>
                                                    <div className='col-md-2 border-end pt-1 pb-1'>
                                                        {item?.completedDate}
                                                    </div>
                                                    <div className='col-md-3 border-end pt-1 pb-1'>
                                                        {item?.Platform}
                                                    </div>
                                                    <div className='col-md-3 border-end pt-1 pb-1'>
                                                        <a href={item.urlForCertificationon} target="_blank"><span className='font_color_ornage pointer_event'>{'View certificate'}</span></a>
                                                    </div>
                                                    <div className='col-md-1 text-center pt-1 pb-1'>
                                                        <i className='fa fa-trash' style={{ color: 'red' }} onClick={() => { deleteCertificationsFun(item) }}></i>
                                                    </div>
                                                </div>)}
                                            {certificationsList?.length === 0 &&
                                                <>
                                                    <div className='border row text-center d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                        <div className='col-md-12 pt-1 pb-1'>
                                                            No Data Found !!
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>

                            <div className='text-start ms-2 pb-2 mt-3 d-flex justify-content-between align-items-center gap-2'>
                                <button
                                    type="button"
                                    className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                    style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                    onClick={() => {
                                        //nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'current', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'is-done', step_8: 'is-done' })
                                        previousStepsUpdate('step_3')
                                    }}
                                >
                                    {'Previous'}
                                </button>
                                <div className='gap-3 d-flex'>
                                    <button
                                        type="button"
                                        className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                        style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                        onClick={() => {
                                            nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'current', total_completed: total_completed >= 40 ? total_completed : 40 })
                                            setCurrentOldStep(5)
                                            toast.info("You skipped certification step")
                                        }}
                                    >
                                        {'Skip this step'}
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn btn-primary font_size_12 pt-1 pb-1 fw-medium`}
                                        onClick={() => {
                                            certificationSuccessNext()
                                        }}
                                    >
                                        {'Next'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {stepsCompleted?.step_5 === 'current' &&
                        <div >
                            <div className='my-3 mx-4'>
                                <div className="row bd-example font_size_14 pb-2 ps-3 pt-0 border-bottom">
                                    <div className='row text-start pb-2 ps-0 mt-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-md-3'>
                                            <label className="requiredTrue mb-3">{'Project Name'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.projectName && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="projectName"
                                                    placeholder=""
                                                    {...register("projectName", {
                                                        required: "Field is required",
                                                        minLength: {
                                                            value: 2,
                                                            message: "Min length is 2"
                                                        },
                                                    })}
                                                    className="did-floating-input"
                                                />
                                                <label className="did-floating-label ms-2">{'Project Name'}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="requiredTrue mb-3">{'Tools/Technology'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.designation && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="toolsTechnology"
                                                    placeholder=""
                                                    {...register("toolsTechnology", {
                                                        required: "Field is required",
                                                        minLength: {
                                                            value: 2,
                                                            message: "Min length is 2"
                                                        },
                                                    })}
                                                    className="did-floating-input"
                                                />
                                                <label className="did-floating-label ms-2">{'Tools'}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label className="requiredTrue mb-3">{'Duration (from – to)'}</label>
                                            <Controller
                                                name="experienceDuration"
                                                control={control}
                                                render={({
                                                    field: { onChange, name, value },
                                                }) => (
                                                    // render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <DatePicker
                                                        // value={value || ""}
                                                        // onChange={(date) => {
                                                        //     onChange(date);
                                                        // }}
                                                        range
                                                        numberOfMonths={2}
                                                        plugins={[
                                                            // <Footer position="bottom" />
                                                        ]}
                                                        placeholder='Select Date'
                                                        selectsRange={true}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(e) => {
                                                            setDateRangeProject(e);
                                                            onChange(e);
                                                        }}
                                                        isClearable={true}
                                                        className="form-control"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='col-md-1'>
                                            <label className="mb-3">{''}</label>
                                            <div
                                                className={`did-floating-label-content mt-3 mb-0 border p-2 text-center pointer_event hover_orange_bg`}
                                                onClick={() => { step_5_fun() }}
                                            >
                                                <i class="fa fa-plus" style={{ fontSize: "14px" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row text-start pb-2 mt-0 d-flex justify-content-between align-items-center'>
                                        <Editor
                                            editorState={projectDesEditorSatte}
                                            onEditorStateChange={setProjectDesEditorSatte}
                                            wrapperClassName="wrapper-class p-0"
                                            editorClassName="editor-class min_h_150"
                                            toolbarClassName="toolbar-class"
                                        />
                                    </div>
                                    <div className="d-flex text-muted height_step_2_employees_v_bar_project mt-4 mb-2 ps-0">
                                        <PerfectScrollbar className="sidebar-items w-100 pb-3">
                                            <div className="pt-3 pb-3 pe-2 bg-body rounded">
                                                <div className="accordion" id="accordionExample">
                                                    {projectExpList?.map((item, idx) =>
                                                        <div className="accordion-item border rounded mb-2">
                                                            <h6 className="accordion-header font_color_imp font_size_13" id={`heading_${idx}`}>
                                                                <div className='row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2'>
                                                                    <div className='col-md-3 border-end pt-1 pb-1'>
                                                                        {item?.projectName}
                                                                    </div>
                                                                    <div className='col-md-4 border-end pt-1 pb-1'>
                                                                        {new Date(item?.startDateProject)?.toLocaleDateString() + ' To ' + new Date(item?.endDateProject)?.toLocaleDateString()}
                                                                    </div>
                                                                    <div className='col-md-3 border-end pt-1 pb-1'>
                                                                        {item?.toolsTechnology}
                                                                    </div>
                                                                    <div className='col-md-1 text-center pt-1 pb-1'>
                                                                        <i className='fa fa-trash pointer_event' style={{ color: 'red' }} onClick={() => { deleteProjectExpFun(item) }}></i>
                                                                    </div>
                                                                    <div className='col-md-1 text-center pt-1 pb-1'>
                                                                        <button
                                                                            className={`accordion-button ${idx > 0 && 'collapsed'} font_size_12`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#collapse_${idx}`}
                                                                            aria-expanded={`${idx > 0 ? 'false' : 'true'}`}
                                                                            aria-controls={`collapse_${idx}`}
                                                                            style={{
                                                                                boxShadow: 'none',
                                                                                background: 'white'
                                                                            }}
                                                                        >
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </h6>
                                                            <div
                                                                id={`collapse_${idx}`}
                                                                className={`accordion-collapse collapse`}
                                                                aria-labelledby={`heading_${idx}`}
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body"
                                                                    dangerouslySetInnerHTML={createMarkup(item?.convertedContentProjDes)}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {projectExpList?.length === 0 &&
                                                <>
                                                    <div className='border row text-center d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                        <div className='col-md-12 pt-1 pb-1'>
                                                            No Data Found !!
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>

                            <div className='text-start pb-2 mt-3 position-fixed bottom-0 col-md-6_8 custom_bottom_fixed'>
                                <div className='d-flex justify-content-between align-items-center gap-2 '>
                                    <button
                                        type="button"
                                        className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                        style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                        onClick={() => {
                                            //nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'current', step_5: 'is-done', step_6: 'is-done', step_7: 'is-done', step_8: 'is-done' })
                                            previousStepsUpdate('step_4')
                                        }}
                                    >
                                        {'Previous'}
                                    </button>
                                    <div className='gap-3 d-flex'>
                                        <button
                                            type="button"
                                            className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                            style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                            onClick={() => {
                                                nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'current', total_completed: total_completed >= 50 ? total_completed : 50 })
                                                setCurrentOldStep(6)
                                                toast.info("You skipped project experience step")
                                            }}
                                        >
                                            {'Skip this step'}
                                        </button>
                                        <button
                                            type="button"
                                            className={`btn btn-primary font_size_12 pt-1 pb-1 fw-medium`}
                                            onClick={() => { projectExpSuccessNext() }}
                                        >
                                            {'Next'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {stepsCompleted?.step_6 === 'current' &&
                        <div className='my-3 mx-4 mt-0'>
                            <div>
                                <div className="row bd-example font_size_14 p-3 pt-3 border-bottom">
                                    <div className='row text-start pb-2 mt-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-md-6'>
                                            <label className="requiredTrue mb-3">{'School'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.school && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="school"
                                                    placeholder=""
                                                    {...register("school", {
                                                        required: false,
                                                        // minLength: {
                                                        //     value: 2,
                                                        //     message: "Min length is 2"
                                                        // },
                                                    })}
                                                    className="did-floating-input"
                                                />
                                                <label className="did-floating-label ms-2">{'school'}</label>
                                            </div>
                                            {/* <div className="form-outline">
                                                <Controller
                                                    name="school"
                                                    control={control}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            // isClearable
                                                            options={collegesArrState}
                                                            value={collegesArrState?.find((c) => c.value === value)}
                                                            onChange={(val) => onChange(val?.value)}
                                                            defaultValue={collegesArrState?.find((c) => c.value === typeOfschool)}
                                                            isSearchable={true}
                                                            placeholder="Select"
                                                            className="basic-single my-react-select"
                                                            classNamePrefix="select"
                                                            components={{
                                                                ValueContainer: CustomValueContainer
                                                            }}
                                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'school': true }) }}
                                                            styles={{
                                                                control: (styles) => ({
                                                                    ...styles,
                                                                    ...errorBorderForSelectBox(errors.school && 'school'),
                                                                }),
                                                                container: (provided, state) => ({
                                                                    ...provided,
                                                                    marginTop: 5,
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    overflow: "visible"
                                                                }),
                                                                placeholder: (provided, state) => ({
                                                                    ...provided,
                                                                    position: "absolute",
                                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                                    background: "white",
                                                                    transition: "top 0.1s, font-size 0.1s",
                                                                    fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                                    fontWeight: 100,
                                                                    padding: '0.3px 8.5px',
                                                                    borderRadius: '8px',
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    border: isSelected['school'] ? '1px solid #cccccc' : ''
                                                                }),
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                                    ...styles,
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    //   cursor: isDisabled ? 'not-allowed' : 'default',
                                                                })
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "Field is required" }}
                                                />
                                            </div> */}
                                        </div>
                                        <div className="col-md-5">
                                            <label className="requiredTrue mb-3">{'Field of study'}</label>
                                            <div className="form-outline">
                                                <Controller
                                                    name="fieldOfstudy"
                                                    control={control}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            // isClearable
                                                            options={fieldOfStudyArrState}
                                                            value={fieldOfStudyArrState?.find((c) => c.value === value)}
                                                            onChange={(val) => onChange(val?.value)}
                                                            defaultValue={fieldOfStudyArrState?.find((c) => c.value === fieldOfstudyValue)}
                                                            isSearchable={true}
                                                            placeholder="Select"
                                                            className="basic-single my-react-select"
                                                            classNamePrefix="select"
                                                            components={{
                                                                ValueContainer: CustomValueContainer
                                                            }}
                                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'fieldOfstudy': true }) }}
                                                            styles={{
                                                                control: (styles) => ({
                                                                    ...styles,
                                                                    ...errorBorderForSelectBox(errors.fieldOfstudy && 'fieldOfstudy'),
                                                                }),
                                                                container: (provided, state) => ({
                                                                    ...provided,
                                                                    marginTop: 5,
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    overflow: "visible"
                                                                }),
                                                                placeholder: (provided, state) => ({
                                                                    ...provided,
                                                                    position: "absolute",
                                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                                    background: "white",
                                                                    transition: "top 0.1s, font-size 0.1s",
                                                                    fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                                    fontWeight: 100,
                                                                    padding: '0.3px 8.5px',
                                                                    borderRadius: '8px',
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    border: isSelected['fieldOfstudy'] ? '1px solid #cccccc' : ''
                                                                }),
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                                    ...styles,
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    //   cursor: isDisabled ? 'not-allowed' : 'default',
                                                                })
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "Field is required" }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <label className="requiredTrue mb-3">{'Highest Degree'}</label>
                                            <div className="form-outline">
                                                <Controller
                                                    name="highestDegree"
                                                    control={control}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            // isClearable
                                                            options={highestEduArrState}
                                                            value={highestEduArrState?.find((c) => c.value === value)}
                                                            onChange={(val) => onChange(val?.value)}
                                                            defaultValue={highestEduArrState?.find((c) => c.value === highestDegreeValue)}
                                                            isSearchable={true}
                                                            placeholder="Select"
                                                            className="basic-single my-react-select"
                                                            classNamePrefix="select"
                                                            components={{
                                                                ValueContainer: CustomValueContainer
                                                            }}
                                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'highestDegree': true }) }}
                                                            styles={{
                                                                control: (styles) => ({
                                                                    ...styles,
                                                                    ...errorBorderForSelectBox(errors.highestDegree && 'highestDegree'),
                                                                }),
                                                                container: (provided, state) => ({
                                                                    ...provided,
                                                                    marginTop: 5,
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    overflow: "visible"
                                                                }),
                                                                placeholder: (provided, state) => ({
                                                                    ...provided,
                                                                    position: "absolute",
                                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                                    background: "white",
                                                                    transition: "top 0.1s, font-size 0.1s",
                                                                    fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                                    fontWeight: 100,
                                                                    padding: '0.3px 8.5px',
                                                                    borderRadius: '8px',
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    border: isSelected['highestDegree'] ? '1px solid #cccccc' : ''
                                                                }),
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                                    ...styles,
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    //   cursor: isDisabled ? 'not-allowed' : 'default',
                                                                })
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "Field is required" }}
                                                />
                                            </div>
                                        </div> */}
                                        <div className='col-md-1'>
                                            <label className="mb-3">{''}</label>
                                            <div className={`did-floating-label-content mt-3 mb-0 p-2 text-center`}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row text-start pb-2 mt-2 d-flex justify-content-between align-items-center'>
                                        {/* <div className='col-md-3'>
                                            <label className="mb-3">{'Other Degree'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.otheerHighestDegree && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="otheerHighestDegree"
                                                    placeholder=""
                                                    {...register("otheerHighestDegree", {
                                                        required: false,
                                                        // minLength: {
                                                        //     value: 2,
                                                        //     message: "Min length is 2"
                                                        // },
                                                    })}
                                                    className="did-floating-input"
                                                />
                                                <label className="did-floating-label ms-2">{'Other degree'}</label>
                                            </div>
                                        </div> */}
                                        <div className="col-md-4">
                                            <label className="requiredTrue mb-3">{'Highest Degree'}</label>
                                            <div className="form-outline">
                                                <Controller
                                                    name="highestDegree"
                                                    control={control}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            // isClearable
                                                            options={highestEduArrState}
                                                            value={highestEduArrState?.find((c) => c.value === value)}
                                                            onChange={(val) => onChange(val?.value)}
                                                            defaultValue={highestEduArrState?.find((c) => c.value === highestDegreeValue)}
                                                            isSearchable={true}
                                                            placeholder="Select"
                                                            className="basic-single my-react-select"
                                                            classNamePrefix="select"
                                                            components={{
                                                                ValueContainer: CustomValueContainer
                                                            }}
                                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'highestDegree': true }) }}
                                                            styles={{
                                                                control: (styles) => ({
                                                                    ...styles,
                                                                    ...errorBorderForSelectBox(errors.highestDegree && 'highestDegree'),
                                                                }),
                                                                container: (provided, state) => ({
                                                                    ...provided,
                                                                    marginTop: 5,
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    overflow: "visible"
                                                                }),
                                                                placeholder: (provided, state) => ({
                                                                    ...provided,
                                                                    position: "absolute",
                                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                                    background: "white",
                                                                    transition: "top 0.1s, font-size 0.1s",
                                                                    fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                                    fontWeight: 100,
                                                                    padding: '0.3px 8.5px',
                                                                    borderRadius: '8px',
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    border: isSelected['highestDegree'] ? '1px solid #cccccc' : ''
                                                                }),
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                                    ...styles,
                                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                                    //   cursor: isDisabled ? 'not-allowed' : 'default',
                                                                })
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "Field is required" }}
                                                />
                                            </div>
                                        </div> 
                                        <div className='col-md-3'>
                                            <label className="requiredTrue mb-3">{'From date'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.fromDate && 'did-error-input'}`}>
                                                <input
                                                    className="did-floating-input"
                                                    name="fromDateCollege"
                                                    type="date"
                                                    placeholder=""
                                                    {...register("fromDateCollege", {
                                                        required: "Field is required",

                                                    })}
                                                />
                                                <label className="did-floating-label ms-2">{'From date'}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="requiredTrue mb-3">{'To date'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.toDate && 'did-error-input'}`}>
                                                <input
                                                    className="did-floating-input"
                                                    name="toDateCollege"
                                                    type="date"
                                                    placeholder=""
                                                    {...register("toDateCollege", {
                                                        required: "Field is required",

                                                    })}
                                                />
                                                <label className="did-floating-label ms-2">{'To date'}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-1'>
                                            <label className="mb-3">{''}</label>
                                            <div className={`did-floating-label-content mt-3 mb-0 border p-2 text-center pointer_event hover_orange_bg`} onClick={() => { step_6_fun() }}>
                                                <i class="fa fa-plus" style={{ fontSize: "14px" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex text-muted height_step_2_employees_v_bar_cer mt-3">
                                        <PerfectScrollbar className="sidebar-items w-100 pt-2 pb-3">
                                            {educationQualificationList?.map((item) =>
                                                <div className='border row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                    <div className='col-md-2 border-end pt-1 pb-1 word-break-all'>
                                                        {item?.school || '---'}
                                                    </div>
                                                    <div className='col-md-2 border-end pt-1 pb-1 word-break-all'>
                                                        {item?.fieldOfstudy || '---'}
                                                    </div>
                                                    <div className='col-md-2 border-end pt-1 pb-1 word-break-all'>
                                                        {item?.highestDegree || '---'}
                                                    </div>
                                                    {/* <div className='col-md-2 border-end pt-1 pb-1'>
                                                        {item?.otheerHighestDegree}
                                                    </div> */}
                                                    <div className='col-md-2 border-end pt-1 pb-1'>
                                                        {item?.fromDateCollege}
                                                    </div>
                                                    <div className='col-md-2 border-end pt-1 pb-1'>
                                                        {item?.toDateCollege}
                                                    </div>
                                                    <div className='col-md-1 text-center pt-1 pb-1'>
                                                        <i className='fa fa-trash pointer_event' style={{ color: 'red' }} onClick={() => { deleteEducationsFun(item) }}></i>
                                                    </div>
                                                </div>)}
                                            {educationQualificationList?.length === 0 &&
                                                <>
                                                    <div className='border row text-center d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                        <div className='col-md-12 pt-1 pb-1'>
                                                            No Data Found !!
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>

                            <div className='text-start ms-2 pb-2 mt-3 d-flex justify-content-between align-items-center gap-2'>
                                <button
                                    type="button"
                                    className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                    style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                    onClick={() => {
                                        //nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'current', step_6: 'is-done', step_7: 'is-done', step_8: 'is-done' }) 
                                        previousStepsUpdate('step_5')
                                    }}
                                >
                                    {'Previous'}
                                </button>
                                <div className='gap-3 d-flex'>
                                    <button
                                        type="button"
                                        className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                        style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                        onClick={() => {
                                            nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'current', total_completed: total_completed >= 60 ? total_completed : 60 })
                                            setCurrentOldStep(7)
                                            toast.info("You skipped education step")
                                        }}
                                    >
                                        {'Skip this step'}
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn btn-primary font_size_12 pt-1 pb-1 fw-medium`}
                                        onClick={() => { educationStepSuccessNext() }}
                                    >
                                        {'Next'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {stepsCompleted?.step_7 === 'current' &&
                        <div className='my-3 mx-4 mt-0'>
                            <div>
                                <div className="row bd-example font_size_14 p-3 border-bottom">
                                    <div className='row text-start pb-2 mt-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-md-12'>
                                            <label className="mb-3">{'Linked profile (optional)'}</label>
                                            <div className={`did-floating-label-content mb-0 ${errors.certificateName && 'did-error-input'}`}>
                                                <input
                                                    type="text"
                                                    name="certificateName"
                                                    placeholder=""
                                                    {...register("certificateName", {
                                                        required: false,
                                                        // minLength: {
                                                        //     value: 2,
                                                        //     message: "Min length is 2"
                                                        // },
                                                    })}
                                                    className="did-floating-input"
                                                />
                                                <label className="did-floating-label ms-2">{'Add url'}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row text-start pb-2 mt-3 d-flex justify-content-between align-items-center'>
                                        <div className='col-md-12'>
                                            <label className="mb-2 requiredTrue">{'Resume'}</label>
                                            <div className="mb-3 font_size_11">{'Upload your resume to autocomplete your remote72 profile'}</div>
                                            <div className={`marginbottom_int did-floating-label-content ${fileUploadedLength && errors.file && 'did-error-input'}`}>
                                                <>
                                                    {selectedCompanyDetailsRowList.length > 0 && selectedCompanyDetailsRowList.length < 2
                                                        ?
                                                        <input
                                                            className="did-floating-input" //mb-4 font_size_14
                                                            type="file"
                                                            name="file"
                                                            accept=".pdf"
                                                            placeholder=""
                                                            {...register("file", {
                                                                required: false,
                                                                validate
                                                            })}
                                                            style={{ paddingTop: '7px' }}
                                                        //onKeyDown={fileUploadedOnDown}
                                                        />
                                                        :
                                                        <div className='reCaptcha'>
                                                            <div style={{ position: 'absolute', background: 'rgba(242, 244, 252, 0.6)', width: '100%', height: '100px', paddingTop: '30px' }} className='text-center'>
                                                                <img
                                                                    src={require(`../../assets/images/cloud_upload.svg`).default}
                                                                    alt="upload file"
                                                                    className="img_w_h_18"
                                                                />
                                                                {(getValues('file')?.length > 0) ? <div> {uploadedResumes || getValues('file')[0]?.name} </div> : <div>Upload resume. PDF, Doc format</div>}
                                                            </div>
                                                            <input
                                                                className="did-floating-input" //mb-4 font_size_14
                                                                type="file"
                                                                name="file"
                                                                accept=".pdf"
                                                                placeholder=""
                                                                {...register("file", {
                                                                    required: "File is required",
                                                                    validate: (value) => {
                                                                        const acceptedFormats = ['pdf'];
                                                                        const fileExtension = value[0]?.name.split('.').pop().toLowerCase();
                                                                        if (!acceptedFormats.includes(fileExtension)) {
                                                                            return 'Invalid file format. Only PDF files are allowed.';
                                                                        }
                                                                        return true;
                                                                    },
                                                                    onBlur: (e) => { fileUploadedOnDown(e) },
                                                                    onChange: (e) => { fileUploadedOnDown(e) }
                                                                })}
                                                                style={{ paddingTop: '7px', opacity: 0, zIndex: 999, width: '100%', border: '1px solid gray', height: '100px' }}
                                                            />
                                                        </div>
                                                    }
                                                    {/* <span className="btn btn-primary upload_file_css font_size_14 rounded-0 rounded-end pb-0_6 right-0">
                                                        <img
                                                            src={require(`../../assets/images/upload_file.svg`).default}
                                                            alt="upload file"
                                                            className="img_w_h_18"
                                                        />
                                                    </span> */}
                                                </>
                                                <div>
                                                    <label className="fw-medium py-3">{'Uploaded Resumes List :-'}</label>
                                                    {resume_file_location &&
                                                        <span
                                                            className="d-flex justify-content-start align-items-center font_color_ornage hover_underline"
                                                            onClick={() => {
                                                                downloadPdf(resume_file_location, resumeFileName)
                                                            }}
                                                        >
                                                            <img
                                                                src={require(`../../assets/images/pdf-file-icon.svg`).default}
                                                                alt="Sow"
                                                                className="img_w_h_18"
                                                            />
                                                            <div
                                                                className="fw-medium font_size_12 border-1"
                                                            >
                                                                {resumeFileName}
                                                            </div>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-start ms-2 pb-2 mt-3 d-flex justify-content-between align-items-center gap-2'>
                                    <button
                                        type="button"
                                        className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                        style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                        onClick={() => {
                                            //nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'current', step_7: 'is-done', step_8: 'is-done' }) 
                                            previousStepsUpdate('step_6')
                                        }}
                                    >
                                        {'Previous'}
                                    </button>
                                    {isEdit && <button type="submit"
                                        className="btn btn-primary pb-2 ps-4 pe-4 font_size_13 btn_height_36 padding_top_6 mt-2 mb-0"
                                        onClick={() => { onUpdate() }}
                                    >
                                        Update
                                    </button>}
                                    {!isEdit && <button type="submit"
                                        className="btn btn-primary pb-2 ps-4 pe-4 font_size_13 btn_height_36 padding_top_6 mt-2 mb-0"
                                        onClick={() => { onSubmit() }}
                                    >
                                        Submit
                                    </button>}
                                </div>
                            </div>
                        </div>
                    }
                    {stepsCompleted?.step_8 === 'current' &&
                        <div className={`rounded rounded_20 bg-body rounded shadow-sm`}>
                            {step_8 === 'current' &&
                                <div className='my-3 mx-4 mt-0'>
                                    <div>
                                        <div className="row bd-example font_size_14 p-3 pt-3 border-bottom">

                                            <div className='text-center my-2 p-0 col-md-12 d-flex justify-content-start align-items-start'>
                                                <div className='col-md-1_01'>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
                                                    </div>
                                                </div>
                                                <div className='col-md-7 font_size_13'>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <h6 className="font_color_imp font_size_17 ps-3 fw-bold">{user?.company_name}</h6>
                                                    </div>
                                                    <div className='text-start ms-3 pb-2 col-md-6 pt-2'>
                                                        <div>Email : {user?.buisness_email_id}</div>
                                                        <div>Country : india</div>
                                                        <div>Timezone : 5:30</div>
                                                    </div>
                                                </div>
                                                <div className='col-md-5 p-3 pt-0 '>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <span className="pt-5 pe-5 font_color_imp font_size_13">
                                                            Available to work these hours in your timezone: 10AM - 8PM
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="pb-1 mb-0 font_color_imp fw-semibold">{'Summary'}</h6>
                                            </div>
                                            <div className="d-flex text-muted my-2">
                                                {/* height_step_2_employees_v_bar_cer */}
                                                <PerfectScrollbar className="sidebar-items w-100 pt-2 pb-3 border">
                                                    <div className='px-3' dangerouslySetInnerHTML={createMarkup(convertedContent||formData?.profileSummary)}></div>
                                                </PerfectScrollbar>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center pt-1">
                                                <h6 className="pb-0 mb-0 font_color_imp fw-semibold">{'Technical Expertise & Skills'}</h6>
                                            </div>
                                            <div className="d-flex text-muted my-1">
                                                {/* height_step_2_employees_v_bar_cer */}
                                                <PerfectScrollbar className="sidebar-items w-100 pt-1 pb-1">
                                                    {technicalExpertizeSkills?.map((item) =>
                                                        <div className='border row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                            <div className='col-md-5 border-end pt-1 pb-1'>
                                                                {item?.languageType}
                                                            </div>
                                                            <div className='col-md-4 border-end pt-1 pb-1'>
                                                                {item?.competency}
                                                            </div>
                                                            <div className='col-md-2 border-end pt-1 pb-1'>
                                                                {item?.numberOfYears} years
                                                            </div>
                                                            <div className='col-md-1 text-center pt-1 pb-1'>
                                                                <i className='fa fa-trash pointer_event' style={{ color: 'red' }} onClick={() => { deleteSkillsFun(item) }}></i>
                                                            </div>
                                                        </div>)}
                                                    {technicalExpertizeSkills?.length === 0 &&
                                                        <>
                                                            <div className='border row text-center d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                                <div className='col-md-12 pt-1 pb-1'>
                                                                    No Data Found !!
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </PerfectScrollbar>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="pb-0 mb-0 font_color_imp fw-semibold">{'Professional Experience'}</h6>
                                            </div>
                                            <div className="d-flex text-muted mt-1">
                                                {/* height_step_2_employees_v_bar_cer */}
                                                <PerfectScrollbar className="sidebar-items w-100 pt-1 pb-1">
                                                    {professionalExperience?.map((item) =>
                                                        <div className='border row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                            <div className='col-md-3 border-end pe-2 ps-3 pr-2 pt-1 pb-1 ps-1'>
                                                                {item?.company}
                                                            </div>
                                                            <div className='col-md-4 border-end pt-1 pb-1'>
                                                                {item?.designation}
                                                            </div>
                                                            <div className='col-md-4 border-end pt-1 pb-1'>
                                                                {new Date(item?.startDate * 1000) + ' To ' + new Date(item?.endDate * 1000)}
                                                            </div>
                                                            <div className='col-md-1 text-center pt-1 pb-1'>
                                                                <i className='fa fa-trash' style={{ color: 'red' }} onClick={() => { deleteWrkExperienceFun(item) }}></i>
                                                            </div>
                                                        </div>)}
                                                    {professionalExperience?.length === 0 &&
                                                        <>
                                                            <div className='border row text-center d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                                <div className='col-md-12 pt-1 pb-1'>
                                                                    No Data Found !!
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </PerfectScrollbar>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="pt-1 mb-0 font_color_imp fw-semibold">{'Certification'}</h6>
                                            </div>
                                            <div className="d-flex text-muted mt-1">
                                                {/* height_step_2_employees_v_bar_cer */}
                                                <PerfectScrollbar className="sidebar-items w-100 pt-1 pb-1">
                                                    {certificationsList?.map((item) =>
                                                        <div className='border row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                            <div className='col-md-3 border-end pt-1 pb-1'>
                                                                {item?.certificateName}
                                                            </div>
                                                            <div className='col-md-2 border-end pt-1 pb-1'>
                                                                {item?.completedDate}
                                                            </div>
                                                            <div className='col-md-3 border-end pt-1 pb-1'>
                                                                {item?.Platform}
                                                            </div>
                                                            <div className='col-md-3 border-end pt-1 pb-1'>
                                                                <a href={item.urlForCertificationon} target="_blank"><span className='font_color_ornage pointer_event'>{'View certificate'}</span></a>
                                                            </div>
                                                            <div className='col-md-1 text-center pt-1 pb-1'>
                                                                <i className='fa fa-trash' style={{ color: 'red' }} onClick={() => { deleteCertificationsFun(item) }}></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {certificationsList?.length === 0 &&
                                                        <>
                                                            <div className='border row text-center d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                                <div className='col-md-12 pt-1 pb-1'>
                                                                    No Data Found !!
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </PerfectScrollbar>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="pt-1 mb-0 font_color_imp fw-semibold">{'Project Experience'}</h6>
                                            </div>
                                            <div className="d-flex text-muted">
                                                {/* height_step_2_employees_v_bar_cer */}
                                                <PerfectScrollbar className="sidebar-items w-100 pb-1">
                                                    <div className="pt-1 pb-1 pe-2 bg-body rounded">
                                                        <div className="accordion" id="accordionExample">
                                                            {projectExpList?.map((item, idx) =>
                                                                <div className="accordion-item border rounded-2 mb-2">
                                                                    <h6 className="accordion-header font_color_imp font_size_13" id={`heading_${idx}`}>
                                                                        <div className='row text-start d-flex justify-content-evenly align-items-center ms-0 me-0 rounded-2 background_table_row'>
                                                                            <div className='col-md-3 border-end pt-1 pb-1'>
                                                                                {item?.projectName}
                                                                            </div>
                                                                            <div className='col-md-4 border-end pt-1 pb-1'>
                                                                                {new Date(item?.startDateProject * 1000) + ' To ' + new Date(item?.endDateProject * 1000)}
                                                                            </div>
                                                                            <div className='col-md-3 border-end pt-1 pb-1'>
                                                                                {item?.toolsTechnology}
                                                                            </div>
                                                                            <div className='col-md-1 text-center pt-1 pb-1'>
                                                                                <i className='fa fa-trash pointer_event' style={{ color: 'red' }} onClick={() => { deleteProjectExpFun(item) }}></i>
                                                                            </div>
                                                                            <div className='col-md-1 text-center pt-1 pb-1'>
                                                                                <button
                                                                                    className={`accordion-button ${idx > 0 && 'collapsed'} font_size_12`}
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target={`#collapse_${idx}`}
                                                                                    aria-expanded={`${idx > 0 ? 'false' : 'true'}`}
                                                                                    aria-controls={`collapse_${idx}`}
                                                                                    style={{
                                                                                        boxShadow: 'none',
                                                                                        background: '#f8f9fa ! important'
                                                                                    }}
                                                                                >
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </h6>
                                                                    <div
                                                                        id={`collapse_${idx}`}
                                                                        className={`accordion-collapse collapse`}
                                                                        aria-labelledby={`heading_${idx}`}
                                                                        data-bs-parent="#accordionExample"
                                                                    >
                                                                        <div
                                                                            className="accordion-body"
                                                                            dangerouslySetInnerHTML={createMarkup(item?.convertedContentProjDes)}
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {projectExpList?.length === 0 &&
                                                        <>
                                                            <div className='border row text-center d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                                <div className='col-md-12 pt-1 pb-1'>
                                                                    No Data Found !!
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </PerfectScrollbar>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                                                <h6 className="pb-0 mb-0 font_color_imp fw-semibold">{'Education Qualifications'}</h6>
                                            </div>
                                            <div className="d-flex text-muted">
                                                {/* height_step_2_employees_v_bar_cer */}
                                                <PerfectScrollbar className="sidebar-items w-100 pt-1 pb-1">
                                                    {educationQualificationList?.map((item) =>
                                                        <div className='border row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                            <div className='col-md-2 border-end pt-1 pb-1 word-break-all'>
                                                                {item?.school}
                                                            </div>
                                                            <div className='col-md-2 border-end pt-1 pb-1 word-break-all'>
                                                                {item?.fieldOfstudy}
                                                            </div>
                                                            <div className='col-md-2 border-end pt-1 pb-1 word-break-all'>
                                                                {item?.highestDegree}
                                                            </div>
                                                            {/* <div className='col-md-2 border-end pt-1 pb-1'>
                                                        {item?.otheerHighestDegree}
                                                    </div> */}
                                                            <div className='col-md-2 border-end pt-1 pb-1'>
                                                                {item?.fromDateCollege}
                                                            </div>
                                                            <div className='col-md-2 border-end pt-1 pb-1'>
                                                                {item?.toDateCollege}
                                                            </div>
                                                            <div className='col-md-1 text-center pt-1 pb-1'>
                                                                <i className='fa fa-trash pointer_event' style={{ color: 'red' }} onClick={() => { deleteEducationsFun(item) }}></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {educationQualificationList?.length === 0 &&
                                                        <>
                                                            <div className='border row text-center d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                                                <div className='col-md-12 pt-1 pb-1'>
                                                                    No Data Found !!
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </PerfectScrollbar>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-1">
                                                <h6 className="pb-1 mb-0 font_color_imp fw-semibold">{'Uploaded resume'}</h6>
                                            </div>
                                            <div className="d-flex text-muted">
                                                <PerfectScrollbar className="sidebar-items w-100 pt-2 pb-3">
                                                    <div className="d-flex justify-content-start align-items-center gap-3">
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                                            style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                                        >
                                                            {resumeFileName}
                                                            <i className='fa fa-edit ps-5'></i>
                                                        </button>
                                                        <button type="submit"
                                                            className="btn btn-primary ps-2 pe-2 font_size_12 btn_height_29 pt-1 padding_top_6 mt-0 mb-0"
                                                            onClick={() => {
                                                                downloadPdf(resume_file_location, resumeFileName)
                                                            }}
                                                        >
                                                            Download Resume
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                                            style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                                            onClick={() => { downloadPDFNewCall(remotefile_location || resume_file_location, remotefileName) }}
                                                        >
                                                            {'View remote72 format of resume'}
                                                        </button>
                                                    </div>
                                                </PerfectScrollbar>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='text-start ms-2 pb-2 mt-3 d-flex justify-content-between align-items-center gap-2'>
                                        <button
                                            type="button"
                                            className={`btn btn-primary ps-2 pe-2 font_size_12 btn_height_36 pt-1 padding_top_6 mt-0 mb-0 px-3 w-20`}
                                            onClick={() => {
                                                previousStepsUpdate('step_7')
                                                //nextStep({ step_1: 'is-done', step_2: 'is-done', step_3: 'is-done', step_4: 'is-done', step_5: 'is-done', step_6: 'is-done', step_7: 'current', step_8: 'is-done' })
                                            }}
                                        >
                                            {'Edit user profile'}
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {/* </form> */}
            </div>

            {/* right side cards */}
            <div className="col-md-4 width_col_30">
                {/* card 0 */}
                <div className="my-3 p-3 pt-2 mt-3 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom ">
                        <span className="pb-1 mb-0 font_color_imp font_size_14 fw-bolder">Create a remote72 profile</span>
                    </div>
                    <div>
                        <div className="row bd-example font_size_14">
                            <div className='text-center my-1 pt-3 ps-1 me-1 p-0 mx-3'>
                                <div className="wrapper_v_s_b">
                                    <ul className="StepProgress_v_s_b">
                                        <li className={`StepProgress-item ${step_1}  `}><strong className='track'>Summary about you.</strong></li>
                                        <li className={`StepProgress-item ${step_2} `}><strong className='track'>Technical Proficiency</strong></li>
                                        <li className={`StepProgress-item ${step_3} `}><strong className='track'>Professional Experience</strong></li>
                                        <li className={`StepProgress-item ${step_4} `}><strong className='track'>Certification</strong></li>
                                        <li className={`StepProgress-item ${step_5} `}><strong className='track'>Project Experience</strong></li>
                                        <li className={`StepProgress-item ${step_6} `}><strong className='track'>Education Qualifications</strong></li>
                                        <li className={`StepProgress-item ${step_7} `}><strong className='track'>Upload Resume</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* card 3 */}
                <div className="my-3 p-3 mt-2 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom">
                        <h6 className="pb-2 mb-0 font_color_imp font_size_14 fw-bolder">Your Profile Status</h6>
                    </div>
                    <div>
                        {/* height_scheduled_int_table */}
                        <div className="row bd-example height_scheduled_int_table_y_p_s_non_update">
                            <div className="pt-3">
                                <progress max="100" value={stepsCompleted?.total_completed}></progress>
                                <div className='ps-2 font_color_ornage text-center font_size_13'>{`${stepsCompleted?.total_completed}% completed`} </div>
                            </div>
                            {/* <div className='text-center my-1 me-1 p-2'>
                                <button
                                    type="button"
                                    className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                    onClick={() => { }}
                                >
                                    {'Update Profile'}
                                </button>
                            </div> */}
                            {/* <div className='border-bottom mb-1'></div> */}
                        </div>
                    </div>
                    <div className='font_size_14 text-center'>{'Completing profile will make your chances better to get more interviews'}</div>
                </div>

            </div>
        </div >
    </>
}


export default adminLayout(VerticalStepBar);

const dddddddd = {
    languages: "java, mongodb, javascript,mysql,php,laravel",
    tools: "alltools, no tools, mysql work bench",
    experience: '10 year'
}
const dddddddd1 = {
    languages: "tcs",
    tools: "Software Engineer",
    experience: '2024-05-12 To 2025-04-23'
}
const dddddddd2 = {
    languages: "Certificate in business Analyst",
    tools: "2024",
    experience: 'Genpact internal certificate'
}

const dddddddd6 = {
    languages: "Boston Consulting Group",
    tools: "Oracle Financials, Service Now, JiraAdvanced Excel for Data Analysis",
    experience: 'sep12 - aug2023'
}

