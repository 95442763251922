import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon"
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import CurrencyInput from 'react-currency-input-field';
import Select, { components } from "react-select";
import { addNewRequirementPlaceholders } from '../../Utils/masterData/staticContent'
import { typesOfEmployeeContractList, salaryRange, currencyInputintlConfig } from '../../Utils/masterData/dropDownJson'
import { isEmpty } from '../../Utils/utils'
import { postApi, getAllApi } from '../../services/apiCommonService'
import { create_candidate_details, candidate_details_By_Id, upload_candidate_file } from '../../services/endPointsConstant'
import storePersist from '../../Utils/storePersist'
import { useEffect } from "react";

const { ValueContainer, Placeholder } = components;

// const CustomValueContainer = ({ children, ...props }) => {
//     return (
//         <ValueContainer {...props}>
//             <Placeholder {...props} isFocused={props.isFocused}>
//                 {props.selectProps.placeholder}
//             </Placeholder>
//             {React.Children.map(children, (child) =>
//                 child && child.type !== Placeholder ? child : null
//             )}
//         </ValueContainer>
//     );
// };

const CandidateDetails = (props) => {
    const { returnEditJobRequirementFormDetails, modalPopUpCancel,selectedJobOfferObjects } = props
    const [formData, setFormData] = useState(false);
    const [jobList, setJobList] = useState([])
    const [candidatesDetailsState, setCandidateDetails] = useState([])
    const [salary, setSalaryValue] = useState(null);
    const [currencyValue, setCurrencyValue] = useState(null);
    const [typesOfEmployeeContractValue, setTypesOfEmployeeContractValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const navigate = useNavigate();
    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        control,
        reset
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    const candidateDetailsById = async (props) => {
        const apiReqBody = { candidateId: props?._id }
        const apiResponse = await postApi(candidate_details_By_Id, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = apiResponse?.data?.Data[0]
            reset({ ...apiResponseData })
            setCandidateDetails(apiResponseData)
            // toast.success("Retrived all Candidates List Successfully !!")
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    useEffect(() => {
        async function fetchData() {
            candidateDetailsById()
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (modalPopUpCancel) {
            reset();
        }
    }, [modalPopUpCancel])

    const errorHandler = () => {
        if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const handleOnValueChange = (event) => {
        setSalaryValue(event.target.value)
    }

    const resumeUpload = async (data, candidateDetails) => {
        const baseUrl = 'https://free.remote72.in'
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("candidateId", candidateDetails?._id)
        const apiResponse = await fetch(`${baseUrl}/${upload_candidate_file}`, {
            method: "POST",
            body: formData,
        }).then((res) => res.json());
        if (apiResponse?.message) {
            if (apiResponse?.data) {
                toast.success(apiResponse?.message)
            } else {
                toast.success(apiResponse?.response?.data.message || "resume uploaded successfully !!")
            }
        } else {
            toast.error(apiResponse?.response?.data?.message + '!!')
        }
    }

    const onSubmit = async (data) => {
        setFormData(data);
        const { id } = storePersist.get('userData')
        const companyDetailsStorage = storePersist.get('companyDetails')
        const apiReqBody = {
            userid: id,
            firstName: data.firstName,
            lastName: data.lastName,
            profileTitle: data.profileTitle,
            experience: data.experience,
            skillset: data?.skillset?.split(','),
            candidate_status: 'registered',
            fileName: data.file[0]?.name,
            companyId: companyDetailsStorage && companyDetailsStorage?._id
        }
        const apiResponse = await postApi(create_candidate_details, apiReqBody)
        if (apiResponse?.data) {
            resumeUpload(data, apiResponse?.data?.Data)
            toast.success("Successfully candidate details Added !!")
            returnEditJobRequirementFormDetails(apiReqBody)
            document.getElementById("buttonDismiss").click();
            reset();
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['salary', 'office_type', 'currencyValue'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    const currencyHandler = (val) => {
        setCurrencyValue(val)
    }

    return (
        <div className="form-holder ps-6 pe-6 pb-0 main_bg rounded-0">
            {/* onSubmit={handleSubmit(onSubmit)} */}
            <form >

                <div className="form-outline row gx-3">
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.firstName && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="text"
                            name="firstName"
                            placeholder=""
                            {...register("firstName", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                            })}
                        />
                        <label className="did-floating-label">{'firstName'}</label>
                    </div>
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.lastName && 'did-error-input'}`}>
                        <input
                            className="did-floating-input" //mb-4 font_size_14
                            type="text"
                            name="lastName"
                            placeholder=""
                            {...register("lastName", {
                                required: false,
                                // minLength: {
                                //     value: 2,
                                //     message: "Min length is 2"
                                // }
                            })}
                        />
                        <label className="did-floating-label">{'Last Name'}</label>
                    </div>
                </div>

                <div className="form-outline row gx-3">
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.profileTitle && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="text"
                            name="profileTitle"
                            placeholder=""
                            {...register("profileTitle", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                            })}
                        />
                        <label className="did-floating-label">{'Profile Title'}</label>
                    </div>
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.experience && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="text"
                            name="experience"
                            placeholder=""
                            min = "1"
                            max = "61"
                            {...register("experience", {
                                required: "Field is required",
                                minLength: {
                                    value: 1,
                                    message: "Min length is 1"
                                },
                            })}
                        />
                        <label className="did-floating-label">{'Experience In Years'}</label>
                    </div>
                </div>

                <div className={`form-outline did-floating-label-content ${errors.skillset && 'did-error-input'}`}>
                    <textarea
                        className="did-floating-input w-100 rounded-3 h-100 pt-3 text_area_border"
                        name="skillset"
                        placeholder=''
                        rows="5" cols="33"
                        {...register("skillset", {
                            required: "Field is required",
                            minLength: {
                                value: 2,
                                message: "Min length is 25"
                            },
                            maxlength: 3000,
                            minlength: 2
                        })}
                    />
                    <label className="did-floating-label">{'Skill Set'}</label>
                </div>

                <div className={`form-outline did-floating-label-content ${errors.file && 'did-error-input'} mt-4`}>
                    <input
                        className="did-floating-input" //mb-4 font_size_14
                        type="file"
                        name="file"
                        placeholder=""
                        {...register("file", {
                            required: "File is required",
                        })}
                        style={{ paddingTop: '7px' }}
                    />
                </div>

                <div className="border-top position-sticky bottom-0 footer mt-auto py-5 pb-3 pt-2 main_bg text-center d-flex justify-content-center">
                    <button type="button" className="mt-1 mb-1 fw-medium_imp">
                        {/* onClick={errorHandler} */}
                        Cancel
                        {/* {isSubmitting && <span>Submitting</span>}
                        {!isSubmitting && <span>Add Candidate Details</span>} */}
                    </button>
                </div>
            </form>
            <button className="d-none" id="buttonDismiss" data-bs-dismiss="modal"></button>
            {/* )} */}
        </div>
    );
}

export default CandidateDetails

// import React, { useState, useEffect } from "react";

// const EditJobOfferDetails = (props) => {
//     return (
//         <div className="pb-0 main_bg rounded-0">
//             <div className="overflow_y_on modal-dialog-scrollable pt-0 pb-5 ps-3 pe-3">
//                 <div className="text_v_h_center vh-60 font_size_24">
//                 </div>
//             </div>
//             <div className="border-top position-sticky bottom-0 footer mt-auto py-3 px-3 bg-white  d-flex align-item-center justify-content-center">
//                 {/* <div className="col-md-12 d-flex align-item-center justify-content-center"> */}
//                     <button
//                         type="close"
//                         className="mt-1 mb-1 fw-medium_imp btn btn-outline-primary bg-white btn_height_36 padding_top_6 pb-2 pe-4 ps-4 font_size_14 orange_color_border orange_color"
//                         data-bs-dismiss="modal"
//                     >
//                         Cancel
//                     </button>
//                 {/* </div> */}
//                 {/* <div className="col-md-3 d-flex align-item-center justify-content-center"> */}
//                     {/* <button
//                                     type="button"
//                                     className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 mt-0 me-3 padding_top_6"
//                                     data-bs-target="#editJobsDetails"
//                                     data-bs-toggle="modal"
//                                     onClick={() => { editJobHandler(page) }}
//                                 >
//                                     Edit
//                                 </button> */}
//                     {/* <button
//                         type="button"
//                         className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 mt-0 padding_top_6"
//                         data-bs-target="#deleteJobOfferDetailsModal"
//                         data-bs-toggle="modal"
//                     >
//                         Delete
//                     </button> */}
//                 {/* </div> */}
//             </div>
//         </div>
//     );
// }

// export default EditJobOfferDetails