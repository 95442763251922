import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { isMobile } from 'react-device-detect';
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import PhoneInput from 'react-phone-input-2';
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import "../../assets/css/login.css"
import authLayout from "../../hoc/authLayout";
import { addTour, deleteTour } from "../../redux/actions/tour";
import { typesOfEmployeeContractList, timeZonesList, masterDatacountryList, statesMasterList } from '../../Utils/masterData/dropDownJson'
import { citiesList } from '../../pages/employeeComponents/all-countries-cities-array'
import { postApi } from '../../services/apiCommonService'
import { createEmployee_api_url, post_company_logo_api_url } from '../../services/endPointsConstant'
import { isEmpty } from '../../Utils/utils'
import Multiselect from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};
const EmployeeSignUp = () => {
    const [formData, setFormData] = useState(false);
    const [typeOfTimeZoneValue, setTypeOfCompanyValue] = useState(null);
    const [typeOfallCountriesValue, settypeOfallCountriesValue] = useState(null);
    const [typeofallCitiesValue, settypeOfallCitiesValue] = useState(null)
    const [typeofallStates, settypeofallStates] = useState(null)
    const [typesOfEmployeeContractValue, setTypesOfEmployeeContractValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const [phoneValue, setPhoneValue] = useState('');
    const [countrySelectedValue, setCountrySelectedValue] = useState('IN');
    const [countryOptions, setCountryOptions] = useState(countryList().getData())
    const [selectedTimezone, setSelectedTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    )
    const [allTimeZones, setAllTimeZones] = useState([]);
    const [allCountriesList, setAllCountriesList] = useState([]);
    const [allCitiesList, setAllCitiesList] = useState([])
    const [allStatesList, setAllStatesList] = useState([])

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        reset,
        control,
        setValue,
        getValues
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        dispatch(addTour({ steps: customSteps }))
        reset({ country: 'IN' })
        const newArray = []
        timeZonesList?.map((item) => {
            newArray.push({
                label: item,
                value: item
            })
        })
        setAllTimeZones(newArray)
        const newArrayCountriesList = []
        masterDatacountryList?.map((item) => {
            newArrayCountriesList.push({
                label: item,
                value: item
            })
        })
        setAllCountriesList(newArrayCountriesList)

        const newArrayStatesList = []
        statesMasterList?.map((item) => {
            newArrayStatesList.push({
                label: item,
                value: item
            })
        })
        setAllStatesList(newArrayStatesList)

        const newArrayCitiesList = []
        citiesList?.map((item) => {
            newArrayCitiesList.push({
                label: item,
                value: item
            })
        })
        setAllCitiesList(newArrayCitiesList)
    }, [])

    const errorHandler = () => {
        if (errors.email) {
            toast.error("Check EmailId address, It should be example@xxxx.com")
        } else if (errors.password) {
            toast.error("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.")
        } else if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    useEffect(() => {
        if (countrySelectedValue) {
            setPhoneValue('')
        } else {
            setPhoneValue('')
        }
    }, [countrySelectedValue])

    const onSubmit = async (data) => {
        setFormData(data);
        const apiReqBody = {
            name: data?.firstName + ' ' + data?.lastName,
            emailId: data?.businessEmailId,
            dob: new Date(),
            phoneNumber: data?.phoneNumber,
            location: data?.location,
            joiningDate: new Date(),
            userEmail: data?.businessEmailId,
            country: data?.country,
            firstname: data?.firstName,
            lastname: data?.lastName
            // createdBy: "admin",
        }
        const apiResponse = await postApi(createEmployee_api_url, apiReqBody)
        if (apiResponse?.data?.message === 'phone number already exist') {
            toast.error(apiResponse?.data?.message)
        } else if (apiResponse?.data?.message === "email id already exist") {
            toast.error(apiResponse?.data?.message)
        }
        else {
            if (apiResponse?.data) {
                window.sessionStorage.setItem('singup', JSON.stringify(apiResponse))
                toast.success("Successfully singedup, Please Activate your account !!")
                navigate(`/employee-signup-success`)
            } else {
                toast.error(apiResponse?.response?.data.message)
            }
        }
    };

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['country', 'phoneNumber', 'typeOfTimeZone', 'numberOfEmployees', 'contractType', 'typeOfallCountries', 'city', 'allStates'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    return (
        <>
            <div className="form-holder login-form vh-100 rounded-0 overflow-auto pb-0 pt-0">
                <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
                    <h1 className="text-center fw-normal mb-4 mt-0 me-3 my-1-step">Sign up for</h1>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="form-outline row gx-3">
                        <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.firstName && 'did-error-input'}`}>
                            <input
                                type="text"
                                name="firstName"
                                placeholder=""
                                {...register("firstName", {
                                    required: "Field is required",
                                    minLength: {
                                        value: 2,
                                        message: "Min length is 2"
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-\s]+$/,
                                        message: "Special characters are not allowed"
                                    }
                                })}
                                className="did-floating-input"
                            />
                            <label className="did-floating-label ms-2 requiredTrue">{'Firstname'}</label>
                            {/* {errors.firstName && <p className="error">{errors.firstName.message}</p>} */}
                        </div>
                        <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.lastName && 'did-error-input'}`}>
                            <input
                                type="text"
                                name="lastName"
                                placeholder=""
                                {...register("lastName", {
                                    required: false,
                                    // minLength: {
                                    //     value: 2,
                                    //     message: "Min length is 2"
                                    // },
                                    // pattern: {
                                    //     value: /^[a-zA-Z0-9._-\s]+$/,
                                    //     message: "Special characters are not allowed"
                                    // }
                                })}
                                className="did-floating-input"
                            />
                            <label className="did-floating-label ms-2">{'Lastname'}</label>
                            {/* {errors.lastName && <p className="error">{errors.lastName.message}</p>} */}
                        </div>
                    </div>
                    <div className={`form-outline did-floating-label-content mt-1 ${errors.businessEmailId && 'did-error-input'}`}>
                        <input
                            type="email"
                            name="businessEmailId"
                            placeholder=""
                            {...register("businessEmailId", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                                pattern: {
                                    value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!yes)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                    message: "Invalid EmailId address, It should be example@xxxx.com"
                                }
                            })}
                            className="did-floating-input"
                        />
                        <label className="did-floating-label requiredTrue">{'Email ID'}</label>
                        {/* {errors.businessEmailId && <p className="error">{errors.businessEmailId.message}</p>} */}
                    </div>

                    <div className="form-outline row gx-3">
                        <div className={`col-md-6 marginbottom_int did-floating-label-content'}`}>
                            {/* <div className="form-outline row did-floating-label-content"> */}
                            {/* <div className={`marginbottom_int did-floating-label-content'}`}> */}
                            <Controller
                                name="country"
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div
                                        // className="countryFlag"
                                        style={{ display: "flex", alignItems: "center", width: "100%" }}
                                    >
                                        <ReactCountryFlag
                                            countryCode={
                                                countrySelectedValue ? countrySelectedValue : ""
                                            }
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            title={countrySelectedValue ? countrySelectedValue : ""}
                                            className={`countryFlag ${errors.country && 'countryFlag_error'}`}
                                        // style={{ border: '1px solid red' }}
                                        />
                                        <div
                                            style={{ color: "black", width: "100%" }}
                                        >
                                            <Select
                                                isSearchable={true}
                                                options={countryOptions}
                                                value={countryOptions.find((c) => c.value === value)}
                                                // onChange={this.changeHandler}
                                                // value={typeOfCompanyList.find((c) => c.value === value)}
                                                onChange={(val) => { onChange(val?.value); setCountrySelectedValue(val?.value) }}
                                                defaultValue={countryOptions.find((c) => c.value === countrySelectedValue)}
                                                placeholder=""
                                                className="basic-single my-react-select"
                                                // classNamePrefix="select"
                                                components={{
                                                    ValueContainer: CustomValueContainer
                                                }}
                                                onMenuClose={() => { value && setIsSelected({ ...isSelected, 'country': true }) }}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        borderRadius: '2px',
                                                        ...errorBorderForSelectBox(errors.country && 'country'),
                                                        minHeight: !countrySelectedValue ? '40px' : '38px'
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                        marginTop: 2,
                                                    }),
                                                    valueContainer: (provided, state) => ({
                                                        ...provided,
                                                        overflow: "visible"
                                                    }),
                                                    placeholder: (provided, state) => ({
                                                        ...provided,
                                                        position: "absolute",
                                                        top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                        background: "white",
                                                        transition: "top 0.1s, font-size 0.1s",
                                                        fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                        fontWeight: 100,
                                                        padding: '0.3px 8.5px',
                                                        borderRadius: '8px',
                                                        color: 'rgba(6, 44, 80, 0.7);',
                                                        border: isSelected['country'] ? '1px solid #cccccc' : ''
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        fontSize: 14,
                                                        color: 'rgba(32, 53, 98, 1)',
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                rules={{ required: "Field is required" }}
                            />
                            {/* {errors.country && <p className="error">{errors.country.message}</p>} */}
                            {/* </div> */}
                        </div>
                        <div className="col-md-6 marginbottom_int did-floating-label-content">
                            {/* <div className="form-outline row did-floating-label-content"> */}
                            {/* <div className="marginbottom_int did-floating-label-content"> */}
                            <Controller
                                render={(props) => (
                                    <PhoneInput
                                        placeholder="Phone number"
                                        country={countrySelectedValue?.toLowerCase()}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        onChange={(e) => {
                                            props.field.onChange(e); setPhoneValue(e)
                                        }}
                                        value={props.field.value}
                                        inputClass={errors.phoneNumber ? 'error_telphone' : ''}
                                        containerClass={errors.phoneNumber ? 'error_telphone_container' : ''}
                                        countryCodeEditable={false}
                                    />
                                )}
                                defaultValue=""
                                name="phoneNumber"
                                control={control}
                                rules={{ required: "Field is required" }}
                            />
                            {/* {errors.phoneNumber && <p className="error">{errors.phoneNumber.message}</p>} */}
                            {/* </div> */}
                        </div>
                    </div>
                    {/* <div className={`form-outline mt-2 col-md-12 marginbottom_int did-floating-label-content ${errors.candidateExperience && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="number"
                            name="candidateExperience"
                            placeholder=''
                            min="1" max="61"
                            {...register("candidateExperience", {
                                required: "Field is required",
                                minLength: {
                                    value: 1,
                                    message: "Min length is 1"
                                },
                                // onChange: (e)=>{
                                //     resetDoc({...getValuesDoc(), candidateExperience: `${e?.target?.value} ${e?.target?.value === 1 ? 'Year' : 'Years'}`})
                                // }
                            })}
                        />
                        <label className="did-floating-label requiredTrue">{'Experience in years'}</label>
                    </div> */}

                    <div className="form-outline">
                        <Controller
                            name="typeOfTimeZone"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={allTimeZones}
                                    value={allTimeZones.find((c) => c.value === value)}
                                    onChange={(val) => onChange(val.value)}
                                    defaultValue={allTimeZones.find((c) => c.value === typeOfTimeZoneValue)}
                                    isSearchable={true}
                                    placeholder="Time zone"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'typeOfTimeZone': true }) }}
                                    styles={{
                                        // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.typeOfTimeZone && 'typeOfTimeZone'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            marginTop: 22,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['typeOfTimeZone'] ? '1px solid #cccccc' : ''
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                            ...styles,
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            //   cursor: isDisabled ? 'not-allowed' : 'default',
                                        })
                                    }} />
                            )}
                            rules={{ required: "Field is required" }}
                        />
                        {/* <label className="did-floating-label">Number of employees</label> */}
                        {/* {errors.typeOfTimeZone && <p className="error">{errors.typeOfTimeZone.message}</p>} */}
                    </div>

                    <div className="form-outline row gx-3 mt-4">
                        {/* <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.city && 'did-error-input'}`}>
                            <input
                                type="text"
                                name="city"
                                placeholder=""
                                {...register("city", {
                                    required: "Field is required",
                                    minLength: {
                                        value: 2,
                                        message: "Min length is 2"
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-\s]+$/,
                                        message: "Special characters are not allowed"
                                    }
                                })}
                                className="did-floating-input"
                            />
                            <label className="did-floating-label ms-2 requiredTrue">{'City'}</label>
                        </div> */}

                        <div className="col-md-6">
                            <Controller
                                name="city"
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Select
                                        // isClearable
                                        options={allCitiesList}
                                        value={allCitiesList.find((c) => c.value === value)}
                                        onChange={(val) => onChange(val.value)}
                                        defaultValue={allCitiesList.find((c) => c.value === typeofallCitiesValue)}
                                        isSearchable={true}
                                        placeholder="City"
                                        className="basic-single"
                                        classNamePrefix="select"
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        onMenuClose={() => { value && setIsSelected({ ...isSelected, 'city': true }) }}
                                        styles={{
                                            // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                            control: (styles) => ({
                                                ...styles,
                                                ...errorBorderForSelectBox(errors.city && 'city'),
                                            }),
                                            container: (provided, state) => ({
                                                ...provided,
                                                // marginTop: 22,
                                            }),
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                overflow: "visible"
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                background: "white",
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                fontWeight: 100,
                                                padding: '0.3px 8.5px',
                                                borderRadius: '8px',
                                                color: 'rgba(6, 44, 80, 0.7);',
                                                border: isSelected['city'] ? '1px solid #cccccc' : ''
                                            }),
                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                ...styles,
                                                color: 'rgba(6, 44, 80, 0.7);',
                                                //   cursor: isDisabled ? 'not-allowed' : 'default',
                                            })
                                        }} />
                                )}
                                rules={{ required: "Field is required" }}
                            />
                        </div>

                        <div className="col-md-6">
                            <Controller
                                name="typeOfallCountries"
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Select
                                        // isClearable
                                        options={allCountriesList}
                                        value={allCountriesList.find((c) => c.value === value)}
                                        onChange={(val) => onChange(val.value)}
                                        defaultValue={allCountriesList.find((c) => c.value === typeOfallCountriesValue)}
                                        isSearchable={true}
                                        placeholder="Country"
                                        className="basic-single"
                                        classNamePrefix="select"
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        onMenuClose={() => { value && setIsSelected({ ...isSelected, 'typeOfallCountries': true }) }}
                                        styles={{
                                            // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                            control: (styles) => ({
                                                ...styles,
                                                ...errorBorderForSelectBox(errors.typeOfallCountries && 'typeOfallCountries'),
                                            }),
                                            container: (provided, state) => ({
                                                ...provided,
                                                // marginTop: 22,
                                            }),
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                overflow: "visible"
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                background: "white",
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                fontWeight: 100,
                                                padding: '0.3px 8.5px',
                                                borderRadius: '8px',
                                                color: 'rgba(6, 44, 80, 0.7);',
                                                border: isSelected['typeOfallCountries'] ? '1px solid #cccccc' : ''
                                            }),
                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                ...styles,
                                                color: 'rgba(6, 44, 80, 0.7);',
                                                //   cursor: isDisabled ? 'not-allowed' : 'default',
                                            })
                                        }} />
                                )}
                                rules={{ required: "Field is required" }}
                            />

                        </div>
                        <div className="form-outline mt-4">
                                <Controller
                                    name="allStates"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            // isClearable
                                            options={allStatesList}
                                            value={allStatesList.find((c) => c.value === value)}
                                            onChange={(val) => onChange(val.value)}
                                            defaultValue={allStatesList.find((c) => c.value === typeofallStates)}
                                            isSearchable={true}
                                            placeholder="All States"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'allStates': true }) }}
                                            styles={{
                                                // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                                control: (styles) => ({
                                                    ...styles,
                                                    ...errorBorderForSelectBox(errors.allStates && 'allStates'),
                                                }),
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    // marginTop: 22,
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    overflow: "visible"
                                                }),
                                                placeholder: (provided, state) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                    background: "white",
                                                    transition: "top 0.1s, font-size 0.1s",
                                                    fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                    fontWeight: 100,
                                                    padding: '0.3px 8.5px',
                                                    borderRadius: '8px',
                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                    border: isSelected['allStates'] ? '1px solid #cccccc' : ''
                                                }),
                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                    ...styles,
                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                    //   cursor: isDisabled ? 'not-allowed' : 'default',
                                                })
                                            }} />
                                    )}
                                    rules={{ required: "Field is required" }}
                                />
                        </div>
                    </div>

                    <button type="submit" disabled={isSubmitting} className="w-100 rounded-2 mt-4 mb-1_5 my-2-step" onClick={errorHandler}>
                        {isSubmitting && <span>Submitting</span>}
                        {!isSubmitting && <span>Sign up now</span>}
                    </button>

                    <div className="d-flex justify-content-center align-items-center">
                        <span className="d-flex text-center text-lg-start justify-content-center align-items-center">
                            {!isMobile ?
                                <p className="small fw-bold">Already have an account?
                                    <Link to="/employee-login" className="fontColor me-2 font_size_16 fw-700"><strong>  Login Here</strong></Link>
                                </p> :
                                <>
                                    <p className="small fw-bold">Already have an account?</p>
                                    <Link to="/employee-login" className="fontColor me-2 font_size_16 fw-700"><strong>Login Here</strong></Link>
                                </>
                            }
                        </span>
                    </div>

                </form>
            </div>
        </>
    );
}

export default authLayout(EmployeeSignUp);

const customSteps = [
    {
        selector: '.my-1-step',
        content: 'singup form for user!  ',
    },
    {
        selector: '.my-2-step',
        content: 'button click to signup!  ',
    }
]