import React, { useState, useLayoutEffect, useEffect } from "react";
import { toast } from 'react-toastify';
import swal from "sweetalert";
// import 'bootstrap/dist/css/bootstrap.css';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlayLoader from 'react-loading-overlay';
import { useForm, Controller } from "react-hook-form";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import { downloadPDF } from '../../../services/axios-common'
import storePersist from '../../../Utils/storePersist'
import { isEmpty } from '../../../Utils/utils'
import { emptyJobList, PageSize } from '../../../Utils/masterData/staticContent'
import { postApi, deleteApiWithBody, getAllApi } from '../../../services/apiCommonService'
import { create_company_url, post_company_logo_api_url, delete_user_url, get_all_account_managers, get_all_users_url, upload_company_files, upload_candidate_file, create_candidate_details, get_all_candidates } from '../../../services/endPointsConstant'
import { returnArryOfObjects } from '../../../Utils/utils'
import CustomSingleSelect from '../../../components/CustomSelectComponent';
import Pagination from '../../../common/pagination/Pagination';
import adminLayout from "../../../hoc/adminLayout";

const masterData = [
    { label: 'Job Type', response: 'job_type', value: 'Remote', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', value: '1234', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', value: '5-10 years', img: 'Y_O_Exp.svg' },
]

const AllLinksPage = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const d = new Date()
    const newDate = d.toISOString().substring(0, 10);
    const { loading } = useSelector((state) => state.loaderReducer);
    const { viewSelectedJobDetails, jobList, selectedRecordDataFun, isOpenViewResume, modalPopUpCancel } = props
    const [cmpyDocumentValue, setCmpyDocumentValue] = useState(null);
    const [current, setCurrent] = useState(viewSelectedJobDetails?.idxValue);
    const [formDataDoc, setFormDataDoc] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [allCandidatesList, setAllCandidatesList] = useState([])
    const [allCandidatesOldList, setAllCandidatesOldList] = useState([])
    const [selectedCompanyDetailsObject, setSelectedCompanyDetailsObject] = useState([]);
    const [selectedCompanyDetailsRowList, setSelectedCompanyDetailsRowList] = useState([]);
    const [selectedJobsObjects, setSelectedJobsObjects] = useState([]);
    const [newEditPoup, setNewEditPoup] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(PageSize);
    const [keywordName, setKeywordName] = useState('');
    const [ascOrder, setAscOrder] = useState(true);
    const [keywordFilter, setKeywordNameFilter] = useState('');
    const [oldAccountManagerList, setOldAccountManagerList] = useState([]);

    const {
        register: registerDoc,
        formState: {
            errors: errorsDoc,
            isSubmitting: isSubmittingDoc
        },
        handleSubmit: handleSubmitDoc,
        control: controlDoc,
        reset: resetDoc,
        getValues: getValuesDoc
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        void getAccountManagerListApi()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (keywordFilter !== '') {
                const results = allCandidatesList.filter((jobData) => {
                    return jobData?.role?.toLowerCase().startsWith(keywordFilter.toLowerCase()) ||
                        jobData?.company_name?.toLowerCase().startsWith(keywordFilter.toLowerCase()) ||
                        jobData?.buisness_email_id?.toLowerCase().startsWith(keywordFilter.toLowerCase()) ||
                        jobData?.contact_person_name?.toLowerCase().startsWith(keywordFilter.toLowerCase()) ||
                        jobData?.phone_number?.startsWith(keywordFilter.toLowerCase())
                });
                setAllCandidatesList(results);
            }
            dispatch(deleteLoader());
        }, 3000)
    }, [keywordFilter])

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.value;
        const lastPageIndex = firstPageIndex + pageSize?.value;
        return allCandidatesList.slice(firstPageIndex, lastPageIndex);
    };
    const stopLoader = () => {
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(allCandidatesList, ids, '_id')
        setSelectedCompanyDetailsObject(finalUsersData)
    }

    const onChangeHandler = (event) => {
        setPageSize(event)
    }

    const handleUserSelect = (jobData) => {
        if (selectedCompanyDetailsRowList.includes(jobData._id)) {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData._id));
            callUsersData(selectedCompanyDetailsRowList.filter(_id => _id !== jobData._id))
        } else {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            callUsersData([...selectedCompanyDetailsRowList, jobData._id])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedCompanyDetailsRowList.includes(jobData._id);
        return isUserSelectedVal
    };

    const selectAll = () => {
        setSelectedCompanyDetailsRowList([]);
        if (!isAllSelected) {
            allCandidatesList.forEach(jobData => {
                setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            });
            callUsersData(allCandidatesList.map((item) => item._id))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const getAccountManagerListApi = async () => {
        dispatch(addLoader());
        const apiReqBody = {}
        const apiResponse = await postApi(get_all_account_managers, apiReqBody)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            setOldAccountManagerList(apiResponseData)
            void getCandidatesApiCallFun(apiResponseData)
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const getCandidatesApiCallFun = async (apiResponseDataOldManger) => {
        dispatch(addLoader());
        const apiReqBody = { jobId: viewSelectedJobDetails?._id }
        const apiResponse = await getAllApi(get_all_users_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            const mainList = []
            apiResponseData?.map(async (item) => {
                const finalList = apiResponseDataOldManger?.filter(({ actManagerId }) => item?.CompanyDetails[0]?.actManagerId?.includes(actManagerId))
                item.totalMangers = finalList?.map(({ emailId }) => emailId)
                mainList.push(item)
            })
            setAllCandidatesList(mainList)
            setAllCandidatesOldList(mainList)
            // toast.success("Retrived all Candidates List Successfully !!")
            dispatch(deleteLoader());
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    };

    const downloadPdf = (downloadPath, name) => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(downloadPath, name)
    }

    const deleteFun = () => {
        swal({
            title: "Delete !!",
            text: "Are you sure you want to Delete Candidate ?",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Candidate deleted successfully !',
                    icon: 'success'
                }).then(async function () {
                    dispatch(addLoader());
                    const apiReqBody = {
                        ids: selectedCompanyDetailsObject && selectedCompanyDetailsObject.map((item) => item._id)
                    }
                    const apiResponse = await postApi(delete_user_url, apiReqBody)
                    if (apiResponse?.data) {
                        toast.success('user deleted successfully!!')
                        // document.getElementById("ProvidedcandidatesListTableModalIdDismiss").click();
                        setSelectedCompanyDetailsRowList([]);
                        void getCandidatesApiCallFun()
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                        dispatch(deleteLoader());
                    }
                });
            } else {
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const filterTextSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = allCandidatesList.filter((jobData) => {
                return jobData?.role?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.company_name?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.buisness_email_id?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.contact_person_name?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.phone_number?.startsWith(keyword.toLowerCase())
            });
            setAllCandidatesList(results);
        } else {
            setAllCandidatesList(allCandidatesOldList);
        }
        setKeywordName(keyword);
    };

    const filterTextSearchTemp = (e) => {
        // dispatch(addLoader());
        // const keyword = e.target.value;
        // if (e?.target?.value === keywordFilter) {
        //     setTimeout(() => {
        //         dispatch(deleteLoader());
        //     }, 2000)
        // } else {
        //     setAllCandidatesList(allCandidatesOldList);
        // }
        // setKeywordNameFilter(keyword);
        dispatch(addLoader());
        const keyword = e.target.value;
        if (e?.target?.value === keywordFilter) {
            setTimeout(() => {
                const results = allCandidatesOldList.filter((jobData) => {
                    return jobData?.role?.toLowerCase().startsWith(keyword.toLowerCase())
                });
                setAllCandidatesList(results);
                dispatch(deleteLoader());
            }, 2000)
        } else {
            if (keyword !== '') {
                const results = allCandidatesOldList.filter((jobData) => {
                    return jobData?.role?.toLowerCase().startsWith(keyword.toLowerCase())
                });
                setAllCandidatesList(results);
            } else {
                setAllCandidatesList(allCandidatesOldList);
            }
        }
        setKeywordNameFilter(keyword);
    };

    const sortFun = (key) => {
        dispatch(addLoader());
        key = key === 'Role' ? 'role' : key
        const final = allCandidatesList?.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            if (ascOrder) {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            } else {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
        setAllCandidatesList(final);
        setAscOrder(!ascOrder)
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 2000)
    }

    return (
        <>
            <LoadingOverlayLoader
                active={loading}
                spinner={true}
                text="Loading your content..."
                styles={{
                    overlay: (base) => ({
                        ...base,
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        zIndex: 9999
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: '70px',
                        '& svg circle': {
                            stroke: '#FE5244',//rgba(255, 0, 0, 0.5)
                        }
                    }),
                    content: (base) => ({
                        ...base,
                        top: "40%",
                        left: '45%',
                        color: '#FE5244',
                    })
                }}
            >
                <h3 className="text-center mb-5 mt-4 font_dark_color_underline">Remote72 website main links</h3>
                <div className=" pt-0 pb-5 ms-5 me-5 border">
                    <h6 className=" text-center p-2">Admin signup :
                        <a className="font_dark_color_underline font_dark_color ms-3" href="https://remote72.co/admin-signup/665e00c8ui908bee10e638df" target="_blank">Click here</a>
                    </h6>
                    <h6 className=" text-center p-2">Admin login :
                        <a className="font_dark_color_underline font_dark_color ms-3" href="https://remote72.co/admin-login/665e00c8ae3f8bee10e638df" target="_blank"> Click here</a>
                    </h6>
                    <h6 className=" text-center p-2">Company signup :
                        <a className="font_dark_color_underline font_dark_color ms-3" href="https://remote72.co/signup" target="_blank">Click here</a>
                    </h6>
                    <h6 className=" text-center p-2">Company login :
                        <a className="font_dark_color_underline font_dark_color ms-3" href="https://remote72.co/login" target="_blank">Click here</a>
                    </h6>
                    <h6 className=" text-center p-2">Account manager login :
                        <a className="font_dark_color_underline font_dark_color ms-3" href="https://remote72.co/act-manager-login" target="_blank">Click here</a>
                    </h6>
                    <h6 className=" text-center p-2">Employee signup :
                        <a className="font_dark_color_underline font_dark_color ms-3" href="https://remote72.co/employee-signup" target="_blank">Click here</a>
                    </h6>
                    <h6 className=" text-center p-2">Employee login :
                        <a className="font_dark_color_underline font_dark_color ms-3" href="https://remote72.co/employee-login" target="_blank">Click here</a>
                    </h6>
                </div>
            </LoadingOverlayLoader>
        </>
    );
}

export default AllLinksPage;
