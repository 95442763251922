export const sign_up_api_url = 'api/v1/user/signup';
export const sign_in_api_url = 'api/v1/user/signin';
export const forgot_password_api_url = 'api/v1/user/forgotpassword';
export const change_password_api_url = 'api/v1/user/changeNewPassword';
export const create_password_api_url = 'api/v1/user/createpassword';
export const create_job_api_url = 'api/v1/job/createjob';
export const edit_job_api_url = 'api/v1/job/editjob'
export const get_job_list_api_url = 'api/v1/job/getjoblisting';
export const delete_job_list_api_url = 'api/v1/job/deletejob';
export const create_offer_api_url = 'api/v1/offer/createOffer';
export const get_offer_api_url = 'api/v1/offer/getOffer';
export const get_Offer_by_candidate_api_url = 'api/v1/offer/getOfferByCandidate';
export const get_Offer_by_job_api_url = 'api/v1/offer/getOfferByJob';
export const edit_Offer_api_url = 'api/v1/offer/editOffer';
export const delete_offer_api_url = 'api/v1/offer/deleteOffer';
export const get_all_users_by_role = 'api/v1/user/getUserByRole';
export const upload_candidate_file = 'api/v1/candidate/upload';
export const upload_offer_sow_file = 'api/v1/offer/uploadSow';
export const get_offer_report = 'api/v1/offer/getOfferReport';
export const get_sow_link = 'api/v1/offer/getSowLink';
export const create_offer_details = 'api/v1/offer/createOffer';
export const get_all_candidates = 'api/v1/candidate/getAllCandidate';
export const create_candidate_details = 'api/v1/candidate/createCandidate';
export const api_getOfferByCandidate = 'api/v1/offer/getOfferByCandidate';
export const candidates_notice_report_url = 'api/v1/offer/getNoticeReportDetails';
export const candidates_onboarded_report_url = 'api/v1/offer/getonboardedReportDetails';
export const candidates_accepted_report_url = 'api/v1/offer/getacceptedReportDetails';
export const candidates_rejected_report_url = 'api/v1/offer/getrejectedReportDetails';
export const candidates_inprogress_report_url = 'api/v1/offer/getonboardInproReportDetails';
export const candidates_resigned_report_url = 'api/v1/offer/getresignedReportDetails';
export const candidates_interviewed_report_url = 'api/v1/offer/getinterviewedReportDetails';
export const create_company_url = 'api/v1/company/createCompany';
export const get_all_companies_list_api_url = 'api/v1/company/getAllcompany';
export const post_company_logo_api_url = 'api/v1/company/uploadLogo';
export const edit_company_details = 'api/v1/company/editCompany';
export const delete_company_details = 'api/v1/company/deleteCompanyId';
export const get_company_id = 'api/v1/company/getCompanyById';
export const get_all_account_managers = 'api/v1/actMgr/getAllacctManager';
export const getAllacctManagerListApiCall = 'api/v1/actMgr/getAllacctManagerList';
export const create_account_managers = 'api/v1/actMgr/createActManager';
export const delete_candidates_details_api_url = 'api/v1/candidate/deleteCandidate'
export const delete_manager_list_api_url = 'api/v1/actMgr/deleteAcctManagerId'
export const assign_acct_mgr_api_url = 'api/v1/company/assignAcctMgr'
export const get_Shortlisted_api_url = 'api/v1/offer/getShortlisted'
export const get_comments_api_url = 'api/v1/offer/getComments'
export const get_admin_all_report = 'api/v1/company/getCompleteReport'
export const get_acct_manager_id = 'api/v1/actMgr/getAcctManagerById'
export const post_comment_api_url = 'api/v1/offer/createComment'
export const get_comment_api_url = 'api/v1/offer/getComments'
export const get_jobs_by_company_id = 'api/v1/job/getAllJobByCompany'
export const edit_offfer_details = 'api/v1/offer/editOffer'
export const upload_company_files = 'api/v1/company/uploadCompanyFiles'
export const edit_Acct_Manager = 'api/v1/actMgr/editAcctManager'
export const get_CandidateOf_ActMgr = 'api/v1/actMgr/getCandidateOfActMgr'
export const post_acct_manager_companies = 'api/v1/actMgr/getCompanyOfActMgr'
export const company_getFileLink = 'api/v1/company/getFileLink'
export const candidate_details_By_Id = 'api/v1/candidate/getCandidateById'
export const reschedule_Interview = 'api/v1/offer/rescheduleInterview'
export const exclude_all_candidates = 'api/v1/offer/rejectResume'
export const get_all_comment_logs_offer = 'api/v1/offer/getComments'
export const get_all_notifications = 'api/v1/notify/getAllnotification'
export const delete_notification_by_id = 'api/v1/notify/deleteNotifications'
export const offer_getShortlisted = 'api/v1/offer/getShortlisted'
export const reset_password_details = 'api/v1/user/resetPassword'
export const request_for_resumes = 'api/v1/offer/requestForResume'
export const unassignAcctMgr_url = 'api/v1/company/unassignAcctMgr'
export const delete_user_url = 'api/v1/user/delete-user'
export const get_verify_all_user_url = 'api/v1/user/getAllVerifyUsers'
export const get_all_users_url = 'api/v1/user/getAllUsers'
export const get_Company_ActMgr_url = 'api/v1/company/getCompanyActMgr'
export const change_New_Password = 'api/v1/user/changeNewPassword'
export const remove_Document_url ='api/v1/company/removeDocument'
export const candidate_edit_url ='api/v1/candidate/editCandidate'
export const existed_job_data_url = 'api/v1/job/getAllJobDetailByJobId'
export const get_Today_Interview_url ='api/v1/offer/getTodayInterview'
export const check_User_Email_url = 'api/v1/user/checkUserEmail'
export const check_User_Phone_url = 'api/v1/user/checkUserPhone'
export const approved_by_admin_url = 'api/v1/user/approved-by-admin'
export const rejected_by_admin_url = 'api/v1/user/rejected-by-admin'
export const getAllRejectedUsers_url = 'api/v1/user/getAllRejectedUsers'
export const createQueryTicket_url = 'api/v1/user/createQueryTicket'
export const updateQueryTickets_url = 'api/v1/user/updateQueryTickets'
export const usergetAllQueryTickets_url = 'api/v1/user/getAllQueryTickets'
export const userdeleteQueryTickets_url = 'api/v1/user/deleteTickets'
export const createEmployee_api_url = 'api/v1/employee/createEmployee'
export const getAllEmployeesList_api_url = 'api/v1/employee/getAllEmployeesList'
export const createEmployeeProfile_api_url = 'api/v1/employee/createEmployeeProfile'
export const uploadEmployeeResume_api_url = 'api/v1/employee/uploadEmployeeResume'
export const all_employee_jobs_api_url = 'api/v1/job/getAllOpenJob'
export const all_get_match_employee_jobs_api_url = 'api/v1/employee/getMatchingJobs'
export const employee_update_profile_api_url = 'api/v1/employee/editEmployee'
export const employee_download_resume_api_url ='api/v1/employee/downloadEmployeeResume'
export const save_job_employee = 'api/v1/employee/saveJobs'
export const get_saved_jobs_list = 'api/v1/employee/getSavedJobs'
export const apply_job_employee = 'api/v1/employee/createJobApplication'
export const get_all_applied_list = 'api/v1/employee/getOpenApplicationOfEmployee'