import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../hoc/adminLayout";
import Pagination from '../../common/pagination/Pagination';
import RangeDatePicker from '../../components/RangeDatePicker';
import data from '../../common/pagination/examples/data/mock-data.json';
import { returnArryOfObjects, randStr } from '../../Utils/utils'
import { typesOfEmployeeContractList } from '../../Utils/masterData/dropDownJson'
import { emptyJobList, PageSize } from '../../Utils/masterData/staticContent'
import { getAllApi, deleteApiWithBody, postApi } from '../../services/apiCommonService'
import { get_job_list_api_url, get_jobs_by_company_id, delete_job_list_api_url, save_job_employee, apply_job_employee } from '../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import { addSelectedJobDetails, deleteSelectedJobDetails } from "../../redux/actions/jobDetails";
import CustomSingleSelect from '../../components/CustomSelectComponent';
import storePersist from '../../Utils/storePersist';
import Chipcomponent from '../../components/ChipComponent';

const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
};

const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
    if (user) {
        return `${user[0]?.toUpperCase()}${user[user?.length - 1]?.toUpperCase()}`
    }
}

const setValue = (functionFor) => {
    return (e) => {
        const value = parseInt(e.target.value);
        functionFor(value);
    }
};

const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
}

const EmpJobDetailsViewPage = (props) => {
    const location = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [jobList, setJobList] = useState([]);
    const [oldJobList, setOldJobList] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedJobsObjects, setSelectedJobsObjects] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [viewSelectedJobDetails, setViewSelectedJobDetails] = useState('')
    const [modalPopUpCancel, setModalPpopUpCancel] = useState(false);
    const [candidateModalPopUpCancel, setCandidateModalPopUpCancel] = useState(false);
    const [pageSize, setPageSize] = useState(PageSize);
    const { role, user, employeeDataAll } = storePersist.get('userData')
    const { _id } = employeeDataAll && employeeDataAll || {}
    const { jobDetails } = useSelector((state) => state.employeeJobsReducer)

    const [range, setRange] = React.useState(10);
    const [saturation, setSaturation] = React.useState(50);
    const [lightness, setLightness] = React.useState(50);
    const [theme, setTheme] = React.useState('Light');

    const saturationRange = getRange(saturation, range);
    const lightnessRange = getRange(lightness, range);

    const userName = `${'Pandu'} ${'Pandu'}`;
    const color = generateColorHsl(userName, saturationRange, lightnessRange);
    const initials = getInitials('Pandu');

    useEffect(() => {
        // void getJobListApi()
    }, []);

    // const getJobListApi = async () => {
    //     dispatch(addLoader());
    //     // const apiResponse = await getAllApi(get_job_list_api_url)
    //     const companyDetailsStorage = storePersist.get('companyDetails')
    //     const apiReqBody = { companyId: companyDetailsStorage && companyDetailsStorage?._id }
    //     const apiResponse = await postApi(get_jobs_by_company_id, apiReqBody)
    //     if (apiResponse?.data?.Data) {
    //         const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
    //         setJobList(apiResponseData)
    //         setOldJobList(apiResponseData)
    //         // toast.success("Retrived all Job List Successfully !!")
    //         setTimeout(() => {
    //             dispatch(deleteLoader());
    //         }, 2500);
    //     } else {
    //         toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
    //         dispatch(deleteLoader());
    //     }
    // }

    const SaveJobApi = async () => {
        dispatch(addLoader());
        const apiReqBody = { employeeId: _id, companyId: jobDetails?.companyId, jobId: jobDetails?._id }
        const apiResponse = await postApi(save_job_employee, apiReqBody)
        if (apiResponse?.data?.Data) {
            toast.success("Job Saved Successfully !!")
            navigate('/employee-jobs-list')
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const applyJobApiCall = async (itemVal) => {
        dispatch(addLoader());
        const apiReqBody = { employeeId: _id, jobId : itemVal?._id }
        const apiResponse = await postApi(apply_job_employee, apiReqBody)
        if (apiResponse?.data?.Data) {
            toast.success("Job Applied Successfully !!")
            navigate('/employee-jobs-list')
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    return <>
        <div className="p-1 d-flex justify-content-between align-items-center">
            <div>
                <h6 className="font_color_imp font_size_16 fw-bolded">Job Details</h6>
            </div>
            <div>

                <button
                    type="button"
                    className={`btn btn-outline-primary fw-medium btn_border btn_height_29 d-flex justify-content-between align-items-center gap-2`}
                    style={{ padding: '1px 4px!important' }}
                    onClick={() => { navigate('/employee-jobs-list') }}
                >
                    <div ><i className='fa fa-remove'> </i></div>
                    <div >close</div>
                </button>
            </div>
        </div>
        <div className="row d-flex justify-content-center align-items-start gx-3">
            {/* Left side card */}
            <div className="col-md-4 width_col_30">
                {/* card-1 */}
                <div className="my-3 p-3 pb-3 mt-2 bg-body rounded shadow-sm rounded_20">
                    {/* d-flex justify-content-between align-items-center  */}
                    <div className="row bd-example height_job_details_int_card_left px-3">
                        <div className='text-center my-2 p-0 col-md-12 d-flex justify-content-start align-items-start'>
                            <div className='col-md-2'>
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{getInitials(jobDetails?.CompanyDetails[0]?.companyName)}</div>
                                </div>
                            </div>
                            <div className='col-md-10 font_size_13'>
                                <div className="d-flex justify-content-start align-items-start">
                                    <h6 className="font_color_imp font_size_17 ps-3 fw-bold">infosys</h6>
                                    <span className="d-flex justify-content-start align-items-center ps-5 pe-1">
                                        <img className="img_w_h_20" alt="Alt content" src={require('../../assets/images/bolt_rect_svg.svg').default} />
                                        <span className='font_color_ornage ps-2'>{'Actively hiring'}</span>
                                    </span>
                                </div>
                                <div className="float-start">
                                    <div className="font_color_imp font_size_13 ps-3 fw-bold">{jobDetails?.job_type}</div>
                                    <div className="font_color_imp font_size_13 ps-3 fw-bold float-start mb-1">Location : india</div>
                                </div>
                                <div className="col-md-12 border-bottom row"></div>
                                <div className="border-bottom d-block row float-start">
                                    <div className="font_color_imp font_size_13 ps-4 mt-3 fw-bold float-start row mb-2">Required Tech Stack:</div>
                                    <div className='text-start ms-3 pb-2 col-md-12 pt-2'>
                                        {jobDetails?.skill?.map((item) => item?.language)?.map((item) => <span className="badge rounded-pill custom_chip_css_job_details">{item}</span>)}
                                    </div>
                                </div>

                                <div className="col-md-12 border-bottom row"></div>
                                <div className="border-bottom d-block row float-start">
                                    <div className="font_color_imp font_size_13 ps-2 mt-2 fw-bold float-start row mb-1">Experience required:</div>
                                    <div className='text-start ms-3 pb-2 col-md-12 pt-2 row'>
                                        {[jobDetails?.year_of_exp].map((item) => <span className="badge rounded-pill custom_chip_css max-width">{item + 'Years'}</span>)}
                                    </div>
                                </div>

                                <div className="col-md-12 border-bottom row"></div>
                                <div className="border-bottom d-block row float-start">
                                    <div className="font_color_imp font_size_13 ps-2 mt-2 fw-bold float-start row mb-1">Role Type</div>
                                    <div className='text-start ms-3 pb-2 col-md-12 pt-2 row'>
                                        {[jobDetails?.office_type].map((item) => <span className="badge rounded-pill custom_chip_css max-width">{item}</span>)}
                                    </div>
                                </div>
                                <div className="col-md-12 border-bottom row"></div>
                                <div className="border-bottom d-block row float-start">
                                    <div className="font_color_imp font_size_13 ps-2 mt-2 fw-bold float-start row mb-1">Working hours:</div>
                                    <div className='text-start ms-3 pb-2 col-md-12 pt-2 row'>
                                        {['8 hours - GMT '].map((item) => <span className="badge rounded-pill custom_chip_css max-width">{item}</span>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* card-2 */}
                {/* <div className="my-3 p-3 mt-2 bg-body rounded shadow-sm rounded_20">
                    
                </div> */}
            </div>

            {/* right side cards */}
            <div className="col-md-8 width_col_69">
                {/* card-1 */}
                <div className="my-3 p-3 mt-2 bg-body rounded shadow-sm rounded_20">
                    <div className="col-md-12">
                        {/* overflow_y_on modal-dialog-scrollable  */}
                        <div className="pt-0 pb-5 ps-3 pe-3">
                            <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                {masterData.map((item, idx) => {
                                    return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                                        <div className="col-xs-12 align-middle">
                                            <div className="col-xs-6 me-3">
                                                <img
                                                    src={require(`../../assets/images/${item.img}`)}
                                                    alt="jobType"
                                                    className="img_w_h_23"
                                                />
                                            </div>
                                            <div className="col-xs-6">
                                                <div className="fw-700 font_size_16 mb-2">{item.label}</div>
                                                <div className="fw-medium font_size_14">{item.response === 'office_type' ? jobDetails[item.response]?.toString()?.split(',').join(", ") : jobDetails[item.response]}</div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                            <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                <div className="col-md-12 d-flex justify-content-start align-content-center pt-4 p-2">
                                    <div className="col-xs-12 align-middle">
                                        <div className="col-xs-6 me-3">
                                            <img
                                                src={require(`../../assets/images/skills_needed.svg`).default}
                                                alt="jobType"
                                                className="img_w_h_23"
                                            />
                                        </div>
                                        <div className="col-xs">
                                            <div className="fw-700 font_size_16 mb-2">{'Skillset'}</div>
                                            <div className="fw-medium font_size_14">{jobDetails['skill']?.map((item) => item?.language)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {[{ title: 'Job description', img: 'job_description.svg', value: 'job_desciption' }, { title: 'Skill needed', img: 'skills_needed.svg', value: 'skill' }].map((item, idx) => <div>
                                <div className="col-md-12 d-flex justify-content-start align-content-center pt-4 p-2">
                                    <div className="col-xs-12 align-middle">
                                        <div className="col-xs-1 me-3">
                                            <img
                                                src={require(`../../assets/images/${item.img}`)}
                                                alt="jobType"
                                                className="img_w_h_23"
                                            />
                                        </div>
                                        <div className="col-xs-11">
                                            <div className="fw-700 font_size_14 mb-2">{item.title}</div>
                                            {/* <div className="fw-medium font_size_14 mb-2">
                                                <b> {`${jobDetails.year_of_exp} Years`} </b>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="fw-medium font_size_14 ms-5">
                                    {
                                        item.value === 'skill'
                                            ?
                                            jobDetails[item.value]?.map((item) => item?.language)
                                            :
                                            jobDetails[item.value]
                                    }
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
                <div className='text-start ps-4 ms-2 pb-2 d-flex justify-content-end align-items-center gap-2'>
                    <button
                        type="button"
                        className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                        style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                        onClick={() => { SaveJobApi() }}
                    >
                        {'Save For Later'}
                    </button>
                    {jobDetails?.applicationStatus !== ''  &&
                        <button
                            type="button"
                            className={`btn btn-primary font_size_12 pt-1 pb-2 fw-medium`}
                            onClick={() => { applyJobApiCall(jobDetails) }}
                        >
                            {'Apply for this Role'}
                        </button>
                    }
                </div>
            </div>
        </div>
    </>
}


export default adminLayout(EmpJobDetailsViewPage);

const masterData = [
    { label: 'Job Type', response: 'job_type', value: 'Remote', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', value: '1234', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', value: '5-10 years', img: 'Y_O_Exp.svg' },
    { label: 'Type of contract', response: 'office_type', value: 'full time', img: 'type_of_contract.svg' },
    // { label: 'Skillset', response: 'skill', value: 'full time', img: 'skills_needed.svg' },
]
