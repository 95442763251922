import React, { useState, useLayoutEffect, useEffect } from "react";
import { toast } from 'react-toastify';
import swal from "sweetalert";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import { downloadPDF } from '../../../services/axios-common'
import storePersist from '../../../Utils/storePersist'
import { isEmpty } from '../../../Utils/utils'
import { postApi, deleteApiWithBody, updateWithOutParamsApi } from '../../../services/apiCommonService'
import { create_company_url, post_company_logo_api_url, delete_offer_api_url, upload_company_files, upload_candidate_file, create_candidate_details, get_all_candidates, candidate_edit_url } from '../../../services/endPointsConstant'
import { returnArryOfObjects, formatBytes } from '../../../Utils/utils'
import Select, { components } from "react-select";
import Chipcomponent from "../../../components/ChipComponent";
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const masterData = [
    { label: 'Job Type', response: 'job_type', value: 'Remote', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', value: '1234', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', value: '5-10 years', img: 'Y_O_Exp.svg' },
]

const UploadResumeJobDetails = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const d = new Date()
    const newDate = d.toISOString().substring(0, 10);
    const { loading } = useSelector((state) => state.loaderReducer);
    const { viewSelectedJobDetails, jobList, selectedRecordDataFun, modalPopUpCancel } = props
    const [cmpyDocumentValue, setCmpyDocumentValue] = useState(null);
    const [current, setCurrent] = useState(viewSelectedJobDetails?.idxValue);
    const [formDataDoc, setFormDataDoc] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [allCandidatesList, setAllCandidatesList] = useState([])
    const [selectedCompanyDetailsObject, setSelectedCompanyDetailsObject] = useState([]);
    const [selectedCompanyDetailsRowList, setSelectedCompanyDetailsRowList] = useState([]);
    const [jobListSelectedValue, setJobListSelectedValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const [ddList, setDDList] = useState([])
    const [fileUploadedLength, setFileUploadedLength] = useState(true)

    const {
        register: registerDoc,
        formState: {
            errors: errorsDoc,
            isSubmitting: isSubmittingDoc
        },
        handleSubmit: handleSubmitDoc,
        control: controlDoc,
        reset: resetDoc,
        getValues: getValuesDoc,
        setValue
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        dispatch(addLoader());
        getCandidatesApiCallFun()
        let arrValue = []
        for (let i = 0; i < 61; i++) {
            arrValue.push({ value: i, label: i })
        }
        setDDList(arrValue)
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }, [])

    const stopLoader = () => {
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }
    useEffect(() => {
        if (modalPopUpCancel) {
            resetDoc();
            resetDoc({});
            resetDoc(null);
        }
    }, [modalPopUpCancel])

    useEffect(() => {
        setCurrent(viewSelectedJobDetails?.idxValue)
        getCandidatesApiCallFun()
    }, [viewSelectedJobDetails])

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(allCandidatesList, ids, 'offer_id')
        setSelectedCompanyDetailsObject(finalUsersData)
    }

    const handleUserSelect = (jobData) => {
        if (selectedCompanyDetailsRowList.includes(jobData.offer_id)) {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData.offer_id));
            callUsersData(selectedCompanyDetailsRowList.filter(_id => _id !== jobData.offer_id))
            resetDoc()
            resetDoc({})
            resetDoc(null)
        } else {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData.offer_id]);
            callUsersData([...selectedCompanyDetailsRowList, jobData.offer_id])
            resetDoc({ ...jobData })
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedCompanyDetailsRowList.includes(jobData.offer_id);
        return isUserSelectedVal
    };

    const selectAll = () => {
        setSelectedCompanyDetailsRowList([]);
        if (!isAllSelected) {
            allCandidatesList.forEach(jobData => {
                setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData.offer_id]);
            });
            callUsersData(allCandidatesList.map((item) => item.offer_id))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const getCandidatesApiCallFun = async () => {
        dispatch(addLoader());
        const apiReqBody = { jobId: viewSelectedJobDetails?._id }
        if (viewSelectedJobDetails?._id) {
            const apiResponse = await postApi(get_all_candidates, apiReqBody)
            if (apiResponse?.data?.Data) {
                let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
                let finalList = [];
                const cv_rejected = [];
                const interview_rejected = [];
                apiResponseData?.map((item) => {
                    if (item?.resume_status === 'rejected') {
                        cv_rejected.push(item)
                    } else if (item?.interview_status === 'rejected') {
                        interview_rejected.push(item)
                    } else {
                        finalList.push(item)
                    }
                })
                if (cv_rejected?.length > 0 && interview_rejected?.length > 0) {
                    finalList = [...finalList, ...interview_rejected, ...cv_rejected]
                } else if (cv_rejected?.length > 0) {
                    finalList = [...finalList, ...cv_rejected]
                } else if (interview_rejected?.length > 0) {
                    finalList = [...finalList, ...interview_rejected]
                }
                setAllCandidatesList(finalList)
                // toast.success("Retrived all Candidates List Successfully !!")
                dispatch(deleteLoader());
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        } else {
            dispatch(deleteLoader());
        }
    };

    const errorHandlerDoc = () => {
        if (errorsDoc.documentName) {
            toast.error("document name can not be emepty")
        } else if (errorsDoc.file) {
            toast.error("please select file")
        } else if (!isEmpty(errorsDoc)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const resumeUpload = async (data, candidateDetails) => {
        const baseUrl = 'https://free.remote72.in'
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("candidateId", candidateDetails?.candidate_id)
        try {
            const apiResponse = await fetch(`${baseUrl}/${upload_candidate_file}`, {
                method: "POST",
                body: formData,
            }).then((res) => res.json());
            if (apiResponse?.message) {
                if (apiResponse?.Data) {
                    toast.success(apiResponse?.message)
                    getCandidatesApiCallFun()
                    dispatch(deleteLoader());
                } else {
                    toast.success(apiResponse?.response?.data.message || "resume uploaded successfully !!")
                }
            } else {
                toast.error(apiResponse?.response?.data?.message + '!!')
                dispatch(deleteLoader());
            }
        } catch (err) {
            if (err === 'TypeError: Failed to fetch') {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            } else {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            }
            dispatch(deleteLoader());
        }
    }

    const onSubmitDoc = async (data) => {
        if (selectedCompanyDetailsRowList.length > 0 && selectedCompanyDetailsRowList.length < 2) {
            dispatch(addLoader());
            setFormDataDoc(data);
            const apiReqBody = {
                firstName: data.candidateFirstName,
                experience: data.candidateExperience,
                skillset: data.candidateSkillset,
                fileName: data.file[0]?.name,
                emailId: data.candidateEmailId,
                phone_number: data.phone_number,
                candidateId: selectedCompanyDetailsObject?.length > 0 && selectedCompanyDetailsObject?.map(({ candidate_id }) => candidate_id)[0]
            }
            let file_size = data.file[0]?.size;
            let file_name = data.file[0]?.name;
            let file_type = data.file[0]?.type;
            if (formatBytes(file_size) < 500) {
                const apiResponse = await updateWithOutParamsApi(candidate_edit_url, apiReqBody)
                if (apiResponse?.data?.message) {
                    if (data?.file?.length > 0) {
                        resumeUpload(data, { candidate_id: selectedCompanyDetailsObject?.length > 0 && selectedCompanyDetailsObject?.map(({ candidate_id }) => candidate_id)[0] })
                    }
                    handleUserSelect(selectedCompanyDetailsObject[0])
                    setFileUploadedLength(true)
                    toast.success("Successfully candidate details Updated !!")
                    resetDoc();
                    resetDoc({});
                    resetDoc(null);
                    void getCandidatesApiCallFun()
                    dispatch(deleteLoader());
                    document.location.reload();
                } else {
                    dispatch(deleteLoader());
                    toast.success(apiResponse?.response?.data.message || "Resume uploaded successfully !!")
                }
            } else {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            }
        } else {
            dispatch(addLoader());
            setFormDataDoc(data);
            const { id } = storePersist.get('userData')
            const companyDetailsStorage = storePersist.get('companyDetails')
            const actMgrDataStorage = storePersist.get('actMgrData')
            const apiReqBody = {
                userid: id,
                firstName: data.candidateFirstName,
                profileTitle: viewSelectedJobDetails && viewSelectedJobDetails?.job_type,
                experience: data.candidateExperience,
                skillset: data.candidateSkillset,
                candidate_status: 'registered',
                fileName: data.file[0]?.name,
                companyId: params?.id || companyDetailsStorage && companyDetailsStorage?._id,
                actManagerId: actMgrDataStorage?._id,
                jobId: viewSelectedJobDetails?._id,
                emailId: data.candidateEmailId,
                phone_number: data.phone_number,
            }
            let file_size = data.file[0]?.size;
            let file_name = data.file[0]?.name;
            let file_type = data.file[0]?.type;
            if (formatBytes(file_size) < 500) {
                const apiResponse = await postApi(create_candidate_details, apiReqBody)
                if (apiResponse?.data?.message === 'Candidate has already applied for this job') {
                    // toast.error(apiResponse?.data.message || "Candidate has already applied for this job")
                } else if (apiResponse?.data) {
                    resumeUpload(data, apiResponse?.data?.offer || apiResponse?.data?.data)
                    toast.success("Successfully candidate details Added !!")
                    setFileUploadedLength(true)
                    // document.getElementById("UploadResumeJobDetailsModalIdDismiss").click();
                    resetDoc();
                } else {
                    dispatch(deleteLoader());
                    toast.success(apiResponse?.response?.data.message || "Resume uploaded successfully !!")
                }
            } else {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            }
        }
    };

    const downloadPdf = (downloadPath, name) => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(downloadPath, name)
    }

    const cancelModalFunction = () => {
        props.cancelModalFun()
        document.location.reload();
    }

    const editCandidateDetails = () => {
        // setTimeout(() => {
        // }, 3000);
    }

    const deleteFun = () => {
        swal({
            title: "Delete !!",
            text: "Are you sure you want to Delete Candidate ?",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Candidate deleted successfully !',
                    icon: 'success'
                }).then(async function () {
                    dispatch(addLoader());
                    const apiReqBody = {
                        offerId: selectedCompanyDetailsObject && selectedCompanyDetailsObject.map((item) => item.offer_id)
                    }
                    const apiResponse = await deleteApiWithBody(delete_offer_api_url, apiReqBody)
                    if (apiResponse?.data) {
                        toast.success('Offer deleted successfully !!')
                        resetDoc()
                        resetDoc({})
                        resetDoc(null)
                        document.getElementById("UploadResumeJobDetailsModalIdDismiss").click();
                        dispatch(deleteLoader());
                        void getCandidatesApiCallFun()
                        document.location.reload();
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                        dispatch(deleteLoader());
                    }
                });
            } else {
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const validate = (value) => {
        const { file } = getValuesDoc()
        if (!file?.length === 0) return true;
        if (file?.length > 0) {
            const acceptedFormats = ['pdf'];
            const fileExtension = value[0]?.name.split('.').pop().toLowerCase();
            if (!acceptedFormats.includes(fileExtension)) {
                return 'Invalid file format. Only PDF files are allowed.';
            }
            return true;
        }
    };

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['candidateExperience'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    const limit = (element) => {
        var max_chars = 10;
        if (element?.target?.value?.length > max_chars) {
            element.target.value = element?.target?.value?.substr(0, max_chars);
        }
    }

    const fileUploadedOnDown = (e) => {
        console.log("===hhj====", getValuesDoc('file')?.length,e.target.value)
        if (getValuesDoc('file')?.length > 0) {
            setFileUploadedLength(false)
        } else {
            setFileUploadedLength(true)
        }
    }

    return (
        <>
            <div className="overflow_y_on modal-dialog-scrollable pt-0 ps-3 pe-3">
                <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                    {masterData.map((item, idx) => {
                        return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                            <div className="col-xs-12 align-middle">
                                <div className="col-xs-6 me-3">
                                    <img
                                        src={require(`../../../assets/images/${item.img}`)}
                                        alt="jobType"
                                        className="img_w_h_23"
                                    />
                                </div>
                                <div className="col-xs-6">
                                    <div className="fw-700 font_size_15 mb-2">{item.label}</div>
                                    <div className="fw-medium font_size_14">{(viewSelectedJobDetails && viewSelectedJobDetails[item.response]) || ''}</div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
                <div className="pt-2 font_size_14">
                    <b>Upload Resume for the this profile, Maximum 5 resume can be uploaded.</b>
                    <>
                        <section class="py-1">
                            <form onSubmit={handleSubmitDoc(onSubmitDoc)}>
                                <div className="form-outline row gx-3 mt-4">
                                    <div className={`col-md-3 marginbottom_int did-floating-label-content ${errorsDoc.candidateFirstName && 'did-error-input'}`}>
                                        <input
                                            className="did-floating-input"
                                            type="text"
                                            name="candidateFirstName"
                                            placeholder=''
                                            {...registerDoc("candidateFirstName", {
                                                required: "Field is required",
                                                minLength: {
                                                    value: 2,
                                                    message: "Min length is 2"
                                                },
                                            })}
                                        />
                                        <label className="did-floating-label requiredTrue">{'Name Of Candidate'}</label>
                                    </div>

                                    <div className={`col-md-3 marginbottom_int did-floating-label-content ${errorsDoc.candidateEmailId && 'did-error-input'}`}>
                                        <input
                                            className="did-floating-input" //mb-4 font_size_14
                                            type="email"
                                            name="candidateEmailId"
                                            placeholder=""
                                            {...registerDoc("candidateEmailId", {
                                                required: "Field is required",
                                                minLength: {
                                                    value: 2,
                                                    message: "Min length is 2"
                                                },
                                                pattern: {
                                                    value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                                    message: "Invalid email address, It should be example@google.com"
                                                }
                                            })}
                                        />
                                        <label className="did-floating-label requiredTrue">{'E-mail ID'}</label>
                                    </div>

                                    <div className={`col-md-3 marginbottom_int did-floating-label-content ${errorsDoc.phone_number && 'did-error-input'}`}>
                                        <input
                                            className="did-floating-input"
                                            type="number"
                                            name="phone_number"
                                            placeholder=""
                                            {...registerDoc("phone_number", {
                                                required: "Field is required",
                                                maxLength: {
                                                    value: 10,
                                                    message: "Min length is 2"
                                                },
                                                pattern: {
                                                    value: /^[7-9][0-9]{9}$/,
                                                    message: "Allow only numbers"

                                                }
                                            })}
                                            onKeyDown={(e) => { limit(e) }}
                                            onKeyUp={(e) => { limit(e) }}
                                        />
                                        <label className="did-floating-label requiredTrue">{'Phone Number'}</label>
                                        {/* <input
                                            className="did-floating-input"
                                            type="number"
                                            name="phone_number"
                                            placeholder=''
                                            {...registerDoc("phone_number", {
                                                required: "Field is required",
                                                minLength: {
                                                    value: 10,
                                                    message: "Min length is 10"
                                                },
                                                valueAsNumber: true,
                                                pattern: {
                                                    value: /^[1-9]{4}$/
                                                },
                                            })}
                                            onInput={(e) => {
                                                if (e.target.value.length > e.target.maxLength)
                                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                                            }}
                                            maxLength={10}
                                            min={1111111111}
                                        />
                                        <label className="did-floating-label">{'Mobile No'}</label> */}
                                    </div>
                                    {/* <div className="col-md-3 form-outline did-floating-label-content">
                                        <Controller
                                            name="candidateExperience"
                                            control={controlDoc}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Select
                                                    // isClearable
                                                    options={ddList}
                                                    value={ddList?.find((c) => c.value === value)}
                                                    onChange={(val) => onChange(val.value)}
                                                    defaultValue={ddList?.find((c) => c.value === jobListSelectedValue)}
                                                    isSearchable={true}
                                                    placeholder={'Experience in years'}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'candidateExperience': true }) }}
                                                    styles={{
                                                        control: (styles) => ({
                                                            ...styles,
                                                            ...errorBorderForSelectBox(errorsDoc.candidateExperience && 'candidateExperience'),
                                                        }),
                                                        container: (provided, state) => ({
                                                            ...provided,
                                                            // marginTop: 25,
                                                        }),
                                                        valueContainer: (provided, state) => ({
                                                            ...provided,
                                                            overflow: "visible"
                                                        }),
                                                        placeholder: (provided, state) => ({
                                                            ...provided,
                                                            position: "absolute",
                                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                            background: "white",
                                                            transition: "top 0.1s, font-size 0.1s",
                                                            fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                            fontWeight: 100,
                                                            padding: '0.3px 8.5px',
                                                            borderRadius: '8px',
                                                            color: 'rgba(6, 44, 80, 0.7);',
                                                            border: isSelected['candidateExperience'] ? '1px solid #cccccc' : ''
                                                        })
                                                    }}
                                                />
                                            )}
                                            rules={{ required: "Field is required" }}
                                        />
                                    </div> */}

                                    <div className={`col-md-3 marginbottom_int did-floating-label-content ${errorsDoc.candidateExperience && 'did-error-input'}`}>
                                        <input
                                            className="did-floating-input"
                                            type="number"
                                            name="candidateExperience"
                                            placeholder=''
                                            min="1" max="61"
                                            {...registerDoc("candidateExperience", {
                                                required: "Field is required",
                                                minLength: {
                                                    value: 1,
                                                    message: "Min length is 1"
                                                },
                                                // onChange: (e)=>{
                                                //     resetDoc({...getValuesDoc(), candidateExperience: `${e?.target?.value} ${e?.target?.value === 1 ? 'Year' : 'Years'}`})
                                                // }
                                            })}
                                        />
                                        <label className="did-floating-label requiredTrue">{'Experience in years'}</label>
                                    </div>

                                    <div className={`col-md-3 marginbottom_int did-floating-label-content ${errorsDoc.candidateSkillset && 'did-error-input'}`}>
                                        <input
                                            className="did-floating-input"
                                            type="text"
                                            name="candidateSkillset"
                                            placeholder=''
                                            {...registerDoc("candidateSkillset", {
                                                required: false,
                                            })}
                                        />
                                        <label className="did-floating-label">{'Skills'}</label>
                                    </div>
                                    <div className={`col-md-4 marginbottom_int did-floating-label-content ${fileUploadedLength && errorsDoc.file && 'did-error-input'}`}>
                                        <>
                                            {selectedCompanyDetailsRowList.length > 0 && selectedCompanyDetailsRowList.length < 2
                                                ?
                                                <input
                                                    className="did-floating-input" //mb-4 font_size_14
                                                    type="file"
                                                    name="file"
                                                    accept=".pdf"
                                                    placeholder=""
                                                    {...registerDoc("file", {
                                                        required: false,
                                                        validate
                                                    })}
                                                    style={{ paddingTop: '7px' }}
                                                //onKeyDown={fileUploadedOnDown}
                                                />
                                                :
                                                <input
                                                    className="did-floating-input" //mb-4 font_size_14
                                                    type="file"
                                                    name="file"
                                                    accept=".pdf"
                                                    placeholder=""
                                                    {...registerDoc("file", {
                                                        required: "File is required",
                                                        validate: (value) => {
                                                            const acceptedFormats = ['pdf'];
                                                            const fileExtension = value[0]?.name.split('.').pop().toLowerCase();
                                                            if (!acceptedFormats.includes(fileExtension)) {
                                                                return 'Invalid file format. Only PDF files are allowed.';
                                                            }
                                                            return true;
                                                        },
                                                        onBlur: (e) => { fileUploadedOnDown(e) },
                                                        onChange: (e) => { fileUploadedOnDown(e) }
                                                    })}
                                                    style={{ paddingTop: '7px' }}
                                                />
                                            }
                                            <span className="btn btn-primary upload_file_css font_size_14 rounded-0 rounded-end pb-0_6">
                                                <img
                                                    src={require(`../../../assets/images/upload_file.svg`).default}
                                                    alt="upload file"
                                                    className="img_w_h_18"
                                                />
                                            </span>
                                        </>
                                        <label className="did-floating-label requiredTrue">{'Upload Resume'}</label>
                                        {selectedCompanyDetailsObject?.length > 0 &&
                                            <span
                                                className="d-flex justify-content-start align-items-center font_color_ornage hover_underline"
                                                onClick={() => {
                                                    downloadPdf(selectedCompanyDetailsObject && selectedCompanyDetailsObject[0]?.file_location, selectedCompanyDetailsObject && selectedCompanyDetailsObject[0]?.candidateFirstName)
                                                }}
                                            >
                                                <img
                                                    src={require(`../../../assets/images/pdf-file-icon.svg`).default}
                                                    alt="Sow"
                                                    className="img_w_h_18"
                                                />
                                                <div
                                                    className="fw-medium font_size_12 border-1"
                                                >
                                                    {selectedCompanyDetailsObject && selectedCompanyDetailsObject[0]?.candidateCvfileName}
                                                </div>
                                            </span>
                                        }
                                    </div>

                                    <div className={`col-md-4`}>
                                        <button type="submit"
                                            className="btn btn-primary pb-2 ps-4 pe-4 font_size_13 btn_height_36 padding_top_6 mt-2 mb-0" onClick={errorHandlerDoc}>
                                            {isSubmittingDoc && <span>Submitting</span>}
                                            {!isSubmittingDoc && <span>{selectedCompanyDetailsRowList.length > 0 && selectedCompanyDetailsRowList.length < 2 ? 'Edit Resume' : 'Add Resume'}</span>}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </section>
                        <div>
                            <div className="row bd-example">
                                <div className="d-flex justify-content-between align-items-center border-bottom pt-2">
                                    {/* <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2"> */}
                                    <div className="col-md-10 d-flex justify-content-start align-items-center gap-3 ms-2 margin_bottom_20">
                                        <span><b>Total Candidates list : </b>(<span className='orange_color'><b>{allCandidatesList?.length}</b></span>)</span>
                                        <span><b>Resumes Rejected : </b>(<span className='orange_color'><b>{allCandidatesList?.filter((item) => item?.resume_status === 'rejected')?.length}</b></span>)</span>
                                        <span><b>Interview Rejected : </b>(<span className='orange_color'><b>{allCandidatesList?.filter((item) => item?.interview_status === 'rejected')?.length}</b></span>)</span>
                                        <span><b>Selected/New List : </b>(<span className='orange_color'><b>{allCandidatesList?.filter((item) => item?.interview_status !== 'rejected' && item?.resume_status !== 'rejected')?.length}</b></span>)</span>
                                    </div>
                                    {/* <button
                                            type="button"
                                            className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                                            disabled={selectedCompanyDetailsRowList.length > 0 && selectedCompanyDetailsRowList.length < 2 ? false : true}
                                            // data-bs-target="#editCompanyTestId"
                                            // data-bs-toggle="modal"
                                            onClick={editCandidateDetails}
                                        >
                                            Edit
                                        </button> */}
                                    <div className="col-md  d-flex justify-content-end align-items-center gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                                        <button
                                            type="button"
                                            className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                            disabled={selectedCompanyDetailsRowList.length > 0 ? false : true}
                                            // data-bs-target="#deleteCompanyDetailsModalID"
                                            // data-bs-toggle="modal"
                                            onClick={deleteFun}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    {/* </div> */}
                                </div>
                                <div className="table-container">
                                    <div className="d-flex text-muted">
                                        <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                        </th>
                                                        {['Candidate Name', 'Contact email', 'Phone', 'Resume Status', 'Experience', 'Interview Status', 'Date uploaded', 'OnBoard status', 'Resume'].
                                                            map((item, idx) =>
                                                                <th key={idx} className={`text-white`}>
                                                                    {item}
                                                                </th>
                                                            )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allCandidatesList?.map((item, idx) => {
                                                        return (
                                                            <tr key={item._id}>
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''}`}>
                                                                    <input
                                                                        className="width_12"
                                                                        type="checkbox"
                                                                        checked={isUserSelected(item)}
                                                                        onChange={() => handleUserSelect(item)}
                                                                    />
                                                                </td>
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''} fw-bolder`}>
                                                                    {item?.candidateFirstName}
                                                                </td>
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''}`}>
                                                                    {item?.candidateEmailId}
                                                                </td>
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''}`}>
                                                                    {item?.phone_number || '--'}
                                                                </td>
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''}`}>
                                                                    {/* <Chipcomponent
                                                                        // letter={item?.job_status?.charAt(0)}
                                                                        title={item?.resume_status || 'New'}
                                                                        color={(item?.resume_status ? '' : 'green')}
                                                                    /> */}
                                                                    {item?.resume_status
                                                                        ?
                                                                        <span class="badge rounded-pill bg-warning text-dark">{item?.resume_status}</span>
                                                                        :
                                                                        <span class="badge rounded-pill bg-success">{item?.resume_status || 'New'}</span>
                                                                    }
                                                                </td>
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''}`}>
                                                                    {item?.candidateExperience || '--'}
                                                                </td>
                                                                {/* <td>{item?.interview_status || 'New Upload'}</td> */}
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''}`}>
                                                                    {/* <Chipcomponent
                                                                        // letter={item?.job_status?.charAt(0)}
                                                                        title={item?.interview_status || (item?.resume_status !== 'rejected' ? 'New' : 'NA')}
                                                                    /> */}
                                                                    {
                                                                        item?.interview_status || (item?.resume_status !== 'rejected'
                                                                            ?
                                                                            <span class="badge rounded-pill bg-success">{'New'}</span>
                                                                            :
                                                                            <span class="badge rounded-pill bg-warning text-dark">{'NA'}</span>)
                                                                    }
                                                                </td>
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''}`}>
                                                                    <div className="text_ellipse_90">
                                                                        {new Date(item?.createdAt).toLocaleString() || '--'}
                                                                    </div>
                                                                </td>
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''}`}>
                                                                    {/* <Chipcomponent
                                                                        // letter={item?.job_status?.charAt(0)}
                                                                        title={(item?.resume_status === 'rejected' || item?.interview_status === 'rejected' || item?.interview_status === null) ? 'NA' : item?.onboarding_status}
                                                                    /> */}
                                                                    {
                                                                        (item?.resume_status === 'rejected' || item?.interview_status === 'rejected' || item?.interview_status === null)
                                                                            ?
                                                                            <span class="badge rounded-pill bg-success">{'NA'}</span>
                                                                            :
                                                                            <span class="badge rounded-pill bg-warning text-dark">{item?.onboarding_status}</span>
                                                                    }
                                                                </td>
                                                                <td className={`${item?.resume_status === 'rejected' ? 'resume_rejected_css' : item?.interview_status === 'rejected' ? '' : ''}`}>
                                                                    <span
                                                                        className="d-flex justify-content-between align-items-center gap-2 border_color py-1 px-2 pointer_event"
                                                                        onClick={() => {
                                                                            downloadPdf(item?.file_location, item?.candidateFirstName)
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={require(`../../../assets/images/pdf-file-icon.svg`).default}
                                                                            alt="Sow"
                                                                            className="img_w_h_18"
                                                                        />
                                                                        <div
                                                                            className="fw-medium font_size_12 border-1 text_ellipse"
                                                                        >
                                                                            {item?.candidateCvfileName}
                                                                        </div>
                                                                        <img
                                                                            src={require(`../../../assets/images/download_gray_edge.svg`).default}
                                                                            alt="Sow"
                                                                            className="img_w_h_15"
                                                                        />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
            {/* <div className="border-top position-sticky bottom-0 footer mt-auto py-3 pb-3 bg-white text-center d-flex justify-content-center"> */}
            <div className="col-md-12 border-top position-sticky bottom-0 footer mt-2 py-3 px-3 bg-white  d-flex align-item-center justify-content-center">
                {/* <div className="col-md-8"> */}
                <button
                    type="close"
                    className="mt-1 mb-1 fw-medium_imp btn btn-outline-primary bg-white btn_height_36 padding_top_6 pb-2 pe-4 ps-4 font_size_14 orange_color_border orange_color"
                    data-bs-dismiss="modal"
                    onClick={() => { cancelModalFunction() }}
                >
                    Save
                </button>
            </div>
            <button className="d-none" id="UploadResumeJobDetailsModalIdDismiss" data-bs-dismiss="modal"></button>
        </>
    );
}

export default UploadResumeJobDetails