import React, { useState, useLayoutEffect, useEffect } from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import { updatedCompanyDetails } from "../../../redux/actions/defaultCompanyDetails";
import { downloadPDF } from '../../../services/axios-common'
import { postApi, updateWithOutParamsApi } from '../../../services/apiCommonService'
import { edit_company_details, post_company_logo_api_url, upload_company_files, company_getFileLink, remove_Document_url, get_company_id, reset_password_details } from '../../../services/endPointsConstant'

const masterData = [
    { label: 'Contact Person', response: 'contactName', value: '5-10 years', img: 'ellipse_icon.svg' },
    { label: 'Account Manager', response: 'actManagerName', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Onboarded', response: 'onboardedDate', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Contract Time', response: 'contractMonths', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Business MailID', response: 'companyEmail', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Account Manager MailID', response: 'emailId', value: 'full time', img: 'ellipse_icon.svg' },
]

const candidatesMasterData = [
    { label: 'No. Resources', response: 'totalAssociatesCount', value: 'Remote', img: 'ellipse_green.svg' },
    { label: 'In notice Period', response: 'noticeCount', value: '1234', img: 'ellipse_green.svg' },
    { label: 'Total contract Value', response: 'totalContractValue', value: 'full time', img: 'ellipse_green.svg' },
]

const ViewCompanyDetails = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.loaderReducer);
    const { viewSelectedCompanyDetails, allCompaniesOldList, selectedRecordDataFun, isDeleteHide } = props
    const [current, setCurrent] = useState(viewSelectedCompanyDetails?.idxValue);
    const [documentDetailsList, setDocumentDetailsList] = useState([])
    const [document_list_state, set_document_list_state] = useState([])
    const length = allCompaniesOldList?.length;

    useEffect(() => {
        dispatch(addLoader());
        void getCompanyDocApiCallFun()
        stopLoader()
    }, [])

    const stopLoader = () => {
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }

    useEffect(() => {
        setCurrent(viewSelectedCompanyDetails?.idxValue)
        void getCompanyDocApiCallFun()
    }, [viewSelectedCompanyDetails])

    const nextPage = (company, indexVal) => {
        const selectedCompany = allCompaniesOldList?.find((item, idx) => idx === indexVal + 1)
        selectedRecordDataFun(selectedCompany)
        setCurrent(current === length - 1 ? 0 : current + 1);
        dispatch(addLoader());
        stopLoader()
    }

    const prevPage = (company, indexVal) => {
        const selectedCompany = allCompaniesOldList?.find((item, idx) => idx === indexVal - 1)
        selectedRecordDataFun(selectedCompany)
        setCurrent(current === 0 ? length - 1 : current - 1);
        dispatch(addLoader());
        stopLoader()
    }

    const editCompanyHandler = (company, type) => {
        if (company) {
            // selectedRecordDataFun(company)
            dispatch(updatedCompanyDetails({ companyDetails: company, type: type }))
        }
    }

    const navigationPage = () => {
        document.getElementById("buttonViewCompanyDetailsDismiss").click();
        navigate('/short-list-resumes');
    }

    const downloadPdf = (downloadPath, name) => {
        if (downloadPath) {
            toast.success("Successfully downloaded profile !!")
            downloadPDF(downloadPath, name)
        } else {
            toast.error("No File Found !!")
        }
    }

    const getCompanyDocApiCallFun = async () => {
        dispatch(addLoader());
        if (viewSelectedCompanyDetails?.pageData?._id) {
            let apiReqBody = {
                companyId: viewSelectedCompanyDetails?.pageData?._id
            }
            const apiResponse = await postApi(company_getFileLink, apiReqBody)
            if (apiResponse?.data?.Data) {
                const finalRes = apiResponse?.data?.Data
                setDocumentDetailsList(finalRes || [])
                const resultDat = finalRes?.map((item) => item.docId)
                set_document_list_state(resultDat)
                dispatch(deleteLoader());
            } else {
                toast.error(apiResponse?.response?.data.message)
                dispatch(deleteLoader());
            }
        }
    };

    const cancelPopUp = () => {
        document.location.reload();
    }

    return (
        <>
            {allCompaniesOldList?.map((page, index) => {
                return (<>
                    {index === current && <div className="col-md-12" key={index}>
                        <div className="border-bottom position-sticky top-0 footer mt-auto py-3 pb-3 text-center modal-header main-bg-dark-color">
                            <div className="col-md-12 d-flex align-item-center justify-content-between">
                                <div className="col-md-3 d-flex justify-content-start">
                                    {/* {index !== 0 &&
                                        <button
                                            className="border-0 main-bg-dark-color mt-0 mb-0 pb-0 pt-0"
                                            onClick={() => { prevPage(page, index) }}
                                        // disabled={(index > 0)}
                                        >
                                            <img src={require('../../../assets/images/prev_page.svg').default} alt="Alt content" />
                                        </button>
                                    } */}
                                </div>
                                <div className="col-md">
                                    <div className="pt-0.5">Company Details</div>
                                </div>
                                <div className="col-md-3  d-flex justify-content-end">
                                    {/* {index !== (allCompaniesOldList.length - 1) &&
                                        <button
                                            className="border-0 main-bg-dark-color mt-0 mb-0 pb-0  pt-0"
                                            onClick={() => { nextPage(page, index) }}
                                        // disabled={!(index !== (allCompaniesOldList.length - 1))}
                                        >
                                            <img src={require('../../../assets/images/next_1_page.svg').default} alt="Alt content" />
                                        </button>
                                    } */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="overflow_y_on modal-dialog-scrollable pt-3 pb-3 ps-3 pe-3"> */}
                        <div className="col-md-12 border-bottom d-flex align-item-center justify-content-between px-3 py-2">
                            <div className="col-md-1 ms-1">
                                <div className="fw-bold pt-2 d-flex align-item-center justify-content-between">
                                    {viewSelectedCompanyDetails?.pageData?.logo ?
                                        <img src={viewSelectedCompanyDetails?.pageData?.logo} alt="logo" className='company_Details_profile' />
                                        :
                                        <span>{viewSelectedCompanyDetails?.pageData?.companyName}</span>
                                    }
                                </div>
                            </div>

                            <div className="col-md-3 d-flex align-item-center justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 mt-0 me-3 padding_top_6"
                                    data-bs-target="#editCompanyDetailsModalId"
                                    data-bs-toggle="modal"
                                    onClick={() => { editCompanyHandler(page, 'Main') }}
                                >
                                    Edit
                                </button>
                                {/* {!isDeleteHide &&
                                    <button
                                        type="button"
                                        className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 mt-0 padding_top_6"
                                        data-bs-target="#deleteCompanyDetailsModalId"
                                        data-bs-toggle="modal"
                                    >
                                        Delete
                                    </button>
                                } */}
                            </div>
                        </div>

                        <div className="overflow_y_on modal-dialog-scrollable pt-0 pb-5 ps-3 pe-3">

                            <div class="container">
                                <div class="row">
                                    <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                        {masterData.map((item, idx) => {
                                            return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                                                <div className="col-xs-6 me-2">
                                                    <img
                                                        src={require(`../../../assets/images/${item.img}`)}
                                                        alt="jobType"
                                                        className="img_w_h_11"
                                                    />
                                                </div>
                                                <div className="col-xs-6">
                                                    <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                    <div className="fw-medium font_size_14">
                                                        {
                                                            item.response === 'actManagerName'
                                                                ?
                                                                (viewSelectedCompanyDetails?.pageData[item.response] || <span className="error_text"> Manager not assigned</span>)
                                                                :
                                                                item.label === 'Account Manager MailID'
                                                                    ?
                                                                    (viewSelectedCompanyDetails?.pageData?.actMgr?.length >0 && viewSelectedCompanyDetails?.pageData?.actMgr[0][item.response] || <span className="error_text"> Manager not assigned</span>)
                                                                    :
                                                                    viewSelectedCompanyDetails?.pageData[item.response] || '- No Value -'
                                                        }
                                                        {/* emailId Account Manager MailID */}
                                                    </div>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div class="container">
                                <div class="row">
                                    <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                        {candidatesMasterData.map((item, idx) => {
                                            return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                                                <div className="col-xs-6 me-2">
                                                    <img
                                                        src={require(`../../../assets/images/${item.img}`)}
                                                        alt="jobType"
                                                        className="img_w_h_11"
                                                    />
                                                </div>
                                                <div className="col-xs-6">
                                                    <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                    <div className="fw-medium font_size_14">{viewSelectedCompanyDetails?.pageData[item.response]}</div>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom pb-4">
                                <div className="col-md-12 d-flex justify-content-between align-content-center pt-2 p-2 mx-2">
                                    <div className="col-xs-12 align-middle">
                                        <div className="col-xs-1 me-2">
                                            <img
                                                src={require(`../../../assets/images/download_org_edge1.png`)}
                                                alt="jobType"
                                                className="img_w_h_18"
                                            />
                                        </div>
                                        <div className="col-xs-11">
                                            <div className="fw-700 font_size_14 mb-2 mt-3">View / Download Contracts</div>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 mt-0 me-3 padding_top_6"
                                        data-bs-target="#editCompanyDetailsModalId"
                                        data-bs-toggle="modal"
                                        onClick={() => { editCompanyHandler(page, 'Document') }}
                                    >
                                        Edit
                                    </button>
                                </div>

                                <div className="col-md-12 mx-3 d-flex justify-content-start align-content-center gap-6 mt-2">
                                    {documentDetailsList?.length > 0 &&
                                        documentDetailsList?.map((item) =>
                                            <div
                                                className="d-flex justify-content-between align-items-center gap-2 border_color py-1 px-2"
                                                onClick={() => { downloadPdf(item?.fileLocation, item?.fileName) }}
                                            >
                                                <img
                                                    src={require(`../../../assets/images/pdf-file-icon.svg`).default}
                                                    alt="Sow"
                                                    className="img_w_h_18"
                                                />
                                                <div
                                                    className="fw-medium font_size_12 border-1"
                                                >
                                                    {item?.fileName}
                                                </div>
                                                <img
                                                    src={require(`../../../assets/images/download_gray_edge.svg`).default}
                                                    alt="Sow"
                                                    className="img_w_h_15"
                                                />
                                            </div>
                                        )}

                                    {documentDetailsList?.length === 0 &&
                                        <div>
                                            No Data Found
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pt-3 p-2 pb-4">
                                <div className="col-xs-1 me-2">
                                    <img
                                        src={require(`../../../assets/images/company-info-icon.svg`).default}
                                        alt="jobType"
                                        className="img_w_h_18"
                                    />
                                </div>
                                <div className="col-xl-10">
                                    <div className="fw-700 font_size_14 mb-2">{`About the ${page?.companyName} Software`}</div>
                                    <div className="fw-medium font_size_14">{viewSelectedCompanyDetails?.pageData?.aboutCompany || <span className="error_text">-- Yet to add --</span>}</div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="border-top position-sticky bottom-0 footer mt-auto py-3 pb-3 bg-white text-center d-flex justify-content-center"> */}
                        <div className="col-md-12 border-top position-sticky bottom-0 footer mt-auto py-3 px-3 main_bg  d-flex align-item-center justify-content-center">
                            <button
                                type="close"
                                className="mt-1 mb-1 fw-medium_imp btn btn-outline-primary bg-white btn_height_36 padding_top_6 pb-2 pe-4 ps-4 font_size_14 orange_color_border orange_color"
                                data-bs-dismiss="modal"
                                onClick={cancelPopUp}
                            >
                                Cancel
                            </button>
                            {/* <div className="col-md-3 d-flex align-item-center justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 mt-0 me-3 padding_top_6"
                                    data-bs-target="#editCompanyDetailsModalId"
                                    data-bs-toggle="modal"
                                    onClick={() => { editCompanyHandler(page) }}
                                >
                                    Edit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 mt-0 padding_top_6"
                                    data-bs-target="#deleteCompanyDetailsModalId"
                                    data-bs-toggle="modal"
                                >
                                    Delete
                                </button>
                            </div> */}
                        </div>
                        {/* </div> */}
                        <button className="d-none" id="buttonViewCompanyDetailsDismiss" data-bs-dismiss="modal"></button>
                    </div>}
                </>)
            }
            )}
        </>
    );
}

export default ViewCompanyDetails