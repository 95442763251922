export const citiesList = [
    "Agartala",
    "Agra",
    "Agumbe",
    "Ahmedabad",
    "Aizawl",
    "Ajmer",
    "Alappuzha Beach",
    "Allahabad",
    "Alleppey",
    "Almora",
    "Amarnath",
    "Amritsar",
    "Anantagir",
    "Andaman and Nicobar Islands",
    "Araku valley",
    "Aurangabad",
    "Ayodhya",
    "Badrinath",
    "Bangalore",
    "Baroda",
    "Bastar",
    "Bhagalpur",
    "Bhilai",
    "Bhimtal",
    "Bhopal",
    "Bhubaneswar",
    "Bhuj",
    "Bidar",
    "Bilaspur",
    "Bodh Gaya",
    "Calicut",
    "Chail",
    "Chamba",
    "Chandigarh",
    "Chennai",
    "Chennai Beaches",
    "Cherai",
    "Cherrapunji",
    "Chidambaram",
    "Chikhaldara Hills",
    "Chopta",
    "Coimbatore",
    "Coonoor",
    "Coorg",
    "Corbett National Park",
    "Cotigao Wild Life Sanctuary",
    "Cuttack",
    "Dadra and Nagar Haveli",
    "Dalhousie",
    "Daman and Diu",
    "Darjeeling",
    "Dehradun",
    "Delhi",
    "Devikulam",
    "Dhanaulti",
    "Dharamashala",
    "Dindigul",
    "Dudhwa National Park",
    "Dwaraka",
    "Faridabad",
    "Gandhinagar",
    "Gangotri",
    "Gangtok",
    "Gir Wildlife Sanctuary",
    "Goa",
    "Great Himalayan National Park",
    "Gulmarg",
    "Gurgaon",
    "Guruvayoor",
    "Guwahati",
    "Gwalior",
    "Hampi",
    "Haridwar",
    "Hogenakkal",
    "Horsley Hills",
    "Hyderabad",
    "Idukki",
    "Imphal",
    "Indore",
    "Itangar",
    "Jabalpur",
    "Jaipur",
    "Jaisalmer",
    "Jalandhar",
    "Jammu",
    "Jamshedpur",
    "Jodhpur",
    "Kanchipuram",
    "Kanha National Park",
    "Kanpur",
    "Kanyakumari",
    "Kargil",
    "Karwar",
    "Kausani",
    "Kedarnath",
    "Keoladeoghana National Park",
    "Khajuraho",
    "Kochi",
    "Kodaikanal",
    "Kolkata",
    "Kollam",
    "Konark",
    "Kotagiri",
    "Kottakkal and Ayurveda",
    "Kovalam",
    "Kovalam and Ayurveda",
    "Kudremukh",
    "Kullu",
    "Kumaon",
    "Kumarakom",
    "Kumarakom and Ayurveda",
    "Kumarakom Bird Sanctuary",
    "Kurukshetra",
    "Lakshadweep",
    "Lucknow",
    "Ludhiana",
    "Madurai",
    "Mahabalipuram",
    "Malpe Beach",
    "Manas National Park",
    "Mangalore",
    "Maravanthe Beach",
    "Margoa",
    "Mount Abu",
    "Mumbai",
    "Munnar",
    "Mussoorie",
    "Mysore",
    "Nahsik",
    "Nalanda",
    "Nanda Devi National Park",
    "Nandi Hills",
    "Netravali Wild Life Sanctuary",
    "Ooty",
    "Orchha",
    "Pahalgam",
    "Palakkad",
    "Panchgani",
    "Patna",
    "Patnitop",
    "Pattadakkal",
    "Periyar Wildlife Sanctuary",
    "Pithoragarh",
    "Pondicherry",
    "Pune",
    "Puri",
    "Pushkar",
    "Raipur",
    "Rajaji National Park",
    "Rajgir",
    "Rameshwaram",
    "Ranchi",
    "Ranganthittu Bird Sanctuary",
    "Ranikhet",
    "Ranthambore",
    "Rishikesh",
    "Rourkela",
    "Sanchi",
    "Saputara",
    "Sariska Wildlife Sanctuary",
    "Shillong",
    "Shimla",
    "Sohna_Hills",
    "Srinagar",
    "Sunderbans",
    "Surat",
    "Tezpur",
    "Thanjavur",
    "Thiruvananthapuram",
    "Thrissur",
    "Tirunelveli",
    "Tirupati",
    "Trichy",
    "Udaipur",
    "Ujjain",
    "Vaishali",
    "Valley of Flowers",
    "Varanasi",
    "Varkala and Ayurveda",
    "Vijayawada",
    "Vishakhapatnam",
    "Vrindhavan",
    "Warangal",
    "Wayanad",
    "Wayanad Wildlife Sanctuary",
    "Yercaud",
    "Zanskar",
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Marianas Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Virgin Islands",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "Anhui Province",
    "Beijing Municipality",
    "Chongqing Municipality",
    "Fujian Province",
    "Guangdong Province",
    "Gansu Province",
    "Guangxi Zhuang Autonomous Region",
    "Guizhou Province",
    "Henan Province",
    "Hubei Province",
    "Hebei Province",
    "Hainan Province",
    "Hong Kong Special Administrative Region",
    "Heilongjiang Province",
    "Hunan Province",
    "Jilin Province",
    "Jiangsu Province",
    "Jiangxi Province",
    "Liaoning Province",
    "Macau Special Administrative Region",
    "Inner Mongolia Autonomous Region",
    "Ningxia Hui Autonomous Region",
    "Qinghai Province",
    "Sichuan Province",
    "Shandong Province",
    "Shanghai Municipality",
    "Shaanxi Province",
    "Shanxi Province",
    "Tianjin Municipality",
    "Taiwan Province*",
    "Xinjiang Uyghur Autonomous Region",
    "Tibet Autonomous Region",
    "Yunnan Province",
    "Zhejiang Province",
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon"
]