import 'font-awesome/css/font-awesome.min.css';
import './assets/css/app.css';
import './assets/css/inputfloating.scss'
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';
import "react-toastify/dist/ReactToastify.css";
import DashboardPage from './pages/dashboard/DashboardPage';
import TypographyPage from './pages/TypographyPage';
import AdminLoginPage from './pages/auth/Admin-LoginPage';
import CompanyLoginPage from './pages/auth/Company-LoginPage';
import ActManagerLoginPage from './pages/auth/ActManager-LoginPage';
import SignUpPage from './pages/auth/SignUpPage';
import ResetPassword from './pages/auth/ResetPassword';
import ProfilePage from './pages/profile/ProfilePage';
import ChangePasswordPage from './pages/profile/ChangePasswordPage';
import UserPreferencesPage from './pages/profile/UserPreferencesPage';
import VerifyYourAccount from './pages/auth/VerifyYourAccount';
import PostJobsPage from './pages/jobCRUDComponent/PostJobsListPage';
import JobOfferedListPage from './pages/jobOffer/JobOffersList';
import ViewContractsPage from './pages/viewContracts/ViewContractsPage';
import ShortListResumesPage from './pages/scheduledInterviews/ShortListResumesPage';
import HiredCandidatesListPage from './pages/dashboard/HiredCandidatesList';
import InprogressCandidatesListPage from './pages/dashboard/InprogressCandidatesList';
import InterviewdCandidatesListPage from './pages/dashboard/InterviewdCandidatesList';
import NoticeperiodCandidatesListPage from './pages/dashboard/NoticeperiodCandidatesList';
import OnBoardedCandidatesListPage from './pages/dashboard/OnBoardedCandidatesList';
import DealsClosedCandidatesListPage from './pages/dashboard/DealsClosed';
import RejectedCandidatesListPage from './pages/dashboard/RejectedCandidatesList';
import ResignedCandidatesListPage from './pages/dashboard/ResignedCandidatesList';
import JobOfferedPage from './pages/scheduledInterviews/JobOffered';
import ScheduledForInterviewPage from './pages/scheduledInterviews/onboard_candidate';
import ShortListedResumesPage from './pages/scheduledInterviews/ShortListedResumePage'
import AdminDashboardPage from './pages/adminComponents/adminDashboard/AdminDashboardPage'
import AdminCcompaniesListPage from './pages/adminComponents/companies/CompaniesListPage';
import AdminSignUpPage from './pages/auth/AdminSignUpPage';
import AdminAccountManagersListPage from './pages/adminComponents/accountManagers/AccountManagersList';
import AssignedDetailsPage from './pages/adminComponents/assignedDetails/AssignedDetails';
import AddCandidateDetailsPage from './pages/adminComponents/Candidate/AddCandidate';
import CandidatesListPage from './pages/adminComponents/Candidate/CandidatesList';
import CompanyPostJobsListPage from './pages/adminComponents/companies/CompanyPostJobsListPage';
import ActMgrCompaniesListPage from './pages/adminComponents/companies/ActMgrCompaniesListPage';
import TabsViewScheduledInterviewPage from './pages/scheduledInterviews/TabsView_Scheduled_Interview';
import ActiveUsersListCmp from './pages/adminComponents/activeUsersList/ActiveUsersListCmp';
import NotificationsPageAdminPage from './pages/adminComponents/adminDashboard/NotificationsPage';
import NotificationsPage from './pages/dashboard/NotificationsPage';
import HelpUsPage from './pages/auth/Helpus'
import NotFound from './pages/NotFound';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { addTour } from './redux/actions/tour';
import ActMgrOnBoardedCandidatesList from './pages/dashboard/ActMgrOnBoardedCandidatesList';
import ActivateUserListPage from './pages/adminComponents/activeUsersList/ActivateUsersList';
import AdminVerificationPendingCmp from './pages/auth/AdminVerificationPendingCmp';
import RejectedRegCmpList from './pages/adminComponents/activeUsersList/RejectedRegCmpList';
import ResetSuccessTemplate from './pages/auth/ResetSuccessTemplate';
import RaiseTicket from './pages/auth/RaiseTicket';
import TicketSuccessTemplate from './pages/auth/TicketSuccessTemplate';
import QueryTicketsList from './pages/adminComponents/activeUsersList/QueryTicketsList';
import UnsubscribeSuccessTemplate from './pages/auth/UnsubscribeSuccessTemplate';
import EmployeeSignUpPage from './pages/auth/EmployeeSignUpPage';
import EmployeeLoginPage from './pages/auth/Employee-LoginPage';
import EmployeeDashboardPage from './pages/employeeComponents/EmployeeDashboardPage';
import EmployeePostJobsPage from './pages/employeeComponents/EmployeePostJobsPage';
import AllLinksPage from './pages/adminComponents/activeUsersList/AllLinksPage';
import VerticalStepsBar from './pages/employeeComponents/VerticalStepsBar';
import EmployeeSignUpSuccessTemplate from './pages/auth/EmployeeSignupSuccessTemplateCmp';
import EmpJobDetailsViewPage from './pages/employeeComponents/EmpJobDetailsView';
import ActMgrCmpyAppliedJobList from './pages/adminComponents/companies/ActMgrCmpyAppliedJobList';

const App = () => {
    const dispatch = useDispatch();
    const { steps, isOpenTour } = useSelector((state) => state.tourReducer);

    const onClickTourClose = () => {
        dispatch(addTour({ isOpenTour: false }))
    }

    return (
        <Router>
            <Routes>
                <Route exact path='/dashboard' element={<DashboardPage />} />
                <Route exact path='/employee-dashboard' element={<EmployeeDashboardPage />} />
                <Route exact path='/admin-signup/665e00c8ui908bee10e638df' element={<AdminSignUpPage />} />
                <Route exact path='/employee-signup' element={<EmployeeSignUpPage />} />
                <Route exact path='/signup' element={<SignUpPage />} />
                <Route exact path='/verify-your-account/:id' element={<VerifyYourAccount />} />
                <Route exact path='/verify-your-account' element={<VerifyYourAccount />} />
                <Route exact path='/login' element={<CompanyLoginPage />} />
                <Route exact path='/act-manager-login' element={<ActManagerLoginPage />} />
                <Route exact path='/admin-login/665e00c8ae3f8bee10e638df' element={<AdminLoginPage />} />
                <Route exact path='/reset-password' element={<ResetPassword />} />
                <Route exact path='/profile' element={<ProfilePage />} />
                <Route exact path='/change-password' element={<ChangePasswordPage />} />
                <Route exact path='/preferences' element={<UserPreferencesPage />} />
                <Route exact path='/typography' element={<TypographyPage />} />
                <Route exact path='/jobs-list' element={<PostJobsPage />} />
                <Route exact path='/job-offers-list' element={<JobOfferedListPage />} />
                <Route exact path='/view-contracts' element={<ViewContractsPage />} />
                        
                <Route exact path='/employees-applied-list/:id' element={<ActMgrCmpyAppliedJobList />} />

                <Route exact path='/employee-jobs-list' element={<EmployeePostJobsPage />} />
                <Route exact path='/emp-jobs-details' element={<EmpJobDetailsViewPage />} />
                <Route exact path='/Employee-scheduled-interviews' element={<ViewContractsPage />} />
                <Route exact path='/Employee-interview-feedback' element={<ViewContractsPage />} />
                <Route exact path='/Employee-onboarding' element={<ViewContractsPage />} />
                <Route exact path='/Employee-time-sheet' element={<ViewContractsPage />} />
                <Route exact path='/Employee-leave-details' element={<ViewContractsPage />} />

                <Route exact path='/employee-login' element={<EmployeeLoginPage />} />
                {/* <Route exact path='/short-list-resumes' element={<ShortListResumesPage />} /> */}
                <Route exact path='/short-list-resumes' element={<TabsViewScheduledInterviewPage />} />
                <Route exact path='/hired-candidates-list' element={<HiredCandidatesListPage />} />
                <Route exact path='/interviewed-candidates-list' element={<InterviewdCandidatesListPage />} />
                <Route exact path='/reject-candidates-list' element={<RejectedCandidatesListPage />} />
                <Route exact path='/resigned-candidates-list' element={<ResignedCandidatesListPage />} />
                <Route exact path='/onboard-candidates-list' element={<OnBoardedCandidatesListPage />} />
                <Route exact path='/act-mgr-onboard-candidates-list' element={<ActMgrOnBoardedCandidatesList />} />
                <Route exact path='/deals-closed-list' element={<DealsClosedCandidatesListPage />} />
                <Route exact path='/inprogress-candidates-list' element={<InprogressCandidatesListPage />} />
                <Route exact path='/noticeperiod-candidates-list' element={<NoticeperiodCandidatesListPage />} />
                <Route exact path='/short-listed-resume' element={<ShortListedResumesPage />} />
                {/* <Route exact path='/onboarded-candidate/:query_candidate_id' element={<ScheduledForInterviewPage />} /> */}
                <Route exact path='/onboarded-candidate/:query_candidate_id' element={<TabsViewScheduledInterviewPage />} />
                <Route exact path='/onboarded-candidate' element={<ScheduledForInterviewPage />} />
                <Route exact path='/job-offered' element={<JobOfferedPage />} />
                <Route exact path='/admin-dashboard' element={<AdminDashboardPage />} />
                <Route exact path='/companies-list' element={<AdminCcompaniesListPage />} />
                <Route exact path='/account-managers-list' element={<AdminAccountManagersListPage />} />
                <Route exact path='/assigned-details' element={<AssignedDetailsPage />} />
                <Route exact path='/status-position' element={<AssignedDetailsPage />} />
                <Route exact path='/candidates-list' element={<CandidatesListPage />} />
                <Route exact path='/add-candidate-details' element={<AddCandidateDetailsPage />} />
                <Route exact path='/active-users-list' element={<ActiveUsersListCmp />} />
                <Route exact path='/companyjoblist/:id' element={<CompanyPostJobsListPage />} />
                <Route exact path='/help-us' element={<HelpUsPage />} />
                <Route exact path='/act-manager-cmpy-list' element={<ActMgrCompaniesListPage />} />
                <Route exact path='/notifications-list' element={<NotificationsPage />} />
                <Route exact path='/admin-notifications-list' element={<NotificationsPageAdminPage />} />
                <Route exact path='/activate-users-list' element={<ActivateUserListPage />} />
                <Route exact path='/admin-verification-pending-page' element={<AdminVerificationPendingCmp />} />
                <Route exact path='/raise-ticket-query' element={<RaiseTicket />} />
                <Route exact path='/rest-success-template' element={<ResetSuccessTemplate />} />
                <Route exact path='/ticket-success-template' element={<TicketSuccessTemplate />} />
                <Route exact path='/rejected-companies-list' element={<RejectedRegCmpList />} />
                <Route exact path='/resolved-tickets-list' element={<QueryTicketsList />} />
                <Route exact path='/unsubscribe' element={<UnsubscribeSuccessTemplate/>} />
                <Route exact path='/Employee-steps-bar' element={<VerticalStepsBar/>} />
                <Route exact path='/employee-signup-success' element={<EmployeeSignUpSuccessTemplate/>} />
                <Route exact path='/not-found' element={<NotFound />} />
                <Route exact path='' element={<Navigate to="/login" replace />} />
                <Route path="*" element={<Navigate to="/not-found" replace />} />
                <Route exact path='/alllinks' element={<AllLinksPage/>} />
            </Routes>
            <ToastContainer position="bottom-right" />
            <Tour
                steps={steps}
                isOpen={isOpenTour}
                onRequestClose={() => { onClickTourClose() }}
            // afterOpen={disableBody}
            // beforeClose={enableBody} 
            // closeWithMask={true}
            />
        </Router>
    )
}

export default App;

const steps = [
    {
        selector: '.my-1-step',
        content: 'This is my awesome login feature!',
    },
    {
        selector: '.my-2-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-3-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-4-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-5-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-6-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-7-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-8-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-9-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-10-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-11-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-12-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-13-step',
        content: 'This another awesome logout feature!',
    },
    {
        selector: '.my-14-step',
        content: 'This another awesome logout feature!',
    },
]
