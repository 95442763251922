import React, { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import storePersist from '../../Utils/storePersist'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import "../../assets/css/login.css"
import authLayout from "../../hoc/authLayout";
import { postApi } from '../../services/apiCommonService'
import { forgot_password_api_url, reset_password_details } from '../../services/endPointsConstant'
import { isEmpty } from '../../Utils/utils'

const ResetPassword = () => {
  const [formData, setFormData] = useState(false);
  let navigate = useNavigate();

  const {
    register,
    formState: { errors, isDirty, isSubmitting, touched },
    handleSubmit
  } = useForm({
    mode: "onBlur",
    shouldFocusError: false
  });

  useEffect(() => {
    if (!isEmpty(errors)) {
      toast.error("Fill all manditatory fields")
      return
    }
  }, [errors])

  const onSubmit = async (data) => {
    // const { user, email, role, id } = storePersist.get('userData')
    // storePersist.set('userData', {user, email, role, id})

    setFormData(data);
    const apiReqBody = {
      "emailId": data?.email
    }
    const apiResponse = await postApi(reset_password_details, apiReqBody)
    if (apiResponse?.data?.Data) {
      toast.success("Verification email is sent successfully to your registered email id !!")
      // toast.success(`OTP : ${apiResponse?.data?.Data?.otp}`)
      // navigate(`/verify-your-account/${apiResponse?.data?.Data?.userid}`);
      navigate(`/rest-success-template`)
    } else {
      toast.error(apiResponse?.data?.message + '!!' || "Api Error Response !!")
    }
  };

  return (
    <div className="form-holder login-form vh-100 rounded-0">
      <div className="d-flex justify-content-center align-items-center my-4 mt-5">
        <img src={require('./../../assets/images/login_page_logo.svg').default} alt="Alt content" />
      </div>
      {!isMobile ?
        <div className="justify-content-center align-items-center my-4 mt-5">
          <h1 className="text-center fw-normal mb-1 mt-3">Good Morning !</h1>
          <h1 className="text-center fw-normal mb-5 mt-3 me-3">You can reset your password here.</h1>
        </div> :
        <div className="justify-content-center align-items-center my-4 mt-4 mb-5">
          <div className="col-md-5">
            <h1 className="text-center fw-normal mb-1 mt-4">Good Morning !</h1>
          </div>
          <div className="col-md-5">
            <h1 className="text-center fw-normal mb-5 mt-3">You can reset your password here.</h1>
          </div>
        </div>
      }

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`form-outline did-floating-label-content ${errors.email && 'did-error-input'}`}>
          <input
            type="email"
            name="email"
            placeholder=""
            {...register("email", {
              required: "Field is required",
              minLength: {
                value: 2,
                message: "Min length is 2"
              },
              pattern: {
                value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                message: "Invalid email address, It should be example@google.com"
              }
            })}
            className="did-floating-input"
          />
          <label className="did-floating-label requiredTrue">{'Enter username'}</label>
          {/* {errors.email && <p className="error">{errors.email.message}</p>} */}
        </div>

        <button type="submit" disabled={isSubmitting} className="w-100 rounded-2">
          {isSubmitting && <span>Submitting</span>}
          {!isSubmitting && <span>Reset Password</span>}
        </button>

        <div className="d-flex justify-content-center align-items-center mb-5">
          <span className="d-flex text-center text-lg-start justify-content-center align-items-center">
            {!isMobile ?
              <p className="small fw-bold">Remember your password ?
                <Link to="/login" className="fontColor me-2"><strong> Login</strong></Link>
              </p> :
              <>
                <p className="small fw-bold">Remember your password ? </p>
                <Link to="/login" className="fontColor me-2"><strong> Login</strong></Link>
              </>
            }
          </span>
        </div>
      </form>
    </div>
  );
}

export default authLayout(ResetPassword);