import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../hoc/adminLayout";
import Pagination from '../../common/pagination/Pagination';
import RangeDatePicker from '../../components/RangeDatePicker';
import data from '../../common/pagination/examples/data/mock-data.json';
import { returnArryOfObjects, randStr } from '../../Utils/utils'
import { typesOfEmployeeContractList } from '../../Utils/masterData/dropDownJson'
import { emptyJobList, PageSize } from '../../Utils/masterData/staticContent'
import { getAllApi, deleteApiWithBody, postApi } from '../../services/apiCommonService'
import { get_job_list_api_url, get_jobs_by_company_id, all_get_match_employee_jobs_api_url, get_saved_jobs_list, apply_job_employee, get_all_applied_list } from '../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import { addUpdateEmployeeJobs } from "../../redux/actions/employeeJobs";
import CustomSingleSelect from '../../components/CustomSelectComponent';
import storePersist from '../../Utils/storePersist';
import Chipcomponent from '../../components/ChipComponent';

const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
};

const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
    if (user) {
        return `${user[0]?.toUpperCase()}${user[user?.length - 1]?.toUpperCase()}`
    }
}

const setValue = (functionFor) => {
    return (e) => {
        const value = parseInt(e.target.value);
        functionFor(value);
    }
};

const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
}

const EmployeePostJobsPage = (props) => {
    const location = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [jobList, setJobList] = useState([]);
    const [oldJobList, setOldJobList] = useState([]);
    const [savedJobList, setSavedJobList] = useState([]);
    const [savedOldJobList, setSavedOldJobList] = useState([]);
    const [appliedJobsList, setAppliedJobsList] = useState([]);
    const [appliedOldJobsList, setAppliedOldJobsList] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedJobsObjects, setSelectedJobsObjects] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [viewSelectedJobDetails, setViewSelectedJobDetails] = useState('')
    const [modalPopUpCancel, setModalPpopUpCancel] = useState(false);
    const [candidateModalPopUpCancel, setCandidateModalPopUpCancel] = useState(false);
    const [pageSize, setPageSize] = useState(PageSize);
    const { role, user, employeeDataAll } = storePersist.get('userData')
    const { _id } = employeeDataAll && employeeDataAll || {}

    const { total_completed } = useSelector((state) => state.employeeStepsBarReducer)

    const [range, setRange] = React.useState(10);
    const [saturation, setSaturation] = React.useState(50);
    const [lightness, setLightness] = React.useState(50);
    const [theme, setTheme] = React.useState('Light');

    const saturationRange = getRange(saturation, range);
    const lightnessRange = getRange(lightness, range);

    const userName = `${'Pandu'} ${'Pandu'}`;
    const color = generateColorHsl(userName, saturationRange, lightnessRange);
    const initials = getInitials('Pandu');

    useEffect(() => {
        void getJobListApi()
        void getSavedJobListApi()
        void getAllAppliedJobList()
    }, []);

    const getJobListApi = async () => {
        dispatch(addLoader());
        const apiReqBody = { employeeId: _id }
        const apiResponse = await postApi(all_get_match_employee_jobs_api_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData?.filter((item) => item?.finalScore !== 0)
            setJobList(apiResponseData)
            setOldJobList(apiResponseData)
            // toast.success("Retrived all Job List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const getSavedJobListApi = async () => {
        dispatch(addLoader());
        const apiReqBody = { employeeId: _id }
        const apiResponse = await postApi(get_saved_jobs_list, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            const newApiResponseData = apiResponseData.map((item) => {
                item = { ...item, ...item.jobDetails[0] }
                return item
            })
            setSavedJobList(newApiResponseData)
            setSavedOldJobList(newApiResponseData)
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const applyJobApiCall = async (itemVal) => {
        dispatch(addLoader());
        const apiReqBody = { employeeId: _id, jobId : itemVal?._id }
        const apiResponse = await postApi(apply_job_employee, apiReqBody)
        if (apiResponse?.data?.Data) {
            toast.success("Job Applied Successfully !!")
            getAllAppliedJobList()
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const getAllAppliedJobList = async (itemVal) => {
        dispatch(addLoader());
        const apiReqBody = { employeeId: _id }
        const apiResponse = await postApi(get_all_applied_list, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            const newApiResponseData = apiResponseData.map((item) => {
                item = { ...item, ...item.jobDetails[0] }
                return item
            })
            setAppliedJobsList(newApiResponseData)
            setAppliedOldJobsList(newApiResponseData)
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }
    
    return <>
        <div className="row d-flex justify-content-center align-items-start gx-3 margintop">
            {/* Left side card */}
            <div className="col-md-8 width_col_69">
                {/* card-1 */}
                <div className="my-3 mb-1 p-3 pt-1 ps-1 mt-1 rounded rounded_20 border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="pb-2 mb-0 font_color_imp fw-semibold">Remote full-time jobs</h6>
                    </div>
                    <div>
                        <div className="row bd-example">
                            <div className="col-xl-12 col-sm-6 ps-3">
                                <span className="font_color_imp font_size_16">Actively hiring now / Fast apply hands on support from remote72 team</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* card-2 */}
                <div className="my-1 p-1 mt-1 ms-2 me-2 rounded_20">
                    {/* <div className="d-flex justify-content-between align-items-center">
                        <h6 className="pb-2 mb-2 font_color_imp fw-semibold">Important Notifications :</h6>
                    </div> */}
                    <div>
                        {/* height_notification_table */}
                        <div className="row bd-example">
                            <Tabs
                                defaultActiveKey="Browse Jobs"
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                            >
                                <Tab eventKey="Browse Jobs" title={`Browse Jobs (${oldJobList?.length})`}>
                                    <div className="d-flex text-muted height_job_post_table_employee">
                                        <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                            {oldJobList?.map((item) => <div className="my-1 p-3 mb-3 bg-body rounded shadow-sm rounded_20">
                                                <div className='text-center my-2 p-0 col-md-12 d-flex justify-content-start align-items-start'>
                                                    <div className='col-md-1_01'>
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{getInitials(item?.CompanyDetails[0]?.companyName)}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-7 font_size_13'>
                                                        <div className="d-flex justify-content-start align-items-start">
                                                            <h6 className="font_color_imp font_size_17 ps-3 fw-bold">{item?.job_type}</h6>
                                                            <span className="d-flex justify-content-start align-items-center ps-5 pe-1">
                                                                <img className="img_w_h_20" alt="Alt content" src={require('../../assets/images/bolt_rect_svg.svg').default} />
                                                                <span className='font_color_ornage ps-2'>{'Actively hiring'}</span>
                                                            </span>
                                                        </div>
                                                        <div className='text-start ms-3 pb-2 col-md-12 pt-2 d-flex justify-content-start align-items-start'>
                                                            <div className='col-md-5'>
                                                                {item?.skill?.map((item) => item?.language)?.map((itemVal) => <span className="badge rounded-pill custom_chip_css">{itemVal}</span>)}
                                                            </div>
                                                            <div className='col-md-7'>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5 p-3 pt-0 '>
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <span className="pt-1 ps-3 pe-5 font_color_imp font_size_13">
                                                                Overlap with Eastern time USA  & Canada working hours
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='text-start ps-4 ms-2 pb-2 d-flex justify-content-end align-items-center gap-2'>
                                                    <button
                                                        type="button"
                                                        className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                                        style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                                        onClick={() => {
                                                            dispatch(addUpdateEmployeeJobs(item))
                                                            navigate('/emp-jobs-details') //applicationStatus
                                                        }}
                                                    >
                                                        {'View Full Details'}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={`btn btn-primary font_size_12 pt-1 pb-2 fw-medium`}
                                                        onClick={() => { applyJobApiCall(item) }}
                                                    >
                                                        {'Apply for this Role'}
                                                    </button>
                                                </div>
                                            </div>
                                            )}
                                            {oldJobList?.length === 0 &&
                                                <>
                                                    <div className='text-center my-5 p-0 col-md-12 d-flex justify-content-center align-items-center'>
                                                        <div className='col-md-12'>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                No Matched Jobs Found
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </PerfectScrollbar>
                                    </div>
                                </Tab>
                                <Tab eventKey="Saved Jobs" title={`Saved Jobs (${savedOldJobList?.length})`}>
                                    <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                        {savedOldJobList?.map((item) => <div className="my-1 p-3 mb-3 bg-body rounded shadow-sm rounded_20">
                                            <div className='text-center my-2 p-0 col-md-12 d-flex justify-content-start align-items-start'>
                                                <div className='col-md-1_01'>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{getInitials(item?.CompanyDetails[0]?.companyName)}</div>
                                                    </div>
                                                </div>
                                                <div className='col-md-7 font_size_13'>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <h6 className="font_color_imp font_size_17 ps-3 fw-bold">{item?.job_type}</h6>
                                                        <span className="d-flex justify-content-start align-items-center ps-5 pe-1">
                                                            <img className="img_w_h_20" alt="Alt content" src={require('../../assets/images/bolt_rect_svg.svg').default} />
                                                            <span className='font_color_ornage ps-2'>{'Actively hiring'}</span>
                                                        </span>
                                                    </div>
                                                    <div className='text-start ms-3 pb-2 col-md-6 pt-2'>
                                                        {item?.skill?.map((item) => item?.language)?.map((itemVal) => <span className="badge rounded-pill custom_chip_css">{itemVal}</span>)}
                                                    </div>
                                                </div>
                                                <div className='col-md-5 p-3 pt-0 '>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <span className="pt-1 ps-3 pe-5 font_color_imp font_size_13">
                                                            Overlap with Eastern time USA  & Canada working hours
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='text-start ps-4 ms-2 pb-2 d-flex justify-content-end align-items-center gap-2'>
                                                <button
                                                    type="button"
                                                    className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                                    style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                                    onClick={() => { 
                                                        dispatch(addUpdateEmployeeJobs(item))
                                                        navigate('/emp-jobs-details') //applicationStatus
                                                    }}
                                                >
                                                    {'View Full Details'}
                                                </button>
                                                {/* <button
                                                    type="button"
                                                    className={`btn btn-primary font_size_12 pt-1 pb-2 fw-medium`}
                                                    onClick={() => { }}
                                                >
                                                    {'Apply for this Role'}
                                                </button> */}
                                            </div>
                                        </div>
                                        )}
                                        {savedOldJobList?.length === 0 &&
                                            <>
                                                <div className='text-center my-5 p-0 col-md-12 d-flex justify-content-center align-items-center'>
                                                    <div className='col-md-12'>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            No Saved Jobs Found
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </PerfectScrollbar>
                                </Tab>
                                <Tab eventKey="Applied" title={`Applied Jobs (${appliedOldJobsList?.length})`}>
                                    <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                        {appliedOldJobsList?.map((item) => <div className="my-1 p-3 mb-3 bg-body rounded shadow-sm rounded_20">
                                            <div className='text-center my-2 p-0 col-md-12 d-flex justify-content-start align-items-start'>
                                                <div className='col-md-1_01'>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{getInitials(item?.CompanyDetails[0]?.companyName)}</div>
                                                    </div>
                                                </div>
                                                <div className='col-md-7 font_size_13'>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <h6 className="font_color_imp font_size_17 ps-3 fw-bold">{item?.job_type}</h6>
                                                        <span className="d-flex justify-content-start align-items-center ps-5 pe-1">
                                                            <img className="img_w_h_20" alt="Alt content" src={require('../../assets/images/bolt_rect_svg.svg').default} />
                                                            <span className='font_color_ornage ps-2'>{'Actively hiring'}</span>
                                                        </span>
                                                    </div>
                                                    <div className='text-start ms-3 pb-2 col-md-6 pt-2'>
                                                        {item?.skill?.map((item) => item?.language)?.map((itemVal) => <span className="badge rounded-pill custom_chip_css">{itemVal}</span>)}
                                                    </div>
                                                </div>
                                                <div className='col-md-5 p-3 pt-0 '>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <span className="pt-1 ps-3 pe-5 font_color_imp font_size_13">
                                                            Overlap with Eastern time USA  & Canada working hours
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='text-start ps-4 ms-2 pb-2 d-flex justify-content-end align-items-center gap-2'>
                                                <button
                                                    type="button"
                                                    className={`btn btn-outline-primary font_size_11 fw-medium min_width btn_border`}
                                                    style={{ fontSize: '11px !important', padding: '1px 10px !important' }}
                                                    onClick={() => {
                                                        dispatch(addUpdateEmployeeJobs(item))
                                                        navigate('/emp-jobs-details') //applicationStatus
                                                     }}
                                                >
                                                    {'View Full Details'}
                                                </button>
                                                {/* <button
                                                    type="button"
                                                    className={`btn btn-primary font_size_12 pt-1 pb-2 fw-medium`}
                                                    onClick={() => { }}
                                                >
                                                    {'Apply for this Role'}
                                                </button> */}
                                            </div>
                                        </div>
                                        )}
                                    </PerfectScrollbar>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

            {/* right side cards */}
            <div className="col-md-4 width_col_30">
                {/* card 0 */}
                <div className="my-3 p-3 pt-2 mt-3 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom ">
                        <span className="pb-1 mb-0 font_color_imp font_size_14 fw-bolder">Today's Scheduled Interview</span>
                    </div>
                    <div>
                        <div className="row bd-example height_scheduled_int_table_resource_all font_size_14">
                            <div className='text-center my-1 me-1 p-0 mx-3'>
                                <div className="d-flex justify-content-start align-items-center">
                                    <img className="w-10 whitecircle rounded-circle" alt="Alt content" src={require('../../assets/images/user-profile-icon.svg').default} />
                                    <h6 className="pt-1 ps-3 font_color_imp font_size_13">TCS</h6>
                                </div>
                                <div className='text-start ps-4 ms-2 pb-2'>
                                    <div className='ps-1 font_size_13'>Inter Date and Time : </div>
                                    <div className='ps-1 font_size_13'>06-Aug-2023, Friday 10.00 AM to 11.00 AM</div>
                                </div>
                                <div className='border-bottom w-90 mt-1'></div>
                                {/* whitecircle rounded-circle */}
                                <div className='text-center my-1 me-4 pt-1'>
                                    <button
                                        type="button"
                                        className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                        onClick={() => { }}
                                    >
                                        {'Confirm Interview'}
                                    </button>
                                    <div className='font_size_13 font_color_ornage text-center pt-2'>Note: Date cannot be changed once Confimed</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* card 1 */}
                <div className="my-3 p-3 pt-2 mt-3 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom ">
                        <span className="pb-1 mb-0 font_color_imp font_size_14 fw-bolder">Coding Test</span>
                    </div>
                    <div>
                        <div className="row bd-example height_scheduled_int_table_resource_all font_size_14">
                            <div className='text-center my-1 me-1 p-0 mx-3'>
                                <div>
                                    {/* whitecircle rounded-circle */}
                                    <img className="w-36 h-100" alt="Alt content" src={require('../../assets/images/coding_test_1.svg').default} />
                                    <div className='text-center my-1 me-4 pt-2'>
                                        <button
                                            type="button"
                                            className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                            onClick={() => { }}
                                        >
                                            {'Coding Test'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* card 2 */}
                <div className="my-3 p-3 pt-2 mt-3 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom ">
                        <span className="pb-1 mb-0 font_color_imp font_size_14 fw-bolder">Communication Test</span>
                    </div>
                    <div>
                        <div className="row bd-example height_scheduled_int_table_resource_all font_size_14">
                            <div className='text-center my-1 me-1 p-0 mx-3'>
                                <div>
                                    <img className="w-40 h-100" alt="Alt content" src={require('../../assets/images/comm_test_2.svg').default} />
                                    <div className='text-center my-1 me-3 pt-2'>
                                        <button
                                            type="button"
                                            className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                            onClick={() => { }}
                                        >
                                            {'Communication Test'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* card 3 */}
                <div className="my-3 p-3 mt-2 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom">
                        <h6 className="pb-2 mb-0 font_color_imp font_size_14 fw-bolder">Your Profile Status</h6>
                    </div>
                    <div>
                        {/* height_scheduled_int_table */}
                        <div className="row bd-example height_scheduled_int_table_y_p_s">
                            <div className="">
                                <progress max="100" value={total_completed}></progress>
                                <div className='ps-2 font_color_ornage text-center'>{`${total_completed} completed`} </div>
                            </div>
                            <div className='text-center my-1 me-1 p-2'>
                                <button
                                    type="button"
                                    className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width btn_height_36`}
                                    onClick={() => {
                                        navigate('/Employee-steps-bar');
                                    }}
                                >
                                    {'Update Profile'}
                                </button>
                            </div>
                            <div className='border-bottom mb-1'></div>
                        </div>
                    </div>
                    <div className='font_size_14 text-center'>{'Completing profile will make your chances better to get more interviews'}</div>
                </div>
                {/* card 4 */}
                <div className="my-3 p-3 mt-3 bg-body shadow-sm rounded_20">
                    <div className="d-flex justify-content-between align-items-center border-bottom ">
                        <h6 className="pb-2 mb-0 font_color_imp font_size_14 fw-bolder">Resources</h6>
                    </div>
                    <div>
                        <div className="row bd-example height_scheduled_int_table_resource font_size_14">
                            <div className='text-center my-1 me-1 p-2 mx-3'>
                                <div className="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img className="" alt="Alt content" src={require('../../assets/images/take_test.svg').default} />
                                    </span>
                                    <span className='mx-2'>Take a screening test</span>
                                </div>
                                <div className="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img className="" alt="Alt content" src={require('../../assets/images/question-answer-line-1 1.svg').default} />
                                    </span>
                                    <span className='mx-2'>Interview Q&As.</span>
                                </div>
                                <div className="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img className="" alt="Alt content" src={require('../../assets/images/lasttest.svg').default} />
                                    </span>
                                    <span className='mx-2'>Resume Templated & Tips</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default adminLayout(EmployeePostJobsPage);