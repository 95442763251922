import React from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const authLayout = (ChildComponent) => {
    const AuthLayout = (props) => {
        return <>
            <section className="">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        {!isMobile &&
                            <>
                                <div className="col-lg-8 vh-100 login_bg_color d-flex justify-content-center align-items-center">
                                    {(window.location.pathname === '/signup' || window.location.pathname === '/admin-signup/665e00c8ui908bee10e638df') &&
                                        <div className="position-absolute my-4 top-0">
                                            <img src={require('./../assets/images/logo_white_svg.svg').default} alt="Alt content" />
                                        </div>
                                    }
                                    <div
                                        className={`${(window.location.pathname === '/signup' || window.location.pathname === '/admin-signup/665e00c8ui908bee10e638df')
                                            ? 'col-md-4 text-center fw-medium font_size_24 white_font_color child-content lh-sm mt-5'
                                            :
                                            'col-md-4 text-center fw-medium font_size_24 white_font_color child-content lh-sm'}`}
                                    >
                                        Welcome to manage all your hiring process with right developers
                                    </div>
                                    <div
                                        className={`${(window.location.pathname === '/signup' || window.location.pathname === '/admin-signup/665e00c8ui908bee10e638df')
                                            ? 'container-fluid pt-5 mt-8'
                                            :
                                            'container-fluid pt-5'}`}
                                    >
                                        <div className="row">
                                            <div className="col-xs-12 align-middle">
                                                <div className="col-xs-6 login_remote_work_color p-3 m-5 ms-0 rounded_20">
                                                    <span className="fw-medium font_size_24 login_remote_work_font_color d-block text-center pb-4">Remote work</span>
                                                    <img
                                                        src={require('./../assets/images/remote_wrk_img.svg').default}
                                                        alt="remoteworkimage"
                                                    />
                                                </div>
                                                <div className="col-xs-6">
                                                    <div className="login_interview_schedule p-3 mb-5 rounded_20">
                                                        <span className="fw-medium font_size_24 d-block login_interview_schedule_font_color d-block text-center pb-4">Interview Schedule</span>
                                                        <img
                                                            src={require('./../assets/images/interview_schedule_login.svg').default}
                                                            alt="interview_schedule_login"
                                                        />
                                                    </div>
                                                    <div className="login_easy_management p-3 rounded_20">
                                                        <span className="fw-medium font_size_24 d-block login_easy_management_font_color d-block text-center pb-4">Easy Management</span>
                                                        <img
                                                            src={require('./../assets/images/login_easy_management.svg').default}
                                                            alt="login_easy_management"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="col-lg-4 vh-100 bg-primary">
                            <ChildComponent {...props} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    }
    return AuthLayout;
}

export default authLayout;